import { ArrowBackIos } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import CryptoJS from 'crypto-js';

const SellerIndividual = () => {
  const options = [
    { value: "Aadhar Card", label: "Aadhar Card" },
    { value: "Driving Liscence", label: "Driving Liscence" },
    { value: "Pan Card", label: "Pan Card" },
  ];

  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [info, setInfo] = useState({
    seller_type: "individual"
  });
  const navigate = useNavigate();

  const secretKey = 'SECRET_KEY';

  const encryptMessage = (message) => {
    const ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
    return ciphertext;
  };

  const decryptMessage = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalMessage = bytes.toString(CryptoJS.enc.Utf8);
    return originalMessage;
  };

  // Update info when selectedOption changes
  useEffect(() => {
    if (selectedOption) {
      setInfo({
        ...info,
        government_id: {
          ...info.government_id,
          id_type: selectedOption.value,
        }
      });
    }
  }, [selectedOption]);

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("No file provided");
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  // Function to handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const base64Data = await fileToBase64(file);
        setUploadedDocument(file);
        setInfo({
          ...info,
          government_id: {
            ...info.government_id,
            id_image: base64Data,
          }
        });
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  return (
    <div>
      <div className="px-4.5 mt-7 xl:px-16 xl:mt-10">
        <div className=" border-[1px] border-black px-3 mb-7 xl:border-[1px] xl:px-0 xl:flex xl:items-start xl:mb-10">
          <div className="pt-6 xl:px-8 xl:pt-10 xl:w-[51%]">
            <div className=" flex items-center border-b-[1.5px] border-black pb-3 xl:pb-5">
              <ArrowBackIos color="#000" />
              <h1 className=" text-2xl xl:text-[42px] mediumFont text-black leading-7 p-0 tracking-wide">
                Individual
              </h1>
            </div>
            <div>

              <div>
                <h1 className="text-sm xl:text-base mediumFont text-blue-900 mt-5">
                  Choose an ID
                </h1>
                <div className="mt-1">
                  <Select
                    options={options}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    className="w-full"
                  />
                </div>
              </div>
              <div className="mt-6">
                <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                  Upload Photos
                </h1>
                <label
                  htmlFor="file-upload"
                  className="w-full h-32 outline-none  mt-2 text-center px-2 font-light flex items-center justify-center border-2 border-dashed border-black rounded cursor-pointer"
                >
                  <div className="flex items-center justify-center flex-col">
                    <img
                      src={require("../assets/new design/file_img.png")}
                      alt=""
                    />
                    <h1 className=" text-sm mediumFont text-black">
                      Upload Document
                    </h1>
                    <p>File format: pdf, Jpg, Png</p>
                  </div>
                  <input
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={handleFileUpload}
                  />
                </label>
                {uploadedDocument && (
                  <>
                    <div className="mt-2">
                      <p>Uploaded Document: {uploadedDocument.name}</p>
                    </div>
                    {uploadedDocument.type.includes('image') || uploadedDocument.type === 'application/pdf' ? (
                      <embed src={URL.createObjectURL(uploadedDocument)} width="500" height="375" type={uploadedDocument.type} />
                    ) : (
                      <a href={URL.createObjectURL(uploadedDocument)} download={uploadedDocument.name}>
                        Download File
                      </a>
                    )}
                  </>
                )}
              </div>
              <div>
                <h1 className="text-sm xl:text-base mediumFont text-blue-900 mt-5">
                  Enter ID Number (Selected)
                </h1>
                <div className="mt-1">
                  <input
                    type="text"
                    value={info.IdNumber}
                    onChange={(e) => {
                      setInfo({
                        ...info,
                        government_id: {
                          ...info.government_id,
                          id_number: encryptMessage(e.target.value),
                        }
                      })
                    }}
                    className="w-full rounded border-[1px] border-black mt-1 xl:mt-1.5 py-1.5"
                  />
                </div>
              </div>
              <div className="mt-5 mb-10">
                <button
                  onClick={() => navigate("/seller/individual/address", { state: { data: info } })}
                  className=" w-full text-[14px] xl:text-base py-3 px-10 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumFont"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <div className="hidden xl:flex">
            <img
              src={require("../assets/new design/side_img.png")}
              alt=""
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerIndividual;

import React from "react";
import { useNavigate } from "react-router";
import {
  AccessTimeOutlined,
  ArrowBackIos,
  ArrowForwardIos,
  LocationOnOutlined,
  MailOutline,
  Star,
} from "@mui/icons-material";
import DropDown from "../components/DropDown";
import { useState } from "react";
import PreviewCard from "../components/Seller/PreviewCard";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserDrafts, saveDraft, saveProductDetails } from "../redux/actions/product";
import { useEffect } from "react";
import SaveItemSellingDraft from "../components/Modals/SaveItemSellingDraft";
import { toast } from "react-toastify";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import { Link } from "react-router-dom";

const LocationScreen = () => {
  const navigate = useNavigate();
  const width = 100;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { product } = useSelector((state) => state.product);
  const [info, setInfo] = useState(product);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleAddressSelection = (address) => {
    setSelectedAddress(address);
    setIsOpen(false);
  };

  const handleAutoDetect = async () => {
    if ("geolocation" in navigator) {
      toast.info("Fetching Address Details");
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDzt5uKoU9PIrfldJcdqDjf9X-KJNCrohk`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
            const addressDetails = {};

            const componentMapping = {
              premise: "premise",
              sublocality_level_2: "sublocality_level_2",
              sublocality_level_1: "sublocality_level_1",
              locality: "city",
              administrative_area_level_1: "state",
              postal_code: "pincode"
            };

            for (const component of addressComponents) {
              const componentType = component.types.find(type => componentMapping[type]);
              if (componentType) {
                addressDetails[componentMapping[componentType]] = component.long_name;
              }
            }

            const address = [
              addressDetails.premise,
              addressDetails.sublocality_level_2,
              addressDetails.sublocality_level_1
            ].filter(Boolean).join(", ");

            addressDetails.address_line1 = address;
            delete addressDetails.premise;
            delete addressDetails.sublocality_level_2;
            delete addressDetails.sublocality_level_1;

            console.log(addressDetails);
            const formattedAddress = addressDetails.address_line1 + `, ` + addressDetails.city + `, ` + addressDetails.state;
            const loc = {
              type: "Point",
              coordinates: [latitude, longitude],
              formattedAddress: formattedAddress
            }

            setInfo({ ...info, product_address: addressDetails, loc });
          } else {
            console.log("No address components found.");
            toast.error("Please Fill the Details");
          }
        } catch (error) {
          console.error("Error fetching geolocation data:", error);
        }
      } catch (error) {
        console.error("Error getting location:", error.message);
      }
    } else {
      toast.info("Geolocation is not supported by this browser.");
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const productDetailHandler = () => {
    dispatch(saveProductDetails(info));
    navigate("/sellnow/listing/preview");
  };

  const handleSave = () => {
    setModalOpen(true);
    dispatch(saveProductDetails(info));
  }

  const handleSaveDraft = () => {
    // dispatch save Products
    dispatch(saveDraft(product));
    dispatch(getAllUserDrafts());
    navigate("/sellnow/drafts");

  }

  const addresses = ["Address 1", "Address 2", "Address 3"];

  // to change latitude longitude to city, but should enable billing
  // //changing latitude and longitude to city name

  return (
    <>
      {user && user.role !== "customer_unverified" ?
        <div>
          <div className="px-4.5 mt-7 md:px-[4vw] md:mt-10">
            <div className=" border-[1px] h-[80vh] border-black pl-3 max-[768px]:pr-3 mb-7 xl:border-[2px] xl:px-0 lg:flex md:items-start md:mb-10 overflow-hidden">
              <div className=" lg:w-[51%] lg:px-[2vw] lg:border-r-[2px] border-black h-full">
                <div className="flex-col align-top h-full">
                  {/* what are u selling today */}
                  <div className="h-[10%] flex items-start mt-7 gap-x-3 ">
                    <div>
                      <img
                        src={require("../assets/new design/Vector.png")}
                        alt=""
                        className="w-9 xl:w-12"
                      />
                    </div>
                    <h1 className=" text-2xl xl:text-4xl mediumFont text-black leading-7 p-0">
                      What are you selling today?
                    </h1>
                  </div>
                  {/* actual loaction */}
                  <div className="h-[70%] overflow-y-auto no-scrollbar md:pr-7">
                    <div className="mt-6 border-b-[1px] border-black pb-2.5">
                      <h1 className=" text-[21px] xl:text-2xl mediumFont text-black">
                        Location & Shipping
                      </h1>
                    </div>
                    <div className="mt-5">
                      {/* location */}
                      <div className="w-full flex flex-col space-y-2 ">
                        {" "}
                        <div className="w-full text-black text-[16px] boldFont">
                          Location
                        </div>
                        <form className="w-full flex">
                          <input
                            type="text"
                            placeholder="Enter location"
                            className="md:w-3/4 w-full focus:outline-none border-[1px] border-black rounded-md md:rounded-r-none py-2 px-4"
                          />
                          <button
                            type="button"
                            onClick={handleAutoDetect}
                            className="bg-[#FFDC25] hidden md:flex text-[16px] mediumFont w-1/4 text-black justify-center py-2 rounded-md rounded-l-none border-l-[0px] border-[1px] border-black"
                          >
                            Auto Detect
                          </button>
                        </form>
                        <div className="flex items-center space-x-2">
                          <input type="checkbox" className="border-black h-4 w-4" />
                          <span className="text-black text-[16px] mediumFont">
                            Use StakeAtlas Shipping
                          </span>
                        </div>
                      </div>
                      {/* select from saved address */}
                      <div className="w-full mt-2">
                        <h2 className="text-[16px] boldFont mb-2">Save Address</h2>
                        <div className="border border-black rounded">
                          <div
                            className={`border-[1px] rounded-md px-4 py-2 cursor-pointer flex justify-between items-center ${selectedAddress ? "text-black" : "text-gray-400"
                              }`}
                            onClick={toggleAccordion}
                          >
                            <button>
                              {selectedAddress
                                ? selectedAddress
                                : "Select Address You Have Saved"}
                            </button>
                            {isOpen ? (
                              <RiArrowUpSLine className="ml-2" />
                            ) : (
                              <RiArrowDownSLine className="ml-2" />
                            )}
                          </div>
                          {isOpen && (
                            <div className="px-4 py-2">
                              <ul>
                                {addresses.map((address) => (
                                  <li
                                    key={address}
                                    className={`mb-2 cursor-pointer ${selectedAddress === address
                                      ? "text-black"
                                      : "text-gray-500"
                                      }`}
                                    onClick={() => handleAddressSelection(address)}
                                    onClick={toggleAccordion}
                                  >
                                    {address}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* address */}
                      <div className="w-full mt-2">
                        <div className="py-2">
                          <h2 className="mb-2 text-[16px] boldFont">Address </h2>
                          <input
                            type="text"
                            id="address"
                            onChange={(e) =>
                              setInfo((prevInfo) => ({
                                ...prevInfo,
                                product_address: {
                                  ...prevInfo.product_address,
                                  address_line1: e.target.value,
                                },
                              }))
                            }
                            value={info?.product_address?.address_line1}
                            className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                            placeholder="Select or enter your address"
                          />
                        </div>
                      </div>
                      {/* landmark */}
                      <div className="w-full mt-2 flex md:justify-between md:space-x-6 flex-col md:flex-row ">
                        <div className="py-2 w-full md:w-[60%]">
                          <h2 className="mb-2 text-[16px] boldFont">Landmarks </h2>
                          <input
                            type="text"
                            id="landmarks"
                            onChange={(e) =>
                              setInfo((prevInfo) => ({
                                ...prevInfo,
                                product_address: {
                                  ...prevInfo.product_address,
                                  landmarks: e.target.value,
                                },
                              }))
                            }
                            className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                            placeholder="Enter Landkmarks"
                          />
                        </div>
                        <div className="py-2 w-full md:w-[40%]">
                          <h2 className="mb-2 text-[16px] boldFont">State </h2>
                          <input
                            type="text"
                            id="state"
                            onChange={(e) =>
                              setInfo((prevInfo) => ({
                                ...prevInfo,
                                product_address: {
                                  ...prevInfo.product_address,
                                  state: e.target.value,
                                },
                              }))
                            }
                            value={info?.product_address?.state}
                            className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                            placeholder="Enter State"
                          />
                        </div>
                      </div>
                      {/* city */}
                      <div className="w-full mt-2 flex md:justify-between md:space-x-6 flex-col md:flex-row ">
                        <div className="py-2 w-full lg:w-[60%]">
                          <h2 className="mb-2 text-[16px] boldFont">City </h2>
                          <input
                            type="text"
                            id="city"
                            onChange={(e) =>
                              setInfo((prevInfo) => ({
                                ...prevInfo,
                                product_address: {
                                  ...prevInfo.product_address,
                                  city: e.target.value,
                                },
                              }))
                            }
                            value={info?.product_address?.city}
                            className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                            placeholder="Enter City"
                          />
                        </div>
                        <div className="py-2 w-full lg:w-[40%]">
                          <h2 className="mb-2 text-[16px] boldFont">Pincode </h2>
                          <input
                            type="text"
                            id="pincode"
                            onChange={(e) =>
                              setInfo((prevInfo) => ({
                                ...prevInfo,
                                product_address: {
                                  ...prevInfo.product_address,
                                  pincode: e.target.value,
                                },
                              }))
                            }
                            value={info?.product_address?.pincode}
                            className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                            placeholder="Enter Pincode"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* buttons */}
                  <div className="h-[20%] flex items-center justify-between gap-2 ">
                    <button
                      onClick={() => navigate("/sellnow/listing/details")}
                      className="text-[14px] py-3 2md:px-10 min-[444px]:px-10 px-5 rounded-[4px] bg-[#fff] text-black border-[1px] border-sa-border-black mediumFont"
                    >
                      Previous
                    </button>
                    <div className="space-x-2 px-2">
                      <button
                        onClick={() => handleSave()}
                        className="text-[14px] py-3 2md:px-10 min-[444px]:px-10 px-5 rounded-[4px] bg-[#00BEA7] text-black border-[1px] border-sa-border-black mediumFont"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => productDetailHandler()}
                        className="text-[14px] py-3 2md:px-10  min-[444px]:px-10 px-5 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont"
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[49%] overflow-y-auto h-full pb-6 no-scrollbar">
                <PreviewCard info={info} width={width} />
              </div>
            </div>
          </div>
          <SaveItemSellingDraft
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSuccess={() => handleSaveDraft()}
            title="Save Listing As a Draft"
            content="You can comeback anytime to complete this listing."
            confirmText="Save"
            cancelText="Cancel"
          />
        </div>
        :
        user && user.role === "customer_unverified" && user.seller_request !== "created" ?
          <div className=" min-h-screen md:flex hidden">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must register as a seller to Proceed.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Only One listing per customer is allowed, For more please

                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/seller/registration">Register Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div> : user && (user.role === "customer_unverified" || user.role === "customer") && user.seller_request === "created" ?
            <div className="h-[50vh] bg-[#F3CEB4] px-5 py-14 xl:pt-28 xl:h-[60vh]">
              <div className=" border-[1px] border-black bg-white py-12 px-5 xl:w-fit xl:mx-auto xl:px-10 xl:py-12">
                <h1 className="text-3xl mediumFont text-black text-center tracking-wide xl:text-[40px]">
                  Your Request is under Review!
                </h1>
                <p className="text-lg mediumFont text-black text-center mt-2 tracking-wide xl:text-[29px] xl:mt-3">
                  You will be informed, when we are done.
                </p>
                <div className="flex items-center justify-center gap-x-12 mt-5 xl:mt-8">
                  <h1 className="text-[22px] boldFont tracking-wide text-[#737373] xl:text-[25px]">
                    Status
                  </h1>
                  <div className="flex items-center gap-x-1">
                    <AccessTimeOutlined fontSize="small" />
                    <h1 className="text-[22px] boldFont tracking-wide text-sa-icon-green xl:text-[25px]">
                      Pending
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>

      }
    </>
  );
};

export default LocationScreen;

import React from "react";

const ActiveListingAnalytics = () => {
  return (
    <div className="overflow-hidden">
      {/* analytics */}
      {/* dektop */}
      <div className="hidden 2md:flex 2md:flex-col">
        <div className="flex px-[45px] justify-between overflow-x-auto">
          <div className="px-10 flex justify-center items-center border border-black rounded-md bg-white aspect-[2/1]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[32px] text-black boldFont">2.6L</div>
              <div className="text-[18px] mediumFont text-slate-700">views</div>
            </div>
          </div>
          <div className="px-5 flex justify-center items-center border border-black rounded-md bg-white aspect-[2/1]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[32px] text-black boldFont">24</div>
              <div className="text-[18px] mediumFont text-slate-700">
                Added To Wishlist
              </div>
            </div>
          </div>
          <div className="px-10 flex justify-center items-center border border-black rounded-md bg-white aspect-[2/1]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[32px] text-black boldFont">25</div>
              <div className="text-[18px] mediumFont text-slate-700">
                messages
              </div>
            </div>
          </div>
          <div className="px-10 flex justify-center items-center border border-black rounded-md bg-white aspect-[2/1]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[32px] text-black boldFont">12</div>
              <div className="text-[18px] mediumFont text-slate-700">
                offers
              </div>
            </div>
          </div>
          <div className="px-10 flex justify-center items-center border border-black rounded-md bg-white aspect-[2/1]">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[32px] text-black boldFont">10k</div>
              <div className="text-[18px] mediumFont text-slate-700">
                shares
              </div>
            </div>
          </div>
        </div>
        <div>Graph here</div>
      </div>
      {/* mobile */}
    </div>
  );
};

export default ActiveListingAnalytics;

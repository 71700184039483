import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineAttachFile } from "react-icons/md";
import Messaging from "../components/Messaging";
import { useSelector } from "react-redux";

const ActiveListingMessages = () => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const { chats } = useSelector((state) => state.auth.user);

  const handleSelectMessage = (message) => {
    setSelectedMessage(message);
  };

  const handleGoBack = () => {
    setSelectedMessage(null);
  };

  const handleMessageInputChange = (event) => {
    setMessageInput(event.target.value);
  };

  const handleSendMessage = () => {
    console.log("Message sent:", messageInput);
    setMessageInput("");
  };

  const messages = [
    {
      username: "@panwadi",
      message: "200 rupay mei de rahe ho toh batao",
      time: "7 min",
      unread: true,
    },
    {
      username: "@rakeshsupari",
      message: "Bhai kitne mei doge last!",
      time: "2 h",
      unread: false,
    },
    {
      username: "@panwadi",
      message: "200 rupay mei de rahe ho toh batao",
      time: "3 h",
      unread: true,
    },
    {
      username: "@rakeshsupari",
      message: "Bhai kitne mei doge last!",
      time: "1 d",
      unread: false,
    },
  ];

  // Sort messages array with unread messages first
  const sortedMessages = [...messages].sort((a, b) => {
    if (a.unread && !b.unread) {
      return -1;
    } else if (!a.unread && b.unread) {
      return 1;
    }
    return 0;
  });
  const [newMessage, setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([
    { sender: "sender", message: "Hey, how are you?", time: "10:00 AM" },
    {
      sender: "receiver",
      message: "I am good, thanks for asking!",
      time: "10:05 AM",
    },
    {
      sender: "sender",
      message: "What have you been up to lately?",
      time: "10:10 AM",
    },
    {
      sender: "receiver",
      message: "Not much, just working on a project.",
      time: "10:12 AM",
    },
    { sender: "receiver", message: "How about you?", time: "10:15 AM" },
    {
      sender: "sender",
      message: "I have been busy with work too.",
      time: "10:20 AM",
    },
    {
      sender: "sender",
      message: "By the way, have you seen the new movie?",
      time: "10:25 AM",
    },
    {
      sender: "receiver",
      message: "Yes, I watched it last weekend. It was great!",
      time: "10:30 AM",
    },
    {
      sender: "sender",
      message: "Awesome! I will check it out.",
      time: "10:35 AM",
    },
  ]);

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const sendNewMessage = () => {
    if (newMessage.trim() !== "") {
      setChatMessages([
        ...chatMessages,
        {
          sender: "sender",
          message: newMessage.trim(),
          time: getCurrentTime(),
        },
      ]);
      setNewMessage("");
    }
  };

  const getCurrentTime = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    return time;
  };

  return (
    <div>
      {/* Desktop */}
      <div className="w-full border border-black rounded-md overflow-hidden hidden h-full 2md:flex flex-col">
        {selectedMessage ? (
          // <div className=" flex flex-col bg-white h-[490px]">
          //   <div className="flex items-center bg-black text-white py-2">
          //     <FaArrowLeft
          //       className="text-gray-500  justify-start float-left flex mr-4 cursor-pointer"
          //       onClick={handleGoBack}
          //     />
          //     <h2 className="w-full flex justify-center">Message Detail</h2>
          //   </div>
          //   <div className="border-t px-4 border-b border-black overflow-y-auto no-scrollbar flex-grow">
          //     {chatMessages.map((message, index) => (
          //       <div
          //         key={index}
          //         className={`mb-2 ${
          //           message.sender === "sender"
          //             ? "flex justify-end"
          //             : "flex justify-start"
          //         }`}
          //       >
          //         <div
          //           className={`rounded-md p-2 border border-black ${
          //             message.sender === "sender"
          //               ? "bg-white text-black"
          //               : "bg-[#FFFCF8] text-black"
          //           }`}
          //         >
          //           <div>{message.message}</div>
          //           <div className="text-xs text-gray-500">{message.time}</div>
          //         </div>
          //       </div>
          //     ))}
          //   </div>
          //   <div className="flex">
          //     <input
          //       type="text"
          //       className="w-full  px-4 py-2 focus:outline-none"
          //       placeholder="Type a message..."
          //       value={newMessage}
          //       onChange={handleNewMessageChange}
          //     />
          //     <MdOutlineAttachFile
          //       className="text-black cursor-pointer"
          //       size={30}
          //     />
          //     <button
          //       className="ml-2 px-4 py-2 bg-[#F7CB45] text-black border-black border-l"
          //       onClick={sendNewMessage}
          //     >
          //       Send
          //     </button>
          //   </div>
          // </div>
          <Messaging sender={selectedMessage?.latestMessage?.sender} chatId={selectedMessage._id} />
        ) : (
          <div>
            <div className="flex items-center justify-between py-4 border-b border-black mediumFont">
              <div className="w-[20%] flex justify-left pl-6">Username</div>
              <div className="w-[70%] flex justify-left">Message</div>
              <div className="w-[10%] flex justify-center">Time</div>
            </div>
            {chats.map((message, index) => (
              <div
                key={index}
                className={`flex items-center justify-between h-16 ${index === sortedMessages?.length - 1
                    ? "border-b-[0px]"
                    : "border-b"
                  } border-black ${message?.unread ? "bg-white mediumFont" : "bg-zinc-100"
                  }`}
                onClick={() => handleSelectMessage(message)}
              >
                <div className="w-[20%] pl-2 flex items-center">
                  <div
                    className={`w-2 h-2 bg-blue-500 rounded-full mr-2  ${!message?.unread && "opacity-0"
                      }`}
                  />
                  <div>@{message?.latestMessage?.sender?.username}</div>
                </div>
                <div className="w-[70%] flex items-center justify-left">
                  {message?.latestMessage?.content}
                </div>
                <div className="w-[10%] border-l h-full border-black flex items-center justify-center">
                  {message?.time}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Mobile Version */}
      <div className="w-full 2md:hidden pt-[32px] px-[10px]">
        {selectedMessage ? (
          <Messaging sender={selectedMessage?.latestMessage?.sender} chatId={selectedMessage._id} />
        ) : (
          <div>
            {sortedMessages.map((message, index) => (
              <div
                key={index}
                className={`flex flex-col py-4 px-2 border border-black  ${index === sortedMessages.length - 1
                    ? "border-b border-black"
                    : "border-b-[0px]"
                  } ${message.unread ? "bg-white mediumFont" : "bg-zinc-100"}`}
                onClick={() => handleSelectMessage(message)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex ">
                    <div
                      className={`w-2 h-2 mt-2 bg-blue-500 rounded-full mr-2 ${!message.unread && "opacity-0"
                        }`}
                    />
                    <div className="mr-2">
                      {message.username}
                      <div className="mt-2 text-left">{message.message}</div>
                    </div>
                  </div>
                  <div className="text-gray-500">{message.time}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveListingMessages;

import React from "react";
import {
  AccessTimeOutlined,
  Favorite,
  LocationOnOutlined,
  FavoriteBorder,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { ACCEPT_OFFER_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { useState } from "react";

const ProductCardMob = ({ product, isBuying, remove, status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleLiked, setToggleLiked] = useState(true);

  // Condition
  let condition;
  if (product?.condition) {
    switch (product?.condition) {
      case "Used - Good":
        condition = "Used";
        break;
      case "Used - Fair":
        condition = "Used";
        break;
      case "Refurbished":
        condition = "Refurb";
        break;
      default:
        condition = product?.condition;
        break;
    }
  }
  const toggleToWishlist = () => {
    // code to add to wishlist
    setToggleLiked(!toggleLiked);
  };


  // Address
  let formattedAddress = product?.loc?.formattedAddress;
  if (formattedAddress) {
    const parts = formattedAddress.split(",");
    if (parts.length >= 3) {
      const lastIndex = parts.length - 1;
      const secondToLastIndex = lastIndex - 1;
      const result = `${parts[secondToLastIndex].trim()}, ${parts[lastIndex].trim()}`;
      formattedAddress = result; // This will contain the desired result
    }
  }

  // Expiry date
  let expiryDate = product?.listing_duration;
  let expiryDateMob = product?.listing_duration;
  if (expiryDate) {
    const listingDurationDate = new Date(expiryDate);
    const currentDate = new Date();
    const timeDifference = listingDurationDate - currentDate;

    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    // Update expiryDate with the number of days and hours left
    expiryDate = `${daysLeft} days ${hoursLeft} hours`;
    expiryDateMob = `${daysLeft} d ${hoursLeft} h`
  }

  return (
    <div className="max-w-[1023px] bg-white border-[1px] border-black buttonHover rounded relative mb-5">
      {isBuying && (
        <div className="border-b-[1px] border-black flex items-center">
          <div className="border-r-[1px] border-black w-[50%]">
            {/* You can add content here */}
          </div>
          <div
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ACCEPT_OFFER_MODAL,
                  modalTitle: "",
                  modalSubTitle: "",
                  modalProps: product,
                })
              );
            }}
            className={
              status === "Open"
                ? "bg-black justify-center w-[50%] text-center"
                : status === "Declined"
                  ? "bg-[#D1503B] justify-center w-[50%] text-center"
                  : status === "counter-offer"
                    ? "bg-sky-blue justify-center w-[50%] text-center"
                    : status === "Accepted"
                      ? "bg-sa-success-green justify-center w-[50%] text-center"
                      : "bg-black justify-center w-[50%] text-center"
            }
          >
            <h1 className="text-md leading-8 underline underline-offset-4 decoration-1 boldFont text-white text-center py-2 pl-3">
              {status}
            </h1>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          if (!remove) navigate(`/product/${product?._id}`);
        }}
        className="flex overflow-hidden h-auto aspect-auto"
      >
        <div
          className={`flex absolute ${isBuying ? "top-14" : "top-3"
            } -left-2 rounded-md`}
        >
          <div className="relative flex flex-col w-[78px] h-[35px]">
            <div className="mb-2">
            <img
              src={require("../../assets/icons/main.svg").default}
              alt="Main"
              className="w-full h-full relative z-10 fill-#FFDC25"
            />
            </div>
            <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
            <img
              src={require("../../assets/icons/shadow.svg").default}
              alt="Shadow"
              className="w-full h-full absolute z-0"
            />
            </div>
          </div>
          <h3 className="boldFont ml-[-65px] mt-[2px] z-30">{condition}</h3>
        </div>
        {/* Thumbnail image */}
        <div className="w-[47%] h-[190px] rounded-sm rounded-r-none border-r-[1px] border-black overflow-hidden">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="overflow-hidden"
          >
            <img
              src={product?.thumbnail}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        <div className="flex-1 w-[53%] flex-col justify-center overflow-hidden">
          {/* Location and name */}
          <div className="px-1.5 sm:h-1/4 h-[45%]">
            <div className="flex items-center mt-2">
              <LocationOnOutlined className="text-sa-icon-green" fontSize="12" />
              <h1 className="text-[14px] pl-[6.3px] pb-0 text-sa-text-gray">
                {formattedAddress}
              </h1>
            </div>
            <h1 className="sm:text-[18px] text-[16px] pt-[2px] text-black">
              {`${product?.title}`}
            </h1>
          </div>
          {/* Price and time */}
          <div className="w-full border-l-[0px] sm:h-1/2 h-[27%] border-black flex items-center">
            <div className="border-[1px] sm:h-[35px] h-[30px] flex-col justify-center flex border-l-[0px] w-[52%] border-black">
              <h1 className="text-[16px] sm:text-[18px] boldFont text-sa-menu-green text-left py-auto pr-2 pl-1">
                &#8377; {product?.cost}
              </h1>
            </div>
            <div className="flex w-[48%] border-y-[1px] h-[30px] sm:h-[35px] py-auto border-black items-center gap-x-1 pl-1">
              <AccessTimeOutlined className="text-black" fontSize={"10"} />
              <h1 className="text-[14px] text-black">{expiryDateMob}</h1>
            </div>
          </div>
          {/* Distance and ownership */}
          <div className="flex items-center sm:h-1/4 h-[28%] justify-between pl-[8px] pb-2">
            <div className="flex justify-between px-2 w-full items-center">
              <div>
                <h1 className="text-sm mediumFont text-sa-light-brown">
                  Offers : {product?.make_offer ? "Open " : "Close "}
                </h1>
              </div>
              <div>
                <h1 className="text-sm mediumFont text-sa-light-brown">
                  Shipping : {product?.enable_shipping ? "Available" : "Not Available"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardMob;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUser } from "../../redux/actions/admin";

const AdminUsersMobile = () => {
    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.product);
    const { users } = useSelector((state) => state.admin);

    const listings = products;

    useEffect(() => {
        dispatch(getAllUser());
    }, []);


    return (
        <div>
            {/* mobile */}
            <div className="grid grid-cols-1 gap-4 m-3">
                {users &&
                    users.map((user, index) => (
                        <div
                            className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-black dark:border-black"
                            key={index}
                        >
                            <div className="flex justify-end px-4 pt-4">
                            </div>
                            <div className="flex flex-col p-5">
                                <div className="flex justify-between" >
                                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{user?.first_name}</h5>
                                    <span className="text-sm text-gray-500 dark:text-gray-400">@{user?.username}</span>
                                </div>
                                <span className="text-sm text-gray-500 dark:text-gray-400">Email : {user?.email}</span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">Role : {user?.role}</span>
                                <div className="flex mt-4 space-x-3 md:mt-6">
                                    <Link to={`/user/admin/editUser/${user.id}`} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-black bg-sa-primary-yellow rounded-lg hover:bg-sa-primary-yellow focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:bg-sa-primary-yellow dark:hover:bg-sa-primary-yellow dark:focus:ring-yellow-300">Edit</Link>
                                    <Link className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Message</Link>
                                    <Link to={`/user/admin/listings/${user.id}`} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Listings</Link>
                                    <Link className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Details</Link>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default AdminUsersMobile;
import React from "react";

import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineClockCircle, AiOutlineMail } from "react-icons/ai";
import maincar from "../../assets/new design/car_img.png";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const ProductCard = ({ product,status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let containerClass = "";
  let text = "";

  if (status === "Pending Payment") {
    containerClass =
      "w-full h-[45px] text-[20px] bg-[#0076BE] font-white mediumFont flex justify-center items-center text-white align-middle";
    text = "Pending Payment";
  } else if (status === "sold") {
    containerClass =
      "w-full  h-[45px] text-[20px] bg-[#004643] font-white mediumFont flex justify-center items-center text-white align-middle";
    text = "Sold";
  } else if (status === "completed") {
    containerClass =
      "w-full h-[45px] text-[20px] bg-black font-white mediumFont flex justify-center items-center text-white align-middle";
    text = "Completed";
  }

  // condition
  let condition;
  if (product?.condition) {
    switch (product?.condition) {
      case "Used - Good":
        condition = "Used";
        break;
      case "Used - Fair":
        condition = "Used";
        break;
      case "Refurbished":
        condition = "Refurb";
        break;
      default:
        condition = product?.condition
        break;
    }
  }

  // Address
  let formattedAddress = product?.loc?.formattedAddress;
  if (formattedAddress) {
    const parts = formattedAddress.split(',');
    if (parts.length >= 3) {
      const lastIndex = parts.length - 1;
      const secondToLastIndex = lastIndex - 1;
      const result = `${parts[secondToLastIndex].trim()}, ${parts[lastIndex].trim()}`;

      formattedAddress = result; // This will contain the desired result
    }
  }
  // expiry date
  let expiry_date = product?.listing_duration;
  if (expiry_date) {
    const listingDurationDate = new Date(expiry_date);
    const currentDate = new Date();
    const timeDifference = listingDurationDate - currentDate;

    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    // Update expiry_date with the number of days and hours left
    expiry_date = `${daysLeft} D ${hoursLeft} H`;
  }

  //thumbnail image
  let thumbnail = product?.thumbnail;

  return (
    <div className="w-[300px] pb-9">
      {/* pending payment */}
      <div className="w-full relative">
        <div
          className=" w-full z-20  relative bg-white  rounded-md hover:cursor-pointer hover:-translate-x-1 hover:-translate-y-1 duration-100 ease-in"
          onClick={() => {
            navigate("/selling/sold/product");
          }}
        >
          {/* tag ribbon */}
          <div className="flex absolute top-12 -left-2 rounded-sm">
            {/* <TagRibbon
    textClasses="text-[14px] mediumFont text-black text-center"
    tagText="USED"
    bgColor="#FFDC25"
  /> */}
            <div className="relative flex flex-col w-[78px] h-[35px]">
              <div className="mb-2">
                <img
                  src={require("../../assets/icons/main.svg").default}
                  alt="Main"
                  className="w-full h-full relative z-10 fill-#FFDC25"
                />
              </div>

              <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
                <img
                  src={require("../../assets/icons/shadow.svg").default}
                  alt="Shadow"
                  className="w-full h-full absolute z-0"
                />
              </div>
            </div>

            <h3 className="boldFont ml-[-65px] mt-[2px] z-30">{condition}</h3>
          </div>
          {/* status card */}
          <div className="overflow-hidden border border-b-[0px] border-black rounded-t-md">
            <div className={containerClass}>{text}</div>
          </div>

          {/* image */}
          <div className="w-full border border-t-[0px] border-black border-b-[0px] flex items-center justify-center pt-[11px] ">
            <img
              class="w-[280px]  rounded-lg border aspect-square border-black"
              src={thumbnail}
            />
          </div>

          {/* location */}

          <div className="w-full border-x border-black flex px-[10px]  pt-3">
            <div>
              <IoLocationOutline className="text-[#2D7F77] text-[18px]" />
            </div>
            <span className="mediumFont text-[12px] pr-[3px]">
              {" "}
              {formattedAddress}
            </span>
          </div>

          {/* item name */}
          <div className="px-[14px] border-x border-black pb-[10px] text-[20px] mediumFont pt-[5px]">
            {product?.title}
          </div>

          {/* price and time */}
          <div className="w-full flex justify-between border-t border-x rounded-b-md border-black px-[14px]">
            <div className="border-r border-black w-[60%] text-emerald-900 text-[20px] boldFont py-[8px]">
              ₹ {product?.cost}{" "}
            </div>
            <div className="w-[40%] r flex pl-[10px]">
              <div className="flex flex-col justify-center">
                <AiOutlineClockCircle />
              </div>

              <div class="text-black flex flex-col justify-center pl-[4px] text-[14px] mediumFont leading-tight">
                {expiry_date}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute z-10 inset-0 w-full h-[459.4px] bg-black rounded-md"></div>
      </div>

      {/* buttons */}
      <div className="mt-[12px] flex justify-between">
        <div className="w-[80%] h-[45.6px] relative">
          <button className="flex hover:-translate-x-1 hover:-translate-y-1 duration-100 ease-in z-20 relative  justify-center w-full h-full bg-white border border-black rounded-md items-center text-black text-[14px] font-medium leading-tight">
            Cancel Sale
          </button>
          <div className="absolute z-10 inset-0 w-full h-full bg-black rounded-md"></div>
        </div>

        <div className="h-[45.6px] relative">
          <button className="bg-[#FFDC25] hover:-translate-x-1 hover:-translate-y-1 duration-100 ease-in relative z-20 h-full px-[14px] items-center border border-black rounded-md">
            <AiOutlineMail className="text-black text-[20px]" />
          </button>
          <div className="absolute z-10 inset-0  h-full bg-black rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

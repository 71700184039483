import {
  Bookmark,
  ExpandLess,
  ExpandMore,
  Star,
  StarOutline,
} from "@mui/icons-material";
import { useState } from "react";
import ProductCard from "../components/Home/ProductCard";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import ReactLoading from 'react-loading';
import NestedFilterDropDown from "../components/NestedFilterDropDown";

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchtag = location?.state?.data;
  const { categories } = useSelector((state) => state.category);
  const [isLoading, setIsLoading] = useState(true);
  const [showNoResults, setShowNoResults] = useState(false);

  //sort
  const sortarray = ["Price: Low to High", "Price: High to low", "Recently Added"];
  const [showsort, setshowsort] = useState(false);
  const [sorttype, setsorttype] = useState(2);
  const sortOption = ['cost', '-cost', '-createdAt']

  // filter
  const [category, setCategory] = useState("");
  const [condition,setCondition] = useState("");

  const [products, setProducts] = useState([]);
  const ActiveProducts = products?.filter(item => item.status.state === 'active');

  useEffect(() => {
    // getting all products 
    if (searchtag) {
      let url = "";
      // if (category) {
       
      // } else {
      //   url = `product/all?title=${searchtag}&sort=${sortOption[sorttype]}`
      // }
      url = `product/all?title=${searchtag}&sort=${sortOption[sorttype]}&category=${category}&condition=${condition}`;

      axiosInstance
        .get(url)
        .then((res) => {
          const activeSearchResults = res.data.data?.filter(item => item.status.state === 'active');
          setProducts(activeSearchResults);
          setIsLoading(false);
          setShowNoResults(false);
          if (activeSearchResults.length === 0) {
            setTimeout(() => {
              setShowNoResults(true);
            }, 1000);
          }
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");

                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");

                break;
              default:
                // server error
                toast.error("Oops, something went wrong");

                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        })
    }
  }, [searchtag, sorttype, category,condition])


  return (
    <>
      <div>
        <div className="bg-[#005C51] px-16 h-[42vh] border-b-2 border-black relative">
          <div className=" flex items-center gap-x-4 pt-16">
            <div className="flex items-start gap-x-4">
              <div>
                <h1 className=" text-[52px] text-white mediumfont tracking-wide">
                  Search Results for : {searchtag}
                </h1>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
        {/* shop */}
        <div className="pl-16 pr-8 flex items-start pt-9">
          <div className="w-[23%]">
            {/* <div className="w-full border-[1px] border-black rounded py-7 px-3">
                <h1 className="text-sa-menu-green text-[20px] boldfont">
                  OUR CATEGORIES
                </h1>
                <div className="mt-4 space-y-3">
                  <div className="flex items-start">
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          require("../assets/icons/electronics_icon.svg").default
                        }
                        alt="summary icon"
                      />
                    </div>
                    <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                      Electronics
                    </h1>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          require("../assets/icons/electronics_icon.svg").default
                        }
                        alt="summary icon"
                      />
                    </div>
                    <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                      Automobiles
                    </h1>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          require("../assets/icons/electronics_icon.svg").default
                        }
                        alt="summary icon"
                      />
                    </div>
                    <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                      Collectibles & Art
                    </h1>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          require("../assets/icons/electronics_icon.svg").default
                        }
                        alt="summary icon"
                      />
                    </div>
                    <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                      Musical Instruments & Gear
                    </h1>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center justify-center">
                      <img
                        src={
                          require("../assets/icons/electronics_icon.svg").default
                        }
                        alt="summary icon"
                      />
                    </div>
                    <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                      Toys & Hobbies
                    </h1>
                  </div>
                </div>
              </div> */}

            <div className="w-full border-[1px] border-black rounded py-7 px-3 mt-4">
              <h1 className="text-sa-menu-green text-[20px] boldfont">
                SHOP BY CATEGORY
              </h1>
              <div className="mt-4 space-y-3">
                {/* <div>
                    <div
                      className="flex items-start justify-between"
                      onClick={() => setExpand(!expand)}
                    >
                      <div className="flex items-start">
                        <div className="flex items-center justify-center">
                          <img
                            src={
                              require("../assets/icons/electronics_icon.svg")
                                .default
                            }
                            alt="summary icon"
                          />
                        </div>
                        <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                          Cell Phone & SmartPhones
                        </h1>
                      </div>
                      {expand ? (
                        <ExpandLess className="text-black text-2xl" />
                      ) : (
                        <ExpandMore className="text-black text-2xl" />
                      )}
                    </div>
                    {expand && (
                      <div className="pl-9">
                        <h1
                          className={`text-[16px] mediumfont  tracking-wide ml-4 mt-2 block text-[#005C51]`}
                        >
                          Smartwatch Accessories
                        </h1>
                        <h1
                          className={`text-[16px] mediumfont  tracking-wide ml-4 mt-2 block text-black`}
                        >
                          Smartwatch parts
                        </h1>
                        <h1
                          className={`text-[16px] mediumfont  tracking-wide ml-4 mt-2 block text-black`}
                        >
                          Smartwatches
                        </h1>
                      </div>
                    )}
                  </div> */}
                <div>
                  <div
                    className="flex items-start justify-between"
                  >
                    <div className="w-full">
                      <NestedFilterDropDown categories={categories} filter setCategory={setCategory} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full border-[1px] border-black rounded py-7 px-3 mt-4">
              <h1 className="text-sa-menu-green text-[20px] boldfont">
                FILTERS
              </h1>
              <div className="mt-4 space-y-3">
                <div>
                  <div
                    className="flex items-start justify-between"
                  >
                    <div className="w-full">
                      Condition : 
                    </div>
                    <div className="w-full cursor-pointer" onClick={() => {setCondition("New")}}>
                      New
                    </div>
                    <div className="w-full cursor-pointer" onClick={() => {setCondition("Used-Good")}}>
                      Used
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 space-y-3">
                <div>
                  <div
                    className="flex items-start justify-between"
                  >
                    <div className="w-full">
                      Price Range
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 space-y-3">
                <div>
                  <div
                    className="flex items-start justify-between"
                  >
                    <div className="w-full">
                      Buying Format
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 space-y-3">
                <div>
                  <div
                    className="flex items-start justify-between"
                  >
                    <div className="w-full">
                      Free Shipping
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="flex-1">
            <div className="flex justify-end">
              <div className="relative z-50 w-max place-items-center">
                <button
                  onClick={() => {
                    setshowsort(!showsort);
                  }}
                  className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
                    ${showsort ? "rounded rounded-b-none border-b-0" : "rounded"} 
                    text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}
                >
                  Sort: {sortarray[sorttype]} &nbsp;
                  {!showsort ? <ExpandMore /> : <ExpandLess />}
                </button>
                <div
                  className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort
                    ? "max-h-[100vh] border-[1px]"
                    : "max-h-0 border-[0px]"
                    }`}
                >
                  {sortarray.map((type, i) => (
                    <div
                      key={`cat${i}`}
                      onClick={() => {
                        setsorttype(i);
                        setshowsort(!showsort);
                      }}
                      className="mt-2.5 py-2 px-5 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3"
                    >
                      <p>{type}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="pl-12 pt-9 h-[70vh] overflow-y-auto no-scrollbar">
                <div className="w-[100%] mb-10 font-bold text-[42px]">
                  <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
                </div>
              </div>
            )}
            {showNoResults && !isLoading && ActiveProducts.length === 0 && (
              <div className="pl-12 pt-9 h-[400px] overflow-y-auto no-scrollbar">
                <div className="w-[100%] mb-10 font-bold text-[42px]">
                  <p className="font-bold text-[42px]">No Results Found</p>
                </div>
              </div>
            )}
            {ActiveProducts.length > 0 && !isLoading && !showNoResults && (
              <div className="pl-12 pt-9 h-[70vh] overflow-y-auto flex items-start flex-wrap gap-x-10 no-scrollbar">
                {ActiveProducts.map((e) => (
                  <div className="w-[30%] mb-10">
                    <ProductCard product={e} />
                  </div>
                ))}
              </div>)}
          </div>
        </div>
      </div >
    </>
  );
};

export default SearchPage;

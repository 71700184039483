import { useDispatch, useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import ProductCardMob from "./ProductCardMob";
import { useEffect, useState } from "react";
import { uploadProductImage } from "../../redux/actions/product";


const ListingSection = ({ title }) => {
  const dispatch = useDispatch();
  const {products} = useSelector((state) => (state.product));
  const [image,setimage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    console.log(formData);
    dispatch(uploadProductImage(formData));
  }

  const handleImageChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  return (
    <div className="border-t-2 border-black bg-[#FCF9F4] relative pb-20">
      <div className="w-[100%] py-5 pl-3 border-r-[1.5px] border-b-[1.5px] xl:w-[48.5%] bg-sa-primary-yellow xl:pl-20 xl:py-10 rounded-br-3xl xl:border-r-2 xl:border-b-2 border-black 3xl:py-12 4xl:py-[50px]">
        <h1 className="text-2xl xl:text-5xl boldFont text-black 3xl:text-[55px] 4xl:text-[65px]">
          {title}
        </h1>
      </div>
      <div className="hidden xl:flex items-center justify-around py-16 px-10">
        {products?.map((product) => (
          (product.status.state === 'active') && 
          <div className="w-[23%]">
            <ProductCard product = {product}/>
          </div>
        ))}
      </div>

      <div className="xl:hidden px-3 pt-5">
        {products?.map((product) => (
          (product.status.state === 'active' ) && 
          <div className="mb-5">
            <ProductCardMob product = {product}/>
          </div>
        ))}
      </div>

      <div className="xl:hidden w-full flex items-center justify-center mt-10">
        <button className="bg-[#000] text-white  py-2 px-6 rounded-md mediumFont text-xs text-center">
          Visit Now
        </button>
      </div>
    </div>
  );
};

export default ListingSection;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const signInEmail = location?.state?.email ? location?.state?.email : "";
  const [email, setemail] = useState(signInEmail);
  const [error, setError] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otpvalue, setOtpValue] = useState(0);
  const [showNewPasswordInputs, setShowNewPasswordInputs] = useState(false);

  const handleSendClick = () => {
    const value = document.getElementById("id_input").value;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailRegex.test(value)) {
      axiosInstance
        .post(`/auth/forgotPassword/${value}`)
        .then((response) => {
          setShowOTPInput(true);
          setError("");
          setOtpValue(response.data.data);

        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                toast.error("Invalid Data!");
                break;
              case 401:
                toast.error("Invalid credentials!");
                break;
              case 404:
                setError("User Does not Exist!");
                break;
              default:
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    } else {
      setError("Please enter a valid Email");
    }
  };

  const handleVerifyClick = () => {
    const otp = document.getElementById("otp_input").value; // Get OTP value
    if (otp === otpvalue.toString()) {
      setShowNewPasswordInputs(true);
      setShowOTPInput(false);
      setError("");      
    } else {
      setError("Invalid OTP. Please try again.");
    }
  };

  const handleResetPasswordClick = () => {
    const email = document.getElementById("id_input").value;
    const newPassword = document.getElementById("new_password_input").value;
    const confirmPassword = document.getElementById("confirm_password_input").value;

    if (newPassword === confirmPassword) {
      const req = {
        email : email,
        password : newPassword
      }

      axiosInstance
        .post(`/auth/resetPassword`,req)
        .then((response) => {
          toast.success("Reset password Successful!");
          navigate('/signin/username');
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");
                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");
                break;
              default:
                // server error
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    } else {
      setError("Passwords do not match. Please try again.");
    }
  };

  return (
    <div className="w-full bg-[#FFF9F0] flex items-center justify-center py-10">
      <div className="cursor-default border md:border-2 border-black w-[90%] max-w-[450px] min-h-[73%] rounded-md py-6 md:py-12  px-4 md:px-7 bg-white">
        <h1 className="text-3xl mediumFont text-black">Forgot Password</h1>
        <div className="mt-8">
          <p className="text-base mediumFont text-black">
            Email to send reset instructions to
          </p>
          <input
            id="id_input"
            type="text"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            className="w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
          />

          {showOTPInput && (
            <div className="mt-5">
              <p className="text-base mediumFont text-black">Enter OTP</p>
              <input
                id="otp_input"
                type="text"
                className="w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
              />
              {error && (
                <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                  <p className="text-sm">{error}</p>
                </div>
              )}
              <button
                onClick={handleVerifyClick}
                className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
              >
                Verify
              </button>
            </div>
          )}

          {showNewPasswordInputs && (
            <div className="mt-5">
              <p className="text-base mediumFont text-black">New Password</p>
              <input
                id="new_password_input"
                type="password"
                className="w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
              />
              <p className="text-base mediumFont text-black">Confirm Password</p>
              <input
                id="confirm_password_input"
                type="password"
                className="w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
              />
              {error && (
                <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                  <p className="text-sm">{error}</p>
                </div>
              )}
              <button
                onClick={handleResetPasswordClick}
                className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
              >
                Reset Password
              </button>
            </div>
          )}

          {!showOTPInput && !showNewPasswordInputs && (
            <>
              {error && (
                <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                  <p className="text-sm">{error}</p>
                </div>
              )}

              <button
                onClick={handleSendClick}
                className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
              >
                Send
              </button>
            </>
          )}


        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;

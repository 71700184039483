import React from "react";
import { useNavigate } from "react-router";

const ChangePasswordSuccessScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-[72.5vh] w-full bg-[#FFF9F0] flex items-center justify-center py-10">
      <div className="border md:border-2 border-black w-[90%] max-w-[450px] min-h-[73%] rounded-md py-12 px-4 md:px-7 bg-white">
        <h1 className="text-3xl mediumFont text-black leading-10">
          You’ve successfully changed <br />
          your password
        </h1>
        <button
          onClick={() => navigate("/")}
          className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
        >
          Go Back To Home
        </button>
      </div>
    </div>
  );
};

export default ChangePasswordSuccessScreen;

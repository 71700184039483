import React from "react";
import { useState } from "react";
import GalleryClosed from "./ImageGallery3/GalleryClosed";
import Gallery from "./ImageGallery3/Gallery";

const ImageGalleryTest3 = () => {
  const [showGallery, setShowGallery] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index) => {
    if (index === 8) {
      setCurrentImageIndex(-1);
    } else {
      setCurrentImageIndex(index);
    }
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    setShowGallery(false);
  };

  const images = [
    require("../assets/new design/phone/image00001.jpeg"),
    require("../assets/new design/phone/image00002.jpeg"),
    require("../assets/new design/phone/image00003.jpeg"),
    require("../assets/new design/phone/image00004.jpeg"),
    require("../assets/new design/phone/image00005.jpeg"),
    require("../assets/new design/phone/image00001 - Copy.jpeg"),
    require("../assets/new design/phone/image00002 - Copy.jpeg"),
    require("../assets/new design/phone/image00003 - Copy.jpeg"),
    require("../assets/new design/phone/image00004 - Copy.jpeg"),
    require("../assets/new design/phone/image00005 - Copy.jpeg"),
  ];
  return (
    <div className="">
      <GalleryClosed handleImageClick={handleImageClick} />
      {showGallery && (
        <Gallery
          images={images}
          currentIndex={currentImageIndex}
          onClose={handleCloseGallery}
        />
      )}
    </div>
  );
};

export default ImageGalleryTest3;

import React, { useEffect } from "react";
import SideMenu from "../components/Buying/SideMenu";
import { useState } from "react";
import ActiveListingCard from "../components/Selling/ActiveListingCard";
import { ExpandMore, ExpandLess, Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUserChats, userActiveListings } from "../redux/actions/auth";
import { getProductCompressedImages } from "../redux/actions/product";
import { Link } from "react-router-dom";
import { getAllUser } from "../redux/actions/admin";
import AdminUsers from "../components/Admin/AdminUsers";
import AdminUsersMobile from "../components/Admin/AdminUsersMobile";
import AdminListings from "../components/Admin/AdminListings";
import AdminMetrics from "../components/Admin/AdminMetrics";
import AdminListingMobile from "../components/Admin/AdminListingMobile";

const AdminScreen = () => {
  const dispatch = useDispatch();
  const offerTabs = ["Users", "Listings", "Metrics", "Disputes", "Offers"];
  const [fontSize, setFontSize] = useState("text-4xl");
  const [activeTab, setActiveTab] = useState("Users");
  const { products } = useSelector((state) => state.product);
  const { users } = useSelector((state) => state.admin);

  const listings = products;

  useEffect(() => {
    dispatch(getAllUser());
  }, []);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setFontSize("text-xl");
    } else {
      setFontSize("text-4xl");
    }
  };
  const data = [
    {
      id: 1,
      status: "Pending Payment",
    },
    {
      id: 2,
      status: "paid",
    },
    {
      id: 3,
      status: "completed",
    },
    { id: 4, status: "Pending Payment" },
    {
      id: 5,
      status: "paid",
    },
    {
      id: 6,
      status: "completed",
    },
    { id: 7, status: "Pending Payment" },
    {
      id: 8,
      status: "paid",
    },
    {
      id: 9,
      status: "completed",
    },
    { id: 10, status: "Pending Payment" },
  ];
  const [phonesidenav, showphonesidenav] = useState(true);
  const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
  const [showsort, setshowsort] = useState(false);
  const [sorttype, setsorttype] = useState(0);


  return (
    <div>
      {/* desktop */}
      <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
        <div className="self-stretch">
          <SideMenu />
        </div>
        <div className="flex-1 w-full overflow-hidden bg-[#FCF9F4]">
          <div
            className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
              }`}
          >
            <h1
              className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
            >
              Admin Panel
            </h1>
            <div
              className={`${fontSize === "text-4xl" ? "mt-5" : "mt-2"
                } transition-all duration-300 ease-in-out flex items-center justify-between`}
            >
              <div className="flex items-center flex-wrap max-w-[600px] mr-10 grow justify-between">
                {offerTabs.map(function (e, i) {
                  return (
                    <button
                      onClick={() => setActiveTab(e)}
                      key={i}
                      className={`px-5 text-[18px] mt-2.5 py-2  rounded-md ${activeTab === e
                        ? "bg-white border-[1px] border-sa-border-black"
                        : "bg-[#FCF9F4]  border-[1px] border-[#FCF9F4]"
                        } text-black mediumFont flex items-center justify-center`}
                    >
                      {e}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            {activeTab === "Users" ? <AdminUsers /> : activeTab === "Listings" ?  <AdminListings/>: <AdminMetrics/>}
          </div>
        </div>
      </div>
      {/* mobile */}

      <div className="bg-[#FCF9F4] min-h-screen pb-28 md:hidden">
        <div
          className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
            } overflow-hidden`}
        >
          <SideMenu />
        </div>
        <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
          <h1 className="text-[26px] mediumFont text-black tracking-wide">
            Admin Panel
          </h1>
        </div>
        <div className="fixed z-50 bottom-0 w-full grid grid-cols-[100%]">
          <div
            className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
              }`}
          >
            <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
            <div
              onClick={() => {
                setshowsort(!showsort);
              }}
              className=" absolute top-4 right-3"
            >
              <Cancel className="text-base " />
            </div>
            {sortarray.map((type, i) => (
              <button
                className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                  }`}
                onClick={() => {
                  setsorttype(i);
                }}
              >
                {type}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-[50%_50%]">
            <button
              onClick={() => {
                showphonesidenav(!phonesidenav);
              }}
              className={`w-full text-base py-2 ${phonesidenav
                ? "bg-black text-sa-primary-yellow"
                : "bg-sa-primary-yellow text-black"
                } border-[1px] border-sa-border-black mediumFont`}
            >
              SideMenu
            </button>
            <button
              onClick={() => {
                setshowsort(!showsort);
              }}
              className="w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
            >
              Sort: {sortarray[sorttype]}
            </button>
          </div>
        </div>
        {activeTab === "Users" ? <AdminUsersMobile /> : activeTab === "Listings" ?  <AdminListingMobile/>: <AdminMetrics/>}
      </div>
    </div>
  );
};

export default AdminScreen;

import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useState } from "react";
import Dropdown from "./Dropdown";

// const images = Array(100)
//   .fill()
//   .map((_, index) => {
//     return require("../../assets/new design/car/carimg2.png");
//   });

const PhotoPreview = ({ onClose,uploadedImages }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const images = uploadedImages;

  const handleImageClick = (index) => {
    const isSelected = selectedImages.includes(index);

    if (isSelected) {
      setSelectedImages(selectedImages.filter((item) => item !== index));
    } else {
      setSelectedImages([...selectedImages, index]);
    }
  };

  const handleRemoveClick = (index) => {
    setSelectedImages(selectedImages.filter((item) => item !== index));
  };

  const isImageSelected = (index) => {
    return selectedImages.includes(index);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  return (
    <div className="h-full w-full absolute top-0 left-0 z-40 bg-black/80 ">
      {/* desktop */}
      <div className="h-full w-full hidden md:block">
        <button className="absolute top-2 right-2 text-white" onClick={onClose}>
          <AiFillCloseCircle
            color="red"
            style={{ stroke: "white" }}
            size={20}
          />
        </button>
        <div className=" h-full px-[68px] flex flex-col">
          <div className=" flex justify-between items-center w-full h-[10%]">
            <h1 className="text-[24px] text-white boldFont">
              Categorise media
            </h1>
            <div className="flex space-x-2">
              <button className="bg-white text-black rounded-md capitalize px-4 py-2">
                add more photos
              </button>
              <button className="bg-red-600 text-white rounded-md capitalize px-4 py-2">
                delete photos
              </button>
            </div>
          </div>
          <div className="w-full h-[80%] overflow-y-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {images.map((image, index) => (
              <div key={index} className="relative">
                {/* Image */}
                <div className="aspect-[4/3]">
                  <img
                    src={image}
                    alt={`Image ${index}`}
                    className="w-full object-cover h-full"
                    onClick={() => handleImageClick(index)}
                  />{" "}
                </div>

                {/* selected overlay */}
                {isImageSelected(index) && hoveredIndex !== index && (
                  <div className="absolute top-0 left-0 w-full h-full transition-all duration-300 bg-green-500/30 opacity-100 flex justify-center items-center">
                    <span className="bg-green-600 rounded-md text-[12px] px-5 py-2">
                      Selected
                    </span>
                  </div>
                )}
                {/* Overlay */}
                <div
                  className={`absolute top-0 left-0 w-full h-full ${
                    isImageSelected(index)
                      ? "bg-red-500/30"
                      : "bg-yellow-300/30"
                  } opacity-0 hover:opacity-100 transition-opacity duration-300 flex justify-center items-center`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {isImageSelected(index) ? (
                    <button
                      className="bg-[#D1503B] text-white text-[12px] rounded-md px-5 py-2"
                      onClick={() => handleRemoveClick(index)}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="bg-[#FFDC25] text-black text-[12px] rounded-md px-5 py-2"
                      onClick={() => handleImageClick(index)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className=" w-full flex justify-between items-center h-[10%]">
            <button className=" bg-yellow-400 text-black text-[14px] rounded-md capitalize px-10 py-2">
              all photos
            </button>
            <div className=" flex space-x-4">
              <button className="bg-white text-black text-[14px] rounded-md capitalize px-4 py-2">
                Interior
              </button>
              <button className="bg-white text-black text-[14px] rounded-md capitalize px-4 py-2">
                exterior
              </button>
              <button className="bg-white text-black text-[14px] rounded-md capitalize px-4 py-2">
                Issues
              </button>
            </div>
            <button className=" bg-green-700 text-white text-[14px] rounded-md capitalize px-4 py-2">
              {" "}
              save
            </button>
          </div>
        </div>
      </div>

      {/* mobile */}

      <div className="h-full w-full px-2 md:hidden flex">
        <button className="absolute top-0 right-0 text-white" onClick={onClose}>
          <AiFillCloseCircle
            color="red"
            style={{ stroke: "white" }}
            size={24}
          />
        </button>
        <div className="flex flex-col w-full h-full">
          {" "}
          <div className="flex flex-col justify-between h-[25%]">
            <div className="text-[20px] h-1/3 text-white capitalize">
              categorize media
            </div>
            <div className="flex h-1/3 justify-between space-x-2 items-center">
              <button className="w-1/2 bg-white rounded-md text-black text-[14px] px-2 py-2 capitalize ">
                add more photos
              </button>
              <button className="w-1/2 bg-[#D1503B] rounded-md text-white text-[14px] px-2 py-2 capitalize">
                delete photos
              </button>
            </div>
            <div className="h-1/3">
              <Dropdown />
            </div>
          </div>
          <div className="h-[65%] border-[1px] w-full overflow-y-auto border-white flex flex-wrap">
            {images.map((image, index) => (
              <div key={index} className="w-1/2 p-1 pr-0">
                {/* Image */}
                <div className="relative aspect-[4/3]">
                  <img
                    src={image}
                    alt={`Image ${index}`}
                    className="w-full object-cover h-full"
                    onClick={() => handleImageClick(index)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className=" h-[10%] flex flex-col justify-center items-center">
            <button className="w-full bg-white rounded-md capitalize text-black mx-auto py-2 text-[14px]">
              {" "}
              save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoPreview;

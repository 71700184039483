import React, { useEffect, useState } from "react";
import GoXCircleFill from "react-icons/go";
import "./SignInPanel.css";
import axiosInstance from "../axiosInstance";
import { LOGIN_USER } from "../redux/actions/types";
import { getUserCart } from "../redux/actions/cart";
import { toast } from "react-toastify";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { showModal } from "../redux/actions/modal";
import { CREATE_OFFER_MODAL } from "../extras/constants";
import { getCurrentUser } from "../redux/actions/auth";
import tick from "../assets/icons/tick.svg";

const SignInpanelGeneral = ({ product }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isSignUp, setSignUp] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [number, setNumber] = useState("");
    const [isOtp, setisOtp] = useState(true);
    const { user } = useSelector((state) => (state.auth))
    const divStyle2 =
        "font-mabry-pro text-18 font-normal leading-23 tracking-normal text-center text-black pt-10";

    const divStyle = {
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "#000000",
        background: "#FFFFFF",
    };

    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isOtpVisible, setOtpVisible] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isContinueVisible, setContinue] = useState(true);
    const [isSubmitVisible, setSubmit] = useState(false);
    const [message, setmessage] = useState("");
    const [error, seterror] = useState("");
    const [signUpData, setSignUpData] = useState({
        name: "",
        username: "",
        password: "",
        email: ""
    });
    const [pswrdverify, setpswrdverify] = useState([false, false, false, false]);
    const [messageSignUp, setmessageSignUp] = useState(["", "", "", ""]);
    const [emailValid, setEmailValid] = useState(true);
    const [otp, sentotp] = useState(false);
    const [otpcount, setotpcount] = useState(0);
    const [timeLeft, setTimeLeft] = useState(null);

    const togglePanel = () => {
        document.body.style.overflow = "hidden";
        if (user) {

        }
        else {
            setIsOpen(!isOpen);
        }
    };

    const handleinup = () => {
        setSignUp(!isSignUp);
        setIsOpen(true);
        setEmail("");
        setPassword("");

        setisOtp(true);
        setContinue(true);
        setPasswordVisible(false);
        setOtpVisible(false);
        setSubmit(false);
    };

    const closePanelUp = () => {
        setSignUp(!isSignUp);
        setEmail("");
        setNumber("");
        setSignUp(false);
        setContinue(true);
        setOtpVisible(false);
        setSubmit(false);
        document.body.style.overflow = "visible";
    };

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(null);
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const phoneOTP = (e) => {
        e.preventDefault();
        sentotp(false);
        if (otpcount >= 5) {
            setmessage("You have requested for OTP 5 times. Please try again later!");
        } else if (validator.isMobilePhone(signUpData.phone)) {
            // code for otp verification
            axiosInstance
                .post(`/auth/sendPhoneOtp/${signUpData.phone}`, { phone: signUpData.phone })
                .then(() => {
                    setotpcount(otpcount + 1);
                    seterror("");
                    sentotp(true);
                    setTimeLeft(30);
                    setIsOtpSent(true);
                    setSubmit(!isSubmitVisible);
                })
                .catch((err) => {
                    console.log(err);
                    seterror(err.response.data.error);
                });
        } else {
            setmessage("You have entered an incorrect phone number.");
        }
    };

    const handleContinueSignUp = (e) => {
        e.preventDefault();
        if (!signUpData.name || !signUpData.email || !signUpData.username || !signUpData.password || !(pswrdverify[0] && pswrdverify[1] && pswrdverify[2] && pswrdverify[3])) {
            let msg = [...messageSignUp];

            if (!signUpData.password) {
                msg[3] = "*Please enter your password.";
            }
            if (!(pswrdverify[0] && pswrdverify[1] && pswrdverify[2] && pswrdverify[3])) {
                msg[3] = "*Please enter a correct password.";
            }
            console.log("error message:", msg);
            setmessageSignUp(msg);
            return;
        }
        if (messageSignUp.filter((item) => item === "").length === 4) {
            let data = {
                first_name: signUpData.name,
                last_name: "",
                email: signUpData.email,
                username: signUpData.username,
                password: signUpData.password,
                phone: "",
                otp: "",
            };

            // Code for form submission

            // Checking if email exists
            axiosInstance
                .post(`/auth/checkUserExist`, { email: signUpData.email })
                .then((res) => {
                    seterror("Email already Exist");
                })
                .catch(({ response }) => {
                    try {
                        switch (response.status) {
                            case 400:
                                // Add a toast
                                toast.error("Invalid Data!");
                                break;
                            case 401:
                                // Add a toast
                                toast.error("Invalid credentials!");
                                break;
                            case 404:
                                // Add a toast
                                setOtpVisible(!isOtpVisible);
                                setContinue(!isContinueVisible);
                                setisOtp(!isOtp);
                                seterror("");
                                break;
                            default:
                                // Server error
                                toast.error("Oops, something went wrong");
                                break;
                        }
                    } catch (e) {
                        toast.error("Couldn't reach the server");
                    }
                });
        }
    };

    const closePanel = () => {
        document.body.style.overflow = "visible";
        setIsOpen(!isOpen);
        setEmail("");
        setPassword("");
        setIsOpen(false);
        setContinue(true);
        setPasswordVisible(false);
        setOtpVisible(false);
        setSubmit(false);
    };

    const togglePassword = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const toggleOtp = () => {
        setOtpVisible(!isOtpVisible);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleContinue = (e) => {
        e.preventDefault();
        if (email.includes("@")) {
            togglePassword();
        } else {
            toggleOtp();
        }
        setContinue(!isContinueVisible);
        setSubmit(!isSubmitVisible);
    };

    const toggleSignUp = () => {
        document.body.style.overflow = "hidden";
        setIsOpen(!isOpen);
        setSignUp(!isSignUp);
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        // Add your sign-in logic here

        axiosInstance
            .post("/auth/login", { email, password })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem("accessToken", response.data.token);
                    dispatch({
                        type: LOGIN_USER,
                        payload: response.data,
                    });
                    dispatch(getCurrentUser());
                    toast.success("Logged in Successfully");

                    // Reset the form
                    setmessage(`Welcome, `);
                    setEmail("");
                    setPassword("");
                    setIsOpen(false);
                    setContinue(true);
                    setPasswordVisible(false);
                    setOtpVisible(false);
                    setSubmit(false);
                    setisOtp(true);
                    document.body.style.overflow = "visible";
                }
            })
            .catch((err) => {
                seterror(err.response.data.error);
                toast.error(err.response.data.error);
            })

    };

    // signUp
    useEffect(() => {
        let verifypass = [
            /[A-Z]/.test(signUpData.password),
            /[a-z]/.test(signUpData.password),
            /\d/.test(signUpData.password),
            signUpData.password.length >= 8,
        ];
        setpswrdverify(verifypass);
    }, [signUpData.password]);

    useEffect(() => {
        let msg = [...messageSignUp];
        if (!signUpData.name) {
            msg[0] = "*Please enter your name.";
            seterror("");
        } else {
            msg[0] = "";
        }
        setmessageSignUp(msg);
    }, [signUpData.name]);

    useEffect(() => {
        let msg = [...messageSignUp];
        if (!signUpData.username) {
            msg[2] = "*Please enter your username.";
            seterror("");
        } else {
            msg[2] = "";
        }
        setmessageSignUp(msg);
    }, [signUpData.username]);

    useEffect(() => {
        let msg = [...messageSignUp];
        if (!emailValid) {
            msg[1] = "*Please enter a valid Email ID.";
            seterror("");
        } else {
            msg[1] = "";
        }
        setmessageSignUp(msg);
    }, [emailValid]);

    const handleEmailBlur = () => {
        if (signUpData.email && !validator.isEmail(signUpData.email)) {
            setEmailValid(false);
        } else {
            setEmailValid(true);
        }
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        axiosInstance
            .post("/auth/register", signUpData)
            .then((data) => {
                if (data.data.token) {
                    localStorage.setItem("accessToken", data.data.token);
                }
                setEmail("");
                setNumber("");
                setSignUp(false);
                setContinue(true);
                setOtpVisible(false);
                setSubmit(false);
                setisOtp(true);
                document.body.style.overflow = "visible";
            })
            .catch((err) => {
                setmessage(err.response.data.error);
            });
    };

    return (
        <div>
            <div className="w-full items-center text-center">
                <button
                    className="w-[40%] lg:w-[40%] text-white text-[15px] py-[10px] rounded-[4px] bg-customGreen text-black border-[1px] border-sa-border-black mediumFont p-4"
                    onClick={togglePanel}
                >
                    SignIn / SignUp
                </button>
            </div>
            {isOpen && (
                <div className="fixed top-0 right-0 w-full h-screen bg-black bg-opacity-50 z-50 ">
                    <div
                        className={`fixed top-0 right-${isOpen ? "0" : "full"
                            } lg:w-4/12 w-full md:6/12  h-screen bg-white z-100 border-black border-2 
        transition-transform duration-700 transform ${isOpen ? "translate-x-0" : "translate-x-full"
                            }
        `}
                    >
                        <div className="flex items-center justify-between text-26 p-4 border-b-2 border-black mediumFont px-10">
                            <p>Sign In/Sign Up</p>
                            <button className="text-black" onClick={closePanel}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-10 pt-8">
                            <form onSubmit={handleSignIn}>
                                {!message && <>
                                    <div class="font-mabry-pro text-24 font-normal tracking-normal leading-7 text-left">
                                        Enter your email/phone number to continue.
                                    </div>
                                    <div className="mb-4 pt-8">
                                        <label className="custom-div-bg font-normal" style={divStyle}>
                                            Email ID or Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            id="email"
                                            value={email}
                                            placeholder="Enter Email ID or Phone Number"
                                            onChange={handleEmailChange}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div></>}
                                {isPasswordVisible && !message && (
                                    <div className="mb-6">
                                        <label className="custom-div-bg" style={divStyle}>
                                            Password
                                        </label>
                                        <input
                                            type="text"
                                            id="password"
                                            value={password}
                                            placeholder="Enter Password"
                                            onChange={handlePasswordChange}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div>
                                )}

                                {isOtpVisible && !message && (
                                    <div className="mb-6">
                                        <label className="custom-div-bg" style={divStyle}>
                                            Verify OTP
                                        </label>
                                        <input
                                            type="number"
                                            id="password"
                                            value={password}
                                            placeholder="Enter OTP"
                                            onChange={handlePasswordChange}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div>
                                )}

                                {isContinueVisible && !message && (
                                    <div className="flex justify-end">
                                        <button
                                            className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black text-18 font-normal"
                                            onClick={handleContinue}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                )}

                                {error && (
                                    <div className="text-center text-black text-18 font-normal p-2 bg-red-300">{error}</div>
                                )}

                                {isSubmitVisible && !message && (
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black text-18 font-normal"
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                )}


                                {message && (
                                    <div className="text-center text-green-600 text-18 font-normal">{message}</div>
                                )}

                                <div className={divStyle2}>New To Stake Atlas?</div>

                                <button
                                    className="bg-customWhite  hover:bg-gray-100 text-black px-4 py-2 border-2 border-black border-opacity-50 rounded-none w-full mt-5 mediumfont text-18 font-normal"
                                    onClick={toggleSignUp}
                                >
                                    Sign Up
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {isSignUp && (
                <div className="fixed top-0 right-0 w-full h-screen bg-black bg-opacity-50 z-50 ">
                    <div
                        className={`fixed top-0 right-${isSignUp ? "0" : "full"
                            } lg:w-4/12 w-full h-screen bg-white transition duration-300 z-50`}
                    >
                        <div className="flex items-center justify-between text-26 p-4 border-b-2 border-black mediumFont px-10">
                            <p>Sign In/Sign Up</p>
                            <button className="text-black" onClick={closePanelUp}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="px-10 pt-7">
                            <form onSubmit={handleSignUp}>
                                <div class="font-mabry-pro text-24 font-normal tracking-normal leading-7 text-left pb-3">
                                    Enter your email/phone number to Continue
                                </div>
                                {isOtp && (
                                    <div className="mb-2">
                                        <label className="custom-div-bg" style={divStyle}>
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            value={signUpData.name}
                                            placeholder="Enter Name"
                                            onChange={(e) => {
                                                setSignUpData({
                                                    ...signUpData,
                                                    name: e.target.value
                                                })
                                            }}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div>
                                )}
                                {isOtp && (
                                    <div className="mb-2">
                                        <label className="custom-div-bg" style={divStyle}>
                                            Email ID
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            value={signUpData.email}
                                            onBlur={handleEmailBlur}
                                            placeholder="Enter Email ID "
                                            onChange={(e) => {
                                                setSignUpData({
                                                    ...signUpData,
                                                    email: e.target.value
                                                })
                                            }}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div>
                                )}

                                {isOtp && (
                                    <div className="mb-2">
                                        <label className="custom-div-bg" style={divStyle}>
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            id="username"
                                            value={signUpData.username}
                                            placeholder="Enter Username"
                                            onChange={(e) => {
                                                setSignUpData({
                                                    ...signUpData,
                                                    username: e.target.value
                                                })
                                            }}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div>
                                )}

                                {isOtp && (
                                    <div className=" pb-10">
                                        <label className="custom-div-bg" style={divStyle}>
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            value={signUpData.password}
                                            placeholder="Enter Password"
                                            onChange={(e) => {
                                                setSignUpData({
                                                    ...signUpData,
                                                    password: e.target.value
                                                })
                                            }}
                                            className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                            required
                                        />
                                    </div>
                                )}

                                {isOtpVisible && !isOtpSent && (
                                    <div style={{ position: "relative" }}>
                                        <div className="mb-6 mt-6">
                                            <label className="custom-div-bg" style={divStyle}>
                                                Enter Phone No
                                            </label>
                                            <input
                                                type="number"
                                                id="phone"
                                                value={signUpData.phone}
                                                onChange={(e) => {
                                                    setSignUpData({
                                                        ...signUpData,
                                                        phone: e.target.value
                                                    })
                                                }}
                                                placeholder="Enter Phone no"
                                                className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                                required
                                            />
                                        </div>
                                        <button
                                            id="signupresendbtn"
                                            disabled={timeLeft !== null}
                                            onClick={(e) => {
                                                phoneOTP(e);
                                            }}
                                            style={{
                                                position: "absolute",
                                                top: 28,
                                                right: 0,
                                                height: "60%",
                                                width: "50%",
                                            }}
                                            className={`w-full py-2 px-2 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black p-2.5 text-sm mediumFont text-black ${timeLeft === null ? "bg-amber-300" : "bg-gray-300"
                                                } border border-black`}
                                        >
                                            {otp ? (
                                                `Resend OTP ${timeLeft !== null && `(${timeLeft})`}`
                                            ) : (
                                                "Send OTP"
                                            )}
                                        </button>
                                    </div>
                                )}

                                {isOtpSent && (
                                    <div style={{ position: "relative" }}>
                                        <div className="mb-6 mt-6">
                                            <label className="custom-div-bg" style={divStyle}>
                                                Verify OTP
                                            </label>
                                            <input
                                                type="number"
                                                id="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                placeholder="Enter OTP"
                                                className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full text-18 font-normal"
                                                required
                                            />
                                        </div>

                                        {otp ? <button
                                            id="signupresendbtn"
                                            disabled={timeLeft !== null}
                                            onClick={(e) => phoneOTP(e)}
                                            className={`w-[50%] mt-2.5 py-2 px-2 rounded-r 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black p-2.5 text-sm mediumFont text-black ${timeLeft === null ? "bg-amber-300" : "bg-gray-300"
                                                } border border-black`}
                                            style={{
                                                position: "absolute",
                                                top: 18,
                                                right: 0,
                                                height: "60%",
                                                width: "50%",
                                            }}
                                        >
                                            Resend OTP {timeLeft !== null && `(${timeLeft})`}
                                        </button> :
                                            <button
                                                onClick={(e) => phoneOTP(e)}
                                                className="w-[50%] mt-2.5 py-2 px-2 rounded-r 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black p-2.5 text-sm mediumFont text-black bg-amber-300 border border-black hover:bg-amber-300"
                                                style={{
                                                    position: "absolute",
                                                    top: 18,
                                                    right: 0,
                                                    height: "60%",
                                                    width: "50%",
                                                }}
                                            >
                                                Send OTP
                                            </button>}
                                    </div>
                                )}

                                {isOtp && <table className="table-auto mt-2.5 mb-2.5 w-full">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="flex text-[12px]">
                                                    <img
                                                        className={
                                                            "w-4 h-4 mr-1 " + (!pswrdverify[0] ? "grayscale" : "")
                                                        }
                                                        src={tick}
                                                        alt="Uppercase character"
                                                    />
                                                    One uppercase character
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex text-[12px]">
                                                    <img
                                                        className={
                                                            "w-4 h-4 mr-1 " + (!pswrdverify[1] ? "grayscale" : "")
                                                        }
                                                        src={tick}
                                                        alt="Lowercase character"
                                                    />
                                                    One lowercase character
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="flex text-[12px]">
                                                    <img
                                                        className={
                                                            "w-4 h-4 mr-1 " + (!pswrdverify[2] ? "grayscale" : "")
                                                        }
                                                        src={tick}
                                                        alt="Number"
                                                    />
                                                    One number
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex text-[12px]">
                                                    <img
                                                        className={
                                                            "w-4 h-4 mr-1 " + (!pswrdverify[3] ? "grayscale" : "")
                                                        }
                                                        src={tick}
                                                        alt="Minimum 8 characters"
                                                    />
                                                    8 character minimum
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>}

                                {error && (
                                    <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                                        <p className="text-sm">{error}</p>
                                    </div>
                                )}

                                {messageSignUp.filter((item) => item === "").length !== 4 && (
                                    <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                                        {messageSignUp.map(function (m, i) {
                                            return <p className="text-sm" key={i}>{m}</p>;
                                        })}
                                    </div>
                                )}

                                {isContinueVisible && (
                                    <div className="flex justify-end">
                                        <button
                                            className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black text-18 font-normal"
                                            onClick={(e) => handleContinueSignUp(e)}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                )}

                                {isSubmitVisible && (
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black text-18 font-normal"
                                        >
                                            Sign Up
                                        </button>
                                    </div>
                                )}

                                <div className={divStyle2}>Already a user?</div>

                                <button
                                    className="bg-customWhite  hover:bg-gray-100 text-black px-4 py-2 border-2 border-black border-opacity-50 rounded-none w-full mt-5 mediumfont text-18 font-normal"
                                    onClick={handleinup}
                                >
                                    Sign In
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignInpanelGeneral;

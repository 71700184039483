import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUser } from "../../redux/actions/admin";
import ActiveListingCard from "../Selling/ActiveListingCard";
import ProductCard from "../Home/ProductCard";

const AdminListings = () => {
    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.product);
    const listings = products;

    return (
        <div>
            {/* desktop */}
            <div
                className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 overflow-y-auto overflow-x-hidden grid grid-cols-3 gap-10"
            >
                {listings && listings.map((item, index) => (
                    <div className="mb-4">
                        <ProductCard product={item} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminListings;
import React from "react";
import { useState } from "react";
import GalleryClosed from "./ImageGallery/GalleryClosed";
import Gallery from "./ImageGallery/Gallery";

const ImageGalleryTest = ({ preview, productImages }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index) => {
    if (index === 8) {
      setCurrentImageIndex(-1);
    } else {
      setCurrentImageIndex(index);
    }
    setShowGallery(true);
  };

  const handleCloseGallery = () => {
    setShowGallery(false);
  };
  const images = productImages;
  // const images = [
  //   require("../assets/new design/car/car_img1.png"),
  //   require("../assets/new design/car/car_img2.png"),
  //   require("../assets/new design/car/car_img3.png"),
  //   require("../assets/new design/car/car_img4.png"),
  //   require("../assets/new design/car/car_img5.png"),
  //   require("../assets/new design/car/car_img6.png"),
  //   require("../assets/new design/car/car_img7.png"),
  //   require("../assets/new design/car/car_img8.png"),
  //   require("../assets/new design/car/car_img9.png"),
  //   require("../assets/new design/car/car_img10.jpeg"),
  // ];
  return (
    <div className="">
      <GalleryClosed handleImageClick={handleImageClick} preview={preview} images={images} />
      {showGallery && (
        <Gallery
          Images={images}
          currentIndex={currentImageIndex}
          onClose={handleCloseGallery}
        />
      )}
    </div>
  );
};

export default ImageGalleryTest;

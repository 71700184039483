import React from "react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import validator from "validator";
import axiosInstance from "../axiosInstance";
import { useDispatch } from "react-redux";
import { LOGIN_USER } from "../redux/actions/types";
import { toast } from "react-toastify";
import { getUserCart } from "../redux/actions/cart";
import { getCurrentUser } from "../redux/actions/auth";

const SignInPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const id = location.state.id;
  const [type, settype] = useState(true);
  const [userid, setuserid] = useState(location.state.value);
  const [message, setmessage] = useState("");
  const [otp, sentotp] = useState(false);
  const [otpvalue, setOtpValue] = useState(0);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const inputvalue = ["Email", "Phone Number", "Username"];

  useEffect(() => {
    if (type) return;
    if (timeLeft === 0) {
      document.getElementById("resendbtn")?.classList.add("text-blue-100");
      document.getElementById("resendbtn")?.classList.remove("text-gray-400");
      document.getElementById("resendbtn")?.classList.add("underline");
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    document.getElementById("resendbtn")?.classList.remove("text-blue-100");
    document.getElementById("resendbtn")?.classList.add("text-gray-400");
    document.getElementById("resendbtn")?.classList.remove("underline");
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const phoneOTP = () => {
    sentotp(false);
    // if (otpcount >= 5)
    //   setmessage("You have requested for OTP 5 times. Please try again later!");
    // else

    // code for otp verification
    axiosInstance
      .post(`/auth/checkUserExist`, { phone: userid })
      .then((res) => {
        console.log(res.data);
        axiosInstance
          .post(`/auth/login/phoneOTP/${userid}`)
          .then((response) => {
            sentotp(true);
            setTimeLeft(30);
            const otpReceived = response.data.data;
            setOtpValue(otpReceived);
            console.log(otpReceived);
          })
          .catch((err) => {
            console.log(err);
            setmessage(err.response.data.error);
          });
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              // Add a toast
              toast.error("Invalid Data!");
              break;
            case 401:
              // Add a toast
              toast.error("Invalid credentials!");
              break;
            case 404:
              // Add a toast
              setmessage("User Does not Exist");
              break;
            default:
              // Server error
              toast.error("Oops, something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };

  const sendOTP = () => {
    console.log("Send OTP called", id);
    setmessage("");

    switch (id) {
      case 0: {
        //code for email otp verification
        if (validator.isEmail(userid)) {
          // Checking if email exists
          axiosInstance
            .post(`/auth/checkUserExist`, { email: userid })
            .then((res) => {
              axiosInstance
                .post(`/auth/login/emailOTP/${userid}`)
                .then((response) => {
                  sentotp(true);
                  setTimeLeft(30);
                  const otpReceived = response.data.data;
                  setOtpValue(otpReceived);
                })
                .catch(({ response }) => {
                  try {
                    switch (response.status) {
                      case 400:
                        //   add a toast
                        toast.error("Invalid Data!");
                        sentotp(false);
                        break;
                      case 401:
                        //   add a toast
                        toast.error("Invalid credentials!");
                        sentotp(false);
                        break;
                      default:
                        // server error
                        toast.error("Oops, something went wrong");
                        sentotp(false);
                        break;
                    }
                  } catch (e) {
                    toast.error("Couldn't reach the server");
                  }
                });
            })
            .catch(({ response }) => {
              try {
                switch (response.status) {
                  case 400:
                    // Add a toast
                    toast.error("Invalid Data!");
                    break;
                  case 401:
                    // Add a toast
                    toast.error("Invalid credentials!");
                    break;
                  case 404:
                    // Add a toast
                    setmessage("User does not Exist");
                    break;
                  default:
                    // Server error
                    toast.error("Oops, something went wrong");
                    break;
                }
              } catch (e) {
                toast.error("Couldn't reach the server");
              }
            });
        } else setmessage("You have entered an incorrect email.");
        break;
      }
      case 1: {
        console.log("Sending phone OTP");
        if (validator.isMobilePhone(userid)) {
          // code for phone otp verification
          phoneOTP();
        } else setmessage("You have entered an incorrect mobile.");
        break;
      }
      case 2: {
        console.log("Sneding 3rd OTP");
        // code for username OTP verification
        sentotp(true);
        setTimeLeft(30);
        break;
      }
    }
  };
  const togglePSWRD = () => {
    settype(!type);
    sentotp(false);
    if (type) sendOTP();
  };

  const Submit = (e) => {
    e.preventDefault();
    const pswrd = document.getElementById("password").value;
    switch (id) {
      case 0:
        //verify email password/otp
        type
          ? axiosInstance
            .post("/auth/login", { email: userid, password: pswrd })
            .then((response) => {
              if (response.data.token) {
                console.log("Before Setting Access Token on Login" + response.data.token);
                localStorage.setItem("accessToken", response.data.token);
                console.log("After Setting Access Token on Login" + response.data.token);
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                dispatch(getCurrentUser());
                toast.success("Logged in Successfully");
                navigate("/signin/success");
              }
            })
            .catch((err) => {
              setmessage(err.response.data.error);
            })
          : pswrd === otpvalue.toString() ?
            (axiosInstance
              .post("/auth/loginWithOTP", { email: userid })
              .then((response) => {
                if (response.data.token) {
                  localStorage.setItem("accessToken", response.data.token);
                  dispatch({
                    type: LOGIN_USER,
                    payload: response.data,
                  });
                  dispatch(getCurrentUser());
                  toast.success("Logged in Successfully");
                  navigate("/signin/success");
                }
              })
              .catch((err) => {
                setmessage(err.response.data.error);
              }))
            :
            setmessage("Wrong OTP! Please Try Again");
        break;
      case 1:
        //verify phone password/otp
        type
          ? axiosInstance
            .post("/auth/login", { phone: userid, password: pswrd })
            .then((response) => {
              if (response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                toast.success("Logged in Successfully");
                navigate("/signin/success");
              }
            })
            .catch((err) => {
              setmessage(err.response.data.error);
            })
          : pswrd === otpvalue.toString() ?
            axiosInstance
              .post("/auth/loginWithOTP", { phone: userid })
              .then((response) => {
                if (response.data.token) {
                  localStorage.setItem("accessToken", response.data.token);
                  dispatch({
                    type: LOGIN_USER,
                    payload: response.data,
                  });
                  toast.success("Logged in Successfully");
                  navigate("/signin/success");
                }
              })
              .catch((err) => {
                setmessage(err.response.data.error);
              }) : setmessage("Wrong OTP! Please Try Again");
        break;
      case 2:
        //verify userid password/otp
        type
          ? axiosInstance
            .post("/auth/login", { username: userid, password: pswrd })
            .then((response) => {
              if (response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                toast.success("Logged in Successfully");
                navigate("/signin/success");
              }
            })
            .catch((err) => {
              console.log("You were here");
              console.log(err);
              setmessage(err.response.data.error);
            })
          : setmessage("Functionality not available");
        break;
      case 3:
        //verify email otp
        navigate("/signin/success");
        break;
      default:
        setmessage("You have entered incorrect id and password combination.");
    }
  };


  return (
    <div className=" w-full bg-[#FFF9F0] flex items-center justify-center py-10">
      <div className="cursor-default border md:border-2 border-black w-[90%] max-w-[450px] min-h-[73%] rounded-md py-6 md:py-12 px-4 md:px-7 bg-white">
        <h1 className="text-3xl mediumFont text-black">Sign In</h1>
        <p
          onClick={() => navigate("/signin/username")}
          className="cursor-pointer text-[13px] mediumFont text-blue-100 underline underline-offset-4 mt-1 mb-2"
        >
          &larr; Sign in with {id === 0 ? "Phone Number" : "Email"} /{" "}
          {id === 2 ? "Phone Number" : "Username"}
        </p>
        <div className="mt-8">
          <div>
            <p className="text-base mediumFont text-black">{inputvalue[id]}</p>
            <input
              type="text"
              value={userid}
              placeholder="Your Email"
              className=" w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
            />
          </div>
          <div className="mt-3">
            <div className="flex items-center justify-between">
              <p className="text-base mediumFont text-black">
                {type ? "Password" : "OTP"}
              </p>
              {id !== 2 && <p
                onClick={() => togglePSWRD()}
                className="cursor-pointer text-[13px] mediumFont text-blue-100 underline underline-offset-4"
              >
                Sign in with {type ? "OTP" : "Password"} instead
              </p>}
            </div>
            <input
              id="password"
              type="password"
              placeholder=""
              className=" w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
            />
            {type ? (
              <button
                onClick={() => navigate("/signin/forgotPassword", { state: id === 0 ? { email: userid } : undefined })}
                className="text-[13px] mediumFont text-blue-100 underline underline-offset-4 mt-1 mb-2"
              >
                Forgot your password?
              </button>
            ) : (otp &&
              <button
                id="resendbtn"
                disabled={timeLeft}
                onClick={() => {
                  sendOTP();
                }}
                className="text-[13px] mediumFont underline-offset-4 mt-1 mb-2"
              >
                Resend OTP {timeLeft && "( " + timeLeft + " Secs )"}
              </button>
            )}
            {message !== "" && (
              <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                <p className="text-sm">{message}</p>
              </div>
            )}
            {otp && (
              <div className="mt-3 py-3 px-3 bg-[#ecffe9]">
                <p className="text-sm">
                  OTP Sent on your {id == 1 ? "Phone Number" : "Email"}
                </p>
              </div>
            )}
          </div>
          <button
            onClick={(e) => Submit(e)}
            className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
          >
            Sign In
          </button>
        </div>
        <div className="mt-9">
          <h1 className="text-center">New To Stake Atlas?</h1>
          <button
            onClick={() => navigate("/signup", { state: id === 0 ? { email: userid } : undefined })}
            className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-white text-black border-[1px] border-sa-border-black mediumFont mt-2"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInPasswordScreen;

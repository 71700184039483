
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import DefaultLayout from "./pages/layouts/DefaultLayout";
import LoadingScreen from "./pages/LoadingScreen";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentUser, getUserNotifications, userOrders } from "./redux/actions/auth";
import checkTokens, { getTokens } from "./utils/auth/auth";
import Modal from "./containers/Modal";
import { getAllCategories } from "./redux/actions/category";
import { getAllProducts, getAllUserDrafts } from "./redux/actions/product";
import { getUserCart } from "./redux/actions/cart";
import io from 'socket.io-client';
const ENDPOINT = "https://api.stakeatlas.com";
var socket;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});

function App({ modal }) {
  
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth)
  
  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden" && user) {
      socket.emit('userDeactive', user.id); 
    } else if (user) {
      socket.emit('userActive', user.id);
    }
  };

  useEffect(() => {
    // starting calls on every reload
    setTimeout(() => setLoading(false), 1000);
    dispatch(getAllProducts());
    dispatch(getAllCategories());
    if (checkTokens()) {
      getTokens()
      dispatch(getCurrentUser());
      dispatch(getUserNotifications());
    }

  }, [dispatch]);

  useEffect(() => {
    socket = io(ENDPOINT, {
        path: '/api/v1/socket.io',
      });
    if (user) {
      socket.emit('userActive', user.id);
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }
  }, [user]);


  return (
    <>
      {!loading ? (
        <>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {/* <Toasts /> */}
              <ToastContainer position="top-right" autoClose={2000} />
              <DefaultLayout />
              {modal?.modalType && <Modal modal={modal} />}
            </BrowserRouter>
          </QueryClientProvider>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

export default connect(mapStateToProps)(App);

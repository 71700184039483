import React from "react";
import SideMenu from "../components/Buying/SideMenu";
import ProductCard from "../components/Selling/ProductCard";
import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import ListingPreview from "../components/Selling/ListingPreview";
import { ExpandMore, ExpandLess, Cancel } from "@mui/icons-material";
import PendingPayment from "../components/Selling/PendingPayment";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import { useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

const SoldMenu = () => {
  const navigate = useNavigate();
  const offerTabs = ["All", "Pending Payment", "Paid", "Shipped", "Completed"];
  const { user } = useSelector((state) => state.auth);
  const [fontSize, setFontSize] = useState("text-4xl");
  const [activeTab, setActiveTab] = useState("All");
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setFontSize("text-xl");
    } else {
      setFontSize("text-4xl");
    }
  };
  const [phonesidenav, showphonesidenav] = useState(true);
  const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
  const [showsort, setshowsort] = useState(false);
  const [sorttype, setsorttype] = useState(0);
  const [listings, setListings] = useState([]);

  useEffect(() => {
      axiosInstance
        .get(`/product/activeListings/sold`)
        .then((response) => {
          setListings(response.data.data);
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              default:
                // server error
                toast.error("Oops! Something Went Wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    }, []);

  const data = [
    {
      id: 1,
      status: "Pending Payment",
    },
    {
      id: 2,
      status: "paid",
    },
    {
      id: 3,
      status: "completed",
    },
    { id: 4, status: "Pending Payment" },
    {
      id: 5,
      status: "paid",
    },
    {
      id: 6,
      status: "completed",
    },
    { id: 7, status: "Pending Payment" },
    {
      id: 8,
      status: "paid",
    },
    {
      id: 9,
      status: "completed",
    },
    { id: 10, status: "Pending Payment" },
  ];
  const [selectedOption, setSelectedOption] = useState("New To Old");
  const [isOpen, setIsOpen] = useState(false);

  const options = ["New To Old", "Old To New", "A to Z", "Z to A"];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {/* desktop */}
      { user && listings?
          <div className="hidden 2md:flex items-start min-h-screen">
            <div className="self-stretch">
              <SideMenu />
            </div>
            <div className="flex-1 w-full overflow-hidden bg-[#FCF9F4]">
              <div
                className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                  }`}
              >
                <h1
                  className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
                >
                  Sold
                </h1>
                <div
                  className={`${fontSize === "text-4xl" ? "mt-5" : "mt-2"
                    } transition-all duration-300 ease-in-out flex items-center justify-between`}
                >
                  <div className="flex items-center flex-wrap max-w-[600px] mr-10 grow justify-between">
                    {offerTabs.map(function (e, i) {
                      return (
                        <button
                          onClick={() => setActiveTab(e)}
                          key={i}
                          className={`px-5 text-[18px] mt-2.5 py-2  rounded-md ${activeTab === e
                            ? "bg-white border-[1px] border-sa-border-black"
                            : "bg-[#FCF9F4]  border-[1px] border-[#FCF9F4]"
                            } text-black mediumFont flex items-center justify-center`}
                        >
                          {e}
                        </button>
                      );
                    })}
                  </div>
                  <div className="relative z-30 inline-block text-left">
                    <div
                      className="flex items-center justify-between w-36 px-4 py-2 text-black  bg-transparent border border-black rounded-md shadow-sm cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      <span>{selectedOption}</span>
                      <span className="ml-2">
                        {isOpen ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                      </span>
                    </div>
                    {isOpen && (
                      <ul className="absolute right-0 w-36 mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
                        {options.map((option) => (
                          <li
                            key={option}
                            className={`px-4 py-2 cursor-pointer ${selectedOption === option
                              ? "bg-white"
                              : "bg-transparent"
                              }`}
                            onClick={() => handleOptionSelect(option)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <div className="relative z-20 w-max place-items-center">
                <button
                  onClick={() => { setshowsort(!showsort) }}
                  className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
                  ${(showsort) ? "rounded rounded-b-none border-b-0" : "rounded"} 
                  text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}>
                  Sort: {sortarray[sorttype]}  &nbsp;{!showsort ? <ExpandMore /> : <ExpandLess />}
                </button>
                <div className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort ? "max-h-[100vh] border-[1px]" : "max-h-0 border-[0px]"}`}>
                  {sortarray.map((type, i) => (
                    <div key={`cat${i}`} onClick={() => { setsorttype(i); setshowsort(!showsort); }} className='mt-2.5 py-2 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3'>
                      <p>{type}</p>
                    </div>
                  ))}
                </div>
              </div> */}
                </div>
              </div>
              <div
                onScroll={handleScroll}
                className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 h-screen overflow-y-auto overflow-x-hidden grid grid-cols-fluid"
              >
                {listings.map((item) => (
                  <ProductCard product={item} key={item.id} status={item.status.state} />
                ))}
              </div>
            </div>
          </div> :
          <div className=" min-h-screen md:flex hidden">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
      {/* mobile */}
      {user ?
          <div className=" bg-[#FCF9F4] min-h-screen pb-28 2md:hidden">
            <div
              className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
                } overflow-hidden`}
            >
              <SideMenu />
            </div>
            <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
              <h1 className="text-[20px] mediumFont text-sa-menu-green">Selling</h1>
              <h1 className="text-[26px] mediumFont text-black tracking-wide">
                Active Listings
              </h1>
            </div>
            <div className="pt-7 px-4 grid grid-flow-row align-center justify-center transition-all  duration-500 ease">
              {data.map((item) => (
                <ProductCard key={item.id} status={item.status} />
              ))}
            </div>
            <div className="fixed  z-50 bottom-0 w-full grid grid-cols-[100%]">
              <div
                className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
                  }`}
              >
                <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
                <div
                  onClick={() => {
                    setshowsort(!showsort);
                  }}
                  className=" absolute top-4 right-3"
                >
                  <Cancel className="text-base " />
                </div>
                {sortarray.map((type, i) => (
                  <button
                    className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                      }`}
                    onClick={() => {
                      setsorttype(i);
                    }}
                  >
                    {type}
                  </button>
                ))}
              </div>
              <div className="grid grid-cols-[50%_50%]">
                <button
                  onClick={() => {
                    showphonesidenav(!phonesidenav);
                  }}
                  className={` w-full text-base py-2 ${phonesidenav
                    ? "bg-black text-sa-primary-yellow"
                    : "bg-sa-primary-yellow text-black"
                    } border-[1px] border-sa-border-black mediumFont`}
                >
                  Offers
                </button>
                <button
                  onClick={() => {
                    setshowsort(!showsort);
                  }}
                  className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
                >
                  Sort: {sortarray[sorttype]}
                </button>
              </div>
            </div>
          </div> :
          <div className=" min-h-screen md:hidden">
            <div className="w-full ">
              <div>
                <div className="text-[32px] mediumFont p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default SoldMenu;

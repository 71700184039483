import React, { useRef, useState } from 'react';
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import { MdOutlineAttachFile } from 'react-icons/md';
import { useSelector } from 'react-redux';
import axiosInstance from '../axiosInstance';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import io from 'socket.io-client';
import CryptoJS from 'crypto-js';

const ENDPOINT = "https://api.stakeatlas.com/api/v1";
var socket;

const Messaging = ({ sender, chatId }) => {
  const messageContainerRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const secretKey = 'SECRET_KEY';


  const encryptMessage = (message) => {
    const ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
    return ciphertext;
  };

  const decryptMessage = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalMessage = bytes.toString(CryptoJS.enc.Utf8);
    return originalMessage;
  };

  const fetchMessages = async () => {
    setLoading(true);
    axiosInstance
      .get(`/messaging/allMessages/${chatId}`)
      .then((response) => {
        const decryptedMessages = response.data.data.map((message) => {
          const decryptedMessage = decryptMessage(message.content);
          message.content = decryptedMessage;
          return message;
        });

        setChatMessages(decryptedMessages);
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  }

  useEffect(() => {
    fetchMessages();
  }, []);


  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);

    // typing indicator logic
  };

  const sendNewMessage = () => {
    if (newMessage.trim() !== "") {
      const encryptedMessage = encryptMessage(newMessage);
      setNewMessage("");
      axiosInstance
        .post(`/messaging/sendMessage`, {
          content: encryptedMessage,
          chatId: chatId
        })
        .then((response) => {
          const decryptedResponse = decryptMessage(response.data.data.content);
          socket.emit("new message", response.data.data);
          response.data.data.content = decryptedResponse;

          setChatMessages([
            ...chatMessages,
            response.data.data
          ]);

          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");
                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");
                break;
              default:
                // server error
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    }
  };

  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit('setup', user);
    socket.on('connected', () => setSocketConnected(true));
    socket.emit("join chat", chatId);
  }, []);

  useEffect(() => {
    socket.on("message recieved", (newMessageReceived) => {
      const decryptedMessage = decryptMessage(newMessageReceived.content);
      const updatedMessage = { ...newMessageReceived, content: decryptedMessage };

      if (messageContainerRef.current) {
        const tolerance = 1;
        const isUserAtBottom = Math.abs(messageContainerRef.current.scrollTop + messageContainerRef.current.clientHeight - messageContainerRef.current.scrollHeight) <= tolerance;
        const shouldScrollToBottom = isUserAtBottom;

        setChatMessages([...chatMessages, updatedMessage]);

        if (shouldScrollToBottom) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        } else {
          setScrollToBottom(true);
        }
      }
    });
  });

  const getCurrentTime = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Zero-pad minutes if needed
    const time = `${hours}:${formattedMinutes}`;
    return time;
  };
  const handleGoBack = () => {
    // setSelectedMessage(null);
  };

  const scrollToBottomFunction = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
    setScrollToBottom(false);
  };

  const renderMessages = () => {
    const messageElements = [];
    let currentGroup = null;

    // Array of month abbreviations
    const monthAbbreviations = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    chatMessages.forEach((message, index) => {
      const isUserMessage = message.sender.id === user.id;
      const senderGroup = isUserMessage ? 'user' : 'other';

      // Check if the group has changed
      if (senderGroup !== currentGroup) {
        currentGroup = senderGroup;
        const groupName = senderGroup === 'user' ? 'You' : `@${sender?.username}`;

        // Apply CSS class to headings based on sender group
        const headingClass = senderGroup === 'user' ? 'text-right font-bold' : 'text-left font-bold';
        messageElements.push(<h2 key={`group-heading-${index}`} className={headingClass}>{groupName}</h2>);
      }

      const updatedAtDate = new Date(message.updatedAt);
      const formattedDate = `${updatedAtDate.getDate()} ${monthAbbreviations[updatedAtDate.getMonth()]}`;
      const formattedTime = `${updatedAtDate.getHours()}:${updatedAtDate.getMinutes().toString().padStart(2, '0')}`;


      messageElements.push(
        <div key={index} className={`mb-2 flex ${isUserMessage ? 'justify-end' : 'justify-start'}`}>
          {isUserMessage && <p>{message.sender.name}</p>}
          <div className={`rounded-md p-2 border border-black text-black ${isUserMessage ? 'bg-green-300' : 'bg-blue-300'}`}>
            <div>{message?.content}</div>
            <div className="text-xs text-gray-500">{formattedTime}  {formattedDate}</div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {messageElements}
      </div>
    );
  };


  return (
    <div className=" flex flex-col bg-white h-[490px]">
      <div className="flex items-center bg-black text-white py-2">
        <FaArrowLeft
          className="text-gray-500  justify-start float-left flex mr-4 cursor-pointer"
          onClick={handleGoBack}
        />
        <h2 className="w-full flex justify-center">Message Detail</h2>
      </div>
      <div className="border-t px-4 border-b border-black overflow-y-auto no-scrollbar flex-grow" ref={messageContainerRef}>
        {chatMessages ? renderMessages() : "Loading"}
      </div>
      {scrollToBottom && 
      <div className="fixed bottom-0 left-0 right-0 mb-2 mx-auto">
        <button
          className="px-4 py-2 bg-[#F7CB45] text-black"
          onClick={scrollToBottomFunction}
        >
          Scroll to Bottom
        </button>
      </div>
      }
      <div className="flex">
        <input
          type="text"
          className="w-full  px-4 py-2 focus:outline-none"
          placeholder="Type a message..."
          value={newMessage}
          onChange={handleNewMessageChange}
        />
        <MdOutlineAttachFile
          className="text-black cursor-pointer"
          size={30}
        />
        <button
          className="ml-2 px-4 py-2 bg-[#F7CB45] text-black border-black border-l"
          onClick={sendNewMessage}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Messaging;

  import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

  const style = {
    menu: (base) => ({
      ...base,
      margin : 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      border: `1px solid black`,
      "&:hover": {
        border: `1px solid black`,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black", 
      backgroundColor: state.isFocused ? "#FFDC25" : "white", 
      border: "1px solid black",
      borderTop: "none",
      "&:hover": {
        backgroundColor: "#FFDC25", 
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "black",
    }),
  };

  
  const DropDown = ({ setInfo, info, product, options, tochange, previous }) => {
    const [selectedOption, setSelectedOption] = useState(
      previous ? options.find(option => option.value === previous) : null
    );
    
    useEffect(() => {
      switch (tochange) {
        case "condition":
          setInfo({ ...info, condition: selectedOption?.value });
          break;
        case "listing_duration":
          setInfo({ ...info, listing_duration: selectedOption?.value });
          break;
        default:
          break;
      }
    }, [selectedOption]);
  
    return (
      <div className="mt-1">
        <Select
          options={options}
          value={selectedOption}
          onChange={setSelectedOption}
          styles={style}
          className="w-full focus:outline-none"
        />
      </div>
    );
  };
  
  export default DropDown;
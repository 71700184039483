import {
  AccessTimeOutlined,
  LocationOnOutlined,
  MailOutline,
  Star,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DropDown from "../components/DropDown";
import PreviewCard from "../components/Seller/PreviewCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserDrafts, saveDraft, saveProductDetails } from "../redux/actions/product";
import { itemSpecifics } from "../redux/actions/category";
import NestedFilterDropDown from "../components/NestedFilterDropDown";
import SequentialDropdown from "../components/SequentialDropDown";
import Dropdown2 from "../components/DropDown2";
import SaveItemSellingDraft from "../components/Modals/SaveItemSellingDraft";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import { Link } from "react-router-dom";

const GeneralScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { product } = useSelector((state) => state.product);
  const [info, setInfo] = useState(product);
  const { categories } = useSelector((state) => state.category);
  const { item_specifics } = useSelector((state) => state.category);
  const [offerButton, setOfferButton] = useState(false);
  const [priceButton, setPriceButton] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  const options = [
    { value: "New", label: "New" },
    { value: "Used - Good", label: "Used - Good" },
    { value: "Used - Fair", label: "Used - Fair" },
    { value: "Old", label: "Old" },
    { value: "Refurbished", label: "Refurbished" }
  ];
  const durationOptions = [
    { value: "1 week", label: "1 week" },
    { value: "2 week", label: "2 week" }
  ];


  const width = 25;

  const productDetailHandler = () => {
    dispatch(saveProductDetails(info));
    dispatch(itemSpecifics(product.category));
    navigate("/sellnow/listing/media");

  };

  const handleSave = () => {
    dispatch(saveProductDetails(info));
    setModalOpen(true);
  }

  const handleCheckboxChange = () => {
    setOfferButton(!offerButton);
    setInfo({ ...info, make_offer: !offerButton })
  };

  const handleSaveDraft = () => {
    dispatch(saveDraft(product));
    dispatch(getAllUserDrafts());
    navigate("/sellnow/drafts");
  }



  return (
    <>
      {user && user.role !== "customer_unverified" ?
        <div>
          <div className="px-4.5 mt-7 md:px-[4vw] md:mt-10 mb-10">
            <div className="h-[80vh] border-[1px] border-black pl-3 max-[768px]:pr-3 xl:border-[2px] xl:px-0 lg:flex md:items-start overflow-y-hidden">
              <div className=" lg:w-[51%] lg:px-[2vw] lg:border-r-[2px] border-black h-full ">
                <div className="flex-col align-top h-full">
                  {/* what are u selling today */}
                  <div className="h-[10%] flex items-start mt-7 gap-x-3 ">
                    <div>
                      <img
                        src={require("../assets/new design/Vector.png")}
                        alt=""
                        className="w-9 xl:w-12"
                      />
                    </div>
                    <h1 className=" text-2xl xl:text-4xl mediumFont text-black leading-7 p-0">
                      What are you selling today?
                    </h1>
                  </div>
                  {/* actual desctiption */}
                  <div className="h-[70%] overflow-y-auto no-scrollbar md:pr-7">
                    <div className="mt-6 border-b-[1px] border-black pb-2.5">
                      <h1 className=" text-[21px] xl:text-2xl mediumFont text-black">
                        General Description
                      </h1>
                    </div>
                    <div className="mt-5">
                      <div className="flex justify-between mt-5"> {/* Add this div */}
                        <div className="w-1/2 mr-4"> {/* Divide the width as per your requirement */}
                          <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                            Condition
                          </h1>
                          <DropDown
                            setInfo={setInfo}
                            info={info}
                            options={options}
                            tochange={"condition"}
                            previous={info?.condition}
                          />
                        </div>
                        <div className="w-1/2"> {/* Divide the width as per your requirement */}
                          <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                            Listing Period
                          </h1>
                          <DropDown
                            setInfo={setInfo}
                            info={info}
                            options={durationOptions}
                            tochange={"listing_duration"}
                            previous={info?.listing_duration}
                          />
                        </div>
                      </div>
                      <div className="mt-5">
                        <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                          Title
                        </h1>
                        <input
                          onChange={(e) =>
                            setInfo({ ...info, title: e.target.value })
                          }
                          type="text"
                          value={info?.title}
                          className="w-full rounded border-[1px] border-black mt-1 xl:mt-1.5 py-1.5 px-3 focus:outline-none"
                        />
                      </div>
                      <div className="mt-5">
                        <div className="flex flex-row gap-x-[50px]">
                          <div className={priceButton === true ? "w-1/2" : "w-full"}>
                            <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                              Cost
                            </h1>
                            <input
                              onChange={(e) =>
                                setInfo({ ...info, cost: e.target.value })
                              }
                              onWheel={(e) => e.target.blur()}
                              type="number"
                              value={info?.cost}
                              className={
                                "w-full rounded border-[1px] border-black mt-1 xl:mt-1.5 py-1.5 px-3 focus:outline-none"
                              }
                            />
                          </div>
                          <div
                            className={priceButton === true ? "w-1/2" : "hidden"}
                          >
                            <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                              Minimum offer amount
                            </h1>
                            <input
                              onChange={(e) =>
                                setInfo({ ...info, min_offer_amt: e.target.value })
                              }
                              onWheel={(e) => e.target.blur()}
                              type="number"
                              value={info?.min_offer_amt}
                              className={
                                "w-full rounded border-[1px] border-black mt-1 xl:mt-1.5 py-1.5 px-3 focus:outline-none"
                              }
                            />
                          </div>
                        </div>
                        <div className="flex items-center mt-4 gap-x-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="focus:outline-none"
                            onClick={() => setOfferButton(!offerButton)}
                            onChange={handleCheckboxChange}
                            styles={{
                              webkitOuterSpinButton: "none",
                              webkitInnerSpinButton: "none",
                              mozAppearnace: "none",
                            }}
                          />
                          <h1 className="text-sm xl:text-base mediumFont text-black">
                            Accept Offers
                          </h1>
                        </div>
                        <div
                          className={
                            offerButton === true
                              ? "flex items-center mt-4 gap-x-2"
                              : "hidden items-center mt-4 gap-x-2"
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id="setMinimumOffer"
                            className="focus:outline-none"
                            onClick={() => {
                              setPriceButton(!priceButton);
                              setInfo({ ...info, make_offer: offerButton })
                            }}
                          />
                          <h1 className="text-sm xl:text-base mediumFont text-black">
                            Set Minimum Offer
                          </h1>
                        </div>
                      </div>
                      <div className="mt-3">
                        <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                          Category
                        </h1>
                        {/* <input
                    type="text"
                    className="w-full rounded border-[1px] border-black mt-1 xl:mt-1.5 py-1.5 px-3"
                  /> */}
                        <NestedFilterDropDown categories={categories} />
                      </div>
                      {/* <div className="mt-3" style={{ zIndex: -1 }}>
                      <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                        Item Specifics
                      </h1>
                      <SequentialDropdown items={item_specifics} />
                    </div> */}
                      <div className="mt-5">
                        <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                          Description{" "}
                        </h1>
                        <textarea
                          onChange={(e) =>
                            setInfo({ ...info, description: e.target.value })
                          }
                          type="text"
                          value={info?.description}
                          className="w-full h-auto rounded border-[1px] border-black mt-1 py-1.5 px-3 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  {/* buttons */}
                  <div className=" h-[20%] flex items-center lg:justify-end justify-between  gap-2 px-2">
                    <button
                      onClick={() => handleSave()}
                      className="text-[14px] py-3 px-10 rounded-[4px] bg-[#00BEA7] text-black border-[1px] border-sa-border-black mediumFont"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => productDetailHandler()}
                      className="text-[14px] py-3 px-10 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-[49%]">
                <PreviewCard
                  info={info}
                  offerButton={offerButton}
                  width={width}
                  focusedHighlight={0}
                  pageNo="0%"
                />
              </div>
            </div>
          </div>
          <SaveItemSellingDraft
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSuccess={() => handleSaveDraft()}
            title="Save Listing As a Draft"
            content="You can comeback anytime to complete this listing."
            confirmText="Save"
            cancelText="Cancel"
          />
        </div> :
        user && user.role === "customer_unverified" && user.seller_request !== "created" ?
          <div className=" min-h-screen md:flex hidden">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must register as a seller to Proceed.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Only One listing per customer is allowed, For more please

                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/seller/registration">Register Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div> : user && (user.role === "customer_unverified" || user.role === "customer") && user.seller_request === "created" ?
            <div className="h-[50vh] bg-[#F3CEB4] px-5 py-14 xl:pt-28 xl:h-[60vh]">
              <div className=" border-[1px] border-black bg-white py-12 px-5 xl:w-fit xl:mx-auto xl:px-10 xl:py-12">
                <h1 className="text-3xl mediumFont text-black text-center tracking-wide xl:text-[40px]">
                  Your Request is under Review!
                </h1>
                <p className="text-lg mediumFont text-black text-center mt-2 tracking-wide xl:text-[29px] xl:mt-3">
                  You will be informed, when we are done.
                </p>
                <div className="flex items-center justify-center gap-x-12 mt-5 xl:mt-8">
                  <h1 className="text-[22px] boldFont tracking-wide text-[#737373] xl:text-[25px]">
                    Status
                  </h1>
                  <div className="flex items-center gap-x-1">
                    <AccessTimeOutlined fontSize="small" />
                    <h1 className="text-[22px] boldFont tracking-wide text-sa-icon-green xl:text-[25px]">
                      Pending
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>
      }
    </>
  );
};

export default GeneralScreen;

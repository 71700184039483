import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUser } from "../../redux/actions/admin";
import ActiveListingCard from "../Selling/ActiveListingCard";
import ProductCard from "../Home/ProductCard";
import ProductCardMob from "../Home/ProductCardMob";

const AdminListingMobile = () => {
    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.product);
    const listings = products;


    return (
        <div>
            {/* Mobile */}
            <div className="pt-7 px-4 grid grid-flow-row align-center justify-center transition-all  duration-500 ease">
                {listings && listings.map((item, index) => (
                    <div className="mb-4">
                        <ProductCardMob product={item} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminListingMobile;
import { CLEAR_DETAILS, SAVE_DETAILS, USER_DRAFTS, DELETE_DRAFT,ALL_PRODUCTS, LISTING_OFFERS, CLEAR_IMAGES, PRODUCT_COMPRESSED_IMAGES, PRODUCT_ORIGINAL_IMAGES } from "../actions/types";

const initialState = {
  product: {},
  productCopy: {}
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DETAILS:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload
        },
        productCopy: {
          ...state.product,
          ...action.payload
        }
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        product: null
      };
    case ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }
    case PRODUCT_COMPRESSED_IMAGES:
      return {
        ...state,
        products: state.products,
        images: {
          ...state.images,
          compressed: action.payload
        }
      }
    case PRODUCT_ORIGINAL_IMAGES:
      return {
        ...state,
        products: state.products,
        images: {
          ...state.images,
          original: action.payload
        }
      }
    case CLEAR_IMAGES:
      const { images, ...newState } = state;
      return newState;
    case USER_DRAFTS:
      return {
        ...state,
        userDrafts: action.payload
      }
    case DELETE_DRAFT:
      const draftIdToDelete = action.payload;
      const updatedUserDrafts = state.userDrafts.filter(
        draft => draft.id !== draftIdToDelete
      );
      return {
        ...state,
        userDrafts: updatedUserDrafts
      };
      case LISTING_OFFERS:
        const updatedListings = state.products.map(listing => {
          if (listing.id === action.id) {
            return {
              ...listing,
              offers: action.payload,
            };
          }
          return listing;
        });
  
        return {
          ...state,
          products: updatedListings, 
        };
    default:
      return state;
  }
};

export default productReducer;
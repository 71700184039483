import React from "react";
import { AiFillStar } from "react-icons/ai";
import userIcon from "../../assets/new design/user.png";

const PendingPayment = ({ status }) => {
  return (
    <div>
      {status === "Pending" && (
        <div className="w-full border bg-white border-black pb-[28px]">
          <div className="w-full bg-[#0076BE] text-white capitalize text-[28px] flex justify-center items-center py-[20px]">
            pending payment
          </div>
          {/* details */}
          <div className="flex justify-between">
            {/* details */}
            <div className="pl-[48px] pt-5 w-1/2">
              <div className="text-black text-[24px] mediumFont">
                Buyer's Details:
              </div>
              <div className="pt-[18px] flex">
                {/* image div */}
                <div className="flex border-r border-black pr-[36px]">
                  <img
                    class="w-[58px] h-[58px] rounded-full border border-black"
                    src={userIcon}
                  />
                  <div className="pl-[12px]">
                    <div className="text-black text-[20px] mediumFont">
                      @rakeshphonedukaan
                    </div>
                    <div
                      className="text-neutral-700 text-[18px] 
"
                    >
                      Member since 2022
                    </div>
                  </div>
                </div>
                {/* rating div */}
                <div>
                  <div
                    className="text-stone-500 text-[14px] boldFont pl-[45px]
"
                  >
                    RATING
                  </div>
                  <div className="pl-[36px] flex items-center space-x-1">
                    <div className=" text-black text-[32px] boldFont">4.5</div>
                    <AiFillStar className="text-[#FFDC25] text-[28px] " />
                  </div>
                </div>
              </div>

              {/* comment */}
              <div className="text-black text-[16px] pt-[27px]">
                @rakeshphonedukaan is required to pay in the next 24 hours after
                which we’ll ask you to confirm it.
              </div>
            </div>
            {/* buttons */}
            <div className="flex flex-col items-center justify-evenly w-[180px] mr-[40px]">
              <button className="w-full bg-white capitalize text-black text-[14px] mediumFont flex justify-center items-center px-auto py-3 rounded-md border border-black">
                view buyer profile{" "}
              </button>
              <button className="w-full bg-white text-black text-[14px] mediumFont capitalize flex justify-center items-center px-auto py-3 rounded-md border border-black">
                {" "}
                send payment reminder
              </button>
              <div className="w-full bg-[#D1503B] text-black text-[14px] mediumFont capitalize flex justify-center items-center px-auto py-3 rounded-md border border-black">
                cancel sale
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingPayment;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import axiosInstance from "../axiosInstance";

const SignUpPhoneVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userid, setuserid] = useState("");
  const [message, setmessage] = useState("");
  const [otp, sentotp] = useState(false);
  const [otpcount, setotpcount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const phoneOTP = () => {
    sentotp(false);
    if (otpcount >= 5) {
      setmessage("You have requested for OTP 5 times. Please try again later!");
    } else if (validator.isMobilePhone(userid)) {
      // code for otp verification
      axiosInstance
        .post(`/auth/sendPhoneOtp/${userid}`, { phone: userid })
        .then(() => {
          setotpcount(otpcount + 1);
          setmessage("");
          sentotp(true);
          setTimeLeft(30);
        })
        .catch((err) => {
          console.log(err);
          setmessage(err.response.data.error);
        });
    } else {
      setmessage("You have entered an incorrect phone number.");
    }
  };

  const renderSendOTPButton = () => {
    if (otp) {
      return (
        <button
          id="signupresendbtn"
          disabled={timeLeft !== null}
          onClick={() => phoneOTP()}
          className={`w-[50%] mt-2.5 py-2 px-2 rounded-r 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black p-2.5 text-sm mediumFont text-black ${
            timeLeft === null ? "bg-amber-300" : "bg-gray-300"
          } border border-black`}
        >
          Resend OTP {timeLeft !== null && `(${timeLeft})`}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => phoneOTP()}
          className="w-[50%] mt-2.5 py-2 px-2 rounded-r 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black p-2.5 text-sm mediumFont text-black bg-amber-300 border border-black hover:bg-amber-300"
        >
          Send OTP
        </button>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pswrd = document.getElementById("signuppassword").value;
    let data = location.state.data;
    data["phone"] = userid;
    data["otp"] = pswrd;
    axiosInstance
      .post("/auth/register", data)
      .then((data) => {
        if (data.data.token){
          console.log("Before Setting Access Token on SignUp "+ data.data.token);
           localStorage.setItem("accessToken", data.data.token);
           console.log("After Setting Access Token on SignUp "+ data.data.token);
        }
        navigate("/signin/success");
      })
      .catch((err) => {
        setmessage(err.response.data.error);
      });
  };

  return (
    <div className="w-full bg-[#FFF9F0] flex items-center justify-center py-10">
      <div className="cursor-default border md:border-2 border-black w-[90%] max-w-[450px] min-h-[73%] rounded-md py-6 md:py-12  px-4 md:px-7 bg-white">
        <h1 className="text-3xl mediumFont text-black">Sign Up</h1>
        <div className="mt-8">
          <div className=" items-center">
            <p className="text-base mediumFont text-black">Phone Number</p>
            <div className="flex relative w-full ">
              <input
                type="text"
                value={userid}
                onChange={(e) => setuserid(e.target.value)}
                className="w-full mt-2.5 py-2 px-2 rounded-l 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-t-[1px] border-l-[1px] border-b-[1px] border-black"
              />
              {renderSendOTPButton()}
            </div>
          </div>
          <div className="mt-3">
            <div className="flex items-center justify-between">
              <p className="text-base mediumFont text-black">Verify OTP</p>
            </div>
            <input
              id="signuppassword"
              type="password"
              placeholder=""
              className="w-full mt-2.5 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
            />
            {message !== "" && (
              <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                <p className="text-sm">{message}</p>
              </div>
            )}
            {otp && (
              <div className="mt-3 py-3 px-3 bg-[#ecffe9]">
                <p className="text-sm">OTP Sent</p>
              </div>
            )}
          </div>
          <button
            onClick={(e) => handleSubmit(e)}
            className="w-full text-xm xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
          >
            Create Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpPhoneVerification;

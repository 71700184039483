import React from "react";

import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineClockCircle, AiOutlineMail } from "react-icons/ai";
import maincar from "../../assets/new design/car_img.png";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { BsBarChartLine, BsFillTrashFill, BsTrashFill } from "react-icons/bs";
import { FaPercent } from "react-icons/fa";
import { deleteProduct } from "../../redux/actions/product";

const ActiveListingCard = ({ listing }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // condition
  let condition;
  if (listing?.condition) {
    switch (listing?.condition) {
      case "Used - Good":
        condition = "Used";
        break;
      case "Used - Fair":
        condition = "Used";
        break;
      case "Refurbished":
        condition = "Refurb";
        break;
      default:
        condition = listing?.condition
        break;
    }
  }

  // Address
  let formattedAddress = listing?.loc?.formattedAddress;
  if (formattedAddress) {
    const parts = formattedAddress.split(',');
    if (parts.length >= 3) {
      const lastIndex = parts.length - 1;
      const secondToLastIndex = lastIndex - 1;
      const result = `${parts[secondToLastIndex].trim()}, ${parts[lastIndex].trim()}`;

      formattedAddress = result; // This will contain the desired result
    }
  }

  // expiry date
  let expiry_date = listing?.listing_duration;
  if (expiry_date) {
    const listingDurationDate = new Date(expiry_date);
    const currentDate = new Date();
    const timeDifference = listingDurationDate - currentDate;

    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    // Update expiry_date with the number of days and hours left
    expiry_date = `${daysLeft} D ${hoursLeft} H`;
  }

  //thumbnail image
  let thumbnail = listing?.thumbnail;

  // deleting listing
  const handleDelete = () => {
    dispatch(deleteProduct(listing.id));
  }

  const handleEdit = () => {
    // dispatch(deleteProduct(listing.id));
  }

  return (
    <div className="w-[300px] pb-9">
      {/* pending payment */}

      <div className="w-full relative">
        <div
          className="border w-full z-20 relative bg-white border-black rounded-md hover:cursor-pointer hover:-translate-x-1 hover:-translate-y-1 duration-100 ease-in"
          onClick={() => {
            navigate(`/selling/activelistings/product/${listing._id}`);
          }}
        >
          <div className="flex absolute top-14  -left-2 rounded-sm">
            {/* <TagRibbon
    textClasses="text-[14px] mediumFont text-black text-center"
    tagText="USED"
    bgColor="#FFDC25"
  /> */}
            <div className="relative flex flex-col w-[78px] h-[35px]">
              <div className="mb-2">
                <img
                  src={require("../../assets/icons/main.svg").default}
                  alt="Main"
                  className="w-full h-full relative z-10 fill-#FFDC25"
                />
              </div>

              <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
                <img
                  src={require("../../assets/icons/shadow.svg").default}
                  alt="Shadow"
                  className="w-full h-full absolute z-0"
                />
              </div>
            </div>

            <h3 className="boldFont ml-[-65px] mt-[2px] z-30">{condition}</h3>
          </div>

          <div className="w-full z-20 relative flex border-b border-black rounded-t-md">
            <div className="w-1/3 border-r border-black flex bg-[#F2FBF6] rounded-tl-md justify-center">
              <div className="flex items-center space-x-2 py-[10px]">
                <BsBarChartLine className="text-[20px]" />
                <div className="text-[20px] text-black underline underline-offset-2 boldFont">
                  {listing?.uniqueViews.length}
                </div>
              </div>
            </div>
            <div className="w-1/3 border-r border-black bg-[#EEF5FF] flex justify-center">
              <div className="flex items-center space-x-2 py-[10px]">
                <AiOutlineMail className="text-[20px]" />
                <div className="text-[20px] text-black underline underline-offset-2 boldFont">
                  210
                </div>
              </div>
            </div>
            <div className="w-1/3 bg-[#EAE1F6] rounded-tr-md flex justify-center">
              <div className="flex items-center space-x-2 py-[10px]">
                <FaPercent className="text-[20px]" />
                <div className="text-[20px] text-black underline underline-offset-2 boldFont">
                  40
                </div>
              </div>
            </div>
          </div>

          {/* image */}
          <div className="w-full flex items-center justify-center pt-[11px] ">
            <img
              class="w-[280px]  rounded-lg border aspect-square border-black"
              src={thumbnail}
            />
          </div>

          {/* location */}

          <div className="w-full flex px-[10px]  pt-3">
            <div>
              <IoLocationOutline className="text-[#2D7F77] text-[18px]" />
            </div>
            <span className="mediumFont text-[12px] pr-[3px]">
              {" "}
              {formattedAddress}
            </span>
          </div>

          {/* item name */}
          <div className="px-[14px] pb-[10px] text-[20px] mediumFont pt-[5px]">
            {listing?.title}
          </div>

          {/* price and time */}
          <div className="w-full flex justify-between border-t border-black px-[14px]">
            <div className="border-r border-black w-[60%] text-emerald-900 text-[20px] boldFont py-[8px]">
              ₹{listing.cost}{" "}
            </div>
            <div className="w-[40%] r flex pl-[10px]">
              <div className="flex flex-col justify-center">
                <AiOutlineClockCircle />
              </div>

              <div class="text-black flex flex-col justify-center pl-[4px] text-[14px] mediumFont leading-tight">
                {expiry_date}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute z-10 inset-0 w-full h-[464.4px] bg-black rounded-md"></div>
      </div>

      {/* buttons */}
      <div className="mt-[12px] flex justify-between">
        <div className="w-[80%] h-[45.6px] relative">
          <button className="flex hover:-translate-x-1 hover:-translate-y-1 duration-100 ease-in z-20 relative  justify-center w-full h-full bg-[#FFDC25] border border-black rounded-md items-center text-black text-[14px] font-medium leading-tight" onClick={() => navigate(`/selling/activelistings/edit/general/product/${listing._id}`)}>
            Edit Listing
          </button>
          <div className="absolute z-10 inset-0 w-full h-full bg-black rounded-md"></div>
        </div>

        <div className="h-[45.6px] relative">
          <button className="bg-[#D1503B] hover:-translate-x-1 hover:-translate-y-1 duration-100 ease-in relative z-20 h-full px-[14px] items-center border border-black rounded-md" onClick={() => handleDelete()}>
            <BsTrashFill className="text-white text-[20px]" />
          </button>
          <div className="absolute z-10 inset-0  h-full bg-black rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default ActiveListingCard;

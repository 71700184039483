import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import tick from "../assets/icons/tick.svg";
import validator from "validator";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";

const SignUpFormScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const signInEmail = location?.state?.email ? location?.state?.email : "";
  const signInUsername = location?.state?.username ? location?.state?.username : "";
  const [name, setname] = useState("");
  const [email, setemail] = useState(signInEmail);
  const [username, setusername] = useState(signInUsername);
  const [pswrd, setpswrd] = useState("");
  const [pswrdverify, setpswrdverify] = useState([false, false, false, false]);
  const [message, setmessage] = useState(["", "", "", ""]);
  const [error, showerror] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  useEffect(() => {
    let verifypass = [
      /[A-Z]/.test(pswrd),
      /[a-z]/.test(pswrd),
      /\d/.test(pswrd),
      pswrd.length >= 8,
    ];
    setpswrdverify(verifypass);
  }, [pswrd]);

  useEffect(() => {
    let msg = [...message];
    if (!name) {
      msg[0] = "*Please enter your name.";
      showerror("");
    } else {
      msg[0] = "";
    }
    setmessage(msg);
  }, [name]);

  useEffect(() => {
    let msg = [...message];
    if (!username) {
      msg[2] = "*Please enter your username.";
      showerror("");
    } else {
      msg[2] = "";
    }
    setmessage(msg);
  }, [username]);

  useEffect(() => {
    let msg = [...message];
    if (!emailValid) {
      msg[1] = "*Please enter a valid Email ID.";
      showerror("");
    } else {
      msg[1] = "";
    }
    setmessage(msg);
  }, [emailValid]);

  const handleEmailBlur = () => {
    if (email && !validator.isEmail(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  };

  const Submit = (e) => {
    if (!name || !email || !username || !pswrd || !(pswrdverify[0] && pswrdverify[1] && pswrdverify[2] && pswrdverify[3])) {
      let msg = [...message];

      if (!name) {
        msg[0] = "*Please enter your name.";
      }
      if (!email) {
        msg[1] = "*Please enter your email.";
      }
      if (!username) {
        msg[2] = "*Please enter your username.";
      }
      if (!pswrd) {
        msg[3] = "*Please enter your password.";
      }
      if (!(pswrdverify[0] && pswrdverify[1] && pswrdverify[2] && pswrdverify[3])) {
        msg[3] = "*Please enter a correct password.";
      }
      console.log("error message:", msg);
      setmessage(msg);
      return;
    }
      if (message.filter((item) => item === "").length === 4) {
        let data = {
          first_name: name,
          last_name: "",
          email: email,
          username: username,
          password: pswrd,
          phone: "",
          otp: "",
        };

        // Code for form submission

        // Checking if email exists
        axiosInstance
          .post(`/auth/checkUserExist`, { email: email })
          .then((res) => {
            showerror("Email already Exist");
          })
          .catch(({ response }) => {
            try {
              switch (response.status) {
                case 400:
                  // Add a toast
                  toast.error("Invalid Data!");
                  break;
                case 401:
                  // Add a toast
                  toast.error("Invalid credentials!");
                  break;
                case 404:
                  // Add a toast
                  navigate("/signup/verify", { state: { data: data } });                  
                  break;
                default:
                  // Server error
                  toast.error("Oops, something went wrong");
                  break;
              }
            } catch (e) {
              toast.error("Couldn't reach the server");
            }
          });
      }
    };

    return (
      <div className="w-full bg-[#FFF9F0] flex items-center justify-center py-10">
        <div className="cursor-default border md:border-2 border-black w-[90%] max-w-[450px] min-h-[73%] rounded-md py-6 md:py-12  px-4 md:px-7 bg-white">
          <h1 className="text-3xl mediumFont text-black">Sign Up</h1>
          <div className="mt-8">
            <div className="mt-3">
              <p className="text-base mediumFont text-black">Name</p>
              <input
                type="text"
                value={name}
                onChange={(e) => setname(e.target.value)}
                className={
                  (message[0] ? "border-red-600" : "border-black") +
                  " w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px]"
                }
              />
            </div>
            <div className="mt-3">
              <p className="text-base mediumFont text-black">Email</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                onBlur={handleEmailBlur}
                className={
                  (message[1] ? "border-red-600" : "border-black") +
                  " w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px]"
                }
              />
            </div>
            <div className="mt-3">
              <p className="text-base mediumFont text-black">Username</p>
              <input
                type="text"
                value={username}
                onChange={(e) => setusername(e.target.value)}
                className={
                  (message[2] ? "border-red-600" : "border-black") +
                  " w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px]"
                }
              />
            </div>
            <div className="mt-3">
              <p className="text-base mediumFont text-black">Password</p>
              <input
                type="password"
                value={pswrd}
                onChange={(e) => setpswrd(e.target.value)}
                className={
                  (message[3] ? "border-red-600" : "border-black") +
                  " w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px]"
                }
              />
              <table className="table-auto mt-2.5 mb-2.5 w-full">
                <tbody>
                  <tr>
                    <td>
                      <div className="flex text-[12px]">
                        <img
                          className={
                            "w-4 h-4 mr-1 " + (!pswrdverify[0] ? "grayscale" : "")
                          }
                          src={tick}
                          alt="Uppercase character"
                        />
                        One uppercase character
                      </div>
                    </td>
                    <td>
                      <div className="flex text-[12px]">
                        <img
                          className={
                            "w-4 h-4 mr-1 " + (!pswrdverify[1] ? "grayscale" : "")
                          }
                          src={tick}
                          alt="Lowercase character"
                        />
                        One lowercase character
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex text-[12px]">
                        <img
                          className={
                            "w-4 h-4 mr-1 " + (!pswrdverify[2] ? "grayscale" : "")
                          }
                          src={tick}
                          alt="Number"
                        />
                        One number
                      </div>
                    </td>
                    <td>
                      <div className="flex text-[12px]">
                        <img
                          className={
                            "w-4 h-4 mr-1 " + (!pswrdverify[3] ? "grayscale" : "")
                          }
                          src={tick}
                          alt="Minimum 8 characters"
                        />
                        8 character minimum
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {message.filter((item) => item === "").length !== 4 && (
                <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                  {message.map(function (m, i) {
                    return <p className="text-sm" key={i}>{m}</p>;
                  })}
                </div>
              )}
            </div>
            {error && (
              <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
                <p className="text-sm">{error}</p>
              </div>
            )}
            <button
              onClick={(e) => {
                Submit(e);
              }}
              className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
            >
              Sign Up
            </button>
          </div>
          <div className="mt-9">
            <span className="text-center">Already have an account? </span>
            <Link
              to="/signin/username"
              className="text-blue-100 underline mediumFont"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    );
  };

  export default SignUpFormScreen;

import { useDispatch, useSelector } from "react-redux";
import { ADDRESS_MODAL, ADD_NEW_ADDRESS_MODAL, EDIT_ADDRESS_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { useState } from "react";
import { setCartAddress } from "../../redux/actions/cart";

const DeliveryLocationModal = () => {
  const dispatch = useDispatch();
  const [index, setindex] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const userAddresses = user.addresses;

  const handleConfirm = () => {
    const address = user.addresses[index];
    dispatch(setCartAddress(address));
  }

  return (
    <div>
      {/* Desktop */}
      <div className="bg-white px-5 py-7 rounded hidden xl:flex xl:flex-col">
        <h1 className="text-xl mediumfont text-black mb-3">Delivery Address</h1>
        <div className=" h-[42vh] overflow-y-scroll p-1 no-scrollbar">
          {userAddresses.map((e, i) => (
            <div
              onClick={() => {
                setindex(i);
              }}
              className={`px-3 py-3 border-2 border-black transition-all duration-800 ease mb-4 ${i == index ? "cardSelect" : "cardHover"
                } rounded-md relative`}
            >
              <div className=" flex items-center">
                <div className=" flex-1 flex items-center">
                  <h1 className=" w-[25%] text-lg font-bold text-black">
                    Name
                  </h1>
                  <h1 className=" w-[50%] text-lg font-normal text-black ml-4">
                    {e.name}
                  </h1>
                </div>
                <button
                  onClick={() => {
                    dispatch(
                      showModal({
                        modalType: EDIT_ADDRESS_MODAL,
                        modalProps: {address : e},
                        modalTitle: ``,
                        modalSubTitle: "",
                      })
                    );
                  }}
                  className=" text-[14px] py-1.5 px-3 rounded-[4px] bg-white text-black border-[1px] border-sa-border-black font-bold"
                >
                  Edit
                </button>
              </div>
              <div className=" flex items-center">
                <h1 className="w-[25%] text-lg font-bold text-black">
                  Contact No:
                </h1>
                <h1 className=" w-[50%] text-lg font-normal text-black">
                  {e.phone}
                </h1>
              </div>
              <div className=" flex items-center">
                <h1 className="w-[25%] text-lg font-bold text-black">
                  Address:
                </h1>
                <h1 className=" w-[50%] text-lg font-normal text-black">
                  {e.address_line1}
                </h1>
              </div>
              <div className=" flex items-center">
                <h1 className="w-[25%] text-lg font-bold text-black">
                  City:
                </h1>
                <h1 className=" w-[50%] text-lg font-normal text-black">
                  {e.city}
                </h1>
                <h1 className="w-[25%] text-lg font-bold text-black">
                  State:
                </h1>
                <h1 className=" w-[50%] text-lg font-normal text-black">
                  {e.state}
                </h1>
                <h1 className="w-[25%] text-lg font-bold text-black">
                  Pincode:
                </h1>
                <h1 className=" w-[50%] text-lg font-normal text-black">
                  {e.pincode}
                </h1>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-between gap-x-4">
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_NEW_ADDRESS_MODAL,
                  modalTitle: ``,
                  modalSubTitle: "",
                })
              );
            }}
            className=" w-full text-[14px] py-2 px-3 rounded-[4px] bg-white text-black border-[1px] border-sa-border-black mediumfont mt-5 hover:bg-sa-primary-yellow"
          >
            Add New Address
          </button>
          <button className=" w-full text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont mt-5" onClick={() => handleConfirm()}>
            Confirm Address
          </button>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="xl:hidden">
        <div className="bg-white px-2 py-7 rounded">
          <h1 className="text-xl mediumfont text-black mb-3">Delivery Address</h1>
          <div className="h-[50vh] overflow-y-scroll p-1 no-scrollbar">
            {userAddresses.map((addressData, i) => (
              <div
                key={i}
                onClick={() => {
                  setindex(i);
                }}
                className={`px-3 py-3 border-2 border-black hover:bg-[#FCF9F4]  mb-4 cardHover rounded-md relative ${i === index ? "cardSelect" : ""
                  }`}
              >
                <div className="flex items-start mb-1">
                  <h1 className="w-[40%] text-sm mediumfont text-black">Name</h1>
                  <h1 className="w-[60%] text-sm font-normal text-black">
                    {addressData.name}
                  </h1>
                </div>
                <div className="flex items-start mb-1">
                  <h1 className="w-[40%] text-sm mediumfont text-black">
                    Contact No:
                  </h1>
                  <h1 className="w-[60%] text-sm font-normal text-black">
                    {addressData.phone}
                  </h1>
                </div>
                <div className="flex items-start mb-1">
                  <h1 className="w-[40%] text-sm mediumfont text-black">
                    Address:
                  </h1>
                  <h1 className="w-[60%] text-sm font-normal text-black">
                    {addressData.address_line1}
                  </h1>
                </div>
                <button
                  onClick={() => {
                    dispatch(
                      showModal({
                        modalType: EDIT_ADDRESS_MODAL,
                        modalTitle: ``,
                        modalSubTitle: "",
                      })
                    );
                  }}
                  className="w-full text-[14px] py-1.5 px-3 mt-3 rounded-[4px] bg-white text-black border-[1px] border-sa-border-black mediumfont"
                >
                  Edit
                </button>
              </div>
            ))}
          </div>

          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_NEW_ADDRESS_MODAL,
                  modalTitle: ``,
                  modalSubTitle: "",
                })
              );
            }}
            className="w-full text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont mt-5"
          >
            Add New Address
          </button>

          <button
            onClick={handleConfirm}
            className="w-full text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont mt-2"
          >
            Confirm Address
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryLocationModal;

import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  AccessTimeOutlined,
  ArrowBackIos,
  ArrowForwardIos,
  LocationOnOutlined,
  MailOutline,
  Star,
} from "@mui/icons-material";
import DropDown from "../components/DropDown";
import PreviewCard from "../components/Seller/PreviewCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserDrafts, saveDraft, saveProductDetails } from "../redux/actions/product";
import SequentialDropdown from "../components/SequentialDropDown";
import SaveItemSellingDraft from "../components/Modals/SaveItemSellingDraft";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import { Link } from "react-router-dom";

const DetailsScreen = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  const { user } = useSelector((state) => state.auth);
  const { product } = useSelector((state) => state.product);
  const { item_specifics } = useSelector((state) => state.category);
  const [modalOpen, setModalOpen] = useState(false);
  console.log(product);
  console.log(item_specifics);

  const dispatch = useDispatch();
  const productDetailHandler = () => {
    dispatch(saveProductDetails(info));
    navigate("/sellnow/listing/location");
  };

  const handleSave = () => {
    setModalOpen(true);
    dispatch(saveProductDetails(info));
  }

  const handleSaveDraft = () => {
    // dispatch save Products
    dispatch(saveDraft(product));
    dispatch(getAllUserDrafts());
    navigate("/sellnow/drafts");
  }

  const width = 75;
  return (
    <>
      {user && user.role !== "customer_unverified" ?
        <div>
          <div className="px-4.5 mt-7 md:px-[4vw] md:mt-10">
            <div className="border-[1px] h-[80vh] overflow-y-hidden border-black pl-3 max-[768px]:pr-3 mb-7 xl:border-[2px] xl:px-0 lg:flex md:items-start md:mb-10">
              <div className="lg:w-[51%] lg:border-r-[2px] border-black md:px-[2vw]  h-full">
                <div className="flex-col align-top h-full">
                  {/* what are u seling */}
                  <div className="h-[10%] flex items-start mt-7 gap-x-3 ">
                    <div>
                      <img
                        src={require("../assets/new design/Vector.png")}
                        alt=""
                        className="w-9 xl:w-12"
                      />
                    </div>
                    <h1 className=" text-2xl xl:text-4xl mediumFont text-black leading-7 p-0">
                      What are you selling today?
                    </h1>
                  </div>
                  {/* actual deetails */}
                  <div className="h-[70%] overflow-y-auto no-scrollbar md:pr-7">
                    <div className="mt-6 border-b-[1px] border-black pb-2.5">
                      <h1 className=" text-[21px] xl:text-2xl mediumFont text-black ">
                        Item Specifics
                      </h1>
                    </div>

                    <SequentialDropdown items={item_specifics} />

                  </div>
                  {/* buttons */}
                  <div className="h-[20%] flex items-center justify-between gap-2 ">
                    <button
                      onClick={() => navigate("/sellnow/listing/media")}
                      className="text-[14px] py-3 2md:px-10  min-[444px]:px-10 px-5 rounded-[4px] bg-[#fff] text-black border-[1px] border-sa-border-black mediumFont"
                    >
                      Previous
                    </button>
                    <div className="space-x-2 px-2">
                      <button
                        onClick={() => handleSave()}
                        className="text-[14px] py-3 2md:px-10  min-[444px]:px-10 px-5 rounded-[4px] bg-[#00BEA7] text-black border-[1px] border-sa-border-black mediumFont"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => productDetailHandler()}
                        className="text-[14px] py-3 2md:px-10  min-[444px]:px-10 px-5 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[49%] overflow-y-auto h-full no-scrollbar">
                <PreviewCard info={info} width={width} focusedHighlight={1} />
              </div>
            </div>
          </div>
          <SaveItemSellingDraft
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSuccess={() => handleSaveDraft()}
            title="Save Listing As a Draft"
            content="You can comeback anytime to complete this listing."
            confirmText="Save"
            cancelText="Cancel"
          />
        </div> :
        user && user.role === "customer_unverified" && user.seller_request !== "created" ?
          <div className=" min-h-screen md:flex hidden">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must register as a seller to Proceed.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Only One listing per customer is allowed, For more please

                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/seller/registration">Register Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div> : user && (user.role === "customer_unverified" || user.role === "customer") && user.seller_request === "created" ?
            <div className="h-[50vh] bg-[#F3CEB4] px-5 py-14 xl:pt-28 xl:h-[60vh]">
              <div className=" border-[1px] border-black bg-white py-12 px-5 xl:w-fit xl:mx-auto xl:px-10 xl:py-12">
                <h1 className="text-3xl mediumFont text-black text-center tracking-wide xl:text-[40px]">
                  Your Request is under Review!
                </h1>
                <p className="text-lg mediumFont text-black text-center mt-2 tracking-wide xl:text-[29px] xl:mt-3">
                  You will be informed, when we are done.
                </p>
                <div className="flex items-center justify-center gap-x-12 mt-5 xl:mt-8">
                  <h1 className="text-[22px] boldFont tracking-wide text-[#737373] xl:text-[25px]">
                    Status
                  </h1>
                  <div className="flex items-center gap-x-1">
                    <AccessTimeOutlined fontSize="small" />
                    <h1 className="text-[22px] boldFont tracking-wide text-sa-icon-green xl:text-[25px]">
                      Pending
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>
      }
    </>
  );
};

export default DetailsScreen;

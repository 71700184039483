import { ArrowBackIos } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import useSellerRequest from "../hooks/auth/seller-request/useSellerRequest";
import { sellerRequest } from "../redux/actions/auth";

const SellerIndividualAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const [info, setInfo] = useState(location?.state?.data);
  const [selectedOption, setSelectedOption] = useState(null); 

  const handleAutoDetect = async () => {
    if ("geolocation" in navigator) {
      toast.info("Fetching Address Details");
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDzt5uKoU9PIrfldJcdqDjf9X-KJNCrohk`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
            const addressDetails = {};

            const componentMapping = {
              premise: "premise",
              sublocality_level_2: "sublocality_level_2",
              sublocality_level_1: "sublocality_level_1",
              locality: "city",
              administrative_area_level_1: "state",
              postal_code: "pincode"
            };

            for (const component of addressComponents) {
              const componentType = component.types.find(type => componentMapping[type]);
              if (componentType) {
                addressDetails[componentMapping[componentType]] = component.long_name;
              }
            }

            const address = [
              addressDetails.premise,
              addressDetails.sublocality_level_2,
              addressDetails.sublocality_level_1
            ].filter(Boolean).join(", ");

            addressDetails.address_line1 = address;
            delete addressDetails.premise;
            delete addressDetails.sublocality_level_2;
            delete addressDetails.sublocality_level_1;

            console.log(addressDetails);
            const formattedAddress = addressDetails.address_line1 + `, ` + addressDetails.city + `, ` + addressDetails.state;
            const loc = {
              type: "Point",
              coordinates: [latitude, longitude],
              formattedAddress: formattedAddress
            }

            setInfo({ ...info, seller_address: addressDetails, loc });
          } else {
            console.log("No address components found.");
            toast.error("Please Fill the Details");
          }
        } catch (error) {
          console.error("Error fetching geolocation data:", error);
        }
      } catch (error) {
        console.error("Error getting location:", error.message);
      }
    } else {
      toast.info("Geolocation is not supported by this browser.");
      console.log("Geolocation is not supported by this browser.");
    }
  };  

  return (
    <div>
      <div className="px-4.5 mt-7 xl:px-16 xl:mt-10">
        <div className=" border-[1px] border-black px-3 mb-7 xl:border-[1px] xl:px-0 xl:flex xl:items-start xl:mb-10">
          <div className="pt-6 xl:px-8 xl:pt-10 xl:w-[51%]">
            <div className=" flex items-center border-b-[1.5px] border-black pb-3 xl:pb-5">
              <ArrowBackIos color="#000" />
              <h1 className=" text-2xl xl:text-[42px] mediumFont text-black leading-7 p-0 tracking-wide">
                Individual
              </h1>
            </div>
            <div>
              <div>
                <h1 className="text-sm xl:text-base mediumFont text-blue-900 mt-5">
                  Select from saved address
                </h1>
                <div className="mt-1">
                  <Select
                    options={options}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    className="w-full"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-sm xl:text-base mediumFont text-blue-900 mt-5">
                  Address
                </h1>
                <div className="mt-1 w-full flex">
                  <input
                    type="text"
                    id="address"
                    onChange={(e) =>
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        seller_address: {
                          ...prevInfo.seller_address,
                          address_line1: e.target.value,
                        },
                      }))
                    }
                    value={info?.seller_address?.address_line1}
                    placeholder="Enter your address here"
                    className="md:w-3/4 w-full focus:outline-none border-[1px] border-black rounded-md md:rounded-r-none py-2 px-4"
                  />
                  <button
                    type="button"
                    onClick={handleAutoDetect}
                    className="bg-[#FFDC25] hidden md:flex text-[16px] mediumFont w-1/4 text-black justify-center py-2 rounded-md rounded-l-none border-l-[0px] border-[1px] border-black"
                  >
                    Auto Detect
                  </button>
                </div>
              </div>
              <div className="w-full mt-2 flex md:justify-between md:space-x-6 flex-col md:flex-row ">
                <div className="py-2 w-full md:w-[60%]">
                  <h2 className="mb-2 text-[16px] boldFont">Landmarks </h2>
                  <input
                    type="text"
                    id="landmarks"
                    onChange={(e) =>
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        seller_address: {
                          ...prevInfo.seller_address,
                          landmarks: e.target.value,
                        },
                      }))
                    }
                    className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                    placeholder="Enter Landkmarks"
                  />
                </div>
                <div className="py-2 w-full md:w-[40%]">
                  <h2 className="mb-2 text-[16px] boldFont">State </h2>
                  <input
                    type="text"
                    id="state"
                    onChange={(e) =>
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        seller_address: {
                          ...prevInfo.seller_address,
                          state: e.target.value,
                        },
                      }))
                    }
                    value={info?.seller_address?.state}
                    className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                    placeholder="Enter State"
                  />
                </div>
              </div>
              <div className="w-full mt-2 flex md:justify-between md:space-x-6 flex-col md:flex-row ">
                <div className="py-2 w-full lg:w-[60%]">
                  <h2 className="mb-2 text-[16px] boldFont">City </h2>
                  <input
                    type="text"
                    id="city"
                    onChange={(e) =>
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        seller_address: {
                          ...prevInfo.seller_address,
                          city: e.target.value,
                        },
                      }))
                    }
                    value={info?.seller_address?.city}
                    className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                    placeholder="Enter City"
                  />
                </div>
                <div className="py-2 w-full lg:w-[40%]">
                  <h2 className="mb-2 text-[16px] boldFont">Pincode </h2>
                  <input
                    type="text"
                    id="pincode"
                    onChange={(e) =>
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        seller_address: {
                          ...prevInfo.seller_address,
                          pincode: e.target.value,
                        },
                      }))
                    }
                    value={info?.seller_address?.pincode}
                    className="w-full placeholder-gray-400 focus:outline-none border border-black py-2 px-4 rounded-md"
                    placeholder="Enter Pincode"
                  />
                </div>
              </div>
              <div className="mt-5 mb-10 flex items-center justify-between gap-x-3">
                <button
                  onClick={() => navigate("/seller/individual")}
                  className=" w-1/2 text-[14px] xl:text-base py-3 px-10 rounded-[4px] bg-sa-menu-green text-white border-[1px] border-sa-border-black mediumFont"
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    dispatch(sellerRequest(info));
                    navigate("/seller/registered")
                  }}
                  className=" w-1/2 text-[14px] xl:text-base py-3 px-10 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumFont"
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
          <div className="hidden xl:flex">
            <img
              src={require("../assets/new design/side_img.png")}
              alt="Side Image"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerIndividualAddress;

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import OfferCountered from "./OfferCountered";
import { useState } from "react";
import OfferCreatedModal from "./OfferCreatedModal";
import { useDispatch, useSelector } from "react-redux";
import { createOffer } from "../../redux/actions/product";
import { AiFillCloseCircle } from "react-icons/ai";
import { hideModal } from "../../redux/actions/modal";
import { useEffect } from "react";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";

const SelectDepositForOfferModal = ({ onBack, productId }) => {
    const dispatch = useDispatch();
    const [isCreated, setIsCreated] = useState(false);
    const { modalProps } = useSelector((state) => state.modal);
    const [offerAmount, setOfferAmount] = useState(0);
    const [repeatedOffer, setRepeatedOffer] = useState(false);
    const [deposits,setDeposits] = useState([]);
    const [selectedDeposit,setSelectedDeposit] = useState(null);

    useEffect(() => {
        axiosInstance
          .get(`user/fetchUserDeposits`)
          .then((res) => {
            const { data } = res.data; 
            setDeposits(data);
          })
          .catch(({ response }) => {
            try {
              switch (response.status) {
                default:
                  // server error
                  toast.error("Oops, something went wrong");
    
                  break;
              }
            } catch (e) {
              toast.error("Couldn't reach the server");
            }
          })
    },[])

    const handleCreate = () => {
        const req = {
            product: modalProps,
            offerAmount,
            deposit : selectedDeposit._id
        };
        dispatch(createOffer(req, setRepeatedOffer));
        setIsCreated(true);
    };

    const handleOnClose = () => {
        dispatch(hideModal());
    }

    if (isCreated) {
        return <OfferCreatedModal offerAmount={offerAmount} repeatedOffer={repeatedOffer} />;
    }

    return (
        <div>
            {/* Desktop */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div className="w-[529px] 2md:flex h-[376px] relative bg-stone-50 rounded-lg border border-black">
                    <button className="absolute top-0 right-0 z-10" onClick={() => handleOnClose()}>
                        <AiFillCloseCircle size={20} color="red" />
                    </button>
                    <div className="left-[24px] top-[20px] absolute text-center text-black text-[26px] mediumFont leading-10">
                        Choose Deposit
                    </div>
                    
                    <div className="absolute left-[24px] top-[80px] text-black text-[26px] mediumFont w-[90%]">
                        {deposits.map((deposit,index) => (
                            <div key={index} className="border-[2px] border-black rounded p-2 w-full m-2 flex justify-between">
                                <p>{deposit.amount}</p>
                                <p>Used In : {deposit?.Products?.length}</p>
                            </div>
                        ))}
                    </div>
                    
                    <button className="p-2 absolute bottom-5 right-5 z-10 border-[1px] border-black bg-sa-primary-yellow rounded" onClick={handleCreate}>
                        Create Offer
                    </button>
                    <button className="p-2 absolute bottom-5 left-5 z-10 border-[1px] border-black bg-sa-primary-yellow rounded" onClick={handleCreate}>
                        Create Deposit
                    </button>
                </div>
            </div>

            {/* Mobile */}
            <div className="w-[340px] 2md:hidden h-[450px] relative bg-white rounded-md">
                <div className="w-full justify-center z-0 flex top-[32px] absolute text-center text-black text-[28px] boldfont leading-9">
                    Choose Deposit
                </div>
                <div
                    className="left-[20px] top-[35px] z-10 absolute text-gray-900 text-[30px] mediumFont leading-10 cursor-pointer"
                    onClick={onBack}
                >
                    <FaArrowLeft />
                </div>
            </div>
        </div>
    );
};

export default SelectDepositForOfferModal;
import React from "react";
import SideMenu from "../components/Buying/SideMenu";
import PreviewCardMob from "../components/Seller/PreviewCardMob";
import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import PreviewCardDesktop from "../components/Seller/PreviewCardDesktop";
import PendingPayment from "../components/Selling/PendingPayment";
import ListingPreview from "../components/Selling/ListingPreview";

const SoldMenuOption = () => {
  const data = [
    {
      id: 1,
      status: "Pending Payment",
    },
    {
      id: 2,
      status: "paid",
    },
    {
      id: 3,
      status: "completed",
    },
    { id: 4, status: "Pending Payment" },
    {
      id: 5,
      status: "paid",
    },
    {
      id: 6,
      status: "completed",
    },
    { id: 7, status: "Pending Payment" },
    {
      id: 8,
      status: "paid",
    },
    {
      id: 9,
      status: "completed",
    },
    { id: 10, status: "Pending Payment" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerTabs = ["Status", "Analytics", "Messages", "Offers"];
  const [fontSize, setFontSize] = useState("text-4xl");
  const [activeTab, setActiveTab] = useState("Status");
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setFontSize("text-xl");
    } else {
      setFontSize("text-4xl");
    }
  };
  const [selectedOption, setSelectedOption] = useState("New To Old");
  const [isOpen, setIsOpen] = useState(false);

  const options = ["New To Old", "Old To New", "A to Z", "Z to A"];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="hidden 2md:flex items-start min-h-screen">
      <div className="self-stretch">
        <SideMenu />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex-1 w-full overflow-hidden bg-[#FCF9F4]">
          <div
            className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${
              fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
            }`}
          >
            <div className="flex space-x-2 items-center">
              <button
                onClick={() => {
                  navigate("/selling/sold");
                }}
              >
                {" "}
                <BsArrowLeft className="text-black boldFont text-[35px]" />
              </button>

              <h1
                className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
              >
                Hyundai Venue(2019)
              </h1>
            </div>

            <div
              className={`${
                fontSize === "text-4xl" ? "mt-5" : "mt-2"
              } transition-all duration-300 ease-in-out flex items-center justify-between`}
            >
              <div className="flex items-center flex-wrap max-w-[600px] mr-10 grow justify-between">
                {offerTabs.map(function (e, i) {
                  return (
                    <button
                      onClick={() => setActiveTab(e)}
                      key={i}
                      className={`px-5 text-[18px] mt-2.5 py-2  rounded-md ${
                        activeTab === e
                          ? "bg-white border-[1px] border-sa-border-black"
                          : "bg-[#FCF9F4]  border-[1px] border-[#FCF9F4]"
                      } text-black mediumFont flex items-center justify-center`}
                    >
                      {e}
                    </button>
                  );
                })}
              </div>
              <div className="relative z-30 inline-block text-left">
                <div
                  className="flex items-center justify-between w-36 px-4 py-2 text-black  bg-transparent border border-black rounded-md shadow-sm cursor-pointer"
                  onClick={toggleDropdown}
                >
                  <span>{selectedOption}</span>
                  <span className="ml-2">
                    {isOpen ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                  </span>
                </div>
                {isOpen && (
                  <ul className="absolute right-0 w-36 mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
                    {options.map((option) => (
                      <li
                        key={option}
                        className={`px-4 py-2 cursor-pointer ${
                          selectedOption === option
                            ? "bg-white"
                            : "bg-transparent"
                        }`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* <div className="relative z-20 w-max place-items-center">
            <button
              onClick={() => { setshowsort(!showsort) }}
              className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
              ${(showsort) ? "rounded rounded-b-none border-b-0" : "rounded"} 
              text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}>
              Sort: {sortarray[sorttype]}  &nbsp;{!showsort ? <ExpandMore /> : <ExpandLess />}
            </button>
            <div className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort ? "max-h-[100vh] border-[1px]" : "max-h-0 border-[0px]"}`}>
              {sortarray.map((type, i) => (
                <div key={`cat${i}`} onClick={() => { setsorttype(i); setshowsort(!showsort); }} className='mt-2.5 py-2 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3'>
                  <p>{type}</p>
                </div>
              ))}
            </div>
          </div> */}
            </div>
          </div>
        </div>
        <div className="pl-12 pr-16 bg-[#FCF9F4] py-6">
          <PendingPayment status={"Pending"} />

          <ListingPreview />
        </div>
      </div>
    </div>
  );
};

export default SoldMenuOption;

import { hideModal } from "./modal";
import { ADD_ADDRESS, ADD_TO_CART, REMOVE_FROM_CART, SET_CART_ADDRESS, USER_CART } from "./types";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { useSelector } from "react-redux";
import { handleShipmentCost, handleShipmentCostUser } from "../../utils/ShipmentCost";
import { useState } from "react";
import { getCurrentUser } from "./auth";

export const addAddress = (address) => {
    return function (dispatch) {
        dispatch({
            type: ADD_ADDRESS,
            payload: address,
        });
        dispatch(hideModal())
    };
};

export const setCartAddress = (address) => {
    return function (dispatch) {
        dispatch({
            type: SET_CART_ADDRESS,
            payload: address,
        });
        dispatch(hideModal())
    };
};

export const addProductToCart = (product) => {
    // const shipping = handleShipmentCostUser(product);
    // product = { ...product, shipping }
    
    return function (dispatch) {
        // saving on the backend
        axiosInstance
            .post(`/product/cart/add/${product._id}`, product)
            .then((response) => {
                toast.success('Item Added to Cart!');
                dispatch(getUserCart());
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        default:
                            toast.error('Oops, something went wrong');
                            break;
                    }
                } else {
                    toast.error("Couldn't reach the server");
                }
            });
    };
};

export const removeProductFromCart = (product) => {
    return function (dispatch) {
        // removing from backend
        axiosInstance
            .post(`/product/cart/remove/${product._id}`, product)
            .then((response) => {
                toast.success("Item removed from Cart!");
                dispatch(getUserCart());
            })
            .catch(({ response }) => {
                try {
                    switch (response.status) {
                        default:
                            // server error
                            toast.error("Oops, something went wrong");

                            break;
                    }
                } catch (e) {
                    toast.error("Couldn't reach the server");
                }
            });
    };
};

export const getUserCart = (product) => {
    return function (dispatch) {
        // getting user cart from backend
        axiosInstance
            .get(`/cart/my`)
            .then((response) => {
                dispatch({
                    type: USER_CART,
                    payload: response.data.cart
                })
                dispatch(getCurrentUser());
            })
            .catch(({ response }) => {
                try {
                    switch (response.status) {
                        default:
                            // server error
                            toast.error("Oops, something went wrong");

                            break;
                    }
                } catch (e) {
                    toast.error("Couldn't reach the server");
                }
            });
    };
};


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUser } from "../../redux/actions/admin";
import ActiveListingCard from "../Selling/ActiveListingCard";
import ProductCard from "../Home/ProductCard";
import io from 'socket.io-client';
const ENDPOINT = "https://api.stakeatlas.com/api/v1";
var socket;

const AdminMetrics = () => {
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.admin);

    const governmentVerified = users && users.filter((user) => user?.government_id.comments === "verified");
    const [loggedInUsers, setLoggedInUsers] = useState(0);

    useEffect(() => {
        socket = io(ENDPOINT);
    },[])

    useEffect(() => {
        socket.emit("usersCount");
        socket.on('totalLoggedInUsers', (users) => {
            console.log(users);
            setLoggedInUsers(users);
        });
    });

    return (
        <div>
            {/* desktop */}
            {users && <div
                className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 overflow-y-auto overflow-x-hidden"
            >
                <div className="p-2 m-2 border-[1px] border-black">
                    <p className="text-[30px]">Total Users : {users?.length}</p>
                </div>
                <div className="p-2 m-2 border-[1px] border-black">
                    <p className="text-[30px]">Users with registered government Id : {governmentVerified?.length}</p>
                </div>
                <div className="p-2 m-2 border-[1px] border-black">
                    <p className="text-[30px]">Total number of users browsing the website</p>
                    <div>
                        <p className="text-[20px]">Users Active Right Now : {loggedInUsers}</p>
                    </div>
                </div>

            </div>}
        </div>
    );
}

export default AdminMetrics;

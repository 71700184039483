import { Delete, LocationOnOutlined } from "@mui/icons-material";
import { connect, useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/Home/ProductCard";
import TagRibbon from "../components/TagRibbon";
import { DELIVERY_LOCATION_MODAL, PAYMENT_MODAL } from "../extras/constants";
import { showModal } from "../redux/actions/modal";
import classNames from "../utils/classNames";
import { Link, unstable_HistoryRouter, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ProductCard3 from "../components/Home/ProductCard3";
import { getUserCart, removeProductFromCart } from "../redux/actions/cart";
import { useEffect } from "react";
import { getCurrentUser } from "../redux/actions/auth";
import { handleShipmentCost } from "../utils/ShipmentCost";
import { phonepayCheckOut } from "../redux/actions/product";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import ReactLoading from 'react-loading';
import { USER_CART } from "../redux/actions/types";

// import { showModal } from '../redux/actions/modal';

const CartScreen = ({ showModal }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let userCart = useSelector((state) => state.cart);
  const [shippingCost, setShippingCost] = useState(0);
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [loading,setLoading] = useState(userCart === {} ? false : true);
  const [otherproducts, setotherproducts] = useState([
    {
      id: 1,
      seller: "@AkshatTripathi",
      title: "Hyundai Venue",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      shipping: "200.00",
      deliverydate: "12 Dec 2022",
      price: "₹4,60,000",
      acceptprice: "₹4,00,000",
      img: require("../assets/new design/car_img.png"),
      location: " Ashok Vihar Delhi",
      year: "2019",
      time: "02 D 12 Hr",
      distance: "5000 KMS",
      ownership: "1st",
    },
    {
      id: 2,
      seller: "@AkshatTripathi",
      title: "Hyundai Venue",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      shipping: "200.00",
      deliverydate: "12 Dec 2022",
      price: "₹4,60,000",
      acceptprice: "₹4,00,000",
      img: require("../assets/new design/car_img.png"),
      location: " Ashok Vihar Delhi",
      year: "2019",
      time: "02 D 12 Hr",
      distance: "5000 KMS",
      ownership: "1st",
    },
    {
      id: 3,
      seller: "@AkshatTripathi",
      title: "Hyundai Venue",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      shipping: "200.00",
      deliverydate: "12 Dec 2022",
      price: "₹4,60,000",
      acceptprice: "₹4,00,000",
      img: require("../assets/new design/car_img.png"),
      location: " Ashok Vihar Delhi",
      year: "2019",
      time: "02 D 12 Hr",
      distance: "5000 KMS",
      ownership: "1st",
    },
    {
      id: 4,
      seller: "@AkshatTripathi",
      title: "Hyundai Venue",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      shipping: "200.00",
      deliverydate: "12 Dec 2022",
      price: "₹4,60,000",
      acceptprice: "₹4,00,000",
      img: require("../assets/new design/car_img.png"),
      location: " Ashok Vihar Delhi",
      year: "2019",
      time: "02 D 12 Hr",
      distance: "5000 KMS",
      ownership: "1st",
    },
  ]);

  const totalAmount = shippingCost + userCart?.total_price;

  // let data = [
  //   {
  //     id: 1,
  //     seller: "@AkshatTripathi",
  //     title: "DeepCool PM850D Power Supply Including shipping",
  //     desc: "Interested Buyers Contact Me Shipping All Over India",
  //     price: "₹3,000",
  //     img: require("../assets/new design/car_img.png"),
  //     shipping: "200.00",
  //     deliverydate: "12 Dec 2022",
  //   },
  //   {
  //     id: 6,
  //     seller: "@AkshatTripathi",
  //     title: "Nvidia RTX 3070 Foundation Edition",
  //     desc: "Manufacturing Date- December 2021 Purchased Bill Feb 2022. Used for CAD Application.",
  //     price: "₹3,000",
  //     img: require("../assets/new design/car_img.png"),
  //     shipping: "200.00",
  //     deliverydate: "12 Dec 2022",
  //   },
  //   {
  //     id: 11,
  //     seller: "@AkshatTripathi",
  //     title: "DeepCool PM850D Power Supply Including shipping",
  //     desc: "Interested Buyers Contact Me Shipping All Over India",
  //     price: "₹3,000",
  //     img: require("../assets/new design/car_img.png"),
  //     shipping: "200.00",
  //     deliverydate: "12 Dec 2022",
  //   },
  //   {
  //     id: 21,
  //     seller: "@RakshitTripathi",
  //     title: "Nvidia RTX 3070 Foundation Edition",
  //     desc: "Manufacturing Date- December 2021 Purchased Bill Feb 2022. Used for CAD Application.",
  //     price: "₹3,000",
  //     img: require("../assets/new design/car_img.png"),
  //     shipping: "200.00",
  //     deliverydate: "12 Dec 2022",
  //   },
  // ];


  // Case if direct payment of product without adding in cart
  // let productid = null;
  // let addressid = null;
  // let taxprice = 0;
  // let shippingprice = 0;
  // if (location.state && location.state.data) {
  //   data = [location.state.data];
  //   // find all variable and replace them in below values.
  //   productid = "646faadfce5a5500a72a6424";
  //   addressid = "646fab38ce5a5500a72a6427";
  //   taxprice = 0;
  //   shippingprice = 0;
  // }

  // let arr = [];
  // let cdata = data.sort((a, b) => a.seller > b.seller);
  // for (let i = 0; i < cdata.length; i++) {
  //   if (i === 0 || cdata[i - 1].seller !== cdata[i].seller) {
  //     arr.push({ seller: cdata[i].seller, items: [cdata[i]] });
  //   } else {
  //     arr[arr.length - 1].items.push(cdata[i]);
  //   }
  // }
  // data = arr;

  const handleRemoveFromCart = (product) => {
    const reqProduct = {
      _id: product._id,
      cost: product.cost
    }
    dispatch(removeProductFromCart(reqProduct));
  }

  const cartShippingCost = () => {
    let totalShippingCost = 0;
    for (const items of userCart.items) {
      for (const product of items.products) {
        handleShipmentCost(product, userCart.address.pincode, setShipmentDetails);
        // if (shipmentDetails.Message) {
        //   return;
        // }        
        totalShippingCost += shipmentDetails?.Delivery_Fee || 0;
        setShipmentDetails(null);
      }
    }
    console.log(totalShippingCost);
    setShippingCost(totalShippingCost);
  };

  useEffect(() => {
    if (userCart.address) {
      cartShippingCost();
    }
  }, [userCart.address]);

  useEffect(() => {
    axiosInstance
            .get(`/cart/my`)
            .then((response) => {
                dispatch({
                    type: USER_CART,
                    payload: response.data.cart
                })
                setLoading(false);
                dispatch(getCurrentUser());
            })
            .catch(({ response }) => {
                try {
                    switch (response.status) {
                        default:
                            // server error
                            setLoading(false);
                            break;
                    }
                } catch (e) {
                    setLoading(false);
                    toast.error("Couldn't reach the server");
                }
            });
  }, []);

  const handleCheckOut = () => {
    // const cartToSend = {
    //   ...userCart,
    //   items: userCart.items.map(item => ({
    //     seller : item.seller_id,
    //     products: item.products.map(product => product._id)
    //   }))
    // };

    const object = {
      amount: totalAmount,
      cart : user.cart,
      address : userCart.address._id
    }

    axiosInstance
      .post(`payment/createOrder`, object)
      .then((res) => {
        const { url } = res.data.data;
        window.location.href = url;
        toast.success("Proceeding!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  }

  return (
    // desktop
    <div>
       {loading &&
                <div className="mt-5 flex items-center justify-center flex-col px-3 pb-10 xl:mt-10">
                    <h1 className=" w-[95%] text-[26px] mediumFont text-black text-center xl:text-[32px] pb-10">
                        Please Wait.
                    </h1>
                    <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
                </div>}

      {!loading && user && userCart.items && userCart.items.length > 0 ?
        <div className="px-1 mt-5 xl:px-12 xl:pt-8 xl:mt-0 xl:bg-[#FFFCF8] xl:pb-20 border-b-2 border-black 2xl:px-[64.5px] 2xl:pt-[3.2rem]">
          <div className=" border-[1px] border-black xl:border-none xl:flex xl:flex-row xl:justify-between">
            <div className=" flex-1">
              <div className="flex-1 xl:bg-white  xl:border-[1px] xl:border-black">
                <div className="px-2.5 py-3 xl:hidden">
                  <div className="flex items-center justify-between">
                    <h1 className="text-[15px] mediumFont text-black">
                      Delivery to:
                    </h1>
                  </div>
                  <div className="flex items-start justify-between gap-x-2 mt-2">
                    {userCart.address ?
                      <div className=" flex">
                        <LocationOnOutlined />
                        <p className="text-sm font-normal text-[#2E2E2E] ml-1 2xl:text-base">
                          {userCart?.address?.address_line1}, {userCart?.address?.city}, {userCart?.address?.state}, {userCart?.address?.pincode}
                        </p>
                      </div> :
                      <div className=" flex">
                        <LocationOnOutlined />
                        <p className="text-sm font-normal text-[#2E2E2E] ml-1 2xl:text-base">
                          Please Select an Address
                        </p>
                      </div>
                    }
                    <button
                      onClick={() => {
                        showModal({
                          modalType: DELIVERY_LOCATION_MODAL,
                          modalTitle: ``,
                          modalSubTitle: "",
                        });
                      }}
                      className="underline mediumFont text-sm cursor-pointer text-[#1E44CD] 2xl:text-base "
                    >
                      Select Address
                    </button>
                  </div>
                </div>
                <div className="px-4 py-3 hidden xl:flex xl:flex-col 2xl:py-5">
                  <div className="flex items-center justify-between">
                    <h1 className="text-lg mediumFont text-black 2xl:text-[20.8px]">
                      Delivery to:
                    </h1>
                  </div>
                  <div className="flex items-start justify-between gap-x-2 mt-2">
                    {userCart.address ?
                      <div className=" flex">
                        <LocationOnOutlined />
                        <p className="text-sm font-normal text-[#2E2E2E] ml-1 2xl:text-base">
                          {userCart?.address?.address_line1}, {userCart?.address?.city}, {userCart?.address?.state}, {userCart?.address?.pincode}
                        </p>
                      </div> :
                      <div className=" flex">
                        <LocationOnOutlined />
                        <p className="text-sm font-normal text-[#2E2E2E] ml-1 2xl:text-base">
                          Please Select an Address
                        </p>
                      </div>
                    }
                    <button
                      onClick={() => {
                        showModal({
                          modalType: DELIVERY_LOCATION_MODAL,
                          modalTitle: ``,
                          modalSubTitle: "",
                        });
                      }}
                      className="underline mediumFont text-sm cursor-pointer text-[#1E44CD] 2xl:text-base "
                    >
                      Select Address
                    </button>
                  </div>
                </div>
                {/* mobile */}
                {/* Mobile Version */}
                <div className="xl:hidden px-1 mt-5">
                  {userCart.items && userCart.items.length > 0 ? (
                    userCart.items.map((seller) => (
                      <div className="mb-4" key={seller._id}>
                        <div className="px-2 py-2.5 bg-[#F2FBF6] border border-black flex items-center justify-between">
                          <h1 className="text-xs font-normal text-black">
                            Sold By{" "}
                            <span className="text-sm mediumFont ml-1">
                              {seller.seller.first_name}
                            </span>
                          </h1>
                          <div
                            onClick={() => {
                              showModal({
                                modalType: DELIVERY_LOCATION_MODAL,
                                modalTitle: ``,
                                modalSubTitle: "",
                              });
                            }}
                            className="underline mediumFont text-sm cursor-pointer text-[#1E44CD] "
                          >
                            Shop More
                          </div>
                        </div>
                        <div className="relative mt-1">
                          {seller.products.map((item, j) => (
                            <div key={j} className="py-3 px-2 border-b-[1px] border-black">
                              <div className="flex items-start">
                                <div className="border border-black w-[25%] h-20 rounded">
                                  <img
                                    src={item?.thumbnail}
                                    alt="thumbnail"
                                    className="w-full h-full object-cover aspect-square"
                                  />
                                </div>
                                <div className="flex-1 pl-2">
                                  <h1 className="text-[14px] font-normal text-black leading-4">
                                    {item?.title} - {item?.description}
                                  </h1>
                                  <h1 className="text-lg mediumFont text-black mt-1">
                                    &#8377; {item?.cost}
                                  </h1>
                                </div>
                              </div>
                              {/* <div className="flex items-center justify-between mt-2 border-t-[1px] border-b-[1px] border-black">
                              <div className="w-1/2 py-1 border-r-[1px] border-black">
                                <h1 className="text-xs font-normal text-black">Shipping Charges</h1>
                                <h1 className="text-xs mediumFont ml-1">+₹{item?.shipping}</h1>
                              </div>
                              <div className="w-1/2 py-1">
                                <h1 className="text-xs font-normal text-black text-right">Delivered by</h1>
                                <p className="text-xs mediumFont ml-1 text-right">{item?.deliverydate}</p>
                              </div>
                            </div> */}
                              <div className="flex items-center justify-between mt-2">
                                <div className="underline mediumFont text-sm cursor-pointer text-[#1E44CD] ">
                                  Save for later
                                </div>
                                <div className="underline mediumFont text-sm cursor-pointer text-[#BC0000] ">
                                  <button onClick={() => handleRemoveFromCart(item)}>
                                    <Delete style={{ fontSize: "28px" }} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-full">
                      <div>
                        <h1 className="text-xl mediumFont leading-3 p-10 border-t border-black text-center">
                          Your cart is Empty!
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
                {/* End Mobile Version */}
                {/* desktop */}

                {userCart.items && userCart.items.length > 0 ? (
                  userCart.items.map((seller) => (
                    <div className="hidden xl:flex xl:flex-col" key={seller._id}>
                      <div className="w-full flex flex-col items-start">
                        <div className="w-full flex items-center justify-between px-3 py-3 bg-[#F2FBF6] border-y border-black 2xl:py-3 2xl:mt-1">
                          <h1 className="text-xs mediumFont tracking-[0.5px] 2xl:text-base">
                            Sold By:{" "}
                            <span className="text-sm text-black mediumFont 2xl:text-lg">
                              {seller.seller.first_name}
                            </span>
                          </h1>
                          <h1 className="text-[13.5px] mediumFont text-[#2192FF] underline 2xl:text-base">
                            Shop More from This Seller
                          </h1>
                        </div>
                        {seller.products.map((item, j) => (
                          <div className={`w-full ${j !== 0 ? 'border-t border-black' : ''}`} key={item?._id} >
                            <div className={`px-3.5 py-4.5 flex items-start `}>
                              <div className="flex absolute top-6 left-2 rounded-sm">
                                <div className="relative flex flex-col w-[78px] h-[35px]">
                                  <div className="mb-2">
                                    <img
                                      src={require("../assets/icons/main.svg").default}
                                      alt="Main"
                                      className="w-full h-full relative z-10 fill-#FFDC25"
                                    />
                                  </div>
                                  <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
                                    <img
                                      src={require("../assets/icons/shadow.svg").default}
                                      alt="Shadow"
                                      className="w-full h-full absolute z-0"
                                    />
                                  </div>
                                </div>
                                <h3 className="boldFont ml-[-65px] mt-[2px] z-30">
                                  {item?.condition}
                                </h3>
                              </div>
                              <div className="w-[22%] 2xl:w-[22%] overflow-hidden border border-black rounded-md">
                                <img
                                  src={item?.thumbnail}
                                  alt="thumbnail"
                                  className="w-full aspect-square object-cover h-[10rem]"
                                />
                              </div>
                              <div className="ml-4 self-stretch flex-1 flex flex-col justify-between 2xl:mt-1">
                                <div className="flex items-start justify-between w-full">
                                  <div>
                                    <h1 className="text-lg mediumFont leading-3 2xl:text-[22px]">
                                      {item?.title}
                                    </h1>
                                    <h1 className="text-[13px] mt-1.5 italic text-[#5C5C5C] 2xl:mt-2.5 2xl:text-base">
                                      "{item?.description}"
                                    </h1>
                                  </div>
                                  <div className="flex justify-end flex-col items-end">
                                    <h1 className="text-lg mediumFont leading-3 2xl:text-[22px]">
                                      &#8377; {item?.cost}
                                    </h1>
                                    <h1 className="text-[13px] mt-1.5 text-[#5C5C5C] 2xl:text-base 2xl:mt-2.5">
                                      {item?.shipping}
                                    </h1>
                                  </div>
                                </div>
                                <div className="flex items-center justify-between">
                                  <h1 className="text-[13.5px] mediumFont text-[#2192FF] underline 2xl:text-base 2xl:mediumFont">
                                    Save for later
                                  </h1>
                                  <button
                                    onClick={() => handleRemoveFromCart(item)}
                                    className="text-[14px] mediumFont"
                                  >
                                    <Delete style={{ fontSize: "28px" }} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full">
                    <div>
                      <h1 className="text-xl mediumFont leading-3 2xl:text-[42px] p-10  border-t border-black text-center">
                        Your cart is Empty!
                      </h1>
                    </div>
                  </div>
                )}


                <div className="xl:flex items-center gap-2 mt-2 hidden border-t border-black py-2 bg-[#F2FBF6]">
                  <img src={require("../assets/new design/exchange.png")} />
                  <h1 className="text-[12px] 2xl:text-base">
                    <span className="mediumFont">Returns :</span> Hassle free
                    returns covered by Stake Atlas Guarantee within 14 days of
                    delivery
                  </h1>
                </div>
              </div>
              {/* returns */}


            </div>
            {/* checkout */}
            <div className="bg-white xl:w-[33%] 2xl:w-[33%]  xl:h-fit xl:px-2 xl:border-y-[1px] xl:border-r-[1px] border-black">
              <div className="mt-4 flex items-center justify-between rounded px-2 2xl:px-3 2xl:mt-5">
                <input
                  type="text"
                  placeholder="#Promo Code"
                  className={classNames(
                    " rounded-l outline-none mediumFont flex-1 py-2 px-2 border-[1px] border-black select-none 2xl:py-[12px]"
                  )}
                />
                <button className=" w-24 bg-sa-menu-green text-white px-4 py-2 rounded-r border-[1px] border-black 2xl:py-[12px] 2xl:w-[7.4rem]">
                  {"Apply"}
                </button>
              </div>
              <div className="px-3 mb-20 xl:mb-0">
                <div className="mb-2 mt-3 xl:mb-4 2xl:mt-5">
                  <h1 className="text-lg xl:text-xl mediumFont 2xl:text-[24px]">
                    Your cart
                    <span className="text-[#0F7975]"> ({userCart?.total_qty} items)</span>
                  </h1>
                </div>
                <div className="border-t-[1px] border-black pt-3 2xl:pb-3 2xl:pt-4">
                  <div className="flex items-center justify-between w-full mb-2 2xl:pb-2">
                    <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                      Subtotal
                    </h1>
                    <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                      ₹{userCart?.total_price}/-
                    </h1>
                  </div>
                  {shipmentDetails?.Message ? <div className="flex items-center justify-between w-full 2xl:pb-2">
                    <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                      StakeAtlas Shipping Unavailable
                    </h1>
                  </div> :
                    <div className="flex items-center justify-between w-full 2xl:pb-2">
                      <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                        Shipping
                      </h1>
                      <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                        ₹{shippingCost}.00/-
                      </h1>
                    </div>}
                </div>
                {/* <div className="border-t-[1px] border-black pt-3">
                <div className="flex items-center justify-between w-full mb-2 2xl:pb-3 2xl:pt-2">
                  <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                    Promo DEAL40
                  </h1>
                  <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px] text-black">
                    -₹2,000.00/-
                  </h1>
                </div>
              </div> */}
                <div className="border-t-[1px] xl:border-b-[1px]  xl:pb-2 border-black pt-3 pb-4 2xl: 2xl:pb-6">
                  <div className="flex items-center justify-between w-full">
                    <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                      Total Amount
                    </h1>
                    <h1 className="text-sm xl:text-lg mediumFont 2xl:text-[22px]">
                      ₹{totalAmount}/-
                    </h1>
                  </div>
                </div>
                <button
                  // onClick={() => {
                  //   showModal({
                  //     modalType: PAYMENT_MODAL,
                  //     modalTitle: ``,
                  //     modalSubTitle: "",
                  //     modalProps: {
                  //       productid: productid,
                  //       addressid: addressid,
                  //       taxprice: taxprice,
                  //       shippingprice: shippingprice,
                  //     },
                  //   });
                  //   console.log("Pay");
                  // }}
                  onClick={() => handleCheckOut()}
                  className="w-full bg-sa-menu-green my-4 py-2 xl:my-4 xl:mb-4 text-white rounded buttonHover relative z-40 cursor-pointer"
                >
                  Proceed to checkout
                </button>
              </div>
            </div>
          </div>
        </div> : !user && !loading ?
          <div className="w-full border-b-[1px] border-black">
            <div>
              <div className="text-[32px] mediumFont leading-3 p-40 border-t border-black text-center item-center">
                You Don't Have any items in Cart.
                <p className="text-sm mediumFont leading-3 p-5  text-center">
                  Have a Stake Atlas account? Sign in to see items in cart.
                </p>
                <div className="w-full item-center text-center" >
                  <SignInpanelGeneral />
                </div>
              </div>
            </div>
          </div>
          : !loading &&
          <div className="w-full border-b-[1px] border-black">
            <div>
              <h1 className="text-[32px] mediumFont p-40 border-t border-black text-center ">
                You Don't Have any items in Cart.
                <p className="text-sm mediumFont text-center">
                  Check your saved items or {' '}
                  <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
                </p>
              </h1>
            </div>
          </div>}

      {user && !loading && <div className="px-14 py-10 hidden xl:flex xl:flex-col">
        <h1 className="text-3xl mediumFont 2xl:text-4xl">
          Saved for later
          <span className="text-[#0F7975]">(98)</span>
        </h1>
        <p className=" w-[90%] text-sm font-normal leading-5 mt-2 2xl:text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>

        <div className="hidden xl:flex space-x-4  items-center justify-between mt-10 mb-20">
          {otherproducts.map((e, i) => (
            <ProductCard3 key={i} data={e} />
            // <ProductCard product = {product}/>
          ))}
        </div>
      </div>}
    </div >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(null, mapDispatchToProps)(CartScreen);

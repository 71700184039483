import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import OfferApproveModal from "./OfferApproveModal";
import OfferDeclinedModal from "./OfferDeclinedModal";
import OfferCounterModal from "./OfferCounterModal";

const OfferReceivedModal = ({ onClose ,offer,productId}) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isDeclinedModalOpen, setIsDeclinedModalOpen] = useState(false);
  const [isCounterModalOpen, setIsCounterModalOpen] = useState(false);
  const handleAcceptOffer = () => {
    setIsApproveModalOpen(true);
  };

  const handleDeclineOffer = () => {
    setIsDeclinedModalOpen(true);
  };

  const handleCounterOffer = () => {
    setIsCounterModalOpen(true);
  };

  const handleBack = () => {
    setIsApproveModalOpen(false);
    setIsDeclinedModalOpen(false);
    setIsCounterModalOpen(false);
  };

  return (
    <div className="w-full h-full bg-black/50 ">
      {/* desktop card */}
      <div className="fixed hidden 2md:flex top-0 left-0 w-full h-full  items-center justify-center bg-black bg-opacity-50">
        <div className="relative">
          <button className="absolute top-2 right-2 z-10" onClick={onClose}>
            <AiFillCloseCircle size={20} color="red" />
          </button>
          {isApproveModalOpen ? (
            <OfferApproveModal onBack={handleBack} offer={offer} productId = {productId}/>
          ) : isDeclinedModalOpen ? (
            <OfferDeclinedModal onBack={handleBack} offer={offer} productId = {productId}/>
          ) : isCounterModalOpen ? (
            <OfferCounterModal onBack={handleBack} offer={offer} productId = {productId}/>
          ) : (
            <div className="w-[529px] h-[328px] relative bg-stone-50 z-0 rounded-lg border border border border border-black">
              <div className="left-[123px] top-[32px] absolute text-center text-black text-[42px] mediumFont leading-10">
                Offer Received
              </div>
              <div className="w-[140px] h-[50px] px-8 py-3 left-[24px] top-[250px] absolute bg-[#D1503B] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex">
                <button
                  className="text-white text-[16px] mediumFont leading-tight"
                  onClick={handleDeclineOffer}
                >
                  Decline
                </button>
              </div>
              <div className="w-[140px] h-[50px] px-8 py-3 left-[194px] top-[250px] absolute bg-[#0167DE] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex">
                <button
                  onClick={handleCounterOffer}
                  className="text-white text-[16px] mediumFont leading-tight"
                >
                  Counter
                </button>
              </div>
              <div className="w-[140px] h-[50px] px-8 py-3 left-[365px] top-[250px] absolute bg-[#139F00] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex">
                <button
                  onClick={handleAcceptOffer}
                  className="text-white text-[16px] mediumFont leading-tight"
                >
                  Accept
                </button>
              </div>
              <div className="w-[481px] h-[54px] left-[24px] top-[100px] absolute">
                <div className="w-[109px] h-[54px] left-0 top-0 absolute">
                  <div className="left-0 top-[24px] absolute text-black text-[24px] leading-7">
                    @{offer?.buyer?.username}
                  </div>
                  <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
                    From
                  </div>
                </div>
                <div className="w-[140px] h-[54px] left-[341px] top-0 absolute">
                  <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
                    Offer Amount
                  </div>
                  <div className="w-[140px] h-[30px] left-0 top-[24px] absolute bg-[#FEFFB2] justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-black text-[24px] boldFont leading-7">
                    ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="w-[435px] left-[48px] top-[186px] absolute text-center text-black text-[16px] leading-tight">
                70% buyers accept a counter offer from a seller. Make a counter
                offer to make a deal!
              </div>
            </div>
          )}
        </div>
      </div>
      {/* mobile card */}
      <div className="w-full 2md:hidden flex justify-center h-screen bg-black/50 fixed top-0 left-0 ">
        <div class="fixed flex 2md:hidden top-[100px] w-[340px] h-[450px] items-center justify-center bg-white">
          <button className="absolute top-2 right-2 z-10" onClick={onClose}>
            <AiFillCloseCircle size={20} color="red" />
          </button>

          {isDeclinedModalOpen ? (
            <OfferDeclinedModal onBack={handleBack} offer={offer} productId = {productId}/>
          ) : isApproveModalOpen ? (
            <OfferApproveModal onBack={handleBack} offer={offer} productId = {productId}/>
          ) : isCounterModalOpen ? (
            <OfferCounterModal onBack={handleBack} offer={offer} productId = {productId}/>
          ) : (
            <>
              <div class="w-[145px] h-[50px] py-3 left-[16px] top-[370px] absolute bg-[#139F00] rounded border border-black justify-center items-center gap-2.5 inline-flex">
                <button
                  onClick={handleAcceptOffer}
                  class="text-white text-[16px] mediumFont leading-tight"
                >
                  Accept
                </button>
              </div>
              <div class="w-[145px] h-[50px] py-3 left-[182px] top-[370px] absolute bg-[#D1503B] rounded border border-black justify-center items-center gap-2.5 inline-flex">
                <button
                  onClick={handleDeclineOffer}
                  class="text-white text-[16px] mediumFont leading-tight"
                >
                  Decline
                </button>
              </div>
              <div class="flex w-full justify-center top-[32px] absolute text-center text-black text-[28px] boldFont leading-9">
                Offer Received
              </div>

              <div class="left-[16px] top-[88px] absolute">
                <div class="left-0 top-[25px] absolute text-black text-[24px] font-normal leading-7">
                  @{offer?.buyer?.username}
                </div>
                <div class="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
                  From
                </div>
              </div>
              <div class="w-full flex justify-center h-[57px]  top-[163px] absolute">
                <div class=" text-center text-stone-500 text-[18px] boldFont leading-snug">
                  Offer Amount
                </div>
                <div class=" h-[35px] top-[22px] absolute bg-yellow-100 justify-center items-center gap-2.5 inline-flex">
                  <div class="text-center text-black text-[28px] boldFont leading-9">
                  ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}
                  </div>
                </div>
              </div>
              <div class="h-[55px] left-[275px] top-[88px] absolute">
              </div>
              <div class="w-[310px] h-10 left-[16px] top-[238px] absolute text-center text-black text-[16px] font-normal leading-tight">
                70% sellers accept a counter offer from a buyer. Make a counter
                offer to make a deal!
              </div>
              <div class="w-[310px] h-[50px] px-8 py-3 left-[16px] top-[302px] absolute bg-[#0167DE] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex">
                <button
                  onClick={handleCounterOffer}
                  class="text-white text-[16px] mediumFont leading-tight"
                >
                  Counter
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferReceivedModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  AccessTimeOutlined,
  LocationOnOutlined,
  Star,
  MailOutline,
  ExpandMore,
  ThumbUp,
  StarOutline,
} from "@mui/icons-material";
import Accourdion from "../components/Accourdion";
import ProductCardMob from "../components/Home/ProductCardMob";
import ProductCard from "../components/Home/ProductCard";
import PreviewCardMob from "../components/Seller/PreviewCardMob";
import PreviewCardDesktop from "../components/Seller/PreviewCardDesktop";
import { useDispatch, useSelector } from "react-redux";
import { clearProductDetails, getAllProducts, listProduct } from "../redux/actions/product";
import ReactLoading from 'react-loading';
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";


const PreviewScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productCopy } = useSelector((state) => state.product);
  const [loading, setLoading] = useState(true);
  console.log(loading);

  useEffect(() => {
    setTimeout(() => {
      axiosInstance
        .post(`product/add`, productCopy)
        .then((res) => {
          const { data } = res.data;
          setLoading(false);
          toast.success("Item Listing Successfull! Waiting for Admin Review");
          dispatch(getAllProducts());
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");
                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");
                break;
              case 403:
                //   add a toast
                toast.error("First you have to register yourself as a seller!");
                break;
              default:
                // server error
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        })
      dispatch(clearProductDetails());
    }, 100);
  }, [dispatch]);


  return (
    <div className="bg-[#fdf8f4]">
      {loading ?
        <div className="mt-5 flex items-center justify-center flex-col px-3 pb-10 xl:mt-10">
            <h1 className=" w-[95%] text-[26px] mediumFont text-black text-center xl:text-[32px] pb-10">
                You’ve Listing is under processing.
            </h1>
          <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
        </div>
        :
        <>
          <div className="mt-5  flex items-center justify-center flex-col px-3 xl:mt-10">
            <h1 className=" w-[95%] text-[26px] mediumFont text-black text-center xl:text-[32px]">
              You’ve Successfully Listed your product.
            </h1>
            <p className="text-sm text-center w-[80%] mt-3 xl:w-[30%] xl:text-base">
              Your listing is pending review, we’ll notify you when it gets posted.
              You’ll be redirected to home page in <br />{" "}
              <span className="mediumFont">5sec</span>
            </p>
            <button
              onClick={() => {
                dispatch(clearProductDetails());
                navigate("/")
              }}
              className="text-[14px] py-2.5 px-10 rounded-[4px] bg-yellow-200 text-black border-[1px] border-sa-border-black mediumFont mt-4"
            >
              Go Back Home
            </button>
          </div>
          <div className="px-3 xl:px-40 xl:mb-10">
            {/* mobile */}
            <div className="px-[2.5px] mt-6 xl:hidden">
              <h1 className=" text-xl mediumFont text-black mt-4 mb-2">
                Preview :
              </h1>
              <PreviewCardMob product={productCopy} />
            </div>

            {/* desktop */}
            <div className="px-40">
              <PreviewCardDesktop product={productCopy} />
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default PreviewScreen;

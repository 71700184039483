import { AccessTimeOutlined, Delete } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import ProductCard from "../components/Home/ProductCard";
import ProductCardMob from "../components/Home/ProductCardMob";
import SideMenu from "../components/Seller/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteDraft, getAllUserDrafts } from "../redux/actions/product";
import DraftProductCard from "../components/Home/DraftProductCard";
import SignInpanelGeneral from "../components/SignInpanelGeneral";

const DraftsScreen = () => {
  const dispatch = useDispatch();
  const { userDrafts } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getAllUserDrafts());
    }
  }, []);

  const handleUpdateDraft = (draft) => {
    navigate("/sellnow/listing/general")
  }
  const handleDeleteDraft = (draftId) => {
    dispatch(deleteDraft(draftId))
  }


  const navigate = useNavigate();
  return (
    <div>
      {/* desktop */}
      {user && user.role !== "customer_unverified" ?
        <div className=" min-h-screen md:flex hidden">
          <SideMenu />
          <div className=" flex-1">
            <div className=" flex items-center justify-between pl-12 pr-16 pt-12">
              <h1 className=" text-[41px] mediumFont text-black tracking-wide">
                Choose from a template
              </h1>
              <button
                onClick={() => navigate("/sellnow/listing/general")}
                className=" text-lg py-3 px-10 rounded-[4px] bg-[#005C51] text-white mediumFont buttonHover relative z-40 cursor-pointer"
              >
                Create new listing
              </button>
            </div>
            <div className="flex flex-wrap">
              {userDrafts && userDrafts.length > 0 ? (
                userDrafts.map((draft, index) => (
                  <div key={index} className="w-full md:w-1/3 px-4 mb-8">
                    <div className="flex flex-col h-full p-6 rounded-lg">
                      <DraftProductCard draft={draft} />
                      <div className="mt-3 flex justify-between">
                        <button
                          onClick={() => handleUpdateDraft(draft)}
                          className="flex-1 text-lg py-2.5 rounded-[4px] bg-black text-white border-[1px] border-sa-border-black mediumFont">
                          Edit this draft
                        </button>
                        <button
                          onClick={() => handleDeleteDraft(draft.id)}
                          className="text-lg py-2 px-3 rounded-[4px] bg-[#FFCECE] text-black border-[1px] border-sa-border-black mediumFont">
                          <Delete />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full px-14 py-10">
                  <p className="text-3xl font-bold">No drafts available.</p>
                </div>
              )}
            </div>
          </div>
        </div> :
        user && user.role === "customer_unverified" && user.seller_request !== "created" ?
          <div className=" min-h-screen md:flex hidden">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must register as a seller to Proceed.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Only One listing per customer is allowed, For more please

                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/seller/registration">Register Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div> : user && (user.role === "customer_unverified" || user.role === "customer") && user.seller_request === "created" ?
            <div className="h-[50vh] bg-[#F3CEB4] px-5 py-14 xl:pt-28 xl:h-[60vh]">
              <div className=" border-[1px] border-black bg-white py-12 px-5 xl:w-fit xl:mx-auto xl:px-10 xl:py-12">
                <h1 className="text-3xl mediumFont text-black text-center tracking-wide xl:text-[40px]">
                  Your Request is under Review!
                </h1>
                <p className="text-lg mediumFont text-black text-center mt-2 tracking-wide xl:text-[29px] xl:mt-3">
                  You will be informed, when we are done.
                </p>
                <div className="flex items-center justify-center gap-x-12 mt-5 xl:mt-8">
                  <h1 className="text-[22px] boldFont tracking-wide text-[#737373] xl:text-[25px]">
                    Status
                  </h1>
                  <div className="flex items-center gap-x-1">
                    <AccessTimeOutlined fontSize="small" />
                    <h1 className="text-[22px] boldFont tracking-wide text-sa-icon-green xl:text-[25px]">
                      Pending
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>
      }

      {/* mobile */}
      {user && user.role !== "customer_unverified" ?
        <div className="md:hidden h-[92vh] bg-white">
          <div className="px-4 pt-12">
            <div className="">
              <button
                onClick={() => navigate("/sellnow/listing/general")}
                className=" w-full text-lg py-2 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumFont buttonHover relative z-40 cursor-pointer"
              >
                Create new listing
              </button>
              <h1 className="text-lg mediumFont text-black my-4 text-center">
                Or
              </h1>
              <h1 className="text-[26px] mediumFont text-black tracking-wide">
                Choose from a template
              </h1>
            </div>
            <div className="xl:hidden pt-5 h-[50vh] overflow-y-auto no-scrollbar">
              {userDrafts && userDrafts.length > 0 ? (
                userDrafts.map((e) => (
                  <div className="mb-4" key={e.id}>
                    <ProductCardMob product={e} />
                    <div className="flex items-center justify-between gap-3">
                      <button
                        onClick={() => handleUpdateDraft(e.id)}
                        className="flex-1 text-base py-2.5 rounded-[4px] bg-black text-white border-[1px] border-sa-border-black mediumFont"
                      >
                        Edit this draft
                      </button>
                      <button
                        className="text-base py-2 px-3 rounded-[4px] bg-[#FFCECE] text-black border-[1px] border-sa-border-black mediumFont"
                        onClick={() => handleDeleteDraft(e.id)}
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full px-14 py-10">
                  <p className="text-3xl font-bold">No drafts available.</p>
                </div>
              )}

            </div>
          </div>
          <div className="mt-7">
            <button className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont">
              Sell Now Menu
            </button>
          </div>
        </div> :
        user && user.role === "customer_unverified" && user.seller_request !== "created" ?
          <div className=" md:hidden h-[92vh] bg-white">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must register as a seller to Proceed.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Only One listing per customer is allowed, For more please

                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/seller/registration">Register Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div> : user && user.role === "customer_unverified" && user.seller_request === "created" ?
            <div className="md:hidden h-[92vh] bg-white">
              <div className="h-[50vh] bg-[#F3CEB4] px-5 py-14 xl:pt-28 xl:h-[60vh]">
                <div className=" border-[1px] border-black bg-white py-12 px-5 xl:w-fit xl:mx-auto xl:px-10 xl:py-12">
                  <h1 className="text-3xl mediumFont text-black text-center tracking-wide xl:text-[40px]">
                    Your Request is under Review!
                  </h1>
                  <p className="text-lg mediumFont text-black text-center mt-2 tracking-wide xl:text-[29px] xl:mt-3">
                    You will be informed, when we are done.
                  </p>
                  <div className="flex items-center justify-center gap-x-12 mt-5 xl:mt-8">
                    <h1 className="text-[22px] boldFont tracking-wide text-[#737373] xl:text-[25px]">
                      Status
                    </h1>
                    <div className="flex items-center gap-x-1">
                      <AccessTimeOutlined fontSize="small" />
                      <h1 className="text-[22px] boldFont tracking-wide text-sa-icon-green xl:text-[25px]">
                        Pending
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="md:hidden h-[92vh] bg-white">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>
      }
    </div>
  );
};

export default DraftsScreen;

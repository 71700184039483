import React, { useEffect, useState, useRef } from "react";
import maincar from "../../assets/new design/phone/image00001.jpeg";

const GalleryClosed = ({ handleImageClick }) => {
  const [gridElement, setGridElement] = useState(null);
  const [viewSize, setViewSize] = useState(null);
  const carouselRef = useRef(null);

  const Images = [
    require("../../assets/new design/phone/image00002.jpeg"),
    require("../../assets/new design/phone/image00003.jpeg"),
    require("../../assets/new design/phone/image00004.jpeg"),
    require("../../assets/new design/phone/image00005.jpeg"),
    require("../../assets/new design/phone/image00001 - Copy.jpeg"),
    require("../../assets/new design/phone/image00002 - Copy.jpeg"),
    require("../../assets/new design/phone/image00003 - Copy.jpeg"),
    require("../../assets/new design/phone/image00004 - Copy.jpeg"),
    require("../../assets/new design/phone/image00005 - Copy.jpeg"),
  ];
  const selectedImages = Images.filter((image, index) =>
    [0, 2, 4, 5, 6, 7].includes(index)
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1330) {
        setViewSize("large");
      } else if (window.innerWidth > 768) {
        setViewSize("medium");
      } else if (window.innerWidth <= 768) {
        setViewSize("small");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (viewSize === "small") {
      setGridElement(
        <div
          className=" grid grid-cols-8 grid-rows-2 h-full overflow-x-auto"
          style={{ width: "986px" }}
        >
          <div className=" row-span-2 col-span-4 h-full relative">
            <img
              src={maincar}
              class="absolute top-0 left-0 w-full h-full object-cover p-1 rounded-lg cursor-pointer"
              alt="Background"
              onClick={() => handleImageClick(0)}
            />
          </div>
          {Images.slice(0, 8).map((image, index) => (
            <div className=" h-full relative">
              <img
                src={image}
                className="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
                alt="back"
                onClick={() => handleImageClick(index + 1)}
              />
            </div>
          ))}
        </div>
      );
    } else if (viewSize === "medium") {
      setGridElement(
        <>
          <div className=" w-[40%] h-full relative">
            <img
              src={maincar}
              class="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
              alt="Background Image"
              onClick={() => handleImageClick(0)}
            />
          </div>
          <div className=" grid grid-cols-3 grid-rows-2 w-[60%]">
            {selectedImages.map((image, index) => (
              <div key={index} className=" relative h-full">
                <img
                  src={image}
                  className="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
                  onClick={() => handleImageClick(Images.indexOf(image) + 1)}
                />
              </div>
            ))}
          </div>
        </>
      );
    } else if (viewSize === "large") {
      setGridElement(
        <>
          <div className=" w-[40%] h-full relative">
            <img
              src={maincar}
              class="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
              alt="Background Image"
              onClick={() => handleImageClick(0)}
            />
          </div>
          <div className=" w-[60%] grid grid-cols-4">
            {Images.slice(0, 8).map((image, index) => (
              <div key={index} className=" h-full relative">
                <img
                  src={image}
                  className="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
                  onClick={() => handleImageClick(index + 1)}
                />
              </div>
            ))}
          </div>
        </>
      );
    }

    // Scroll to the beginning of the carousel when resizing
    if (carouselRef.current && viewSize === "small") {
      carouselRef.current.scrollLeft = 0;
    }
  }, [viewSize]);

  return (
    <div className="small:h-[60vh] h-96">
      <div className="flex h-full">
        {viewSize === "small" && (
          <div className="carousel overflow-x-auto" ref={carouselRef}>
            {gridElement}
          </div>
        )}
        {viewSize !== "small" && gridElement}
      </div>
    </div>
  );
};

export default GalleryClosed;

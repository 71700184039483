import {
  ExpandLess,
  ExpandMore,
  Menu,
  Search,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ordersIcon from "../assets/icons/orders_icon_black.svg";
import offersIcon from "../assets/icons/offers_icon_black.svg";
import sellingIcon from "../assets/icons/selling_icon_black.svg";
import messagesIcon from "../assets/icons/messages_icon_black.svg";
import recentIcon from "../assets/icons/recent_icon_black.svg";
import helpIcon from "../assets/icons/help_icon_black.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/auth";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hamBurgerShow, setHamBurgerShow] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const notification = user?.notifications;


  useEffect(() => {
    // getting all seller 
    if (search.length > 0) {
      let url = "";
      url = `product/all?title=${search}`

      axiosInstance
        .get(url)
        .then((res) => {
          const activeSearchResults = res.data.data?.filter(item => item.status.state === 'active');
          setSearchResults(activeSearchResults);
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");

                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");

                break;
              default:
                // server error
                toast.error("Oops, something went wrong");

                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        })
    }
  }, [search])

  const handleSearch = (title) => {
    setSearch("");
    setSearchResults([]);
    navigate('/search', { state: { data: title } });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(search);
    }
  };


  const accountMenuItems = [
    {
      title: "Orders",
      icon: ordersIcon,
      alt: "ordersIcon",
    },
    {
      title: "Offers",
      icon: offersIcon,
      alt: "offersIcon",
    },
    {
      title: "Selling",
      icon: sellingIcon,
      alt: "sellingIcon",
    },
    {
      title: "Messages",
      icon: messagesIcon,
      alt: "messagesIcon",
    },
    {
      title: "Recently Viewed",
      icon: recentIcon,
      alt: "recentIcon",
    },
    {
      title: "Help and Support",
      icon: helpIcon,
      alt: "helpIcon",
    },
    {
      title: "Logout",
      icon: helpIcon,
      alt: "logoutIcon",
    },
  ];
  const notificationItems = [
    {
      title: "Orders",
      icon: ordersIcon,
      alt: "ordersIcon",
    },
    {
      title: "Offers",
      icon: offersIcon,
      alt: "offersIcon",
    },
    {
      title: "Messages",
      icon: messagesIcon,
      alt: "messagesIcon",
    },
  ];
  return (
    <div>
      <div>
        <div className="hidden 2md:flex 2md:w-screen 2md:h-auto flex-1 bg-white border-b-[2px] border-black 2md:border-b-2">
          <div
            onClick={() => navigate("/")}
            className="border-r-2 border-black align-center justify-center w-[17vw] min-w-[218px] cursor-pointer flex items-center justify-center"
          >
            <h1 className="mediumFont text-black text-right 2md:text-4xl line hover:curson-pointer" onClick={() => navigate("/")}>
              Stake Atlas
            </h1>
          </div>
          <div className="border-r-2 border-black 2md:py-4 2md:px-7 2md:w-[23.5%] flex items-center 2md:py-5 2md:w-[23.2%] 42md:py-6 2md:w-[23.2%] relative">
            <div className="relative flex-1 flex items-center gap-1">
              <Search />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={handleKeyPress}
                className="w-full h-10 px-2 2md:text-xl 2md:text-[23px] focus:outline-none outline-none"
              />
              {searchResults.length > 0 && (
                <div className="absolute z-10 mt-12 w-[100%] bg-white border border-gray-300 shadow-lg top-[12px]">
                  <ul>
                    {searchResults.map((result) => (
                      <li
                        key={result.id}
                        className="px-4 py-2 cursor-pointer w-[100%] hover:bg-sa-primary-yellow border-[2px] border-black"
                        onClick={() => handleSearch(result.title)}
                      >
                        {result.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center border-r-2 border-black 2md:py-4 2md:w-[34.5%] 2md:py-5 32md:w-[34.8%] 2md:py-6 2md:w-[34.8%]">
            <div className="flex flex-col-[30%_30%_30%] px-[5%] w-full justify-around">
              <button className="group transition-all w-fit duration-300 ease-in-out font-normal text-black 2md:text-base  2md:text-lg 2md:text-[22px]">
                <span class="bg-left-bottom  pb-1  bg-gradient-to-r from-black to-black bg-[length:0%_1px] bg-no-repeat group-hover:bg-[length:100%_1px] transition-all duration-500 ease-out">
                  Categories
                </span>
              </button>
              <button className="group w-fit transition-all duration-300 ease-in-out font-normal text-black 2md:text-base  2md:text-lg 2md:text-[22px] relative h-full">
                <span class="bg-left-bottom pb-1 bg-gradient-to-r from-black to-black bg-[length:0%_1px] bg-no-repeat group-hover:bg-[length:100%_1px] transition-all duration-500 ease-out">
                  Notifications
                </span>
                <div className="group-hover:block hidden absolute rounded top-0 left-0 mt-[2.5rem] border-[1px] border-black  px-4 pt-5  w-72 bg-white text-black shadow-[4px_4px_18px_rgba(0,0,0,0.18)]">
                  {user && notification ?
                    <div className="my-5 flex flex-col mediumfont">
                      {notification.map(function (e, i) {
                        let iconSrc =
                          e.type === "offer"
                            ? offersIcon
                            : e.type === "order"
                              ? ordersIcon
                              : e.type === "message"
                                ? messagesIcon
                                : e.icon;
                        let navisrc =
                          e.type === "offer"
                            ? "/buying/offers"
                            : e.type === "order"
                              ? "/buying/purchaseHistory"
                                : "/";

                        if (e.type === "message") {
                          return (
                            <div
                              className="outline outline-black outline-[1px] rounded menuHover bg-white hover:rounded text-base flex items-center gap-3 p-4 relative"
                              onClick={() => {
                                navigate("/user/messages", { state: { chat: e.chat } });
                              }}
                            >
                              <img className="w-7 h-7" src={iconSrc} alt={e.alt} />
                              <p className="text-md mediumfont">{e.title}</p>
                            </div>
                          );
                        } else {
                          return (
                            <Link
                              className="outline outline-black outline-[1px] rounded menuHover bg-white hover:rounded text-base flex items-center gap-3 p-4 relative"
                              to={navisrc}
                            >
                              <img className="w-7 h-7" src={iconSrc} alt={e.alt} />
                              <p className="text-md mediumfont">{e.title}</p>
                            </Link>
                          );
                        }
                      })}
                    </div> :
                    <div className="my-5 flex flex-col mediumfont">
                      <div
                        className="outline outline-black outline-[1px] menuHover rounded bg-white hover:rounded text-base flex items-center gap-3 p-4 relative"
                      >
                        <p className="text-md mediumfont">Please Sign in to see notification</p>
                      </div>
                    </div>
                  }
                </div>
              </button>
              <Link
                to={
                  user && localStorage.getItem("accessToken")
                    ? "/buying/purchaseHistory"
                    : "/signin/username"
                }
                className="group w-fit transition-all duration-300 ease-in-out font-normal text-black 2md:text-base  2md:text-lg 2md:text-[22px] relative h-full"
              >
                <button
                  className="group w-fit transition-all duration-300 ease-in-out font-normal text-black 2md:text-base  2md:text-lg 2md:text-[22px] relative h-full"
                >
                  <span class="bg-left-bottom pb-1 bg-gradient-to-r from-black to-black bg-[length:0%_1px] bg-no-repeat group-hover:bg-[length:100%_1px] transition-all duration-500 ease-out">
                    Account
                  </span>
                  {user ? <div className="group-hover:block hidden absolute top-0 left-0 mt-[2.5rem] border-[1px] border-black  px-4 pt-5  w-72 bg-white text-black shadow-[4px_4px_18px_rgba(0,0,0,0.18)]">
                    <div className="flex items-center gap-x-3">
                      <div className="w-14 h-14">
                        <img
                          src={require("../assets/new design/user.png")}
                          alt=""
                          className="border-black rounded-full border-[1px] w-full"
                        />
                      </div>
                      <div>
                        <h1 className=" text-2xl boldfont">Hi, {user?.first_name}</h1>
                        <h1 className="text-sm font-normal">
                          Member since July 2022
                        </h1>
                      </div>
                    </div>
                    <div className="my-5 flex flex-col mediumfont">
                      {accountMenuItems.map(function (e, i) {
                        return (
                          <div className="outline outline-black outline-[1px] menuHover bg-white hover:rounded text-base flex items-center gap-3 p-4">
                            <img className="w-7 h-7" src={e?.icon} alt={e?.alt} />
                            <p className="text-md mediumfont">{e?.title}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div> :
                    <div className="group-hover:block hidden rounded absolute top-0 left-0 mt-[2.5rem] border-[1px] border-black  px-4 pt-5  w-72 bg-white text-black shadow-[4px_4px_18px_rgba(0,0,0,0.18)]">
                      <div className="my-5 flex flex-col mediumfont">
                        <button className="outline outline-black outline-[1px] bg-sa-primary-yellow rounded menuHover hover:rounded text-base items-center gap-3 p-4" onClick={() => navigate("/signin/username")}>
                          Sign In
                        </button>
                      </div>
                      <div>
                        <p className="items-center text-sm">
                          New Here?
                        </p>
                      </div>
                      <div className="my-5 flex flex-col mediumfont">
                        <button className="outline outline-black outline-[1px] rounded menuHover bg-white hover:rounded text-base items-center gap-3 p-4" onClick={() => navigate("/signup")}>
                          Sign Up
                        </button>
                      </div>
                    </div>
                  }
                </button>
              </Link>
            </div>
          </div>

          <div className="flex-1 flex items-center">
            <Link
              to={user?.role === "customer_unverified" ? "/seller/registration" : "/sellnow/drafts"}
              className="group transition-all duration-300 ease-in-out  h-full mediumfont bg-sa-primary-yellow text-black w-[50%] flex items-center justify-center border-r-[2px] border-black 2md:text-base 2md:text-lg 2md:text-[22px] "
            >
              <span class="bg-left-bottom bg-gradient-to-r from-black to-black bg-[length:0%_1px] bg-no-repeat group-hover:bg-[length:100%_1px] transition-all duration-500 ease-out">
                Sell Now
              </span>
            </Link>
            <Link
              to="/cart"
              className="group transition-all duration-300 ease-in-out  h-full font-normal text-black w-[50%] flex items-center justify-center 2md:text-base 32md:text-lg 2md:text-[22px] "
            >
              <span class="bg-left-bottom bg-gradient-to-r from-black to-black bg-[length:0%_1px] bg-no-repeat group-hover:bg-[length:100%_1px] transition-all duration-500 ease-out">
                <ShoppingCartOutlined className="mb-1" />
                Cart
              </span>
            </Link>
          </div>
        </div>

        <div className="2md:hidden border-t-[1px] border-b-[1px] border-black flex items-center justify-between px-[20px] pl-5 h-[4.5rem]">
          <div className="flex items-center w-full">
            <button onClick={() => setHamBurgerShow(!hamBurgerShow)}>
              <GiHamburgerMenu size={24} />
            </button>
            <div className="mx-auto uppercase hover:curson-pointer" onClick={() => navigate("/")}>
              <h1 className="text-2xl boldFont ml-3 mt-1 " >
                Stake <span className=" ml-1"></span> Atlas
              </h1>
            </div>
          </div>
          <Link
            to="/cart"
            className="border-[1px] border-black px-2.5 py-2.5 rounded-sm my-2 hover:bg-gray-200"
          >
            <MdOutlineShoppingCart size={24} />
          </Link>
        </div>
      </div >

      <div
        className={`transition-all duration-500 bg-white z-20 fixed h-screen w-screen text-black top-0 z-2 ${!hamBurgerShow ? "-left-[100vw] w-[100vw]" : "left-0 w-[100vw]"
          }`}
      >
        {user ?
          <div className="py-3 px-3.5 flex items-center justify-between border-b-[2px] border-black">
            <div>
              <h1 className="text-xl hover:cursor-pointer" onClick={() => {
                navigate("/buying/purchaseHistory");
                setHamBurgerShow(!hamBurgerShow);
              }}>Hi, {user?.first_name}</h1>
            </div>
            <div>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="#000"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button onClick={() => setHamBurgerShow(!hamBurgerShow)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="#000"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div> : <div className="py-3 px-3.5 flex items-center justify-between border-b-[2px] border-black">
            <div>
              <h1 className="text-xl hover:cursor-pointer border-[2px] border-black rounded p-2 hover:bg-sa-primary-yellow" onClick={() => {
                navigate("/signin/username");
                setHamBurgerShow(!hamBurgerShow);
              }}>Sign in Or Register</h1>
            </div>
            <div>
              <button onClick={() => setHamBurgerShow(!hamBurgerShow)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="#000"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>}
        {/* herhere */}
        <div className="">
          <h1 className=" text-base font-normal border-b-[2px] px-6 pb-2 pt-2 border-black hover:cursor-pointer hover:bg-sa-primary-yellow" onClick={() => {
            navigate("/");
            setHamBurgerShow(!hamBurgerShow);
          }}>Home</h1>
          <h1 className=" text-base font-normal border-b-[2px] px-6 pb-2 pt-2 border-black hover:cursor-pointer hover:bg-sa-primary-yellow ">Blog</h1>
          <h1 className=" text-base font-normal border-b-[2px] px-6 pb-2 pt-2 border-black hover:cursor-pointer hover:bg-sa-primary-yellow">Recently Viewed</h1>
          <h1 className=" text-base font-normal border-b-[2px] px-6 pb-2 pt-2 border-black hover:cursor-pointer hover:bg-sa-primary-yellow" onClick={() => {
            navigate("/buying/offers");
            setHamBurgerShow(!hamBurgerShow);
          }} >Offers</h1>
          <h1 className=" text-base font-normal border-b-[2px] px-6 pb-2 pt-2 border-black hover:cursor-pointer hover:bg-sa-primary-yellow ">Orders</h1>
          <h1 className=" text-base font-normal border-b-[2px] px-6 pb-2 pt-2 border-black hover:cursor-pointer hover:bg-sa-primary-yellow">Help & Support</h1>
          {/* <div className=" flex items-end" onClick={() => setExpand(!expand)}>
            <h1 className=" text-base font-normal text-white">Account</h1>
            {expand ? (
              <ExpandLess className=" text-white" />
            ) : (
              <ExpandMore className=" text-white" />
            )}
          </div>

          {false
            ? expand && (
                <div className="border-[1px] border-white rounded-md px-4 py-5 mt-3">
                  <h1 className=" text-sm text-white font-normal">
                    Login / Sign Up
                  </h1>
                </div>
              )
            : expand && (
                <div className=" border-[1px] border-white rounded-md px-4 pt-5 mt-3">
                  <div className="flex items-center gap-x-3 border-b-[1px] border-slate-400 pb-3">
                    <div className="w-10">
                      <img
                        src={require("../assets/new design/user.png")}
                        alt=""
                        className=" w-full"
                      />
                    </div>
                    <div>
                      <h1 className=" text-sm mediumfont text-white">
                        Hii, Akshat Tripathi
                      </h1>
                      <h1 className="text-xs font-normal text-grey-200">
                        Member since July 2022
                      </h1>
                    </div>
                  </div>
                  <div className="mt-3">
                    {[
                      "Orders",
                      "Offers",
                      "Selling",
                      "Messages",
                      "Recently Viewed",
                      "Help and Support",
                    ].map((e) => (
                      <h1 className=" text-base font-normal text-white mb-4">
                        {e}
                      </h1>
                    ))}
                  </div>
                </div>
              )} */}
        </div>

        <div className=" px-4 mt-4 flex items-center justify-center">
          <button
            onClick={() => {
              navigate("/sellnow/drafts");
              setHamBurgerShow(!hamBurgerShow);
            }}
            className=" w-full  text-[12px] py-2.5 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1.5px] border-sa-border-black mediumfont text-center"
          >
            Sell Now
          </button>
        </div>
        <div className="flex-grow"></div>

        {user && <div className=" px-4 mt-4 flex items-center justify-center">
          <button
            onClick={() => {
              dispatch(logoutUser());
              navigate("/");
            }}
            className=" w-full  text-[12px] py-2.5 px-3 rounded-[4px] bg-white text-black border-[1.5px] border-sa-border-black mediumfont text-center hover:bg-sa-primary-yellow"
          >
            Logout
          </button>
        </div>}
      </div>
    </div >
  );
};

export default NavBar;

import { combineReducers } from "redux";
import authReducer from "./auth";
import cartReducer from "./cart";
import modalReducer from "./modal";
import productReducer from "./product";
import categoryReducer from "./category"
import adminReducer from "./admin";

const rootReducer = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  product: productReducer,
  category: categoryReducer,
  cart: cartReducer,
  admin : adminReducer
});

export default rootReducer;

import React, { useEffect } from "react";
import SideMenu from "../components/Buying/SideMenu";
import { useState } from "react";
import { AccessTimeFilledOutlined, Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserListingCard from "../components/UserListingCard";
import { approveListing, rejectListing } from "../redux/actions/admin";

const UserListingScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const offerTabs = ["All Active", "Local", "Shipment", "Pending Offers"];
    const [fontSize, setFontSize] = useState("text-4xl");
    const [activeTab, setActiveTab] = useState("All Active");
    const { userId } = useParams();
    const { users } = useSelector((state) => state.admin);
    const user = users.find(user => user.id === userId);
    const { products } = useSelector((state) => state.product);

    const listings = products?.filter(item => item.seller.id === user.id);
    console.log(listings);

    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 0) {
            setFontSize("text-xl");
        } else {
            setFontSize("text-4xl");
        }
    };
    const [phonesidenav, showphonesidenav] = useState(true);
    const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
    const [showsort, setshowsort] = useState(false);
    const [sorttype, setsorttype] = useState(0);

    return (
        <div>
            {/* desktop */}
            <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
                <div className="self-stretch">
                    <SideMenu />
                </div>
                <div className="flex-1 min-h-screen overflow-hidden">
                    <div
                        className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                            }`}
                    >
                        <h1
                            className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
                        >
                            Listings, User : {user.id}
                        </h1>
                    </div>
                    {listings.length > 0 ? <div
                        onScroll={handleScroll}
                        className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 overflow-y-auto overflow-x-hidden grid grid-cols-3 gap-10"
                    >
                        {listings && listings.map((item, index) => (
                            <div className="mb-4" key={index}>
                                <UserListingCard product={item} />
                                <div className="items-center justify-between mt-3">
                                    {item.status.state === 'waiting_for_approval' ? (
                                        <>
                                            <div className="flex items-center justify-between mt-3">
                                                <button
                                                    className={`w-full text-base py-2 rounded-l-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-white hover:bg-green-500`}
                                                    onClick={() => {
                                                        dispatch(approveListing(item.id, {
                                                            state: "active",
                                                            product: item.title,
                                                            userEmail: user.email,
                                                            userId: user.id
                                                        }));
                                                    }}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className={`w-full text-base py-2 rounded-r-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-white
                                                    hover:bg-red-600`}
                                                    onClick={() => { 
                                                        dispatch(rejectListing(item.id, {
                                                        state: "rejected",
                                                        product: item.title,
                                                        userEmail: user.email,
                                                        userId: user.id
                                                    })); }}
                                                >
                                                    Reject
                                                </button>
                                            </div>
                                            <div className="mt-2">
                                                <button
                                                    className={`w-full text-base py-2 rounded-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-white
                                                    hover:bg-black hover:text-white`}
                                                    onClick={() => {navigate(`/user/admin/listings/edit/${item.id}`)}}
                                                >
                                                    Suggest Changes
                                                </button>
                                            </div>
                                        </>
                                    ) : item.status.state === 'rejected' ? (
                                        <button
                                            className={`w-full text-base py-2 rounded-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-red-600`}
                                            disabled
                                        >
                                            Rejected
                                        </button>
                                    ) : (
                                        <button
                                            className={`w-full text-base py-2 rounded-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-green-500`}
                                            disabled
                                        >
                                            Active
                                        </button>
                                    )}
                                </div>

                            </div>
                        ))}
                    </div> :
                        <div className="bg-[#FCF9F4] mb-28">
                            <div
                                className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
                                    } overflow-hidden`}
                            >
                                <SideMenu />
                            </div>
                            <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
                                <div className="text-[32px] mediumFont p-40  text-center item-center">
                                    User Does not have any listings
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            {/* mobile */}
            <div className=" bg-[#FCF9F4] min-h-screen pb-28 2md:hidden">
                <div
                    className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
                        } overflow-hidden`}
                >
                    <SideMenu />
                </div>
                <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
                    <h1 className="text-[26px] mediumFont text-black tracking-wide">
                        Listings, User : {user.id}
                    </h1>
                </div>
                {listings.length > 0 ?
                    <div className="pt-7 px-4 grid grid-flow-row align-center justify-center transition-all  duration-500 ease">
                        {listings && listings.map((item, index) => (
                            <div className="mb-4" key={index}>
                                <UserListingCard product={item} />
                                <div className="items-center justify-between mt-3">
                                    {item.status.state === 'waiting_for_approval' ? (
                                        <>
                                            <div className="flex items-center justify-between mt-3">
                                                <button
                                                    className={`w-full text-base py-2 rounded-l-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-white hover:bg-green-500`}
                                                    onClick={() => {
                                                        dispatch(approveListing(item.id, {
                                                            state: "active",
                                                            product: item.title,
                                                            userEmail: user.email,
                                                            userId: user.id
                                                        }));
                                                    }}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className={`w-full text-base py-2 rounded-r-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-white
                                                    hover:bg-red-600`}
                                                    onClick={() => { }}
                                                >
                                                    Reject
                                                </button>
                                            </div>
                                            <div className="mt-2">
                                                <button
                                                    className={`w-full text-base py-2 rounded-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-white
                                                    hover:bg-black hover:text-white`}
                                                    onClick={() => { }}
                                                >
                                                    Suggest Changes
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <button
                                            className={`w-full text-base py-2 rounded-[4px] mediumFont flex items-center justify-center gap-x-2  border-[1px] border-sa-border-black text-black bg-green-500`}
                                            disabled
                                        >
                                            Active
                                        </button>
                                    )}
                                </div>

                            </div>
                        ))}
                    </div> : <div className="bg-[#FCF9F4] mb-28">
                        <div
                            className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
                                } overflow-hidden`}
                        >
                            <SideMenu />
                        </div>
                        <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
                            <div className="text-[32px] mediumFont p-40  text-center item-center">
                                User Does not have any listings
                            </div>
                        </div>
                    </div>}
                <div className="fixed  z-50 bottom-0 w-full grid grid-cols-[100%]">
                    <div
                        className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
                            }`}
                    >
                        <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
                        <div
                            onClick={() => {
                                setshowsort(!showsort);
                            }}
                            className=" absolute top-4 right-3"
                        >
                            <Cancel className="text-base " />
                        </div>
                        {sortarray.map((type, i) => (
                            <button
                                className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                                    }`}
                                onClick={() => {
                                    setsorttype(i);
                                }}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                    <div className="grid grid-cols-[50%_50%]">
                        <button
                            onClick={() => {
                                showphonesidenav(!phonesidenav);
                            }}
                            className={` w-full text-base py-2 ${phonesidenav
                                ? "bg-black text-sa-primary-yellow"
                                : "bg-sa-primary-yellow text-black"
                                } border-[1px] border-sa-border-black mediumFont`}
                        >
                            Offers
                        </button>
                        <button
                            onClick={() => {
                                setshowsort(!showsort);
                            }}
                            className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
                        >
                            Sort: {sortarray[sorttype]}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserListingScreen;

import {
  AccessTimeOutlined,
  Favorite,
  FavoriteBorder,
  LocationOnOutlined,
  Password,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { ACCEPT_OFFER_MODAL, COUNTER_OFFER_MODAL, COUNTER_OFFER_RECEIVED_MODAL, OFFER_DECLINED_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { useState } from "react";
import axios from 'axios';

const ProductCard = ({ product, isBuying, data, remove, status, offerPrice, offer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fixcardHover, setcardHover] = useState(true);
  const [toggleliked, settoggleliked] = useState(true);
  const [response, setResponse] = useState(null);

  // condition
  let condition;
  if (product?.condition) {
    switch (product?.condition) {
      case "Used - Good":
        condition = "Used";
        break;
      case "Used - Fair":
        condition = "Used";
        break;
      case "Refurbished":
        condition = "Refurb";
        break;
      default:
        condition = product?.condition
        break;
    }
  }

  // Address
  let formattedAddress = product?.loc?.formattedAddress;
  if (formattedAddress) {
    const parts = formattedAddress.split(',');
    if (parts.length >= 3) {
      const lastIndex = parts.length - 1;
      const secondToLastIndex = lastIndex - 1;
      const result = `${parts[secondToLastIndex].trim()}, ${parts[lastIndex].trim()}`;

      formattedAddress = result; // This will contain the desired result
    }
  }

  // expiry date
  let expiry_date = product?.listing_duration;
  if (expiry_date) {
    const listingDurationDate = new Date(expiry_date);
    const currentDate = new Date();
    const timeDifference = listingDurationDate - currentDate;

    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    // Update expiry_date with the number of days and hours left
    expiry_date = `${daysLeft} D ${hoursLeft} H`;
  }

  //thumbnail image
  let thumbnail = product?.thumbnail;

  if (!data)
    data = {
      id: 1,
      seller: "@AkshatTripathi",
      title: "Hyundai Venue",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      shipping: "200.00",
      deliverydate: "12 Dec 2022",
      price: "₹4,60,000",
      acceptprice: "₹4,00,000",
      img: require("../../assets/new design/car/car_img1.png"),
      location: " Ashok Vihar Delhi",
      year: "2019",
      time: "02 D 12 Hr",
      distance: "5000 KMS",
      ownership: "1st",
    };


  const toggleToWishlist = () => {
    // code to add to wishlist
    settoggleliked(!toggleliked);
  };


  // this is for Buyers-offers page only
  const handleClick = () => {
    switch (status) {
      case "Accepted":
        dispatch(
          showModal({
            modalType: ACCEPT_OFFER_MODAL,
            modalTitle: ``,
            modalSubTitle: "",
            modalProps: data,
          })
        );
        break;
      case "Declined":
        dispatch(
          showModal({
            modalType: OFFER_DECLINED_MODAL,
            modalTitle: ``,
            modalSubTitle: "",
            modalProps: data,
          })
        );
        break;
      case "Counter_Offer":
        dispatch(
          showModal({
            modalType: COUNTER_OFFER_RECEIVED_MODAL,
            modalTitle: ``,
            modalSubTitle: "",
            modalProps: offer,
          })
        );
        break;
      case "Closed":
        dispatch(
          showModal({
            modalType: ACCEPT_OFFER_MODAL,
            modalTitle: ``,
            modalSubTitle: "",
            modalProps: data,
          })
        );
        break;

      default:
        break;
    }
  }


  return (
    <div
      className={`border-[1px] bg-white border-black ${fixcardHover ? "cardHover" : "!bg-white cardSelect"
        } rounded-md relative mb-5 min-w-[250px]`}
    >
      {isBuying && (
        <div className="border-b-[1px] border-black flex items-center">
          <h1 className="text-2xl boldFont text-sa-dark-green text-left py-2 pl-3 border-r-[1px] border-black w-[50%]">
            &#8377; {offerPrice}
          </h1>
          <div
            onClick={() => handleClick()}

            className={
              status === "Open"
                ? "bg-black justify-center  w-[50%] text-center"
                : status === "Declined"
                  ? "bg-[#D1503B] justify-center  w-[50%] text-center"
                  : status === "Counter_Offer"
                    ? "bg-blue-300 justify-center  w-[50%] text-center"
                    : status === "Accepted"
                      ? "bg-sa-success-green justify-center  w-[50%] text-center"
                      : "bg-black justify-center  w-[50%] text-center"
            }
          >
            <h1 className="text-xl leading-8 underline underline-offset-4 decoration-1 boldFont text-black text-center py-2 pl-3">
              {status}
            </h1>
          </div>
        </div>
      )}
      <div
        className={`flex absolute ${isBuying ? "top-14" : "top-2"
          } -left-2 rounded-sm`}
      >
        {/* <TagRibbon
    textClasses="text-[14px] mediumFont text-black text-center"
    tagText="USED"
    bgColor="#FFDC25"
  /> */}
        <div className={`relative flex flex-col w-[78px] h-[35px]`}>
          <div className="mb-2">
            <img
              src={condition === 'New' ? require("../../assets/icons/main_new.svg").default : condition === 'Parts' ? require("../../assets/icons/main_parts.svg").default : require("../../assets/icons/main.svg").default}
              alt="Main"
              className={`w-full h-full relative z-10`}
            />
          </div>

          <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
            <img
              src={require("../../assets/icons/shadow.svg").default}
              alt="Shadow"
              className="w-full h-full absolute z-0"
            />
          </div>
        </div>
        <h3 className={`boldFont ml-[-65px] mt-[2px] z-30`}>{condition}</h3>
      </div>
      <div
        className="p-3"
        onClick={() => {
          if (!remove && fixcardHover) navigate(`/product/${product._id}`);
        }}
      >
        <div className="border border-solid border-black rounded-lg">
          <img
            src={thumbnail}
            alt="thumbnail"
            className="w-full aspect-square rounded-lg"
          />
        </div>
        <div
          onClick={() => {
            if (!remove && fixcardHover) navigate("/product");
          }}
        >
          <div className="flex items-center gap-x-1 mt-2">
            <LocationOnOutlined className="text-sa-icon-green" fontSize="10" />
            <h1 className="text-sm mediumFont text-sa-text-gray">
              {formattedAddress}
            </h1>
          </div>
          <h1 className="text-xl mediumFont text-black">
            {`${product?.title}`}
          </h1>
        </div>
      </div>
      <div
        onClick={() => {
          if (!remove && fixcardHover) navigate("/product");
        }}
        className="border-b-[1px] border-black border-t-[1px] flex items-center"
      >
        <div className="border-r-[1px] border-black w-[60%]">
          <h1 className="text-2xl boldFont text-sa-dark-green text-left py-2 pl-3">
            &#8377; {product?.cost}
          </h1>
        </div>
        <div className="flex items-center justify-center gap-x-1 w-[40%] text-center">
          <AccessTimeOutlined className="text-black" fontSize={"10"} />
          <h1 className="text-[15px] mediumFont text-black">{expiry_date}</h1>
        </div>
      </div>
      <div
        onClick={() => {
          if (!remove && fixcardHover) navigate("/product");
        }}
        className="flex items-center wishcover justify-between p-3"
      >
        <div className="flex items-center gap-5">
          <div>
            <h1 className="text-sm mediumFont text-sa-light-brown">
              Offers : {product?.make_offer ? "Open" : "Close"}
            </h1>

          </div>
          <div>
            <h1 className="text-sm mediumFont text-sa-light-brown">
              Shipping : {product?.enable_shipping ? "Available" : "Not Available"}
            </h1>
          </div>
        </div>
        <div
          onMouseEnter={() => setcardHover(false)}
          onMouseLeave={() => setcardHover(true)}
          onClick={() => {
            if (remove) remove(data);
            else toggleToWishlist();
          }}
          className="bg-white border-[1px] border-black buttonHover rounded-md p-2"
        >
          {toggleliked ? (
            <Favorite className="text-sa-brick-red" />
          ) : (
            <FavoriteBorder />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getProductOriginalImages } from "../../redux/actions/product";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Gallery = ({ currentIndex, onClose }) => {
  const dispatch = useDispatch();
  const [currentImageIndex, setCurrentImageIndex] = useState(currentIndex);
  const [showGallery, setShowGallery] = useState(true);
  const products = useSelector((state) => state.product.products);
  const { productId } = useParams();
  const product = products?.find((product) => product.id === productId);
  const Images = useSelector((state) => state.product.images)
  const [images, setImages] = useState(Images.compressed);

  useEffect(() => {
    if(Images.original){
      console.log("RUNNING");
      setImages(Images.original);
    }
  },[dispatch])

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClickImage = () => {
    // Do nothing when the image is clicked
  };
  const handleFullResImages = () => {
    if(Images.original){
      setImages(Images.original);
    }
    else{
      dispatch(getProductOriginalImages(product?.images))
    }
  };

  const handleCategoryClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    showGallery && (
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50">
        <div className="absolute top-10 left-10 z-50 flex flex-wrap gap-4">
          <button
            onClick={() => handleCategoryClick(-1)}
            className={`px-4 py-2 rounded cursor-pointer ${
              currentImageIndex === -1
                ? "bg-gray-300 text-gray-800"
                : "bg-gray-200 text-gray-700"
            } hover:bg-gray-300 hover:text-gray-800 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400`}
          >
            All Photos
          </button>

          <button
            onClick={() => handleCategoryClick(0)}
            className={`px-4 py-2 rounded cursor-pointer ${
              currentImageIndex === 0
                ? "bg-gray-300 text-gray-800"
                : "bg-gray-200 text-gray-700"
            } hover:bg-gray-300 hover:text-gray-800 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400`}
          >
            Exterior
          </button>
          <button
            onClick={() => handleCategoryClick(4)}
            className={`px-4 py-2 rounded cursor-pointer ${
              currentImageIndex === 4
                ? "bg-gray-300 text-gray-800"
                : "bg-gray-200 text-gray-700"
            } hover:bg-gray-300 hover:text-gray-800 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400`}
          >
            Interior
          </button>
          <button
            onClick={() => handleFullResImages()}
            className={`px-4 py-2 rounded cursor-pointer ${
              currentImageIndex === 4
                ? "bg-gray-300 text-gray-800"
                : "bg-gray-200 text-gray-700"
            } hover:bg-gray-300 hover:text-gray-800 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400`}
          >
            Full Res Images
          </button>
        </div>

        {/* all photos */}
        <div className="relative">
          {currentImageIndex !== -1 ? (
            <>
              <img
                src={images[currentImageIndex]}
                alt=""
                className="w-full h-screen object-contain rounded cursor-pointer"
                onClick={handleClickImage}
              />
              <button
                className="fixed top-1/2 left-2 transform -translate-y-1/2 bg-transparent text-white text-2xl rounded-full p-2 shadow-lg z-10 focus:outline-none hover:text-gray-300"
                onClick={handlePrevious}
              >
                <FaChevronLeft />
              </button>

              <button
                className="fixed top-1/2 right-2 transform -translate-y-1/2 bg-transparent text-white text-2xl rounded-full p-2 shadow-lg z-10 focus:outline-none hover:text-gray-300"
                onClick={handleNext}
              >
                <FaChevronRight />
              </button>
            </>
          ) : (
            <div className="flex flex-wrap justify-left items-center p-4 mt-16 z-10 max-h-screen overflow-auto">
              {images && images.map((image, index) => (
                <div key={index} className="m-4 cursor-pointer">
                  <img
                    src={image}
                    alt=""
                    className="w-48 h-48 object-cover rounded"
                    onClick={() => setCurrentImageIndex(index)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <button
          className="absolute top-2 right-2 bg-transparent text-white text-lg outline-none cursor-pointer hover:text-gray-200 transition-colors duration-300"
          onClick={onClose}
        >
          <FaTimes size={32} />
        </button>
      </div>
    )
  );
};

export default Gallery;

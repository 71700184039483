import React, { useEffect, useState, useRef } from "react";
import maincar from "../../assets/new design/car/car_img2.png";
import TagRibbon from "../TagRibbon";
import { useSelector } from "react-redux";

const GalleryClosed = ({ handleImageClick, preview,images }) => {
  const [gridElement, setGridElement] = useState(null);
  const [viewSize, setViewSize] = useState(null);
  const carouselRef = useRef(null);
  const Images = images.compressed;
  // const Images = [
  //   require("../../assets/new design/car/car_img1.png"),
  //   require("../../assets/new design/car/car_img3.png"),
  //   require("../../assets/new design/car/car_img4.png"),
  //   require("../../assets/new design/car/car_img5.png"),
  //   require("../../assets/new design/car/car_img6.png"),
  //   require("../../assets/new design/car/car_img7.png"),
  //   require("../../assets/new design/car/car_img8.png"),
  //   require("../../assets/new design/car/car_img10.jpeg"),
  // ];
  const selectedImages =Images && Images.filter((image, index) =>
    [0, 2, 4, 7].includes(index)
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1330) {
        setViewSize("large");
      } else if (window.innerWidth > 768) {
        setViewSize("medium");
      } else if (window.innerWidth <= 768) {
        setViewSize("small");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (viewSize === "small") {
      setGridElement(
        <div
          className=" grid grid-cols-6 grid-rows-2 h-full overflow-x-auto  rounded-t-[7px]"
          style={{ width: "900px" }}
        >
          {!preview && (
            <div className="flex absolute top-24 left-4 rounded-sm">
              {/* <TagRibbon
    textClasses="text-[14px] mediumFont text-black text-center"
    tagText="USED"
    bgColor="#FFDC25"
  /> */}
              <div className="relative flex flex-col w-[78px] h-[35px]">
                <div className="mb-2">
                  <img
                    src={require("../../assets/icons/main.svg").default}
                    alt="Main"
                    className="w-full h-full relative z-10 fill-#FFDC25"
                  />
                </div>

                <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
                  <img
                    src={require("../../assets/icons/shadow.svg").default}
                    alt="Shadow"
                    className="w-full h-full absolute z-0"
                  />
                </div>
              </div>

              <h3 className="boldFont ml-[-65px] mt-[2px] z-30">USED</h3>
            </div>
          )}

          <div className=" row-span-2 col-span-2 h-auto relative">
            <img
              src={Images[0]}
              class="absolute top-0 left-0 w-full h-full object-cover rounded-tl-[7px] cursor-pointer"
              alt="Background"
              onClick={() => handleImageClick(0)}
            />
          </div>
          {Images && Images.slice(1, 8).map((image, index) => (
            <div className="h-full  relative">
              <img
                src={image}
                className={`absolute top-0 left-0 w-full h-full object-cover ${
                  index >= 4 ? "pb-0" : "pb-1"
                } pl-1 cursor-pointer`}
                alt="back"
                onClick={() => handleImageClick(index + 1)}
              />
            </div>
          ))}
        </div>
      );
    } else if (viewSize === "medium") {
      setGridElement(
        <>
          <div className=" w-[80%] h-full relative">
            <img
              src={Images[0]}
              class="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
              alt="Background Image"
              onClick={() => handleImageClick(0)}
            />
          </div>
          <div className=" grid grid-cols-1 w-[20%] ">
            {Images && selectedImages.map((image, index) => (
              <div key={index} className=" relative h-full">
                <img
                  src={image}
                  className="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
                  onClick={() => handleImageClick(Images.indexOf(image) + 1)}
                />
              </div>
            ))}
          </div>
        </>
      );
    } else if (viewSize === "large") {
      setGridElement(
        <>
          <div className=" w-[70%] h-full relative">
            <img
              src={Images && Images[0]}
              class="absolute top-0 left-0 w-full h-full object-cover p-1 pl-0 cursor-pointer"
              alt="Background Image"
              onClick={() => handleImageClick(0)}
            />
          </div>
          <div className=" w-[30%] grid grid-cols-2">
            {Images && Images.slice(1, 8).map((image, index) => (
              <div key={index} className=" h-full relative">
                <img
                  src={image}
                  className="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
                  onClick={() => handleImageClick(index + 1)}
                />
              </div>
            ))}
          </div>
        </>
      );
    }

    // Scroll to the beginning of the carousel when resizing
    if (carouselRef.current && viewSize === "small") {
      carouselRef.current.scrollLeft = 0;
    }
  }, [viewSize]);

  return (
    <div className="small:h-[500px] medium:h-[600px]  h-[250px] rounded-t-lg">
      <div className="flex h-full">
        {viewSize === "small" && (
          <div
            className="carousel overflow-x-scroll no-scrollbar"
            ref={carouselRef}
          >
            {gridElement}
          </div>
        )}
        {viewSize !== "small" && gridElement}
      </div>
    </div>
  );
};

export default GalleryClosed;

import { getTokens } from "../../utils/auth/auth";
import { ACTIVE_LISTINGS, GET_CURRENT_USER, LISTING_OFFERS, LOGIN_USER, LOGOUT_USER, USER_CHATS, USER_NOTIFICATIONS, USER_OFFERS, USER_ORDERS } from "../actions/types";

const initialState = {
  accessToken: getTokens().accessToken,
  isLoggedIn: false,
  refreshToken: getTokens().refreshToken,
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        accessToken: action.payload.token,
        user : action.payload.data,
        isLoggedIn: true,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
        isLoggedIn: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        accessToken: null,
        user: null,
        isLoggedIn: false,
      };
    case ACTIVE_LISTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          listings: action.payload,
        },   
      };
    case USER_OFFERS:
      return {
        ...state,
        user: {
          ...state.user,
          offers: action.payload,
        },   
      };
    case USER_NOTIFICATIONS:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: action.payload,
        },   
      };
    case USER_ORDERS:
      return {
        ...state,
        user: {
          ...state.user,
          orders: action.payload,
        },   
      };
    case USER_CHATS:
      return {
        ...state,
        user: {
          ...state.user,
          chats: action.payload,
        },   
      };
    default:
      return state;
  }
};

export default authReducer;

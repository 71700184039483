import React from "react";
import smiley from "../../assets/icons/smiley.svg";

const OfferApproved = () => {
  return (
    <div>
      {/* desktop */}
      <div className="w-[529px] 2md:flex hidden h-[271px] relative bg-stone-50 rounded-lg border border border border border-black">
        <div className="left-[166px] top-[32px] absolute text-center text-green-700 text-[42px] mediumFont leading-10">
          Accepted!
        </div>
        <div className="w-[435px] left-[48px] top-[92px] absolute text-center text-black text-[16px] font-normal leading-tight">
          We’ll let @panwadi know. They’ll be required to pay within the next 48
          hours.
        </div>
        <div className="w-[100px] h-[100px] absolute left-1/2 top-[200px] transform -translate-x-1/2 -translate-y-1/2">
          <img src={smiley} alt="Smiley" />
        </div>
      </div>
      {/* mobile */}
      <div class="w-[340px] 2md:hidden h-[290px] relative bg-white rounded-md">
        <div class="w-[300px] h-10 left-[16px] top-[78px] absolute text-center text-black text-[18px] font-normal leading-tight">
          We’ll let @panwadi know. They’ll be required to pay within the next 48
          hours.
        </div>
        <div class="w-full flex justify-center top-[32px] absolute text-center text-green-700 text-[28px] boldFont leading-9">
          Accepted!
        </div>
        <div className="w-full justify-center flex h-[100px] absolute  top-[220px] transform -translate-y-1/2">
          <img src={smiley} alt="Smiley" />
        </div>
      </div>
    </div>
  );
};

export default OfferApproved;

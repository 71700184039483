//modal types
export const DELIVERY_LOCATION_MODAL = "DELIVERY_LOCATION_MODAL";
export const ADD_NEW_ADDRESS_MODAL = "ADD_NEW_ADDRESS_MODAL";
export const ADDRESS_MODAL = "ADDRESS_MODAL";
export const EDIT_ADDRESS_MODAL = "EDIT_ADDRESS_MODAL";
export const APPROVAL_MODAL = "APPROVAL_MODAL";
export const CONTACT_SELLER_MODAL = "CONTACT_SELLER_MODAL";
export const CONTACT_SELLER_SUBMIT_MESSAGE_MODAL =
  "CONTACT_SELLER_SUBMIT_MESSAGE_MODAL";
export const REVIEW_MODAL = "REVIEW_MODAL";
export const REVIEW_SUBMITTED_MODAL = "REVIEW_SUBMITTED_MODAL";
export const REPORT_MODAL = "REPORT_MODAL";
export const REPORT_SUBMITTED_MODAL = "REPORT_SUBMITTED_MODAL";
export const CUSTOMER_SERVICE_MODAL = "CUSTOMER_SERVICE_MODAL";
export const COUNTER_OFFER_RECEIVED_MODAL = "COUNTER_OFFER_RECEIVED_MODAL";
export const ACCEPT_OFFER_MODAL = "ACCEPT_OFFER_MODAL";
export const APPROVE_OFFER_MODAL = "APPROVE_OFFER_MODAL";
export const COUNTER_OFFER_MODAL = "COUNTER_OFFER_MODAL";
export const DECLINE_OFFER_MODAL = "DECLINE_OFFER_MODAL";
export const SEND_MESSAGE_MODAL = "SEND_MESSAGE_MODAL";
export const PAYMENT_MODAL = "PAYMENT_MODAL";
export const CREATE_OFFER_MODAL = "CREATE_OFFER_MODAL";
export const OFFER_CREATED_MODAL = "OFFER_CREATED_MODAL";
export const OFFER_DECLINED_MODAL = "OFFER_DECLINED_MODAL";
export const SELECT_DEPOSIT_FOR_OFFER = "SELECT_DEPOSIT_FOR_OFFER";

import React from "react";
import smiley from "../../assets/icons/smiley.svg";

const OfferCreatedModal = ({ offerAmount, repeatedOffer }) => {
    return (
        <div className="flex items-center justify-center h-screen">
            {/* desktop */}
            <div class="w-[529px] 2md:flex hidden h-[271px] relative bg-stone-50 rounded-lg border border border border border-black">
                {!repeatedOffer ? <div class="left-[50%] top-[50%] absolute transform translate-x-[-50%] translate-y-[-50%] text-center">
                    <div class="text-sky-600 text-[42px] font-medium leading-10">
                        Offer Sent
                    </div>
                    <div class="text-black text-[16px] font-normal mt-4">
                        Offer Amount: ₹ {offerAmount}
                    </div>
                    <div class="w-[435px] text-black text-[16px] font-normal leading-tight mt-4">
                        70% sellers accept an offer from a buyer!
                    </div>
                    <div class="w-[100px] h-[100px] mt-4 mx-auto">
                        <img src={smiley} alt="Smiley" />
                    </div>
                </div> : 
                <div class="left-[50%] top-[50%] absolute transform translate-x-[-50%] translate-y-[-50%] text-center">
                    <div class="text-red-800 text-[42px] font-medium leading-10">
                        Offer Already Exist
                    </div>
                    <div class="w-[435px] text-black text-[16px] font-normal leading-tight mt-4">
                        You have already created a offer on this product, wait for seller's action.
                    </div>
                </div>}
            </div>



            {/* mobile */}
            <div class="w-[340px] 2md:hidden h-[290px] relative bg-white rounded-md">
                <div class="w-[300px] h-10 left-[16px] top-[78px] absolute text-center text-black text-[18px] font-normal leading-tight">
                    70% sellers accept a offer from a buyer!
                </div>
                <div class="w-full flex justify-center top-[32px] absolute text-center text-[#0167DE] text-[28px] boldFont leading-9">
                    Offer Sent
                </div>
                <div className="w-full justify-center flex h-[100px] absolute  top-[220px] transform -translate-y-1/2">
                    <img src={smiley} alt="Smiley" />
                </div>
            </div>
        </div>
    );
};

export default OfferCreatedModal;

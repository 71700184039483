import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";

const SignInSuccessScreen = () => {
  const [message, setMessage] = useState("Please Wait!!");
  const [countdown, setCountdown] = useState(5); // Initial countdown value in seconds
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get("/auth/getMe")
      .then((data) => {
        setMessage(`${data.data.data.first_name} you’ve logged in!`);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
      });

    const timer = setTimeout(() => {
      navigate("/");
    }, countdown * 1000);

    
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [countdown, navigate]);

  return (
    <div className="min-h-[72.5vh] w-full bg-[#FFF9F0] flex items-center justify-center py-10">
      <div className="border md:border-2 border-black w-[80%] max-w-[450px] min-h-[73%] rounded-md py-12 px-4 md:px-7 bg-white">
        <h1 className="text-3xl mediumFont text-black leading-10">
          {message.includes("logged") && "Welcome"}
          <br />
          {message}
        </h1>
        <p className="text-lg text-gray-600 mt-4">
          Redirecting to homepage in {countdown} seconds...
        </p>
      </div>
    </div>
  );
};

export default SignInSuccessScreen;

import { CLEAR_DETAILS, SAVE_DETAILS, USER_DRAFTS, DELETE_DRAFT, ALL_PRODUCTS, LISTING_OFFERS, CLEAR_IMAGES, PRODUCT_COMPRESSED_IMAGES, PRODUCT_ORIGINAL_IMAGES } from "./types";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";
import { getUserCart } from "./cart";
import { getAllOffersBuyer, getCurrentUser } from "./auth";

export const saveProductDetails = (data) => {
  return function (dispatch) {
    dispatch({
      type: SAVE_DETAILS,
      payload: data,
    });
  };
};

export const clearProductDetails = () => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_DETAILS,
    });
  };
};

export const listProduct = (product) => {
  return function (dispatch) {
    axiosInstance
      .post(`product/add`, product)
      .then((res) => {
        const { data } = res.data;
        toast.success("Item Listing Successfull! Waiting for Admin Review");
        dispatch(getAllProducts());
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");
              break;
            case 403:
              //   add a toast
              toast.error("First you have to register yourself as a seller!");
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
};

export const deleteProduct = (productId) => {
  return function (dispatch) {
    axiosInstance
      .post(`product/delete/${productId}`)
      .then((res) => {
        const { data } = res.data;
        toast.success("Listing Deleted!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

export const getAllProducts = () => {
  return function (dispatch) {
    axiosInstance
      .get(`product/all`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: ALL_PRODUCTS,
          payload: data
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const getProductCompressedImages = (id) => {
  return function (dispatch) {
    axiosInstance
      .get(`product/compressedImages/${id}`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: PRODUCT_COMPRESSED_IMAGES,
          payload: data
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("There was a error loading Images.");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const getProductOriginalImages = (id) => {
  return function (dispatch) {
    axiosInstance
      .get(`product/originalImages/${id}`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: PRODUCT_ORIGINAL_IMAGES,
          payload: data
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const clearProductImages = () => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_IMAGES
    })
  };
}

export const uploadProductImage = (formData) => {
  return function (dispatch) {
    axiosInstance
      .post(`product/image/64e90f133604511851fd4318`, formData)
      .then((res) => {
        const { data } = res.data;
        toast.success("Image Uploaded Successfully");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

export const productViewCount = (productId, clientId) => {
  return function (dispatch) {
    axiosInstance
      .post(`product/productViewCount/${productId}`, null, {
        headers: { 'X-Client-Id': clientId }
      })
      .then((res) => {
        const { data } = res.data;
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 422:
              //   add a toast
              toast.error("Invalid Credentials!");

              break;
            case 401:
              //   add a toast
              // toast.error("Invalid Credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong in View Count");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  }
}
export const productToRecentlyViewed = (productId) => {
  return function (dispatch) {
    axiosInstance
      .post(`product/productToRecentlyViewed/${productId}`)
      .then((res) => {
        const { data } = res.data;
        dispatch(getCurrentUser());
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 422:
              //   add a toast
              break;
            case 401:
              //   add a toast
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  }
}

// draft
export const saveDraft = (product) => {
  return function (dispatch) {
    axiosInstance
      .post(`product/saveDraft`, product)
      .then((res) => {
        const { data } = res.data;
        dispatch(getAllUserDrafts());
        dispatch({
          type: CLEAR_DETAILS
        })
        toast.success("Your Listing Has been saved as a Draft.");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
              dispatch({
                type: CLEAR_DETAILS
              })

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");
              dispatch({
                type: CLEAR_DETAILS
              })

              break;
            case 422:
              //   add a toast
              toast.error("You Must Register as a seller first.");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
              dispatch({
                type: CLEAR_DETAILS
              })

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// in this product should contain existing draft Id
export const updateDraft = (product) => {
  return function () {
    axiosInstance
      .post(`product/saveDraft`, product)
      .then((res) => {
        const { data } = res.data;
        toast.success("Your Listing Has been saved as a Draft.");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            case 422:
              //   add a toast
              toast.error("You Must Register as a seller first.");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

export const deleteDraft = (draftId) => {
  return function (dispatch) {
    axiosInstance
      .delete(`product/deleteDraft/${draftId}`)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        dispatch({
          type: DELETE_DRAFT,
          payload: data
        })
        toast.success("Draft Deleted Successfully!");


      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            case 422:
              //   add a toast
              toast.error("You Must Register as a seller first.");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// userDrafts
export const getAllUserDrafts = () => {
  return function (dispatch) {
    axiosInstance
      .get(`product/drafts/all`)
      .then((res) => {
        const { data } = res.data;

        dispatch({
          type: USER_DRAFTS,
          payload: data
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 422:
              //   add a toast
              toast.error("You Must Register as a seller first.");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// offers
export const getProductOffers = (productId) => {
  return function (dispatch) {
    axiosInstance
      .get(`makeOffer/getAllOffers/${productId}`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: LISTING_OFFERS,
          payload: data,
          id: productId
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            case 404:
              //   add a toast
              toast.info("Currently No Offers on Product!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

export const createOffer = (data, setRepeatedOffer) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/createOffer`, data)
      .then((res) => {
        const { data } = res.data;
        toast.success("Offer sent to Seller!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            case 409:
              //   add a toast
              toast.error("You have already sent an Offer!");
              setRepeatedOffer(true);
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}


//offers
// for buyer
export const acceptOfferbyBuyer = (offerId, productId) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/acceptOfferbyBuyer/${offerId}`, { productId })
      .then((res) => {
        dispatch(getAllOffersBuyer());
        dispatch(getUserCart());
        toast.success("Offer Accepted!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const declineOfferbyBuyer = (offerId, productId) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/declineOfferbyBuyer/${offerId}`)
      .then((res) => {
        dispatch(getAllOffersBuyer());
        toast.success("Offer Declined!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const counterOfferbyBuyer = (offerId, productId, amount) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/counterOfferbyBuyer/${offerId}`, { counterOfferAmount: amount })
      .then((res) => {
        dispatch(getAllOffersBuyer());
        toast.success("Counter-Offer Sent!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}


// for seller 
export const acceptOfferbySeller = (offerId, productId) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/acceptOfferbySeller/${offerId}`, { productId })
      .then((res) => {
        dispatch(getProductOffers(productId));
        toast.success("Offer Accepted!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const declineOfferbySeller = (offerId, productId) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/declineOfferbySeller/${offerId}`)
      .then((res) => {
        dispatch(getProductOffers(productId));
        toast.success("Offer Declined!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
export const counterOfferbySeller = (offerId, productId, amount) => {
  return function (dispatch) {
    axiosInstance
      .post(`makeOffer/counterOfferbySeller/${offerId}`, amount)
      .then((res) => {
        dispatch(getProductOffers(productId));
        toast.success("Counter-Offer Sent!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// checkout 
export const phonepayCheckOut = (request) => {
  return function (dispatch) {

  };
}

// review
export const buyerReview = (userId,request) => {
  return function (dispatch) {
    axiosInstance
      .post(`review/${userId}`,request)
      .then((res) => {
        toast.success("Thank You for your Time!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

export const getReviews = (userId) => {
  return function (dispatch) {
    
  };
}


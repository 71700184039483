import { Cancel, Star } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_SELLER_SUBMIT_MESSAGE_MODAL } from "../../extras/constants";
import { hideModal, showModal } from "../../redux/actions/modal";
import TagRibbon from "../TagRibbon";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import { useState } from "react";
import axiosInstance from "../../axiosInstance";

const ContactSellerModal = ({props}) => {
  const dispatch = useDispatch();
  const product = props;  
  const secretKey = 'SECRET_KEY';
  const [message, setMessage] = useState("");

  // seller-since
  const seller_since = new Date(product?.seller?.createdAt);
  const seller_year = seller_since.getFullYear();
  const seller_month = seller_since.toLocaleString('default', { month: 'long' });

  //thumbnail image
  let thumbnail = product?.thumbnail;


  const encryptMessage = (message) => {
    const ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
    return ciphertext;
  };

  const decryptMessage = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalMessage = bytes.toString(CryptoJS.enc.Utf8);
    return originalMessage;
  };

  const sendNewMessage = () => {
    if (message.trim() !== "") {
      const encryptedMessage = encryptMessage(message);
      setMessage("");
      axiosInstance
        .post(`/messaging/sendMessage`, {
          content: encryptedMessage,
          userId : product?.seller?.id,
          productId : product?.id
        })
        .then((response) => {
          toast.success("Message Sent!");
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");
                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");
                break;
              default:
                // server error
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    }
  };

  return (
    <div>
      {/* Desktop */}
      <div className="hidden xl:flex">
        <div className="px-4">
          <div className="border-[1px] border-black rounded relative ">
            <div
              onClick={() => dispatch(hideModal())}
              className=" absolute -top-4 -right-3"
            >
              <Cancel className="text-base text-red-400" />
            </div>
            <div className="flex items-center justify-between bg-[#0076BE] py-4 px-6 rounded-t border-b-[1px] border-black">
              <div className="flex items-center gap-x-1.5">
                <div className="w-30">
                  <img
                    src={require("../../assets/new design/user.png")}
                    alt=""
                    className=" w-full"
                  />
                </div>
                <div>
                  <h1 className=" text-xl underline mediumfont text-white">
                    {product?.seller?.first_name}
                  </h1>
                  <h1 className="text-base font-normal text-white">
                    Member since {seller_month} {seller_year}
                  </h1>
                </div>
              </div>
              <div className=" flex items-center gap-x-0.5">
                <h1 className=" text-2xl boldfont text-white">4.5</h1>
                <Star className=" text-yellow-100 text-xs" />
              </div>
            </div>
            <div className="bg-white px-7 py-5">
              <div className="flex relative items-start">
                <TagRibbon
                  textClasses={"text-[12px] mediumfont text-black text-center"}
                  tagText={`${product?.condition}`}
                  bgColor={"#FFDC25"}
                  customClassesBG={"w-[80px] h-[30px]"}
                  customClasses={"w-[74px] h-[26px]"}
                />
                <div
                  className="w-[20%] border-[1px] border-black rounded"
                  style={{ marginLeft: "-70px" }}
                >
                  <img
                    src={thumbnail}
                    alt="thumbnail"
                    className="w-full aspect-square"
                  />
                </div>
                <div className="ml-3 flex-1 pr-3 flex justify-between flex-col self-stretch">
                  <div className="flex items-start justify-between">
                    <div>
                      <h1 className="text-xl mediumfont text-black underline underline-offset-4">
                        {product?.title}
                      </h1>
                      <h1
                        className="boldfont text-black text-[22px]"
                        style={{ fontWeight: "900" }}
                      >
                        ₹{product?.cost}
                      </h1>
                    </div>
                    <button
                      className="bg-sa-success-green text-xs px-4 py-1 rounded text-white border-[1px] border-black"
                      style={{
                        width: "75px",
                        height: "50px",
                        fontSize: "17.5px",
                      }}
                    >
                      {product?.status?.state}
                    </button>
                  </div>
                  <div className=" w-full flex justify-end mt-3">
                    <div className="flex items-end justify-end flex-col w-1/2 py-1 px-2">
                      <h1 className="text-[13px] mediumfont text-[#667085]">
                        Purchase date
                      </h1>
                      <h1 className="text-[20px] boldfont text-black">
                        07/03/2023
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <textarea
                  placeholder="Ask your question here"
                  type="text"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className="w-full rounded border-[1px] border-black mt-1 px-3 xl:mt-1.5 py-1.5 h-36 mediumfont"
                  style={{ color: "#344054" }}
                />
              </div>
              <div>
                <p
                  className="text-[13px] leading-4 mt-2 mediumfont mediumfont "
                  style={{ color: "#344054" }}
                >
                  To keep everyone safe on Stake Atlas, we prohibit sharing
                  contact information on messages. Do not share your email,
                  phone number or address as multiple attempts might lead to
                  account suspension. Keep all your conversations on Stake Atlas
                  to avoid scams.
                </p>
                <button
                  onClick={() => {
                    sendNewMessage();
                    dispatch(
                      showModal({
                        modalType: CONTACT_SELLER_SUBMIT_MESSAGE_MODAL,
                        modalTitle: ``,
                        modalSubTitle: "",
                      })
                    );
                  }}
                  className=" text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumfont mt-5"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Mobile */}
      <div className="w-screen xl:hidden">
        <div className="px-4">
          <div className="border-[1px] border-black rounded relative ">
            <div
              onClick={() => dispatch(hideModal())}
              className=" absolute -top-4 -right-3"
            >
              <Cancel className="text-base text-red-400" />
            </div>
            <div className="flex items-center justify-between bg-[#0076BE] py-2 px-3 rounded-t border-b-[1px] border-black">
              <div className="flex items-center gap-x-1.5">
                <div className="w-10">
                  <img
                    src={require("../../assets/new design/user.png")}
                    alt=""
                    className=" w-full"
                  />
                </div>
                <div>
                  <h1 className=" text-sm underline mediumfont text-white">
                    {product?.seller?.first_name}
                  </h1>
                  <h1 className="text-xs font-normal text-white">
                    Member since {seller_month} {seller_year}
                  </h1>
                </div>
              </div>
              <div className=" flex items-center gap-x-0.5">
                <h1 className=" text-2xl boldfont text-white">4.5</h1>
                <Star className=" text-yellow-100 text-xs" />
              </div>
            </div>
            <div className="bg-white px-3 py-5">
              <div className="flex relative">
                {/* <TagRibbon
                textClasses={"text-[14px] mediumfont text-black text-center"}
                tagText="USED"
                bgColor={"#FFDC25"}
              /> */}
                <div className="w-[27%] border-[1px] border-black rounded">
                  <img
                    src={thumbnail}
                    alt="thumbnail"
                    className="w-full aspect-square"
                  />
                </div>
                <div className="ml-3 flex-1 pr-3">
                  <h1 className="text-sm mediumfont text-black">
                    {product?.title}
                  </h1>
                  <div className="flex items-start justify-between">
                    <h1 className="mediumfont text-black text-base">
                      ₹{product?.cost}
                    </h1>
                    <button className="bg-sa-success-green text-xs px-4 py-1 rounded text-white mb-2.5">
                      {product?.status?.state}
                    </button>
                  </div>
                </div>
              </div>
              <div className=" w-full border-y-[1px] border-black flex items-center mt-3">
                <div className="flex justify-end flex-col border-r-[1px] border-black w-1/2 py-1 px-2">
                  {/* <h1
                    className="text-[9px] boldfont text-[#667085]"
                    style={{ color: "#667085" }}
                  >
                    Shipping
                  </h1>
                  <h1 className="text-[11px] boldfont text-black">+200.00</h1> */}
                </div>
                <div className="flex items-end justify-end flex-col w-1/2 py-1 px-2">
                  <h1
                    className="text-[9px] mediumfont text-[#667085]"
                    style={{ color: "#667085" }}
                  >
                    Purchase date
                  </h1>
                  <h1 className="text-[11px] boldfont text-black">
                    07/03/2023
                  </h1>
                </div>
              </div>
              <div className="mt-2">
                <input
                  placeholder="Ask your question here"
                  type="text"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className="w-full rounded border-[1px] border-black mt-1 px-3 xl:mt-1.5 py-1.5"
                />
              </div>
              <div>
                <p
                  className="text-[9px] leading-3 mt-2 mediumfont "
                  style={{ color: "#344054" }}
                >
                  To keep everyone safe on Stake Atlas, we prohibit sharing
                  contact information on messages. Do not share your email,
                  phone number or address as multiple attempts might lead to
                  account suspension. Keep all your conversations on Stake Atlas
                  to avoid scams.
                </p>
                <button
                  onClick={() => {
                    sendNewMessage();
                    dispatch(
                      showModal({
                        modalType: CONTACT_SELLER_SUBMIT_MESSAGE_MODAL,
                        modalTitle: ``,
                        modalSubTitle: "",
                      })
                    );
                  }}
                  className=" text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumfont mt-5"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSellerModal;

import {
  AccessTimeOutlined,
  LocationOnOutlined,
  Star,
} from "@mui/icons-material";
import SignInPanel from "../SignInpanel";
import ImageGalleryTest2 from "../ImageGalleryTest2";
import { useSelector } from "react-redux";
import ImageGalleryTest from "../ImageGalleryTest";

const PreviewCardMob = ({ product }) => {
  const { user } = useSelector((state) => state.auth)
  const carImg = product?.images;

  // seller-since
  const seller_since = new Date(user?.createdAt);
  const seller_year = seller_since.getFullYear();
  const seller_month = seller_since.toLocaleString('default', { month: 'long' });

  return (
    <div className="border-[1px] border-black mb-4">
      <div className="px-3 mt-2.5">
        <div className="h-[400px] w-full flex">
          {" "}
          <div className=" w-[60%] h-full relative">
            <img
              src={carImg[0]}
              class="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
              alt="Background Image"
            />
          </div>
          <div className=" w-[40%] grid grid-cols-2">
            {carImg.slice(1, 8).map((image, index) => (
              <div key={index} className=" h-full relative">
                <img
                  src={image}
                  className="absolute top-0 left-0 w-full h-full object-cover p-1 cursor-pointer"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="py-2.5">
          <h1 className=" text-[32px] mediumFont">
            {product?.title}
          </h1>
          <div className="flex justify-between">
            <h1 className="text-[28px] boldFont text-sa-menu-green text-left py-1">
              ₹ {product?.cost}
            </h1>
            {/* <div className="flex items-center justify-start gap-x-1 mt-1">
              <AccessTimeOutlined className="text-grey-100" fontSize={"10"} />
              <h1 className="text-[16px] mediumFont text-grey-100">3d 18hrs</h1>
            </div> */}
          </div>
        </div>

        <SignInPanel product={product} />
      </div>

      <div className=" border-t-[1px] border-black mt-2.5 px-3 py-2.5">
        <h1 className=" text-[18px] mediumFont">Sold By</h1>

        <div className="mt-1 flex items-center justify-between">
          <div className="flex items-center gap-x-1.5">
            <div className="w-14">
              <img
                src={require("../../assets/new design/user.png")}
                alt=""
                className=" w-full"
              />
            </div>
            <div>
              <h1 className=" text-[16px] mediumFont">{user.first_name}</h1>
              <h1 className="text-[14px] font-normal text-grey-100">
                Member since {seller_month} {seller_year}
              </h1>
            </div>
          </div>
          <div className=" flex items-center gap-x-0.5">
            <h1 className=" text-2xl boldFont">4.5</h1>
            <Star className=" text-yellow-100 text-xs" />
          </div>
        </div>

        <div className=" flex items-center gap-10 mt-2">
          <button className=" w-1/2  text-[16px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1.5px] border-sa-border-black mediumFont">
            Save Seller
          </button>
          <button className=" w-1/2  text-[16px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1.5px] border-sa-border-black mediumFont">
            Message
          </button>
        </div>
      </div>

      <div className=" bg-[#EFEFEF] flex justify-center border-t py-6 border-black">
        <h1 className="text-[28px]"> Highlights </h1>
      </div>

      <div className=" px-3 border-t-[1px] border-black py-2">
        <h1 className=" text-[16px] xl:text-[22px] mediumFont ">Description </h1>

        <p className=" text-[16px] font-normal pb-3 mt-4">
          {product.description}
        </p>
      </div>

      <div className=" px-3 border-t-[1px] border-black mt-1">
        <h1 className=" text-[16px] xl:text-[22px] mediumFont mt-3">
          Item Specifics
        </h1>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            className=""
            style={{ width: "50%", background: "#FCF9F4" }}
          >
            {Object.entries(product.specification).slice(0, 4).map(([key, value], index) => (
              <div
                className={`flex flex-row justify-between px-2 py-1 border-b-[1px] border-l-[1px] border-r-[1px] border-black ${index === 0 ? 'border-t-[1px]' : ''}`}
              >
                <h1 className="text-sm 3md:text-sm 4md:text-sm">
                  {key} :
                </h1>
                <h1 className="boldFont text-sm 3md:text-sm 4md:text-sm">
                  {value}
                </h1>
              </div>
            ))}
          </div>
          <div
            className=""
            style={{ width: "50%", background: "#FCF9F4" }}
          >
            {Object.entries(product.specification).slice(4).map(([key, value], index) => (
              <div
                className={`flex flex-row justify-between px-2 py-1 border-b-[1px] border-r-[1px] border-black ${index === 0 ? 'border-t-[1px]' : ''}`}
                key={index}
              >
                <h1 className="text-sm 3md:text-sm 4md:text-sm">
                  {key} :
                </h1>
                <h1 className="boldFont boldFont text-sm 3md:text-sm 4md:text-sm">
                  {value}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCardMob;

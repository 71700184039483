import {
  AccessTimeOutlined,
  ExpandMore,
  LocationOnOutlined,
  MailOutline,
  Star,
  StarOutline,
  ThumbUp,
  TurnedInNot,
} from "@mui/icons-material";

import { MdTurnedIn, MdTurnedInNot } from "react-icons/md";
import SouthIcon from "@mui/icons-material/South";
import SignInPanel from "../components/SignInpanel";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import NorthRoundedIcon from "@mui/icons-material/NorthRounded";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import Accourdion from "../components/Accourdion";
import ProductCard from "../components/Home/ProductCard";
import ProductCardMob from "../components/Home/ProductCardMob";
import ImageGallery from "../components/ImageGallery";
import TabMenu from "../components/TabMenu";
import { useEffect, useState } from "react";
import { showModal } from "../redux/actions/modal";
import { SEND_MESSAGE_MODAL } from "../extras/constants";
import { connect, useDispatch, useSelector } from "react-redux";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ImageGalleryTest from "../components/ImageGalleryTest";
import ProductCard2 from "../components/Home/ProductCard2";
import ProductCardMob2 from "../components/Home/ProductCardMob2";
import ProductCard3 from "../components/Home/ProductCard3";
import axios from 'axios';
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { clearProductImages, getAllProducts, getProductCompressedImages, productToRecentlyViewed, productViewCount } from "../redux/actions/product";
import { addProductToCart, removeProductFromCart } from "../redux/actions/cart";
import { handleShipmentCost } from "../utils/ShipmentCost";
import { v4 as uuidv4 } from 'uuid';
import { getUserChats } from "../redux/actions/auth";


const ProductScreen = ({ showModal }) => {
  const navigate = useNavigate();
  const map = require("../assets/new design/map.png");
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const { user } = useSelector((state) => state.auth);
  const { chats } = useSelector((state) => (state.auth.user ?? { chats: [] }));
  // product ID
  const { productId } = useParams();
  // selecting the specific product
  // this will give error on reloading
  const product = products?.find((product) => product.id === productId);
  let clientId = localStorage.getItem('clientId');
  if (!clientId) {
    clientId = uuidv4();
    localStorage.setItem('clientId', clientId);
  }

  useEffect(() => {
    if (product) {
      dispatch(productViewCount(productId, clientId));
      dispatch(productToRecentlyViewed(productId));
    }
  }, [])

  var chatwithUser = [];
  if (product) {
    chatwithUser = user && chats && chats.find(chat => {
      return chat.users.some(item => item?.id === user?.id) && chat.users.some(item => item?.id === product?.seller?.id) && chat?.product?.id === product?.id;
    });
  }

  const tabMenuContent = {
    Description: (
      <div className="flex flex-col space-y-2">
        <p>
          {product?.description}
        </p>
      </div>
    ),
    "Item Specifics": (
      product && product.specification && (
        <div style={{ display: "flex", justifyContent: "space-around" }} className="mt-3">
          <div className="" style={{ width: "50%", background: "#FCF9F4" }}>
            {Object.entries(product.specification).slice(0, 4).map(([key, value], index) => (
              <div
                className={`flex flex-row justify-between px-2 py-1 border-b-[1px] border-l-[1px] border-r-[1px] border-black ${index === 0 ? 'border-t-[1px]' : ''}`}
                key={index}
              >
                <h1 className="text-[10px] 3md:text-[12px] 4md:text-[18px]">
                  {key} :
                </h1>
                <h1 className="boldFont text-[10px] 3md:text-[12px] 4md:text-[18px]">
                  {value}
                </h1>
              </div>
            ))}
          </div>
          <div className="" style={{ width: "50%", background: "#FCF9F4" }}>
            {Object.entries(product.specification).slice(4).map(([key, value], index) => (
              <div
                className={`flex flex-row justify-between px-2 py-1 border-b-[1px] border-r-[1px] border-black ${index === 0 ? 'border-t-[1px]' : ''}`}
                key={index}
              >
                <h1 className="text-[10px] 3md:text-[15px] 4md:text-[20px]">
                  {key} :
                </h1>
                <h1 className="boldFont text-[10px] 3md:text-[15px] 4md:text-[20px]">
                  {value}
                </h1>
              </div>
            ))}
          </div>
        </div>
      )
    ),

    "Buyers Guide": (
      <div
        className="flex flex-column space-y-2 space-x-2"
        style={{ fontWeight: "400" }}
      >
        This will be Updated by Admin
      </div>
    ),
  };

  const carImg = {
    interior: [
      require("../assets/new design/car/car_img2.png"),
      require("../assets/new design/car/car_img3.png"),
    ],
    exterior: [
      require("../assets/new design/car/car_img4.png"),
      require("../assets/new design/car/car_img5.png"),
      require("../assets/new design/car/car_img6.png"),
      require("../assets/new design/car/car_img7.png"),
    ],
    images: [require("../assets/new design/car/car_img6.png")],
    main: require("../assets/new design/car/car_img1.png"),
    all: require("../assets/new design/car/car_img7.png"),
  };


  const [color, setColor] = useState("#000000");

  const [menu, setMenu] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const [response, setResponse] = useState(null);
  const [buyerPincode, setBuyerPincode] = useState(null);
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const [inCart, setInCart] = useState(false);

  //expiry-date-product
  const product_expiry_date = new Date(product?.listing_duration);
  const year = product_expiry_date.getFullYear();
  const month = product_expiry_date.toLocaleString('default', { month: 'long' });
  const day = product_expiry_date.getDate();

  // seller-since
  const seller_since = new Date(product?.seller?.createdAt);
  const seller_year = seller_since.getFullYear();
  const seller_month = seller_since.toLocaleString('default', { month: 'long' });

  // Product Address
  let formattedAddress = product?.loc?.formattedAddress;
  if (formattedAddress) {
    const parts = formattedAddress.split(',');
    if (parts.length >= 3) {
      const lastIndex = parts.length - 1;
      const secondToLastIndex = lastIndex - 1;
      const result = `${parts[secondToLastIndex].trim()}, ${parts[lastIndex].trim()}`;

      formattedAddress = result; // This will contain the desired result
    }
  }

  // product Address maps
  let mapAddress = formattedAddress;
  if (mapAddress) {
    mapAddress = mapAddress.replace(/,\s+/g, ',');
    mapAddress = mapAddress.replace(/\s/g, '%20');
  }

  // const handleShipmentCost = async () => {
  //   const data = {
  //     pickup_postcode: product?.product_address?.pincode, // listing
  //     delivery_postcode: buyerPincode,
  //     weight: product?.category?.weight,  // category
  //     cod: 0,
  //     declared_value: product?.cost,  // listing // max value 50 lakh
  //     rate_calculator: 1,
  //     blocked: 1,
  //     is_return: 0,
  //     is_web: 1,
  //     is_dg: 0,
  //     length: product?.category?.length,  // category
  //     breadth: product?.category?.breadth, // category
  //     height: product?.category?.height   // category
  //   }

  //   let result = {};
  //   await axiosInstance
  //     .get(`shipRocket/calculate-cost?pickup_postcode=${data.pickup_postcode}&delivery_postcode=${data.delivery_postcode}&weight=${data.weight}&cod=${data.cod}&declared_value=${data.declared_value}&rate_calculator=${data.rate_calculator}&blocked=${data.blocked}&is_return=${data.is_return}&is_web=${data.is_web}&is_dg=${data.is_dg}&length=${data.length}&breadth=${data.breadth}&height=${data.height}`)
  //     .then((res) => {
  //       result = res.data.data;
  //       setShipmentDetails({
  //         Delivery_Fee: result.freight_charge,
  //         ETD: result.etd
  //       });
  //     })
  //     .catch(({ response }) => {
  //       try {
  //         switch (response.data.error) {
  //           case "Delivery to this pincode is currently not available.":
  //             //   add a toast
  //             setShipmentDetails({
  //               Message: "Delivery to this pincode is currently not available."
  //             });
  //             toast.info("Delivery to this pincode is currently not available.");
  //             break;
  //           default:
  //             // server error
  //             toast.error("Oops, something went wrong");
  //             break;
  //         }
  //       } catch (e) {
  //         toast.error("Couldn't reach the server");
  //       }
  //     })

  // };

  // getting compressed images
  useEffect(() => {
    if (product) {
      dispatch(getProductCompressedImages(product?.images));
      dispatch(getUserChats());
    }
    return () => {
      dispatch(clearProductImages());
    };
  }, [dispatch, product])

  const { images } = useSelector((state) => state.product)

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleAddToCart = () => {
    const reqProduct = {
      _id: product?._id,
      cost: product?.cost,
      // pickup_postcode : product?.product_address?.pincode,
      // weight: product?.category?.weight,
      // length: product?.category?.length,  
      // breadth: product?.category?.breadth, 
      // height: product?.category?.height  
    }
    dispatch(addProductToCart(reqProduct));
    setInCart(true);
  }
  const handleRemoveFromCart = () => {
    const reqProduct = {
      _id: product._id,
      cost: product.cost
    }
    dispatch(removeProductFromCart(reqProduct));
    setInCart(false);
  }

  return (
    <>
      <div>
        {/* Desktop Version */}
        {product ?
          <div className=" mt-7 hidden xl:flex xl:flex-col lg:flex lg:flex-col lg:overflow-x-auto h-full bg-[#FFFCF8] rounded">
            <div className="pb-4 px-14 rounded">
              {product?.status?.state === 'sold' && user && product?.buyer === user.id ?
                <div className="bg-white border-t-[1px] border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <div
                    className="py-1 px-5 border-b-[1px] border-black flex items-center justify-between"
                    style={{
                      background: '#000000'
                    }}
                  >
                    <h1 className="text-[19px] boldFont text-white">Order Details</h1>
                  </div>

                  <div className=" flex items-center justify-between px-5">
                    <div className=" py-3.5">
                      <p>You bought this product, Check product Details</p>
                    </div>
                    <button className="text-[15px] mediumFont text-left py-1 bg-black text-white p-2 rounded" onClick={() => { navigate("/buying/purchaseHistory") }}>
                      Order Details
                    </button>
                  </div>
                </div> : product?.status?.state === 'sold' && user && product?.buyer !== user.id ?
                <div className="bg-white border-t-[1px] border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <div
                    className="py-1 px-5 border-b-[1px] border-black flex items-center justify-between"
                    style={{
                      background: '#000000'
                    }}
                  >
                    <h1 className="text-[19px] boldFont text-white">Order Details</h1>
                  </div>

                  <div className=" flex items-center justify-between px-5">
                    <div className=" py-3.5">
                      <p>This Product is already sold</p>
                    </div>
                  </div>
                </div> : product?.status?.state === 'sold' &&
                <div className="bg-white border-t-[1px] border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                <div
                  className="py-1 px-5 border-b-[1px] border-black flex items-center justify-between"
                  style={{
                    background: '#000000'
                  }}
                >
                  <h1 className="text-[19px] boldFont text-white">Order Details</h1>
                </div>

                <div className=" flex items-center justify-between px-5">
                  <div className=" py-3.5">
                    <p>This Product is already sold</p>
                  </div>
                </div>
              </div>
              }
            </div>
            <div className="pb-4 px-14 border-b-[1px] border-black">
              <div className="bg-white border-t-[1px] border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                <div
                  className="py-1 px-5 border-b-[1px] border-black flex items-center justify-between"
                  style={{
                    background:
                      product?.condition === 'New'
                        ? '#B8E1FF'
                        : product?.condition === 'parts'
                          ? '#F35859'
                          : '#FFDC25',
                  }}
                >
                  <h1 className="text-[19px] boldFont">Condition: {product?.condition}</h1>
                  <h1 className="text-[19px] boldFont">
                    Sold by : <span> {product?.seller?.first_name}</span>
                  </h1>
                </div>

                <div className=" flex items-center justify-between px-5">
                  <div className=" py-3.5">
                    <h1 className=" text-[32px] mediumFont">
                      {product?.title}
                    </h1>

                    <div className="flex items-center justify-start gap-x-1 mt-1">
                      <AccessTimeOutlined
                        className="text-grey-100"
                        fontSize={"10"}
                      />
                      <h1 className="text-[18px] text-grey-100">{day} {month}, {year}</h1>
                    </div>
                  </div>
                  <h1 className="text-[36px] mediumFont text-sa-menu-green text-left py-1">
                    &#8377; {product?.cost}
                  </h1>
                </div>
              </div>
              <div className="bg-white px-5 py-5 border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                {images ? <ImageGalleryTest productImages={images} /> :
                  <div>LOADING</div>}
              </div>
              <div className="  mt-4.5 flex space-x-4 relative items-start">
                {/* Description  */}
                <div className=" flex-1 bg-white">
                  <div className="w-full bg-[#EFEFEF]">
                    <div className=" w-full border-[1px] border-b-[0px] border-black py-0">
                      <Accourdion title="Highlights">
                        <div className="bg-white ">
                          <TabMenu
                            state={menu}
                            setState={setMenu}
                            items={tabMenuContent}
                          />
                        </div>
                      </Accourdion>
                    </div>
                  </div>

                  <div>
                    <div className=" border-[1px] border-t-[0px] border-b-[0px]  bg-[#EFEFEF] border-black">
                      <div className="">
                        <Accourdion title={`${product?.seller?.first_name}'s Report Card`}>
                          <div className="bg-white px-6 pt-[18px] pb-4">
                            <div className="  flex items-start justify-between">
                              <div className=" w-[60%] border-x-[1px] border-b-[1px] border-black">
                                {[
                                  {
                                    title: "Communication",
                                    value: "Average",
                                  },
                                  {
                                    title: "On time shipment",
                                    value: "Average",
                                  },
                                  {
                                    title: "Item as described",
                                    value: "Satisfied",
                                  },
                                  {
                                    title: "Commitment after side",
                                    value: "Good",
                                  },
                                ].map((e) => (
                                  <div className=" flex items-center justify-between px-2 border-t-[1px] border-black">
                                    <div className="border-r-[1px] border-black py-2.5 w-[56%]">
                                      <h1 className="text-base ">{e.title}</h1>
                                    </div>
                                    <div className=" w-[44%] py-2.5 flex-1 pl-10">
                                      <h1 className="text-base mediumFont">
                                        {e.value}
                                      </h1>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className=" w-[40%] self-stretch border-y-[1px] border-r-[1px] border-black">
                                <div className=" h-1/2 py-2 pl-10 border-b-[1px] border-black">
                                  <h1 className="text-xl boldFont text-[#888888]">
                                    Items Sold
                                  </h1>
                                  <h1 className=" text-4xl boldFont">04</h1>
                                </div>
                                <div className=" h-1/2 py-2 pl-10">
                                  <h1 className="text-xl boldFont text-[#888888]">
                                    5 Start Reviews
                                  </h1>
                                  <h1 className=" text-4xl boldFont">02</h1>
                                </div>
                              </div>
                            </div>
                            <div className=" mt-4">
                              <h1 className=" text-2xl mediumFont">
                                Here’s what others have to say about Akshat:
                              </h1>
                              <div className=" flex items-center gap-x-1 mt-2">
                                <h1 className=" text-lg boldFont">@Sart....mk</h1>
                                <div className=" flex items-start">
                                  <Star
                                    className=" text-yellow-100"
                                    fontSize="medium"
                                  />
                                  <Star
                                    className=" text-yellow-100"
                                    fontSize="medium"
                                  />
                                  <Star
                                    className=" text-yellow-100"
                                    fontSize="medium"
                                  />
                                  <StarOutline className=" " fontSize="medium" />
                                  <StarOutline className=" " fontSize="medium" />
                                </div>
                              </div>
                              <p className=" text-base font-normal text-grey-100 mt-2">
                                Check who the seller is very carefully. If it says
                                Loop Mobile then the phone is most definitely used
                                even though they say in the phone specifications
                                that it is a new phone. When you open the box, there
                                is paperwork that specifically states that it is a
                                refurbished phone and that there is no warranty on
                                the phone.
                              </p>
                              <div className="underline mediumFont text-lg cursor-pointer text-blue-100 mt-2">
                                See All Reviews
                              </div>
                            </div>
                          </div>
                        </Accourdion>
                      </div>
                    </div>

                    <div className="bg-[#EFEFEF] border-t-[0px] border-b-[0px] border-[1px] border-t-transparent border-black  ">
                      <Accourdion title={"Comments and offers"}>
                        <div className=" px-6 pb-3 pt-[18px] bg-white">
                          <div
                            className="border-[1px] border-black rounded overflow-hidden flex items-start"
                            style={{ backgroundColor: "#F8F8F8" }}
                          >
                            <input
                              type="text"
                              placeholder="Add a comment"
                              className=" w-full px-4 py-1.5 text-black outline-none xl:text-xl 3xl:text-xl 4xl:text-[23px] mt-1.5"
                              style={{ backgroundColor: "#F8F8F8" }}
                            />
                            <button
                              className=" px-4 py-3 rounded-[2px] border-l-[1px] border-black"
                              style={{ background: "#F7CB45" }}
                            >
                              <SouthIcon />
                            </button>
                          </div>
                          <div>
                            <div className="flex items-center gap-3 mt-12 pb-1">
                              <div className="flex flex-col">
                                <img
                                  className="w-[32px]"
                                  src={require("../assets/new design/user.png")}
                                />
                              </div>
                              <div className="flex flex-row items-center">
                                <h1 className="boldFont mt-[2.0px] underline text-base">
                                  @zubaankesari
                                </h1>
                                <h1 className="ml-1">
                                  <CheckCircleRoundedIcon
                                    color="success"
                                    fontSize="x-small"
                                  />
                                </h1>
                                <h1
                                  className="flex items-center ml-3 px-3 text-[10px] rounded-[4px] text-base text-black border-[1px] border-black mediumFont"
                                  style={{ background: "#FFDC25" }}
                                >
                                  Seller
                                </h1>
                                <h1 className=" pl-2.5 ">
                                  <NorthRoundedIcon />
                                </h1>
                                <h1 className="pl-0.5 boldFont text-lg">0</h1>
                                <h1
                                  className="pl-3 text-[0.9rem] "
                                  style={{ color: "#667085" }}
                                >
                                  20m
                                </h1>
                              </div>
                            </div>
                            <div className="flex flex-row ml-[42px] pb-3">
                              <h1
                                className="text-base font-normal"
                                style={{ color: "#344054" }}
                              >
                                Company Warranty has already ended, and no dents.
                              </h1>
                            </div>
                            <div className="flex ml-[42px] align-center">
                              <div
                                className="flex items-center border-[1px] rounded border-radius-[2px]"
                                style={{ borderColor: "#667085" }}
                              >
                                <h1 className="pl-2 mb-[5px]">
                                  <NorthRoundedIcon
                                    style={{ fill: "#667085", height: "0.95rem" }}
                                  />
                                </h1>
                                <h1
                                  className="boldFont text-[0.9rem] pr-2.5"
                                  style={{ color: "#667085" }}
                                >
                                  0
                                </h1>
                              </div>
                              <div className="flex items-center pl-[25px]">
                                <h1
                                  className="text-[0.9rem] cursor-pointer"
                                  style={{ color: "#667085" }}
                                >
                                  Reply
                                </h1>
                                <h1 className="flex align-center py-1 cursor-pointer">
                                  <TurnRightRoundedIcon
                                    sx={{ color: "#667085" }}
                                    fontSize={"small"}
                                  />
                                </h1>
                              </div>
                              <div className="flex items-center pl-[25px]">
                                <h1
                                  className="py-1.5 text-[0.9rem] cursor-pointer"
                                  style={{ color: "#667085" }}
                                >
                                  Report
                                </h1>
                                <h1 className="py-1 w-[16px] mt-1.5 ml-1 cursor-pointer">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    style={{ fill: "#667085" }}
                                  >
                                    <path d="M21.1384 3C21.4146 3 21.6385 3.22386 21.6385 3.5C21.6385 3.58701 21.6157 3.67252 21.5725 3.74807L18 10L21.5725 16.2519C21.7095 16.4917 21.6262 16.7971 21.3865 16.9341C21.3109 16.9773 21.2254 17 21.1384 17H4V22H2V3H21.1384ZM18.5536 5H4V15H18.5536L15.6965 10L18.5536 5Z"></path>
                                  </svg>
                                </h1>
                              </div>
                            </div>
                            <div>
                              <div className="flex items-center gap-3 mt-8 pb-1">
                                <div className="flex flex-col">
                                  <img
                                    className="w-[32px]"
                                    style={{ background: "#9AA4AA" }}
                                    src={require("../assets/new design/user_icon.png")}
                                  />
                                </div>
                                <div className="flex flex-row items-center">
                                  <h1 className="boldFont mt-[2.0px] underline text-base">
                                    @rakesh12
                                  </h1>
                                  <h1 className=" pl-2.5 ">
                                    <NorthRoundedIcon />
                                  </h1>
                                  <h1 className="pl-0.5 boldFont text-lg">33</h1>
                                  <h1
                                    className="pl-3 text-[0.9rem] "
                                    style={{ color: "#667085" }}
                                  >
                                    1hr
                                  </h1>
                                </div>
                              </div>
                              <div className="flex flex-row ml-[42px] pb-1">
                                <h1
                                  className="text-base font-normal"
                                  style={{ color: "#344054" }}
                                >
                                  That’s a nice wagon… Doug, being a wagon guy,
                                  should have done a review of this one as this is
                                  the last BMW wagon offered here in the US
                                </h1>
                              </div>
                              <div className="flex ml-[42px] align-center">
                                <div
                                  className="flex items-center border-[1px] rounded border-radius-[2px]"
                                  style={{ borderColor: "#667085" }}
                                >
                                  <h1 className="pl-2 mb-[5px]">
                                    <NorthRoundedIcon
                                      style={{ fill: "#667085", height: "0.95rem" }}
                                    />
                                  </h1>
                                  <h1
                                    className="boldFont text-[0.9rem] pr-2.5"
                                    style={{ color: "#667085" }}
                                  >
                                    4
                                  </h1>
                                </div>
                                <div className="flex items-center pl-[25px]">
                                  <h1
                                    className="text-[0.9rem] cursor-pointer"
                                    style={{ color: "#667085" }}
                                  >
                                    Reply
                                  </h1>
                                  <h1 className="flex align-center py-1 cursor-pointer">
                                    <TurnRightRoundedIcon
                                      sx={{ color: "#667085" }}
                                      fontSize={"small"}
                                    />
                                  </h1>
                                </div>
                                <div className="flex items-center pl-[25px]">
                                  <h1
                                    className="py-1.5 text-[0.9rem] cursor-pointer"
                                    style={{ color: "#667085" }}
                                  >
                                    Report
                                  </h1>
                                  <h1 className="py-1 w-[16px] mt-1.5 ml-1 cursor-pointer">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      style={{ fill: "#667085" }}
                                    >
                                      <path d="M21.1384 3C21.4146 3 21.6385 3.22386 21.6385 3.5C21.6385 3.58701 21.6157 3.67252 21.5725 3.74807L18 10L21.5725 16.2519C21.7095 16.4917 21.6262 16.7971 21.3865 16.9341C21.3109 16.9773 21.2254 17 21.1384 17H4V22H2V3H21.1384ZM18.5536 5H4V15H18.5536L15.6965 10L18.5536 5Z"></path>
                                    </svg>
                                  </h1>
                                </div>
                              </div>
                              <div>
                                <div className="flex items-center gap-3 mt-8 pb-1">
                                  <div className="flex flex-col">
                                    <img
                                      className="w-[32px]"
                                      style={{ background: "#9AA4AA" }}
                                      src={require("../assets/new design/user_icon.png")}
                                    />
                                  </div>
                                  <div className="flex flex-row items-center">
                                    <h1 className="boldFont mt-[2.0px] underline text-base">
                                      @munnabhaimbbc
                                    </h1>
                                    <h1 className="ml-1">
                                      <CheckCircleRoundedIcon
                                        color="success"
                                        fontSize="x-small"
                                      />
                                    </h1>
                                    <h1 className=" pl-2.5 ">
                                      <NorthRoundedIcon />
                                    </h1>
                                    <h1 className="pl-0.5 boldFont text-lg">0</h1>
                                    <h1
                                      className="pl-3 text-[0.9rem] "
                                      style={{ color: "#667085" }}
                                    >
                                      2hr
                                    </h1>
                                  </div>
                                </div>
                                <div className="flex ml-[42px] align-center">
                                  <h1
                                    className="p-1 flex content-center align-center text-[14px] rounded-[4px] text-black border-[1px] border-black mediumFont"
                                    style={{
                                      background: "#2D2E30",
                                      color: "white",
                                    }}
                                  >
                                    Made an offer
                                  </h1>
                                </div>
                              </div>
                              <div>
                                <div className="flex items-center gap-3 mt-8 pb-1">
                                  <div className="flex flex-col">
                                    <img
                                      className="w-[32px]"
                                      style={{ background: "#9AA4AA" }}
                                      src={require("../assets/new design/user_icon.png")}
                                    />
                                  </div>
                                  <div className="flex flex-row items-center">
                                    <h1 className="boldFont mt-[2.0px] underline text-base">
                                      @abd
                                    </h1>
                                    <h1 className=" pl-2.5 ">
                                      <NorthRoundedIcon />
                                    </h1>
                                    <h1 className="pl-0.5 boldFont text-lg">0</h1>
                                    <h1
                                      className="pl-3 text-[0.9rem] "
                                      style={{ color: "#667085" }}
                                    >
                                      1 day
                                    </h1>
                                  </div>
                                </div>
                                <div className="flex ml-[42px] align-center">
                                  <h1
                                    className="p-1 flex content-center align-center rounded-[4px] text-[14px] text-black border-[1px] border-black mediumFont"
                                    style={{
                                      background: "#2D2E30",
                                      color: "white",
                                    }}
                                  >
                                    Made an offer
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accourdion>
                    </div>
                  </div>
                </div>

                {/* Sold By */}
                <div className=" w-[35%] h-fit border-r-[1px] border-l-[1px] sticky right-0 top-2 border-y-[1px] border-black bg-white">
                  <div className="w-full py-[25px] pl-[25px] border-b-[1px] border-black flex justify-between bg-[#EFEFEF]">
                    <h1 className=" text-[24px] boldFont ">Sold By</h1>
                    <button
                      className={`text-[12px] py-2 px-6 rounded-[4px] text-black flex items-center justify-center `}
                      onClick={handleClick}
                    >
                      {isClicked ? (
                        <MdTurnedIn
                          size={24}
                          style={{
                            fill: "rgb(255, 200, 0)",
                          }}
                        />
                      ) : (
                        <MdTurnedInNot size={24} />
                      )}
                    </button>
                  </div>
                  {/* name membet */}
                  <div className="flex items-center justify-between mt-4 px-[24px]">
                    <div className=" flex items-center gap-x-4">
                      <div className="w-14 border-[1px] border-black rounded-full">
                        <img
                          src={require("../assets/new design/user.png")}
                          alt=""
                          className=" w-full"
                        />
                      </div>
                      <div onClick={() => { navigate(`/sellerShop/${product?.seller?._id}`, { state: { data: product?.seller } }) }}>
                        <h1 className=" text-[18px] underline underline-offset-[6px] boldFont">
                          {product?.seller?.first_name}
                        </h1>
                        <h1 className="text-[16px] text-black mt-0.5">{seller_month} {seller_year}</h1>
                      </div>
                    </div>

                    <div className="flex h-full items-center space-x-2">
                      {/* <button
                    onClick={() => setColor("#FDC225")}
                    className=" w-full text-[12px] py-2 px-3 rounded-[4px]  text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                  >
                    <TurnedInNot sx={{ color: color }} className=" text-xs" />
                  </button> */}
                      {/* <button
                  onClick={() => {
                    showModal({
                      modalType: SEND_MESSAGE_MODAL,
                      modalTitle: ``,
                      modalSubTitle: "",
                    });
                  }}
                  className=" w-full text-[12px] py-2 px-3 rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                >
                  <MailOutline className=" text-xs" />
                </button> */}
                      <div className=" flex items-center h-fit space-x-0.5">
                        <h1 className=" text-2xl boldFont">4.5</h1>
                        <Star className=" text-yellow-100 text-xs" />
                      </div>
                    </div>
                  </div>
                  <div className="px-[25px]">
                    <SignInPanel product={product} />
                    {inCart === false ?
                      <div className="pt-[16px]">
                        <button
                          className="bg-[#FCF9F4] w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                          onClick={() => handleAddToCart()}
                        >
                          Add To Cart
                        </button>
                      </div> :
                      <div className="pt-[16px]">
                        <button
                          className="bg-[#FCF9F4] w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                          onClick={() => handleRemoveFromCart(product)}
                        >
                          Remove from Cart
                        </button>
                      </div>
                    }
                    <div className="pt-[16px]">
                      <div className="flex justify-between">
                        <input
                          id="pincodeInput"
                          type="text"
                          name="pincode"
                          placeholder="Enter Pincode"
                          onChange={(e) => setBuyerPincode(e.target.value)}
                          className="text-[15px] w-full py-[10px] px-auto rounded-[4px] text-black text-center border-[1px] border-sa-border-black mediumFont flex items-center justify-center mr-1"
                        // Adjust the width and border styles as needed
                        />
                        <button
                          onClick={() => handleShipmentCost(product, buyerPincode, setShipmentDetails)}
                          className="bg-[#FCF9F4] text-[15px] w-full py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                        // Adjust the width and border styles as needed
                        >
                          Calculate Shipment Details
                        </button>
                      </div>
                    </div>
                    <div className="pt-[16px]">
                      <button
                        onClick={() => {
                          if (chatwithUser) {
                            navigate("/user/messages", { state: { chat: chatwithUser._id} });
                          } else {
                            showModal({
                              modalType: SEND_MESSAGE_MODAL,
                              modalTitle: ``,
                              modalSubTitle: "",
                              modalProps: product
                            });
                          }
                        }}
                        className="bg-[#FCF9F4] w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                      >
                        Contact {product?.seller?.first_name}
                      </button>
                    </div>

                    {shipmentDetails && <div className="w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont mt-4 flex justify-between px-4">
                      {shipmentDetails.Delivery_Fee &&
                        <>
                          <div> Delivery_Fee : &#8377;{shipmentDetails.Delivery_Fee}</div>
                          <div> ETD: {shipmentDetails.ETD}  </div>
                        </>
                      }
                      {shipmentDetails.Message &&
                        <>
                          <div className="text-black text-center"> {shipmentDetails.Message}</div>
                        </>
                      }

                    </div>}
                  </div>

                  {/* offer history */}
                  {/* <div className=" px-3.5 pt-2 pb-1 border-[1px] rounded border-black mt-5">
              <Accourdion title={"Offer History"}>
                <div className="pb-3">
                  <div className="flex items-center justify-between mt-2">
                    <h1 className="text-[18px] text-[#3E3E3E] mediumFont leading-[16px]">
                      08 Feb, 03:30 AM
                    </h1>
                    <h1 className="text-[18px] text-black mediumFont leading-[22px]">
                      Sukun Verma
                    </h1>
                    <h1 className="font-italic text-black text-[18px]">
                      Made an offer
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <h1 className="text-[18px] text-[#3E3E3E] mediumFont leading-[16px]">
                      08 Feb, 03:30 AM
                    </h1>
                    <h1 className="text-[18px] text-black mediumFont leading-[22px]">
                      Sukun Verma
                    </h1>
                    <h1 className="font-italic text-black text-[18px]">
                      Made an offer
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <h1 className="text-[18px] text-[#3E3E3E] mediumFont leading-[16px]">
                      08 Feb, 03:30 AM
                    </h1>
                    <h1 className="text-[18px] text-black mediumFont leading-[22px]">
                      Sukun Verma
                    </h1>
                    <h1 className="font-italic text-black text-[18px]">
                      Made an offer
                    </h1>
                  </div>
                </div>
              </Accourdion>
            </div> */}
                  <div className="px-[24px] pt-[24px] pb-[36px]">
                    <div className="flex flex-col rounded-lg">
                      <div className="flex items-center space-x-1 -ml-2 pb-[22px]">
                        <LocationOnOutlined className="text-sa-text-gray" />
                        <p className="text-sa-text-gray underline underline-offset-2">
                          {formattedAddress}
                        </p>
                      </div>
                      <div className="rounded-lg border-[1px] border-black">
                        {/* <img src={map} className="object-cover" /> */}
                        {/* 
                          --> have to enable Google Maps embed API

                        
                        src= "https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=Rajinder%20Nagar,New%20Delhi,Delhi"
                        
                        
                        */}
                        <iframe
                          className="rounded-lg border-[1px] border-black"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14006.98438512234!2d77.18340760000001!3d28.6373709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02bcbb3b6d6b%3A0xaac7850da2a43dd9!2sRajinder%20Nagar%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1684321877094!5m2!1sen!2sin"
                          width="100%"
                          height="100%"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          style={{ border: 0 }}
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  {/* Delivery & Returns */}
                  {/* <div className=" px-3.5 pb-1 border-[1px] rounded border-black mt-5">
              <Accourdion title={"Delivery & Returns"}>
                <div className="pb-3">
                  <div className=" flex items-start justify-between">
                    <div>
                      <div className="flex items-center gap-x-1 ">
                        <LocationOnOutlined fontSize="10" />
                        <h1 className="text-lg mediumFont text-sa-text-gray">
                          Delivery to 462016 : FREE
                        </h1>
                      </div>
                      <div className="ml-5 mt-1 space-y-0.5">
                        <p className=" text-base font-normal">
                          ₹520 Standard shipping to other city
                        </p>
                        <p className=" text-base font-normal">
                          Est. delivery Mon, Feb 15-Fri, Feb 18 to 462016
                        </p>
                      </div>
                    </div>
                    <div className=" w-fit underline underline-offset-4 mediumFont text-base cursor-pointer text-blue-100">
                      Change
                    </div>
                  </div>
                  <div className="flex items-start gap-2 mt-2 border-t-[0.5px] border-black pt-4 ">
                    <img
                      src={require("../assets/new design/exchange.png")}
                      className=" w-5 mt-2"
                      alt=""
                    />
                    <h1 className="text-[16px] ">
                      <span className="mediumFont">Returns :</span> Hassle
                      free returns covered by Stake Atlas Guarantee within 14
                      days of delivery
                    </h1>
                  </div>
                </div>
              </Accourdion>
            </div> */}

                  {/* questions Have a Question? */}
                  {/*<div className=" px-3.5 py-3 border-[1px] rounded border-black mt-5 flex items-center justify-between ">
              <div className=" flex items-center gap-x-2">
                <img
                  src={require("../assets/new design/conversation.png")}
                  className="w-7"
                  alt=""
                />
                <h1 className=" text-lg mediumFont">
                  <span className="text-[#2192FF]"> 2 answered questions</span>{" "}
                  | Have a Question?
                </h1>
              </div>
              <ExpandMore />
        </div> */}
                </div>
              </div>{" "}
            </div>

            <div className="py-10 px-14 bg-white hidden xl:flex xl:flex-col lg:flex lg:flex-col border-t-[1px] border-black">
              <h1 className="text-[38px] mediumFont">Recommended Listings</h1>
              <p className=" w-[90%] text-[18px] font-normal leading-5 mt-2 2xl:text-lg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat.
              </p>

              {/* <div className="hidden sm:flex items-center justify-between mt-10 mb-20 gap-5 overflow-x-scroll no-scrollbar">
            <ProductCard />
            <ProductCard />
            <ProductCard3 />
            <ProductCard />
          </div> */}
            </div>
          </div> :
          <div className="container mx-auto ">
            <h1 className="text-red-400 text-3xl">The Product You are trying to access, Does not Exist.</h1>
          </div>
        }

        {/* Mobile Version */}
        {product ?
          <div className="px-[20px] mt-4 lg:hidden bg-[#FFFCF8]">
            {user && product?.buyer === user.id && <div className="bg-white border-t-[1px] border-r-[1px] border-l-[1px] border-b-[1px] border-black">
              <div
                className="py-1 px-5 border-b-[1px] border-black flex items-center justify-between"
                style={{
                  background: '#000000'
                }}
              >
                <h1 className="text-[19px] boldFont text-white">Order Details</h1>
              </div>

              <div className=" flex items-center justify-between px-5">
                <div className=" py-3.5">
                  <p>You bought this product, Check product Details</p>
                </div>
                <button className="text-[15px] mediumFont text-left py-1 bg-black text-white p-2 rounded" onClick={() => { navigate("/buying/purchaseHistory") }}>
                  Order Details
                </button>
              </div>
            </div>}
            <div className="border-black rounded-lg overflow-hidden mb-4 bg-white">
              {/* image and sold by div */}
              <div>
                <div className="border border-black rounded-t-lg overflow-hidden">
                  {images ? <ImageGalleryTest productImages={images} /> :
                    <div>LOADING</div>}
                </div>
                <div className="border-[1px] border-t-[0px] border-black">
                  <div className="px-5 py-6">
                    <h1 className=" text-[32px]">
                      {product?.title}
                    </h1>

                    <div className="flex items-center justify-between gap-x-1 mt-1">
                      <h1 className="text-[28px] boldFont text-sa-menu-green py-1">
                        &#8377; {product?.cost}
                      </h1>
                      <div className="flex items-center">
                        <AccessTimeOutlined
                          className="text-grey-100"
                          fontSize={"12"}
                        />
                        <h1 className="text-[18px] text-grey-100">{day} {month}, {year}</h1>
                      </div>
                    </div>
                    <SignInPanel product={product} />
                    {inCart === false ?
                      <div className="pt-[16px]">
                        <button
                          className="bg-[#FCF9F4] w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                          onClick={() => handleAddToCart()}
                        >
                          Add To Cart
                        </button>
                      </div> :
                      <div className="pt-[16px]">
                        <button
                          className="bg-[#FCF9F4] w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                          onClick={() => handleRemoveFromCart(product)}
                        >
                          Remove from Cart
                        </button>
                      </div>
                    }
                    <div className="pt-[16px]">
                      <div className="flex justify-between">
                        <input
                          id="pincodeInput"
                          type="text"
                          name="pincode"
                          placeholder="Enter Pincode"
                          onChange={(e) => setBuyerPincode(e.target.value)}
                          className="text-[15px] w-full py-[10px] px-auto rounded-l-[4px] text-black text-center border-t-[1px] border-l-[1px] border-b-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                        // Adjust the width and border styles as needed
                        />
                        <button
                          onClick={() => handleShipmentCost(product, buyerPincode, setShipmentDetails)}
                          className="bg-[#FCF9F4] text-[15px] w-full py-[10px] px-auto rounded-r-[4px] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                        // Adjust the width and border styles as needed
                        >
                          Calculate Shipment Details
                        </button>
                      </div>
                    </div>
                    {shipmentDetails && <div className="w-full text-[15px] py-[10px] px-auto rounded-[4px] text-black border-[1px] border-sa-border-black mediumFont mt-4 flex justify-between px-4">
                      {shipmentDetails.Delivery_Fee &&
                        <>
                          <div> Delivery_Fee : &#8377;{shipmentDetails.Delivery_Fee}</div>
                          <div> ETD: {shipmentDetails.ETD}  </div>
                        </>
                      }
                      {shipmentDetails.Message &&
                        <>
                          <div className="text-black text-center"> {shipmentDetails.Message}</div>
                        </>
                      }

                    </div>}
                  </div>
                </div>

                <div className=" border-[1px] border-t-0 border-black px-[20px] py-2.5">
                  <h1 className=" text-[18px] mediumFont">Sold By</h1>

                  <div className="mt-[12px] flex items-center align-middle justify-between">
                    <div className="flex items-center gap-x-1.5">
                      <div className=" w-[54px]">
                        <img
                          src={require("../assets/new design/user.png")}
                          alt=""
                          className=" w-full"
                        />
                      </div>
                      <div className="pl-1">
                        <h1 className=" text-[16px] boldFont  ">{product?.seller?.first_name}</h1>
                        <h1 className="text-[14px] font-normal text-grey-100">
                          Member since {seller_month} {seller_year}
                        </h1>
                      </div>
                    </div>
                    <div className=" flex items-center gap-x-0.5">
                      <h1 className=" text-2xl boldFont">4.5</h1>
                      <Star className=" text-yellow-100 text-xs" />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <button
                      onClick={() => {
                        // Code for Save Seller button action
                      }}
                      className="w-[calc(50%-8px)] text-[15px] mediumFont mt-2.5 py-[10px] px-3  rounded-[4px] bg-white hover:bg-gray-200 text-black border-[1px] border-sa-border-black "
                    >
                      Save Seller
                    </button>

                    <button
                      onClick={() => {
                        showModal({
                          modalType: SEND_MESSAGE_MODAL,
                          modalTitle: ``,
                          modalSubTitle: "",
                        });
                        console.log("run");
                        // Code for Send Message button action
                      }}
                      className="w-[calc(50%-8px)] text-[15px] mediumFont mt-2.5 py-[10px] px-3 rounded-[4px] bg-white hover:bg-gray-200 text-black border-[1px] border-sa-border-black "
                    >
                      Message
                    </button>
                  </div>

                  <div className=" flex flex-col space-y-3 py-3 mt-3 ">
                    <div className="rounded-md w-full mx-auto border-[1px] border-black">
                      {/* 
                      --> have to enable Google Maps embed API

                    
                    src= "https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=Rajinder%20Nagar,New%20Delhi,Delhi"
                    
                    
                    */}
                      <iframe
                        className=" rounded-md border-[1px] border-black"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14006.98438512234!2d77.18340760000001!3d28.6373709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02bcbb3b6d6b%3A0xaac7850da2a43dd9!2sRajinder%20Nagar%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1684321877094!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        style={{ border: 0 }}
                      ></iframe>
                    </div>
                    <div className="flex items-center space-x-0.5">
                      <LocationOnOutlined className="text-sa-text-gray h-3 w-3" />
                      <p className="text-sa-text-gray text-[16px] pl-1">
                        {formattedAddress}
                      </p>
                    </div>
                  </div>
                </div>
                {/* highlights div */}
                <div className="border-l-[1px] border-r-[1px] border-b-[0px] border-black flex flex-col ">
                  <div className="bg-[#EFEFEF] flex justify-center border-black border-[1px] border-t-0 border-l-0 border-r-0">
                    {" "}
                    <h1 className="text-[28px] pt-[24px] pb-[18px] border-black">
                      Highlights
                    </h1>
                  </div>

                  <div className="border-b-[1px] px-4 py-2 border-black">
                    <Accourdion title={"Description"}>
                      <p className=" text-[16px] font-normal pb-3">
                        {product?.description}
                      </p>
                    </Accourdion>
                  </div>
                  <div className=" px-4 py-2 border-b-[1px] border-black">
                    <Accourdion title={"Item Specifics"}>
                      {/* <div className="pb-3">
                  <div className="flex gap-3">
                    <div className="flex flex-col">
                      <ThumbUp className="text-blue-100" fontSize="16" />
                      <span className="text-black text-xs">04</span>
                    </div>
                    <div>
                      <h1 className="mediumFont text-xs">
                        Q. I've received a phone and cable. But there is no
                        adapter, is this phone box comes without an adapter?
                      </h1>
                      <h1 className="text-xs mediumFont mt-1">
                        A. You need to but the adapter separately, the box
                        doesn't contains an adapter.
                      </h1>
                    </div>
                  </div>
                  <div className="underline mediumFont text-xs cursor-pointer text-blue-100 mt-2">
                    See all questions
                  </div>

                  <div className=" mt-3 border-[1px] border-black rounded overflow-hidden">
                    <input
                      type="text"
                      placeholder="Ask Questions from seller"
                      className=" w-full h-10 px-2 text-black outline-none  3xl:text-xl 4xl:text-[23px] mb-3"
                    />
                    <button className=" w-full  text-[12px] py-2 px-3 rounded-[2px] bg-sa-primary-yellow text-black border-x-[0.5px] border-t-[0.5px] border-black mediumFont">
                      Submit
                    </button>
                  </div>
                </div> */}
                      <div
                        className=" border-x-[1px] border-b-[1px] border-black"
                        style={{
                          width: "100%",
                          background: "#FCF9F4",
                          marginBottom: "25px",
                        }}
                      >
                        {Object.entries(product.specification).map(([key, value], index) => (
                          <div
                            className=" flex px-2 py-2 border-t-[1px] border-black"
                            style={{}}
                          >
                            <h1 className=" w-3/5 px-5">{key} : </h1>
                            <h1
                              className=" w-1/2 boldFont"
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              {value}
                            </h1>
                          </div>
                        ))}
                      </div>
                    </Accourdion>
                  </div>
                  <div className=" px-4 py-2 border-black border-b-[1px] ">
                    <Accourdion title={"Buyer's Guide"}>
                      <p>This will be updated by Admin</p>
                    </Accourdion>
                  </div>
                </div>
                {/* akshat's report card div */}
                <div className="border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                  <div className="bg-[#EFEFEF] flex justify-center border-black border-[1px] border-t-0 border-l-0 border-r-0">
                    {" "}
                    <h1 className="text-[28px] pt-[24px] pb-[18px] border-black">
                      {product?.seller?.first_name}'s Report Card
                    </h1>
                  </div>
                  <div className="pb-4 pt-4">
                    <div className=" flex flex-col space-y-2 items-start justify-between px-[20px]">
                      <div className=" w-full border-[1px] border-t-0 border-black">
                        {[
                          {
                            title: "Communication",
                            value: "Average",
                          },
                          {
                            title: "On time shipment",
                            value: "Average",
                          },
                          {
                            title: "Item as described",
                            value: "Satisfied",
                          },
                          {
                            title: "Commitment after side",
                            value: "Good",
                          },
                        ].map((e) => (
                          <div className=" flex items-center justify-between px-2 border-t-[1px] border-black">
                            <div className="border-r-[1px] border-black py-2.5 w-[56%]">
                              <h1 className="text-base ">{e.title}</h1>
                            </div>
                            <div className=" w-[44%] py-2.5 flex-1 mx-auto">
                              <h1 className="text-base mediumFont text-center">
                                {e.value}
                              </h1>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="w-full  justify-between border-b border-black flex">
                        <div className="py-2 flex items-center">
                          <h1 className="text-sm boldFont text-gray-500">
                            Items Sold:
                          </h1>
                          <h1 className="text-2xl boldFont pl-1">04</h1>
                        </div>
                        <div className="flex items-center py-2">
                          <h1 className="text-sm boldFont text-gray-500">
                            5 Star Reviews:
                          </h1>
                          <h1 className="text-2xl boldFont pl-1">02</h1>
                        </div>
                      </div>
                    </div>
                    <div className=" mt-4 px-[20px]">
                      <h1 className=" text-base mediumFont">
                        Here’s what others have to say about Akshat:
                      </h1>
                      <div className=" flex items-center gap-x-1 mt-2">
                        <h1 className=" text-[18px] mediumFont">@Sart....mk</h1>
                        <div className=" flex items-start pl-2">
                          <Star className=" text-yellow-100" fontSize="small" />
                          <Star className=" text-yellow-100" fontSize="small" />
                          <Star className=" text-yellow-100" fontSize="small" />
                          <StarOutline className=" " fontSize="small" />
                          <StarOutline className=" " fontSize="small" />
                        </div>
                      </div>
                      <p className=" text-[16px] font-normal text-grey-100 mt-2">
                        Check who the seller is very carefully. If it says Loop
                        Mobile then the phone is most definitely used even though
                        they say in the phone specifications that it is a new phone.
                        When you open the box, there is paperwork that specifically
                        states that it is a refurbished phone and that there is no
                        warranty on the phone.
                      </p>
                      <div className="underline mediumFont text-base cursor-pointer text-blue-100 mt-2">
                        See All Reviews
                      </div>
                    </div>
                  </div>
                </div>
                {/* comments and offers div */}
                <div className="border-[1px] border-t-transparent rounded-b-lg border-black">
                  <div className="bg-[#EFEFEF] flex justify-center border-black border-[1px] border-t-0 border-l-0 border-r-0">
                    {" "}
                    <h1 className="text-[28px] pt-[24px] pb-[18px] border-black">
                      Comments and Offers
                    </h1>
                  </div>
                  <div className="pb-3 px-[20px] pt-[18px]">
                    <div
                      className="border-[1px] border-black rounded overflow-hidden flex items-center align-middle"
                      style={{ backgroundColor: "#F8F8F8" }}
                    >
                      <input
                        type="text"
                        placeholder="Add a comment"
                        className=" w-full px-4 py-1.5 text-black outline-none xl:text-xl 3xl:text-xl 4xl:text-[23px]"
                        style={{ backgroundColor: "#F8F8F8" }}
                      />
                      <button
                        className=" px-4 py-2 rounded-[2px] border-l-[1px] border-black"
                        style={{ background: "#F7CB45" }}
                      >
                        <SouthIcon />
                      </button>
                    </div>
                    <div>
                      <div className="flex items-center gap-3 mt-[36px] pb-1">
                        <div className="flex flex-col">
                          <img
                            className="w-[32px]"
                            src={require("../assets/new design/user.png")}
                          />
                        </div>
                        <div className="flex flex-row items-center">
                          <h1
                            className="mediumFont mt-[-5px] underline text-base "
                            style={{ textUnderlineOffset: "5px" }}
                          >
                            @zubaankesari
                          </h1>
                          <h1 className="ml-1 mt-[-5px]">
                            <CheckCircleRoundedIcon
                              color="success"
                              fontSize="x-small"
                            />
                          </h1>
                          <h1
                            className="flex items-center ml-3 px-2 text-[12px] py-1 rounded-[4px]  text-black border-[1px] border-black mediumFont"
                            style={{ background: "#FFDC25" }}
                          >
                            Seller
                          </h1>

                          <h1
                            className="pl-3 text-[0.9rem] "
                            style={{ color: "#667085" }}
                          >
                            20m
                          </h1>
                        </div>
                      </div>
                      <div className="flex flex-row ml-[42px] pb-1">
                        <h1
                          className="text-base font-normal"
                          style={{ color: "#344054" }}
                        >
                          Company Warranty has already ended, and no dents.
                        </h1>
                      </div>
                      <div className="flex ml-[42px] align-center">
                        <div
                          className="flex items-center border-[1px] rounded border-radius-[2px]"
                          style={{ borderColor: "#667085" }}
                        >
                          <h1 className="pl-2 mb-[5px]">
                            <NorthRoundedIcon
                              style={{ fill: "#667085", height: "0.95rem" }}
                            />
                          </h1>
                          <h1
                            className="boldFont text-[0.9rem] pr-2.5"
                            style={{ color: "#667085" }}
                          >
                            0
                          </h1>
                        </div>
                        <div className="flex items-center pl-[25px]">
                          <h1
                            className="text-[0.9rem] cursor-pointer"
                            style={{ color: "#667085" }}
                          >
                            Reply
                          </h1>
                          <h1 className="flex align-center py-1 cursor-pointer">
                            <TurnRightRoundedIcon
                              sx={{ color: "#667085" }}
                              fontSize={"small"}
                            />
                          </h1>
                        </div>
                        <div className="flex items-center pl-[25px]">
                          <h1
                            className="py-1.5 text-[0.9rem] cursor-pointer"
                            style={{ color: "#667085" }}
                          >
                            Report
                          </h1>
                          <h1 className="py-1 w-[16px] mt-1.5 ml-1 cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              style={{ fill: "#667085" }}
                            >
                              <path d="M21.1384 3C21.4146 3 21.6385 3.22386 21.6385 3.5C21.6385 3.58701 21.6157 3.67252 21.5725 3.74807L18 10L21.5725 16.2519C21.7095 16.4917 21.6262 16.7971 21.3865 16.9341C21.3109 16.9773 21.2254 17 21.1384 17H4V22H2V3H21.1384ZM18.5536 5H4V15H18.5536L15.6965 10L18.5536 5Z"></path>
                            </svg>
                          </h1>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-3 mt-8 pb-1">
                          <div className="flex flex-col">
                            <img
                              className="w-[32px]"
                              style={{ background: "#9AA4AA" }}
                              src={require("../assets/new design/user_icon.png")}
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <h1
                              className="mediumFont mt-[-5px] underline text-base "
                              style={{ textUnderlineOffset: "5px" }}
                            >
                              @rakesh12
                            </h1>

                            <h1
                              className="pl-3 text-[0.9rem] margin-right-0"
                              style={{ color: "#667085" }}
                            >
                              1h
                            </h1>
                          </div>
                        </div>
                        <div className="flex flex-row ml-[42px] pb-1">
                          <h1
                            className="text-base font-normal"
                            style={{ color: "#344054" }}
                          >
                            That’s a nice wagon… Doug, being a wagon guy, should
                            have done a review of this one as this is the last BMW
                            wagon offered here in the US
                          </h1>
                        </div>
                        <div className="flex ml-[42px] align-center">
                          <div
                            className="flex items-center border-[1px] rounded border-radius-[2px]"
                            style={{ borderColor: "#667085" }}
                          >
                            <h1 className="pl-2 mb-[5px]">
                              <NorthRoundedIcon
                                style={{ fill: "#667085", height: "0.95rem" }}
                              />
                            </h1>
                            <h1
                              className="boldFont text-[0.9rem] pr-2.5"
                              style={{ color: "#667085" }}
                            >
                              4
                            </h1>
                          </div>
                          <div className="flex items-center pl-[25px]">
                            <h1
                              className="text-[0.9rem] cursor-pointer"
                              style={{ color: "#667085" }}
                            >
                              Reply
                            </h1>
                            <h1 className="flex align-center py-1 cursor-pointer">
                              <TurnRightRoundedIcon
                                sx={{ color: "#667085" }}
                                fontSize={"small"}
                              />
                            </h1>
                          </div>
                          <div className="flex items-center pl-[25px]">
                            <h1
                              className="py-1.5 text-[0.9rem] cursor-pointer"
                              style={{ color: "#667085" }}
                            >
                              Report
                            </h1>
                            <h1 className="py-1 w-[16px] mt-1.5 ml-1 cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                style={{ fill: "#667085" }}
                              >
                                <path d="M21.1384 3C21.4146 3 21.6385 3.22386 21.6385 3.5C21.6385 3.58701 21.6157 3.67252 21.5725 3.74807L18 10L21.5725 16.2519C21.7095 16.4917 21.6262 16.7971 21.3865 16.9341C21.3109 16.9773 21.2254 17 21.1384 17H4V22H2V3H21.1384ZM18.5536 5H4V15H18.5536L15.6965 10L18.5536 5Z"></path>
                              </svg>
                            </h1>
                          </div>
                        </div>
                        <div>
                          <div className="flex items-center gap-3 mt-8 pb-1">
                            <div className="flex flex-col">
                              <img
                                className="w-[32px]"
                                style={{ background: "#9AA4AA" }}
                                src={require("../assets/new design/user_icon.png")}
                              />
                            </div>
                            <div className="flex flex-row items-center">
                              <h1
                                className="mediumFont mt-[-5px] underline text-base"
                                style={{ textUnderlineOffset: "5px" }}
                              >
                                @munnabhaimbbc
                              </h1>
                              <h1 className="ml-1">
                                <CheckCircleRoundedIcon
                                  color="success"
                                  fontSize="x-small"
                                />
                              </h1>

                              <h1
                                className="pl-3 text-[0.9rem] "
                                style={{ color: "#667085" }}
                              >
                                2h
                              </h1>
                            </div>
                          </div>
                          <div className="flex ml-[42px] align-center">
                            <h1
                              className="p-1 flex content-center align-middle rounded-[4px] text-[14px] px-2  text-black border-[1px] border-black"
                              style={{ background: "#2D2E30", color: "white" }}
                            >
                              Made an offer
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" pb-4 pt-4">
              <h1 className=" text-[1.1rem] boldFont">Recommended Listings</h1>
              <p className=" text-[16px] mediumFont mt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>
              {/* <div className="sm:hidden pt-5">
            <ProductCardMob />
            <ProductCardMob2 />
            <ProductCardMob />
            <ProductCardMob />
          </div>
          <div className="hidden sm:flex items-center justify-between mt-10 mb-20 gap-5 overflow-x-scroll no-scrollbar">
            <ProductCard />
            <ProductCard2 />
            <ProductCard3 />
            <ProductCard />
          </div> */}

              <div className="xl:hidden w-full flex items-center justify-center">
                <button className="w-full bg-sa-menu-green text-white  py-[14px] px-6 rounded mediumFont text-[16px  ] text-center">
                  View All
                </button>
              </div>
            </div>
          </div> :
          <div className="container mx-auto px-[20px] mt-4 lg:hidden bg-[#FFFCF8] ">
            <h1 className="text-red-400 text-3xl">The Product You are trying to access, Does not Exist.</h1>
          </div>
        }
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(null, mapDispatchToProps)(ProductScreen);

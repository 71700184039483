import {GET_ALL_CATEGORIES,ITEM_SPECIFICS } from "../actions/types";

const initialState = {};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES: 
      return{
        ...state,
        categories: action.payload
      }
    case ITEM_SPECIFICS: 
    return {
      ...state,
      item_specifics: action.payload
    };
    default:
      return state;
  }
};

export default productReducer;
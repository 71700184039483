import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { removeTokens } from "../../utils/auth/auth";

import { GET_USERS } from "./types";
import { getAllProducts } from "./product";


export const getAllUser = () => {
    return function (dispatch) {
        axiosInstance
          .get(`admin/user/all`)
          .then((res) => {
            const { data } = res.data;    
            dispatch({
              type: GET_USERS,
              payload: data
            })
          })
          .catch(({ response }) => {
            try {
              switch (response.status) {
                default:
                  // server error
                  toast.error("Oops, something went wrong");
    
                  break;
              }
            } catch (e) {
              toast.error("Couldn't reach the server");
            }
          })
      };
}
export const editUser = (userId,request) => {
    return function (dispatch) {
        axiosInstance
          .post(`admin/user/edit/${userId}`,request)
          .then((res) => {
            const { data } = res.data;    
            dispatch(getAllUser());
          })
          .catch(({ response }) => {
            try {
              switch (response.status) {
                default:
                  // server error
                  toast.error("Oops, something went wrong");
    
                  break;
              }
            } catch (e) {
              toast.error("Couldn't reach the server");
            }
          })
      };
}

// listings
export const approveListing = (productId,request) => {
  return function (dispatch) {
    axiosInstance
      .post(`admin/product/verify/${productId}`,request)
      .then((res) => {
        const { data } = res.data;    
        dispatch(getAllProducts());
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

export const rejectListing = (productId,request) => {
  return function (dispatch) {
    axiosInstance
      .post(`admin/product/reject/${productId}`,request)
      .then((res) => {
        const { data } = res.data;    
        dispatch(getAllProducts());
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// report 
export const reportOrder = (request) => {
  return function (dispatch) {
    axiosInstance
      .post(`admin/report/order`,request)
      .then((res) => {
        const { data } = res.data;
        toast.success("Your Reports Has been Sent!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
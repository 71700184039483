import { useDispatch, useSelector } from "react-redux";
import { DELIVERY_LOCATION_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { useState } from "react";
import { updateAddress } from "../../redux/actions/auth";

const EditAddressModal = () => {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state) => state.modal);
  const address = modalProps.address;
  const [updatedAddress, setUpdatedAddress] = useState({ ...modalProps.address });

  const handleSave = () => {
    delete updatedAddress.address_type;
    delete updatedAddress.created_by;
    delete updatedAddress.createdAt;
    delete updatedAddress.updatedAt;
    delete updatedAddress.__v;

    dispatch(updateAddress(updatedAddress));
    dispatch(
      showModal({
        modalType: DELIVERY_LOCATION_MODAL,
        modalTitle: ``,
        modalSubTitle: "",
      })
    );
  }
  return (
    <div>
      {/* Desktop */}
      <div className="bg-white px-5 py-7 rounded hidden xl:flex xl:flex-col">
        <h1 className="text-xl mediumfont text-black mb-3">Edit Address</h1>

        <div className="border-2 border-black rounded-md bg-[#FCF9F4]">
          <div className="px-3 py-3 border-b-2 border-black ">
            <div className=" flex items-center">
              <div className=" flex-1 flex items-center">
                <h1 className="w-[25%] text-lg mediumfont text-black">Name</h1>
                <h1 className=" w-[50%] text-lg font-normal text-black ml-8">
                  {address?.name}
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <button
                  onClick={() => {
                    dispatch(
                      showModal({
                        modalType: DELIVERY_LOCATION_MODAL,
                        modalTitle: ``,
                        modalSubTitle: "",
                      })
                    );
                  }}
                  className="  text-[14px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumfont"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleSave()}
                  className="  text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont"
                >
                  Save
                </button>
              </div>
            </div>
            <div className=" flex items-center">
              <h1 className="w-[25%] text-lg mediumfont text-black">
                Contact No:
              </h1>
              <h1 className=" flex-1 text-lg font-normal text-black">
                {address?.phone}
              </h1>
            </div>
            <div className=" flex items-center">
              <h1 className="w-[25%] text-lg mediumfont text-black">
                Address:
              </h1>
              <h1 className=" text-lg font-normal text-black ml-1">
                {address?.address_line1}
              </h1>
            </div>
            <div className=" flex items-center justify-between">
              <div className=" flex items-center justify-between">
                <h1 className="w-[25%] text-lg mediumfont text-black">
                  City:
                </h1>
                <h1 className=" text-lg font-normal text-black ml-1">
                  {address.city}
                </h1>
              </div>
              <div className=" flex items-center justify-between">
                <h1 className="w-[25%] text-lg mediumfont text-black">
                  State:
                </h1>
                <h1 className=" text-lg font-normal text-black ml-1">
                  {address.state}
                </h1>
              </div >
              <div className=" flex items-center justify-between">
                <h1 className="w-[25%] text-lg mediumfont text-black">
                  Pincode:
                </h1>
                <h1 className=" text-lg font-normal text-black ml-1">
                  {address.pincode}
                </h1>
              </div>
            </div>
          </div>

          <div className="px-3 py-3 rounded-md">
            <div className=" flex items-center justify-between gap-4">
              <div className="w-1/2">
                <h1 className="text-lg mediumfont text-black">Name</h1>
                <input
                  type="text"
                  placeholder="Name"
                  value={updatedAddress.name}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      name: e.target.value,
                    })
                  }
                  className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                />
              </div>
              <div className=" w-1/2">
                <h1 className="text-lg mediumfont text-black">
                  Contact Number:
                </h1>
                <input
                  type="text"
                  placeholder="Contact Number"
                  value={updatedAddress.phone}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      phone: e.target.value,
                    })
                  }
                  className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                />
              </div>
            </div>
            <div className=" w-full mt-2">
              <h1 className="text-lg mediumfont text-black">Full Address:</h1>
              <input
                type="text"
                placeholder="Full Address"
                value={updatedAddress.address_line1}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      address_line1: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className=" flex items-center justify-between gap-4 mt-2 mb-3">
              <div className=" w-1/2">
                <h1 className="text-lg mediumfont text-black">State:</h1>
                <input
                  type="text"
                  placeholder="State"
                  value={updatedAddress.state}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      state: e.target.value,
                    })
                  }
                  className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                />
              </div>
              <div className=" w-1/2">
                <h1 className="text-lg mediumfont text-black">City:</h1>
                <input
                  type="text"
                  placeholder="City"
                  value={updatedAddress.city}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      city: e.target.value,
                    })
                  }
                  className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                />
              </div>
              <div className=" w-1/2">
                <h1 className="text-lg mediumfont text-black">Pincode:</h1>
                <input
                  type="text"
                  placeholder="Pincode"
                  value={updatedAddress.pincode}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      pincode: e.target.value,
                    })
                  }
                  className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="bg-white px-2 py-7 rounded xl:hidden">
        <h1 className="text-xl mediumfont text-black mb-3">Edit Address</h1>

        <div className="border-2 border-black rounded-md bg-[#FCF9F4]">
          <div className="px-3 py-3 border-b-2 border-black ">
            <div className=" flex items-start mb-1">
              <h1 className=" w-[40%] text-sm font-bold text-black">Name</h1>
              <h1 className=" w-[60%] text-sm font-normal text-black">
                {address.name}
              </h1>
            </div>
            <div className=" flex items-start mb-1">
              <h1 className=" w-[40%] text-sm font-bold text-black">
                Contact No:
              </h1>
              <h1 className=" w-[60%] text-sm font-normal text-black">
                {address.phone}
              </h1>
            </div>
            <div className=" flex items-start mb-1">
              <h1 className=" w-[40%] text-sm font-bold text-black">
                Address:
              </h1>
              <h1 className=" w-[60%] text-sm font-normal text-black">
                {address.address_line1} {address.city}
              </h1>
            </div>
          </div>

          <div className="px-3 py-3 rounded-md">
            <div className=" w-full mb-2">
              <h1 className="text-sm font-bold text-black">Name</h1>
              <input
                type="text"
                placeholder="Name"
                value={updatedAddress.name}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      name: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className=" w-full mb-2">
              <h1 className="text-sm font-bold text-black">Contact Number:</h1>
              <input
                type="text"
                placeholder="Contact Number"
                value={updatedAddress.phone}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      phone: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className=" w-full mb-2">
              <h1 className="text-sm font-bold text-black">Full Address:</h1>
              <input
                type="text"
                placeholder="Full Address"
                value={updatedAddress.address_line1}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      address_line1: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className=" w-full mb-2">
              <h1 className="text-sm font-bold text-black">State:</h1>
              <input
                type="text"
                placeholder="State"
                value={updatedAddress.state}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      state: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className=" w-full mb-2">
              <h1 className="text-sm font-bold text-black">City:</h1>
              <input
                type="text"
                placeholder="City"
                value={updatedAddress.city}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      city: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className=" w-full mb-2">
              <h1 className="text-sm font-bold text-black">Pincode:</h1>
              <input
                type="text"
                placeholder="Pincode"
                value={updatedAddress.pincode}
                  onChange={(e) =>
                    setUpdatedAddress({
                      ...updatedAddress,
                      pincode: e.target.value,
                    })
                  }
                className=" w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
          </div>

          <div className=" w-full flex items-center gap-2 px-3 pb-4">
            <button
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: DELIVERY_LOCATION_MODAL,
                    modalTitle: ``,
                    modalSubTitle: "",
                  })
                );
              }}
              className=" w-full text-[14px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumfont"
            >
              Cancel
            </button>
            <button
              onClick={() => handleSave()}
              className=" w-full text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAddressModal;

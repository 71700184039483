import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import OfferCountered from "./OfferCountered";
import { useState } from "react";
import OfferCreatedModal from "./OfferCreatedModal";
import { useDispatch, useSelector } from "react-redux";
import { createOffer } from "../../redux/actions/product";
import { AiFillCloseCircle } from "react-icons/ai";
import { hideModal, showModal } from "../../redux/actions/modal";
import { SELECT_DEPOSIT_FOR_OFFER } from "../../extras/constants";

const CreateOfferModal = ({ onBack, productId }) => {
    const dispatch = useDispatch();
    const [isCreated, setIsCreated] = useState(false);
    const { modalProps } = useSelector((state) => state.modal);
    const [offerAmount, setOfferAmount] = useState(0);
    const [repeatedOffer, setRepeatedOffer] = useState(false);

    const handleCreate = () => {
        dispatch(
            showModal({
              modalType: SELECT_DEPOSIT_FOR_OFFER,
              modalTitle: ``,
              modalSubTitle: "",
              modalProps: modalProps,
            })
          );
    };

    const handleOnClose = () => {
        dispatch(hideModal());
    }

    if (isCreated) {
        return <OfferCreatedModal offerAmount={offerAmount} repeatedOffer={repeatedOffer} />;
    }

    return (
        <div>
            {/* Desktop */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div className="w-[529px] 2md:flex h-[376px] relative bg-stone-50 rounded-lg border border-black">
                    <button className="absolute top-0 right-0 z-10" onClick={() => handleOnClose()}>
                        <AiFillCloseCircle size={20} color="red" />
                    </button>
                    <div className="left-[24px] top-[20px] absolute text-center text-black text-[26px] mediumFont leading-10">
                        {modalProps?.title}
                    </div>
                    <div className="w-[484px] h-[64px] left-[394px] top-[24px] absolute text-[26px] font-bold text-[#004643]">
                        &#8377; {modalProps?.cost}
                    </div>
                    <div className="w-[484px] h-[64px] left-[24px] top-[46px] absolute">
                        <div className="w-[484px] h-[54px] left-0 top-[30px] absolute bg-white rounded border border-black"></div>
                        <div className="relative">
                            <input
                                type="number"
                                placeholder={`₹ ${modalProps?.cost} or less`}
                                onChange={(e) => setOfferAmount(e.target.value)}
                                className="left-[18px] top-[43px] absolute text-zinc-600 text-[20px] mediumFont leading-7 rounded focus:outline-none focus:border-none"
                            />
                            <button
                                onClick={handleCreate}
                                className="absolute right-[0px] top-[30px] h-[54px] px-4 bg-[#FFDC25] text-black text-[16px] mediumFont leading-tight rounded border border-black"
                            >
                                Make an Offer
                            </button>
                        </div>
                    </div>
                    <div className="w-[484px] h-[84px] left-[24px] top-[150px] absolute">
                        <div className="left-0 top-0 absolute text-black text-[20px] boldFont leading-snug">
                            Product Offer History
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="w-[340px] 2md:hidden h-[450px] relative bg-white rounded-md">
                <div className="w-full justify-center z-0 flex top-[32px] absolute text-center text-black text-[28px] boldfont leading-9">
                    {modalProps?.title}
                </div>
                <div
                    className="left-[20px] top-[35px] z-10 absolute text-gray-900 text-[30px] mediumFont leading-10 cursor-pointer"
                    onClick={onBack}
                >
                    <FaArrowLeft />
                </div>

                <div className="w-[310px] h-[84px] left-[16px] top-[250px] absolute">
                    <input
                        type="number"
                        placeholder="46,000.00 or Less"
                        className="left-0 h-[50px] border text-[20px] border-black top-[30px] w-[310px] absolute text-zinc-600 text-[20px] mediumFont leading-7 rounded focus:outline-none "
                    />
                    <div className="left-0 top-0 absolute text-stone-500 text-[18px] boldFont leading-snug">
                        Offer Amount
                    </div>
                </div>
                <div className="w-[310px] h-[50px] px-8 py-3 left-[16px] top-[340px] absolute bg-sky-600 rounded border border-black justify-center items-center gap-2.5 inline-flex">
                    <button
                        onClick={handleCreate}
                        className="text-white text-[16px] mediumfont leading-tight"
                    >
                        Create Offer
                    </button>
                </div>
                <div className="w-[310px] h-[84px] left-[16px] top-[150px] absolute">
                    <div className="left-0 top-0 absolute text-black text-[20px] boldFont leading-snug">
                        Product Offer History
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateOfferModal;
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { removeTokens } from "../../utils/auth/auth";

import { ACTIVE_LISTINGS, GET_CURRENT_USER, LISTING_OFFERS, LOGIN_USER, LOGOUT_USER, USER_CHATS, USER_NOTIFICATIONS, USER_OFFERS, USER_ORDERS } from "./types";
import { getUserCart } from "./cart";

export const signupUser = (signupData, setLoading, navigate) => {
  return function (dispatch) {
    axiosInstance
      .post(`/auth/register`, signupData)
      .then((res) => {
        const { message } = res.data;
        toast.success(message);

        setLoading(false);
        if (res.data.success || res.data.token)
          dispatch(completeLogin(res.data.token));
      })

      .catch(({ response }) => {
        setLoading(false);
        try {
          switch (response.data.message) {
            case "No need, already verified":
              //   add a toast
              toast.info("Already Verified! Login.");

              break;
            case "Email Already registered, otp sent again":
              //   add a toast
              toast.success("Already Registered! OTP Sent Again");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
};

export const completeLogin = (data) => {
  return function (dispatch) {
    if (data.token){
      localStorage.setItem("accessToken", data.token);
    }
    dispatch(getCurrentUser());
    dispatch(getUserNotifications());      
    dispatch({
      type: LOGIN_USER,
      payload: data,
    });
    toast.success("Logged in Successfully");
  };
};
export const getUserNotifications = () => {
  return function (dispatch) {
    axiosInstance
      .get(`/user/userNotifications`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: USER_NOTIFICATIONS,
          payload: data
        }); 
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              toast.error("Invalid Data!");

              break;
            case 401:
              toast.error("Invalid credentials!");

              break;
            default:
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
};



export const loginUser = (loginData, setLoading) => {
  return function (dispatch) {
    axiosInstance
      .post(`/auth/login`, loginData)
      .then((response) => {
        if (response.data.isSuccess || response.data.token){
          dispatch(completeLogin(response.data));
        }
        else toast.error("Invalid ID or Password!");
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
};

export const logoutUser = () => {
  return function (dispatch) {
    // remove stored tokens
    removeTokens();
    // LOGOUT_USER
    dispatch({
      type: LOGOUT_USER,
    });
    toast.success("Logged out successfully");
  };
};

export const getCurrentUser = () => {
  return function (dispatch) {
    axiosInstance.get(`/auth/getMe`).then((response) => {
      let data = response.data?.data;
      dispatch({
        type: GET_CURRENT_USER,
        payload: data,
      });
    });
    // dispatch current_user data
  };
};
//user address

// add address
export const addAddress = (address) => {
  return function (dispatch) {
    axiosInstance
      .post(`/auth/addAddress`, address)
      .then((res) => {
        const { data } = res.data;
        toast.success("New Address added!");
        dispatch(getCurrentUser());
      })

      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
}
// update address
export const updateAddress = (address) => {
  // the difference in this is we are sending address id in req.body
  console.log(address);
  return function (dispatch) {
    axiosInstance
      .post(`/auth/addAddress`, address)
      .then((res) => {
        const { data } = res.data;
        toast.success("Address Updated!");
        dispatch(getCurrentUser());
      })

      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
}

// remove address

export const userActiveListings = (id) => {
  return function (dispatch) {
    axiosInstance.get(`/product/productsBySeller?sort=asc`).
    then((response) => {
      let data = response.data?.data;
      dispatch({
        type: ACTIVE_LISTINGS,
        payload: data,
      });
    });
  };
};

export const getAllOffersBuyer = () => {
  return function (dispatch) {
    axiosInstance
      .get(`makeOffer/getAllOffersBuyer`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type : USER_OFFERS,
          payload : data,
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
  
              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");
  
              break;
            case 404:
              //   add a toast
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
  
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// getting user chats
export const getUserChats = () => {
  let url = `messaging`;
  return function (dispatch) {
    axiosInstance
      .get(url)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type : USER_CHATS,
          payload : data
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
  
              break;
            case 401:
              //   add a toast
              // toast.error("Invalid credentials!");  
              break;
            case 404:
              //   add a toast
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
  
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

// seller request
export const sellerRequest = (request) => {
  return function (dispatch) {
    axiosInstance
      .post(`/auth/sellerRequest`,request)
      .then((res) => {
        const { data } = res.data;
        toast.success("Request Sent to Admin!");
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
  
              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");
  
              break;
            case 404:
              //   add a toast
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
  
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}


// checking transaction status
export const transactionStatus = (transactionId) => {
  return function (dispatch) {
    axiosInstance
      .get(`/payment/transactionStatus/${transactionId}`)
      .then((res) => {
        const { data } = res.data;
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
  
              break;
            case 422:
              //   add a toast
              toast.error("Invalid credentials!");
  
              break;
            case 404:
              //   add a toast
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
  
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}

//getting user orders
export const userOrders = (transactionId) => {
  return function (dispatch) {
    axiosInstance
      .get(`/user/userOrders`)
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type : USER_ORDERS,
          payload: data
        })
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");
  
              break;
            case 422:
              //   add a toast
              toast.error("Invalid credentials!");
  
              break;
            case 404:
              //   add a toast
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
  
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  };
}
import {
  AccessTimeOutlined,
  LocationOnOutlined,
  Star,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import TabMenuListing from "../../components/TabMenuListing";
import { useState } from "react";
import { AiTwotoneStar } from "react-icons/ai";

const PreviewCard = ({ info, offerButton, width, focusedHighlight }) => {
  const { product } = useSelector((state) => state.product);

  const tabMenuContent = {
    Description: (
      <div className="flex flex-col space-y-2">
        <p className="overflow-y-auto">
          {product?.description || info?.description}
        </p>
      </div>
    ),
    "Item Specifics": (
      <div>
        {product?.specification &&
          <div style={{ display: "flex", justifyContent: "space-around" }} className="mt-3">
            <div
              className=""
              style={{ width: "50%", background: "#FCF9F4" }}
            >
              {Object.entries(product.specification).slice(0, 4).map(([key, value], index) => (
                <div
                  className={`flex flex-row justify-between px-2 py-1 border-b-[1px] border-l-[1px] border-r-[1px] border-black ${index === 0 ? 'border-t-[1px]' : ''}`}
                >
                  <h1 className="text-[10px] 3md:text-[12px] 4md:text-[12px]">
                    {key} :
                  </h1>
                  <h1 className="boldFont text-[10px] 3md:text-[12px] 4md:text-[12px]">
                    {value}
                  </h1>
                </div>
              ))}
            </div>
            <div
              className=""
              style={{ width: "50%", background: "#FCF9F4" }}
            >
              {Object.entries(product.specification).slice(4).map(([key, value], index) => (
                <div
                  className={`flex flex-row justify-between px-2 py-1 border-b-[1px] border-r-[1px] border-black ${index === 0 ? 'border-t-[1px]' : ''}`}
                  key={index}
                >
                  <h1 className="text-[10px] 3md:text-[12px] 4md:text-[12px]">
                    {key} :
                  </h1>
                  <h1 className="boldFont text-[10px] 3md:text-[12px] 4md:text-[12px]">
                    {value}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    ),
    "Buyers Guide": (
      <div className="" style={{ fontWeight: "400", fontSize: "12px" }}>
        <p>This will be updated by Admin</p>
      </div>
    ),
  };

  const [menu, setMenu] = useState(focusedHighlight ? focusedHighlight : 0);

  return (
    <div className=" h-max hidden lg:flex lg:flex-col flex-1 border-l-[0px] border-black pt-10 2xl:px-8 md:px-4">
      <div className="w-[90%] border-[1px] border-black h-3 mb-8 relative ml-auto mr-auto max-laptop:w-[100%]">
        <div
          className="h-full bg-[#00BEA7] absolute left-0"
          style={{ width: `${width}%` }} // Use the passed width prop to set the width dynamically
        ></div>
      </div>

      <div className="ml-4 mr-4">
        <div className="border-[1px] border-black">
          <div className=" border-b-[1px] border-black">
            <div className=" w-full py-1 bg-yellow-200 px-4 border-black flex items-center justify-between">
              <h1 className=" text-xs mediumFont ">
                Condition: {product?.condition || info?.condition}
              </h1>
            </div>
          </div>
          <div className=" flex items-center justify-between px-5 border-b-[1px] border-black">
            <div className=" py-3.5">
              <h1 className=" text-sm mediumFont">
                {product?.title || info?.title || "...."}
              </h1>
              <div className=" flex items-center gap-x-4">
                <div className="flex items-center gap-x-1 mt-1">
                  <LocationOnOutlined
                    className="text-grey-100"
                    fontSize={"15"}
                  />
                  <h1 className="text-sm font-normal text-grey-100">
                    ....
                  </h1>
                </div>
                <div className="flex items-center justify-start gap-x-1 mt-1">
                  <AccessTimeOutlined
                    className="text-grey-100"
                    fontSize={"10"}
                  />
                  <h1 className="text-[13px] mediumFont text-grey-100">
                  {product?.listing_duration || info?.listing_duration || "...."}
                  </h1>
                </div>
              </div>
            </div>
            <h1 className="text-sm boldFont text-sa-menu-green text-left py-1">
              ₹{product?.cost || info?.cost || "...."}
            </h1>
          </div>
          <div className="px-3.5 py-3 flex items-center justify-center gap-x-2">
            <div className="md:1/4 xl:w-1/5 ">
              <img
                src={require("../../assets/new design/grey.png")}
                alt=""
                className="w-[100%] h-[168px] object-cover"
              />
            </div>
            <div className="md:2/5 xl:w-1/4">
              <div className=" grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-2">
                {[0, 1, 2, 3].map((e) => (
                  <div>
                    <img
                      src={require("../../assets/new design/grey.png")}
                      alt=""
                      className="w-[100%] h-20"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2.5 flex justify-between items-start">
          <div className="flex-col w-[65%] border-l-[1px] border-r-[1px] border-t-[1px] border-b-[1px] border-black">
            <h1 className="bg-gray-400 py-2 px-2 text-[12px] boldFont border-b border-black">
              {" "}
              Highlights
            </h1>
            <div className="p-2">
              <TabMenuListing
                state={menu}
                setState={setMenu}
                items={tabMenuContent}
              />
            </div>
          </div>

          {/* Sold By */}
          <div className="w-[33%] min-h-[100px] h-auto border-x-[1px] border-y-[1px] border-black ">
            <h1 className=" text-[12px] boldFont pl-3 border-b-[1px] border-black py-2 bg-gray-400">
              Sold By
            </h1>
            <div className=" px-3 flex items-start justify-between mt-3">
              <div className=" flex items-start gap-x-1">
                <div className=" ">
                  <img
                    src={require("../../assets/new design/user.png")}
                    alt=""
                    className="w-[1.5vw] border-[1px] border-black rounded-full"
                  />
                </div>
                <div>
                  <h1 className="flex justify-left items-center text-[8px] underline underline-offset-2 boldFont">
                    Akshat Tripathi
                  </h1>
                  <h1 className="flex justify-left text-[6px] mediumFont text-grey-100 mt-0.5">
                    July 2022
                  </h1>
                </div>
              </div>

              <div className=" flex items-top justify-center">
                <div className=" flex items-center gap-x-0.5">
                  <h1 className=" text-[9px] boldFont">4.5</h1>
                  <AiTwotoneStar color="#FFD700" size={12} />
                </div>
              </div>
            </div>
            <div className=" px-3 pb-4.5 pt-2 flex-col space-y-2 items-center justify-between mt-2">
              <button className=" w-full text-[6px]  py-0.5 xl:py-1.5 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black tracking-wider  mediumFont">
                Add to Cart
              </button>
              {offerButton && (
                <button className=" w-full text-[6px]  py-0.5 xl:py-1.5 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black tracking-wider  mediumFont">
                  Make an Offer
                </button>
              )}
              <button className=" w-full text-[6px]  py-0.5 xl:py-1.5 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black tracking-wider  mediumFont">
                Contact Akshat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useState } from "react";
import OfferApproved from "./OfferApproved";
import { useDispatch } from "react-redux";
import {  acceptOfferbySeller } from "../../redux/actions/product";

const OfferApproveModal = ({ onBack,offer }) => {
  const dispatch = useDispatch();
  const [isApproved, setIsApproved] = useState(false);

  const handleApprove = () => {
    dispatch(acceptOfferbySeller(offer._id,offer.product));
    setIsApproved(true);
  };

  if (isApproved) {
    return <OfferApproved />;
  }

  return (
    <div>
      {/* desktop */}
      <div className="w-[529px] 2md:flex hidden h-[330px] relative bg-stone-50 rounded-lg border border border border border-black">
        <div className="left-[127px] top-[32px] absolute text-center text-black text-[42px] mediumFont leading-10">
          Approve Offer
        </div>
        <button
          onClick={handleApprove}
          className="w-[481px] h-[50px] px-8 py-3 left-[24px] top-[248px] absolute bg-[#139F00] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-[16px] mediumFont leading-tight">
            Approve
          </div>
        </button>
        <div className="w-[481px] h-[54px] left-[24px] top-[108px] absolute">
          <div className="w-[109px] h-[54px] left-0 top-0 absolute">
            <div className="left-0 top-[24px] absolute text-black text-[24px] font-normal leading-7">
              @{offer?.buyer?.username}
            </div>
            <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              From
            </div>
          </div>
          <div className="w-[140px] h-[54px] left-[341px] top-0 absolute">
            <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              Offer Amount
            </div>
            <div className="w-[140px] h-[30px] left-0 top-[24px] absolute bg-[#FEFFB2] justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-black text-[24px] boldFont leading-7">
              ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}/-
              </div>
            </div>
          </div>
        </div>
        <div className="w-[435px] left-[48px] top-[184px] absolute text-center text-black text-[16px] font-normal leading-tight">
          {`Once accepted, we’ll let @${offer?.buyer?.username} know. They’ll be required to pay
          within the next 48 hours.`}
        </div>
        <div
          onClick={onBack}
          className="left-[35px] top-[33px] absolute text-gray-900 text-[42px] cursor-pointer mediumFont leading-10"
        >
          <FaArrowLeft size={30} />
        </div>
      </div>
      {/* mobile */}
      <div class="w-[340px] 2md:hidden h-[450px] relative bg-white rounded-md">
        <div
          className="left-[20px] top-[35px] z-10 absolute text-gray-900 text-[30px] mediumFont leading-10 cursor-pointer"
          onClick={onBack}
        >
          <FaArrowLeft />
        </div>
        <div class="w-[310px] h-10 left-[16px] top-[238px] absolute text-center text-black text-[16px] font-normal leading-tight">
          70% sellers accept a counter offer from a buyer. Make a counter offer
          to make a deal!
        </div>
        <div class="w-full flex justify-center z-0 top-[32px] absolute text-center text-black text-[28px] boldFont leading-9">
          Approve Offer
        </div>

        <div class="left-[16px] top-[88px] absolute">
          <div class="left-0 top-[25px] absolute text-black text-[24px] font-normal leading-7">
          @{offer?.buyer?.username}
          </div>
          <div class="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
            From
          </div>
        </div>
        <div class="w-full h-[57px]  top-[163px] absolute">
          <div class="flex w-full justify-center top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
            Offer Amount
          </div>
          <div class="w-full flex justify-center h-[35px] top-[22px] absolute   items-center gap-2.5 ">
            <div class="text-center text-black bg-yellow-100 text-[28px] boldFont leading-9">
            ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}
            </div>
          </div>
        </div>
        <div class="w-[310px] h-[50px] px-8 py-3 left-[16px] top-[302px] absolute bg-green-700 rounded border border border border border-black justify-center items-center gap-2.5 inline-flex">
          <button
            onClick={handleApprove}
            class="text-white text-[16px] mediumFont leading-tight"
          >
            Approve Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferApproveModal;

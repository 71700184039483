import {
  AccessTimeOutlined,
  Favorite,
  LocationOnOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import TagRibbon from "../TagRibbon";
import { useDispatch } from "react-redux";
import { ACCEPT_OFFER_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";

const ProductCardMob = ({ isBuying, data, remove }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!data)
    data = {
      id: 1,
      seller: "@AkshatTripathi",
      title: "Hyundai Venue",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      shipping: "200.00",
      deliverydate: "12 Dec 2022",
      price: "₹4,60,000",
      acceptprice: "₹4,00,000",
      img: require("../../assets/new design/car/car_img2.png"),
      location: " Ashok Vihar Delhi",
      year: "2019",
      time: "02d 12hr",
      distance: "5000 KMS",
      ownership: "1st",
    };
  const toggleToWishlist = () => {
    // code to add to wishlist
  };
  return (
    <div className=" max-w-[1023px] bg-white border-[1px] border-black buttonHover rounded relative mb-5">
      {isBuying && (
        <div className="border-b-[1px] border-black flex items-center">
          <div className="border-r-[1px] border-black w-[50%]">
            <h1 className="text-2xl boldFont text-black text-center py-2 pl-3">
              {data.acceptprice}
            </h1>
          </div>
          <div
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ACCEPT_OFFER_MODAL,
                  modalTitle: ``,
                  modalSubTitle: "",
                  modalProps: data,
                })
              );
            }}
            className="bg-sa-success-green justify-center  w-[50%] text-center"
          >
            <h1 className="text-md leading-8 underline underline-offset-4 decoration-1 boldFont text-white text-center py-2 pl-3">
              Accepted
            </h1>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          if (!remove) navigate("/product2");
        }}
        className="flex overflow-hidden h-auto aspect-auto"
      >
        <div
          className={`flex absolute ${
            isBuying ? "top-14" : "top-3"
          } -left-2 rounded-md`}
        >
          <TagRibbon
            textClasses={"text-[12px] boldFont text-black text-center"}
            tagText="NEW"
            bgColor={"#B8E1FF"}
          />
        </div>
        {/* img div */}
        <div className="w-[47%] h-[190px]  rounded-sm rounded-r-none border-r-[1px] border-black overflow-hidden">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={data.img}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        <div className=" flex-1 w-[53%] flex-col justify-center overflow-hidden">
          {/* location and name */}
          <div className="px-1.5 sm:h-1/4 h-[45%]">
            <div className="flex items-center mt-2">
              <LocationOnOutlined
                className="text-sa-icon-green"
                fontSize="12"
              />
              <h1 className="text-[14px] pl-[6.3px] pb-0 text-sa-text-gray">
                {data.location}
              </h1>
            </div>
            <h1 className="sm:text-[18px] text-[16px] pt-[2px] text-black">
              {`${data.title} (${data.year})`}
            </h1>
          </div>
          {/* price and time */}
          <div className="  w-full border-l-[0px] sm:h-1/2  h-[27%]  border-black flex items-center">
            <div className="border-[1px] sm:h-[35px] h-[30px] flex-col justify-center flex border-l-[0px] w-[52%] border-black">
              <h1 className="text-[16px]  sm:text-[18px] boldFont text-sa-menu-green text-left py-auto pr-2 pl-1">
                {data.price}
              </h1>
            </div>
            <div className="flex w-[48%] border-y-[1px] h-[30px] sm:h-[35px] py-auto border-black items-center gap-x-1 pl-1">
              <AccessTimeOutlined className="text-black" fontSize={"10"} />
              <h1 className="text-[14px] text-black">{data.time}</h1>
            </div>
          </div>
          {/* distance and ownership */}
          <div className="flex items-center sm:h-1/4 h-[28%] justify-between pl-[8px] pb-2 ">
            <div className="flex w-full items-center ">
              {!remove && (
                <div>
                  <h1 className="sm:text-[12px] text-[10px] text-sa-light-brown">
                    {data.distance.split(" ")[1]}
                  </h1>
                  <h1 className="sm:text-[16px] text-[14px] font-black ">
                    {data.distance.split(" ")[0]}
                  </h1>
                </div>
              )}
              <div className="pl-[29px]">
                <h1 className="sm:text-[12px] text-[10px]  text-sa-light-brown">
                  OWNERSHIP
                </h1>
                <h1 className="sm:text-[16px] text-[14px] font-black ">
                  {data.ownership}
                </h1>
              </div>
              {remove && (
                <div
                  onClick={() => {
                    if (remove) remove(data);
                    else toggleToWishlist();
                  }}
                  className="ml-auto bg-white border-[1px]  border-black buttonHover rounded-md p-2"
                >
                  <Favorite className="text-sa-brick-red " />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardMob;

import React, { useState } from "react";
import OfferReceivedModal from "../components/Modals/OfferReceivedModal";
import { getProductOffers } from "../redux/actions/product";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const OffersComponent = ({productId}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const { products } = useSelector((state) => state.product);
  const listing = products?.find(item => item.id === productId);
  const offers = listing?.offers;
  const {user} = useSelector((state) => state.auth);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  useEffect(() => {
    dispatch(getProductOffers(productId));
  },[])

  const timeFromNow = (createdAt) => {
    const currentTime = new Date();
    const createdAtDate = new Date(createdAt);
    const timeDifference = currentTime - createdAtDate;

    // Calculate hours and days
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else {
      return "Just now";
    }
  };

  const handleViewOffer = (offer) => {
    setSelectedOffer(offer);
    setShowModal(true);
  };

  return (
    <div>
      {offers ? (
        <>
          {showModal && (
            <OfferReceivedModal onClose={handleCloseModal} offer={selectedOffer} productId={productId}/>
          )}
          {/* desktop */}
          <div className="hidden md:flex flex-col">
            <div className="py-4">
              {/* Table header */}
              <div className="flex rounded px-4 pb-4 text-[16px]">
                <div className="w-1/4 mediumFont">Buyer</div>
                <div className="w-1/4 mediumFont flex justify-center">
                  Offer (Price in Rs)
                </div>
                <div className="w-1/4 mediumFont flex justify-center">Time</div>
                <div className="w-1/4 mediumFont flex justify-center">Status</div>
                <div className="w-1/4 mediumFont"></div>
              </div>
              {/* Mapping over offers */}
              {offers.map((offer, index) => (
                <div
                  key={index}
                  className={`flex text-[16px] border-x border-t ${index === offers.length - 1
                    ? "border-b"
                    : "border-b-0"
                    } ${index === 0 ? "rounded-t" : "rounded-t-none"} border-black p-4 ${
                      offer.status === "Declined" ? "bg-[#D1503B]" : 
                      offer.status === "Accepted" ? "bg-sa-success-green" :
                      offer.status === "Counter_Offer" ? "bg-blue-300" :
                      offer.status === "Closed" ? "bg-[#D1503B]":
                      "bg-white"
                    }`}
                >
                  <div className="w-1/4 flex flex-col justify-center">
                    <div>{offer?.buyer?.first_name}</div>
                    <div>(@{offer?.buyer?.username})</div>
                  </div>
                  <div className="w-1/4 text-[22px] boldFont flex items-center flex-col justify-center">
                    ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}
                  </div>
                  <div className="w-1/4 flex flex-col items-center justify-center">
                    {timeFromNow(offer?.createdAt)}
                  </div>
                  <div className="w-1/4 flex flex-col items-center justify-center">
                    {offer?.status === "Counter_Offer" &&  offer?.offer[offer?.offer?.length - 1]?.user === user.id 
                    ? <p>Counter Offer Sent</p> : offer?.status === "Counter_Offer" 
                    ? <p>Counter Offer Recieved</p> : offer?.status}
                  </div>
                  {offer.status !== "Declined" && offer.status !=="Accepted" && offer.status !== "Counter_Offer" ? (
                    <div className="w-1/4 flex items-center justify-center">
                      <button
                        onClick={() => handleViewOffer(offer)}
                        className="text-black underline underline-offset-1 boldFont py-2 px-4 rounded"
                      >
                        View Offer
                      </button>
                    </div>
                  ) : offer?.offer[offer?.offer?.length - 1]?.user === user.id  ? (<div className="w-1/4 flex items-center justify-center font-bold ">Sent to Buyer</div>) : (<div className="w-1/4 flex items-center justify-center font-bold ">Buyer Notified</div>)}
                </div>
              ))}
            </div>
          </div>
          {/* mobile */}
          <div className="px-10 md:hidden py-4">
            {/* Mapping over offers */}
            {offers.map((offer, index) => (
              <div
                onClick={() => handleViewOffer(offer)}
                key={index}
                className={`flex text-base bg-white rounded border-x border-t ${index === offers.length - 1
                  ? "border-b"
                  : "border-b-0 rounded-b-none"
                  } ${index === 0 ? "rounded-t" : "rounded-t-none"} border-black p-4`}
              >
                <div>(@{offer?.buyer?.username})</div>
                <div className="w-[40%] flex justify-center text-lg boldFont">
                  {offer?.offer[offer?.offer?.length - 1]?.amount}
                </div>
                <div className="w-[30%]">{timeFromNow(offer?.createdAt)}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="hidden md:flex flex-col text-[72px] font-bold">No offers Yet!</div>
      )}
    </div>
  );
};

export default OffersComponent;

import React, { useEffect } from "react";
import SideMenu from "../components/Buying/SideMenu";
import { useState } from "react";
import { AccessTimeFilledOutlined, Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserListingCard from "../components/UserListingCard";
import { approveListing, sendChanges } from "../redux/actions/admin";
import { PhotographIcon, UserCircleIcon } from "@heroicons/react/outline";
import ImageGalleryTest from "../components/ImageGalleryTest";
import { clearProductImages, getProductCompressedImages } from "../redux/actions/product";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";

const UserListingAdminEditingScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const offerTabs = ["All Active", "Local", "Shipment", "Pending Offers"];
    const [fontSize, setFontSize] = useState("text-4xl");
    const [activeTab, setActiveTab] = useState("All Active");
    const { listingId } = useParams();
    const { products } = useSelector((state) => state.product);
    const [error, setError] = useState("");

    const listing = products?.find(item => item.id === listingId);
    const [newListing, setnewListing] = useState({
        condition: "",
        title: "",
        cost: "",
        category: "",
        description: "",
        specifications: "",
    });
    const [phonesidenav, showphonesidenav] = useState(true);
    const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
    const [showsort, setshowsort] = useState(false);
    const [sorttype, setsorttype] = useState(0);

    useEffect(() => {
        dispatch(getProductCompressedImages(listing.images));
        return () => {
            dispatch(clearProductImages());
        };
    }, [dispatch])
    const { images } = useSelector((state) => state.product)

    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 0) {
            setFontSize("text-xl");
        } else {
            setFontSize("text-4xl");
        }
    };
    console.log(listing.id);

    const handleChanges = () => {
        const request = {
            ...newListing,
            userId : listing?.seller?.id,
            product : listing?.title,
            userEmail : listing?.seller?.email
        }
        // console.log(request);
        axiosInstance
            .post(`admin/sendChanges/${listingId}`, request)
            .then((res) => {
                const { data } = res.data;
                toast.success("Changes Sent!");
                navigate(-1);
            })
            .catch(({ response }) => {
                try {
                    switch (response.status) {
                        default:
                            // server error
                            toast.error("Oops, something went wrong");

                            break;
                    }
                } catch (e) {
                    toast.error("Couldn't reach the server");
                }
            })
    };

    return (
        <div>
            {/* desktop */}
            <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
                <div className="self-stretch">
                    <SideMenu />
                </div>
                <div className="flex-1 min-h-screen overflow-hidden">
                    <div
                        className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                            }`}
                    >
                        <h1
                            className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
                        >
                            Listing : {listing.id}
                        </h1>
                    </div>
                    <div
                        onScroll={handleScroll}
                        className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 overflow-y-auto overflow-x-hidden"
                    >
                        <div className="border-2 border-black rounded-md bg-[#FCF9F4]">

                            <div className="bg-white px-5 py-5 border-r-[1px] border-l-[1px] border-b-[1px] border-black">
                                {images ? <ImageGalleryTest productImages={images} /> :
                                    <div>LOADING</div>}
                            </div>
                            {/* <div className="overflow-y-scroll p-1 no-scrollbar">
                                <div
                                    className={`px-3 py-3 border-b-2 border-black transition-all duration-800 ease mb-4`}
                                >   
                                    <div className=" flex items-center">
                                        <div className=" flex-1 flex items-center">
                                            <h1 className=" w-[25%] text-lg font-bold text-black">
                                                Condition
                                            </h1>
                                            <h1 className=" w-[25%] text-lg font-normal text-black ml-4">
                                                {listing?.condition}
                                            </h1>
                                        </div>
                                        <button
                                        onClick={() => {
                                        }}
                                        className=" text-[14px] py-1.5 px-3 rounded-[4px] bg-white text-black border-[1px] border-sa-border-black font-bold"
                                    >
                                        Edit
                                    </button>
                                    </div>
                                    <div className=" flex items-center">
                                        <h1 className="w-[25%] text-lg font-bold text-black">
                                            Title
                                        </h1>
                                        <h1 className=" w-[50%] text-lg font-normal text-black">
                                            {listing?.title}
                                        </h1>
                                    </div>
                                    <div className=" flex items-center">
                                        <h1 className="w-[25%] text-lg font-bold text-black">
                                            Cost
                                        </h1>
                                        <h1 className=" w-[50%] text-lg font-normal text-black">
                                            {listing?.cost}
                                        </h1>
                                    </div>
                                    <div className=" flex items-center">
                                        <h1 className="w-[25%] text-lg font-bold text-black">
                                            Category:
                                        </h1>
                                        <h1 className=" w-[50%] text-lg font-normal text-black">
                                            {listing?.category?.name}
                                        </h1>
                                        <h1 className="w-[25%] text-lg font-bold text-black">
                                            Description:
                                        </h1>
                                        <h1 className=" w-[50%] text-lg font-normal text-black">
                                            {listing?.description}
                                        </h1>
                                        <h1 className="w-[25%] text-lg font-bold text-black">
                                            Specificaiton:
                                        </h1>
                                        <h1 className=" w-[50%] text-lg font-normal text-black">
                                            {listing?.pincode}
                                        </h1>
                                    </div>
                                </div>
                            </div> */}


                            <div className="px-3 py-3 border-b-2 border-black">
                                <div className="flex items-start mb-1 pb-2 border-b-2 border-black">
                                    <h1 className="w-[40%] text-lg font-bold text-black">Detail</h1>
                                    <h1 className="w-[40%] text-lg font-bold text-black">Old</h1>
                                    <h1 className="w-[40%] text-lg font-bold text-black">New</h1>
                                </div>
                                <div className="flex items-start mb-1">
                                    <h1 className="w-[40%] text-lg font-bold text-black">Condition:</h1>
                                    <h1 className="w-[40%] text-lg font-normal text-black">{listing?.condition}</h1>
                                    <input
                                        type="text"
                                        placeholder="Condition"
                                        value={newListing?.condition}
                                        onChange={(e) =>
                                            setnewListing({
                                                ...newListing,
                                                condition: e.target.value,
                                            })
                                        }
                                        className="w-[40%] text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                                    />
                                </div>
                                <div className="flex items-start mb-1">
                                    <h1 className="w-[40%] text-lg font-bold text-black">
                                        Title:
                                    </h1>
                                    <h1 className="w-[40%] text-lg font-normal text-black">
                                        {listing?.title}
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="Title"
                                        value={newListing?.title}
                                        onChange={(e) =>
                                            setnewListing({
                                                ...newListing,
                                                title: e.target.value,
                                            })
                                        }
                                        className="w-[40%] text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                                    />
                                </div>
                                <div className="flex items-start mb-1">
                                    <h1 className="w-[40%] text-lg font-bold text-black">Cost:</h1>
                                    <h1 className="w-[40%] text-lg font-normal text-black">₹{listing.cost}</h1>
                                    <input
                                        type="text"
                                        placeholder="Cost"
                                        value={newListing?.cost}
                                        onChange={(e) =>
                                            setnewListing({
                                                ...newListing,
                                                cost: e.target.value,
                                            })
                                        }
                                        className="w-[40%] text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                                    />
                                </div>
                                <div className="flex items-start mb-1">
                                    <h1 className="w-[40%] text-lg font-bold text-black">Category:</h1>
                                    <h1 className="w-[40%] text-lg font-normal text-black">{listing?.category?.name}</h1>
                                    <input
                                        type="text"
                                        placeholder="Category"
                                        value={newListing?.category}
                                        onChange={(e) =>
                                            setnewListing({
                                                ...newListing,
                                                category: e.target.value,
                                            })
                                        }
                                        className="w-[40%] text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                                    />
                                </div>
                                <div className="flex items-start mb-1">
                                    <h1 className="w-[40%] text-lg font-bold text-black">Description:</h1>
                                    <h1 className="w-[40%] text-lg font-normal text-black">{listing?.description}</h1>
                                    <input
                                        type="text"
                                        placeholder="Description"
                                        value={newListing?.description}
                                        onChange={(e) =>
                                            setnewListing({
                                                ...newListing,
                                                description: e.target.value,
                                            })
                                        }
                                        className="w-[40%] text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                                    />
                                </div>
                                {/* <div className="flex items-start mb-1">
                                    <h1 className="w-[40%] text-lg font-bold text-black">Specifications:</h1>
                                    {listing?.specification && listing?.specification.map((item) => (
                                        <>
                                            <h1 className="w-[40%] text-lg font-bold text-black">{item[0]}</h1>
                                            <input
                                                type="text"
                                                placeholder="Specifications"
                                                value={newListing?.specifications}
                                                onChange={(e) =>
                                                    setnewListing({
                                                        ...newListing,
                                                        specifications: e.target.value,
                                                    })
                                                }
                                                className="w-[40%] text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
                                            />
                                        </>
                                    ))}
                                </div> */}
                                {error && (
                                    <p className="text-red-500 text-lg mt-2">{error}</p>
                                )}
                            </div>

                            <div className="px-3 py-3 rounded-md">
                                <div className="w-full flex items-center gap-2 justify-end">
                                    <button
                                        onClick={() => { }}
                                        className="text-[14px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumfont hover:cursor-pointer"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => handleChanges()}
                                        className="text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont hover:cursor-pointer"
                                    >
                                        Send Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile */}
            <div className=" bg-[#FCF9F4] min-h-screen pb-28 2md:hidden">
                <div
                    className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
                        } overflow-hidden`}
                >
                    <SideMenu />
                </div>
                <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
                    <h1 className="text-[26px] mediumFont text-black tracking-wide">
                        Listing : {listing.id}
                    </h1>
                </div>





                <div className="fixed  z-50 bottom-0 w-full grid grid-cols-[100%]">
                    <div
                        className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
                            }`}
                    >
                        <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
                        <div
                            onClick={() => {
                                setshowsort(!showsort);
                            }}
                            className=" absolute top-4 right-3"
                        >
                            <Cancel className="text-base " />
                        </div>
                        {sortarray.map((type, i) => (
                            <button
                                className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                                    }`}
                                onClick={() => {
                                    setsorttype(i);
                                }}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                    <div className="grid grid-cols-[50%_50%]">
                        <button
                            onClick={() => {
                                showphonesidenav(!phonesidenav);
                            }}
                            className={` w-full text-base py-2 ${phonesidenav
                                ? "bg-black text-sa-primary-yellow"
                                : "bg-sa-primary-yellow text-black"
                                } border-[1px] border-sa-border-black mediumFont`}
                        >
                            SideMenu
                        </button>
                        <button
                            onClick={() => {
                                setshowsort(!showsort);
                            }}
                            className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
                        >
                            Sort: {sortarray[sorttype]}
                        </button>
                    </div>
                </div>
            </div>




        </div>
    );
};

export default UserListingAdminEditingScreen;
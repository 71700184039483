import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";


export const handleShipmentCost = async (product,buyerPincode,setShipmentDetails) => {
    const data = {
      pickup_postcode: product?.product_address?.pincode, // listing
      delivery_postcode: buyerPincode,
      weight: product?.category?.weight,  // category
      cod: 0,
      declared_value: product?.cost,  // listing // max value 50 lakh
      rate_calculator: 1,
      blocked: 1,
      is_return: 0,
      is_web: 1,
      is_dg: 0,
      length: product?.category?.length,  // category
      breadth: product?.category?.breadth, // category
      height: product?.category?.height   // category
    }

    let result = {};
    await axiosInstance
      .get(`shipRocket/calculate-cost?pickup_postcode=${data.pickup_postcode}&delivery_postcode=${data.delivery_postcode}&weight=${data.weight}&cod=${data.cod}&declared_value=${data.declared_value}&rate_calculator=${data.rate_calculator}&blocked=${data.blocked}&is_return=${data.is_return}&is_web=${data.is_web}&is_dg=${data.is_dg}&length=${data.length}&breadth=${data.breadth}&height=${data.height}`)
      .then((res) => {
        result = res.data.data;
        setShipmentDetails({
          Delivery_Fee: result.freight_charge,
          ETD: result.etd
        });
      })
      .catch(({ response }) => {
        console.log(response);
        try {
          switch (response.data.error) {
            case "Delivery to this pincode is currently not available.":
              //   add a toast
              setShipmentDetails({
                Message: "Delivery to this pincode is currently not available."
              });
              toast.info("Delivery to this pincode is currently not available.");
              break;
            case "Delivery to this pincode is currently not available..":
              //   add a toast
              setShipmentDetails({
                Message: "Delivery to this pincode is currently not available."
              });
              toast.info("Delivery to this pincode is currently not available.");
              break;
            default:
              // server error
              toast.error("Oops, something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })

  };
// export const handleShipmentCostUser = async (product) => {
//     const data = {
//       pickup_postcode: product?.product_address?.pincode, // listing
//       delivery_postcode: buyerPincode,
//       weight: product?.category?.weight,  // category
//       cod: 0,
//       declared_value: product?.cost,  // listing // max value 50 lakh
//       rate_calculator: 1,
//       blocked: 1,
//       is_return: 0,
//       is_web: 1,
//       is_dg: 0,
//       length: product?.category?.length,  // category
//       breadth: product?.category?.breadth, // category
//       height: product?.category?.height   // category
//     }

//     let result = {};
//     await axiosInstance
//       .get(`shipRocket/calculate-cost?pickup_postcode=${data.pickup_postcode}&delivery_postcode=${data.delivery_postcode}&weight=${data.weight}&cod=${data.cod}&declared_value=${data.declared_value}&rate_calculator=${data.rate_calculator}&blocked=${data.blocked}&is_return=${data.is_return}&is_web=${data.is_web}&is_dg=${data.is_dg}&length=${data.length}&breadth=${data.breadth}&height=${data.height}`)
//       .then((res) => {
//         result = res.data.data;
//         setShipmentDetails({
//           Delivery_Fee: result.freight_charge,
//           ETD: result.etd
//         });
//       })
//       .catch(({ response }) => {
//         try {
//           switch (response.data.error) {
//             case "Delivery to this pincode is currently not available.":
//               //   add a toast
//               setShipmentDetails({
//                 Message: "Delivery to this pincode is currently not available."
//               });
//               toast.info("Delivery to this pincode is currently not available.");
//               break;
//             default:
//               // server error
//               toast.error("Oops, something went wrong");
//               break;
//           }
//         } catch (e) {
//           toast.error("Couldn't reach the server");
//         }
//       })

//   };

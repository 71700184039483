import React from "react";
import smiley from "../../assets/icons/smiley.svg";

const OfferCountered = () => {
  return (
    <div>
      {/* desktop */}
      <div class="w-[529px] 2md:flex hidden h-[271px] relative bg-stone-50 rounded-lg border border border border border-black">
        <div class="left-[134px] top-[32px] absolute text-center text-sky-600 text-[42px] font-medium leading-10">
          Counter Sent
        </div>

        <div class="w-[435px] left-[47px] top-[92px] absolute text-center text-black text-[16px] font-normal leading-tight">
          70% buyers accept a counter offer from a seller. Make a counter offer
          to make a deal!
        </div>
        <div className="w-[100px] h-[100px] absolute left-1/2 top-[200px] transform -translate-x-1/2 -translate-y-1/2">
          <img src={smiley} alt="Smiley" />
        </div>
      </div>
      {/* mobile */}
      <div class="w-[340px] 2md:hidden h-[290px] relative bg-white rounded-md">
        <div class="w-[300px] h-10 left-[16px] top-[78px] absolute text-center text-black text-[18px] font-normal leading-tight">
          70% buyers accept a counter offer from a seller. Make a counter offer
          to make a deal!
        </div>
        <div class="w-full flex justify-center top-[32px] absolute text-center text-[#0167DE] text-[28px] boldFont leading-9">
          Counter Sent
        </div>
        <div className="w-full justify-center flex h-[100px] absolute  top-[220px] transform -translate-y-1/2">
          <img src={smiley} alt="Smiley" />
        </div>
      </div>
    </div>
  );
};

export default OfferCountered;

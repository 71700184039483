import { ExpandMore, Cancel, ExpandLess } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Accourdion from "../components/Accourdion";
import SideMenu from "../components/Buying/SideMenu";
import TagRibbon from "../components/TagRibbon";
import {
  CONTACT_SELLER_MODAL,
  REPORT_MODAL,
  REVIEW_MODAL,
} from "../extras/constants";
import { showModal } from "../redux/actions/modal";
import { useNavigate } from "react-router";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import { Link } from "react-router-dom";
import { userOrders } from "../redux/actions/auth";
import ReactLoading from 'react-loading';

const PurchaseHistory = ({ showModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fontSize, setFontSize] = useState("text-4xl");
  const [activeTab, setActiveTab] = useState("All Purchases");
  const [phonesidenav, showphonesidenav] = useState(true);
  const [showsort, setshowsort] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sorttype, setsorttype] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const { products } = useSelector((state) => state.product);
  const { orders } = user || {};
  const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];

  useEffect(() => {
    dispatch(userOrders());
    setLoading(false);
  }, [])

  const purchaseHistoryTabs = [
    "All Purchases",
    "Shipped",
    "Pending",
    "Returned",
    "Failed",
  ];
  const data = [
    {
      title: "DeepCool PM850D Power Supply Including shipping",
      desc: "Interested Buyers Contact Me Shipping All Over India",
      price: "3,000",
      img: require("../assets/new design/fan_img.png"),
    },
  ];

  const calculatePurchaseDate = (paidAt) => {
    const paidDate = new Date(paidAt);
    return `${paidDate.getDate()} ${paidDate.toLocaleString("en-us", {
      month: "long",
    })} ${paidDate.getFullYear()}`;
  };


  const handleScroll = (event) => {
    console.log(event.currentTarget.scrollTop);
    if (event.currentTarget.scrollTop > 0) {
      setFontSize("text-xl");
    } else {
      setFontSize("text-4xl");
    }
  };

  return (
    <div>
      {loading && 
      <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
        <div className="self-stretch">
          <SideMenu />
        </div>
        <div className="w-full  overflow-hidden bg-[#FCF9F4] item-center">
          <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
        </div>
      </div>}

      {/* desktop */}
      {!loading && user && orders && orders.length > 0 ?
        <div className="hidden 2md:flex items-start bg-[#FCF9F4] min-h-screen">
          <div className="self-stretch">
            <SideMenu />
          </div>
          <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
            <div
              className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                }`}
            >
              <h1
                className={`${fontSize} transition-all duration-300 ease mediumFont text-black`}
              >
                Purchase History
              </h1>
              <div
                className={`${fontSize === "text-4xl" ? "mt-5" : "mt-2"
                  } transition-all duration-300 ease-in-out flex items-center justify-between`}
              >
                <div className="flex items-center flex-wrap max-w-[600px] mr-10 grow justify-between">
                  {purchaseHistoryTabs.map(function (e, i) {
                    return (
                      <button
                        onClick={() => setActiveTab(e)}
                        key={i}
                        className={`px-5 text-[18px] mt-2.5 py-2  rounded-md ${activeTab === e
                          ? "bg-white border-[1px] border-sa-border-black"
                          : "bg-[#FCF9F4]  border-[1px] border-[#FCF9F4]"
                          } text-black mediumFont flex items-center justify-center`}
                      >
                        {e}
                      </button>
                    );
                  })}
                </div>
                <div className="relative z-20 w-max place-items-center">
                  <button
                    onClick={() => {
                      setshowsort(!showsort);
                    }}
                    className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
                  ${showsort ? "rounded rounded-b-none border-b-0" : "rounded"} 
                  text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}
                  >
                    Sort: {sortarray[sorttype]} &nbsp;
                    {!showsort ? <ExpandMore /> : <ExpandLess />}
                  </button>
                  <div
                    className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort
                      ? "max-h-[100vh] border-[1px]"
                      : "max-h-0 border-[0px]"
                      }`}
                  >
                    {sortarray.map((type, i) => (
                      <div
                        key={`cat${i}`}
                        onClick={() => {
                          setsorttype(i);
                          setshowsort(!showsort);
                        }}
                        className="mt-2.5 py-2 px-5 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3"
                      >
                        <p>{type}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="pl-12 pr-16 pt-9 h-screen overflow-y-auto"
              onScroll={handleScroll}
            >
              {orders && orders.map((order, index) => (
                <div className="border-[1px] border-black mb-7" key={index}>
                  <div className="py-3 border-b-[1px] border-black px-6 flex items-center justify-between">
                    <div className="flex items-center gap-x-16">
                      <h1 className="text-[17px] font-normal text-black tracking-wide">
                        Order No:{" "}
                        <span className="mediumFont text-[#1E44CD] ml-2 underline underline-offset-2">
                          #{order?._id}
                        </span>
                      </h1>
                      <h1 className="text-[17px] font-normal text-black tracking-wide">
                        Purchase Date:
                        <span className="mediumFont text-black ml-2 ">
                          {calculatePurchaseDate(order?.paid_at)}
                        </span>
                      </h1>
                    </div>
                    <h1 className="text-[17px] font-normal text-black tracking-wide">
                      Order Total:
                      <span className="mediumFont text-black ml-2">₹{order?.amount}/-</span>
                    </h1>
                  </div>
                  <div className="w-full">
                    {order && products && order?.order_items.map((productId, index) => {
                      const product = products.find(
                        (product) => product?.id === productId
                      );
                      return (
                        <div
                          key={index}
                          className={`mx-3.5 py-4.5 flex items-start ${data.length - 1 === index ? "" : "border-b-[1.5px]"
                            }   border-black 2xl:mx-4 relative`}
                        >
                          <div className="flex absolute top-6 -left-2 rounded-sm">
                            {/* <TagRibbon
    textClasses="text-[14px] mediumFont text-black text-center"
    tagText="USED"
    bgColor="#FFDC25"
  /> */}
                            <div className="relative flex flex-col w-[78px] h-[35px]">
                              <div className="mb-2">
                                <img
                                  src={require("../assets/icons/main.svg").default}
                                  alt="Main"
                                  className="w-full h-full relative z-10 fill-#FFDC25"
                                />
                              </div>

                              <div className="absolute -translate-y-[1px] inset-0 flex items-center justify-center">
                                <img
                                  src={
                                    require("../assets/icons/shadow.svg").default
                                  }
                                  alt="Shadow"
                                  className="w-full h-full absolute z-0"
                                />
                              </div>
                            </div>

                            <h3 className="boldFont ml-[-65px] mt-[2px] z-30">
                              {product?.condition}
                            </h3>
                          </div>
                          <div className=" w-[21%] max-w-[180px] border-[2px] border-black rounded-md ">
                            <img
                              src={product?.thumbnail}
                              alt=""
                              className="w-full aspect-square object-cover h-[10.9rem]"
                            />
                          </div>
                          <div className=" ml-4 self-stretch flex-1 flex flex-col justify-between 2xl:mt-1">
                            <div className="flex items-start justify-between w-full pr-2 h-full">
                              <div className="flex flex-col justify-between self-stretch">
                                <div>
                                  <h1 className="text-lg mediumFont 2xl:text-[22px]">
                                    {product?.title}
                                  </h1>
                                  <h1 className="text-lg mediumFont 2xl:text-[22px]">
                                    ₹{product?.cost}/-
                                  </h1>
                                </div>
                                <div>
                                  <button className="bg-sa-success-green text-base px-4 py-1 rounded text-white mb-2.5">
                                    Shipped
                                  </button>
                                  <div className="flex items-center gap-x-12">
                                    <h1 className="text-base mediumFont text-black">
                                      Status:{" "}
                                      <span className="mediumFont text-black ml-2 ">
                                        Left facility, in t...
                                      </span>
                                    </h1>
                                    <h1 className="text-base mediumFont text-black">
                                      Track ID:{" "}
                                      <span className="mediumFont text-[#1E44CD] ml-2 underline underline-offset-2">
                                        10007363782
                                      </span>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col justify-between self-stretch">
                                <button
                                  onClick={() => {
                                    showModal({
                                      modalType: CONTACT_SELLER_MODAL,
                                      modalTitle: ``,
                                      modalSubTitle: "",
                                    });
                                  }}
                                  className="text-[14px] py-3 px-8 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                                >
                                  Contact Seller
                                </button>
                                <button
                                  onClick={() => {
                                    showModal({
                                      modalType: REVIEW_MODAL,
                                      modalTitle: ``,
                                      modalSubTitle: "",
                                      modalProps: {
                                        product,
                                        order
                                      }
                                    });
                                  }}
                                  className="text-[14px] py-3 px-8 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                                >
                                  Leave a review
                                </button>
                                <button
                                  onClick={() => {
                                    showModal({
                                      modalType: REPORT_MODAL,
                                      modalTitle: ``,
                                      modalSubTitle: "",
                                      modalProps: {
                                        product,
                                        order
                                      }
                                    });
                                  }}
                                  className="text-[14px] py-3 px-8 rounded-[4px] bg-[#D1503B] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                                >
                                  Report a problem
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> : !loading && user && orders && orders.length === 0 ?
          <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
            <div className="self-stretch">
              <SideMenu />
            </div>
            <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
              <div className="text-[32px] mediumFont p-40  text-center item-center">
                You Don't Have any Orders Yet.
                <p className="text-sm mediumFont text-center">
                  Start by buying something, {' '}
                  <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
                </p>
              </div>
            </div>
          </div>
          :!loading && user && !orders ?
            <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
              <div className="self-stretch">
                <SideMenu />
              </div>
              <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
                <div className="text-[32px] mediumFont p-40  text-center item-center">
                  You Don't Have any Orders Yet.
                  <p className="text-sm mediumFont text-center">
                    Start by buying something, {' '}
                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
                  </p>
                </div>
              </div>
            </div>
            :
            <div className=" min-h-screen md:flex hidden">
              <div className="w-full border-b-[1px] border-black">
                <div>
                  <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                    You must Sign In to View this page.
                    <p className="text-sm mediumFont leading-3 p-5  text-center">
                      Have a Stake Atlas account? Sign in, or Sign Up
                    </p>

                    <div className="w-full item-center text-center" >
                      <SignInpanelGeneral />
                    </div>
                  </div>
                </div>
              </div>
            </div>
      }

      {/* mobile */}
      {!loading && user && orders && orders.length > 0 ?
        <div className=" bg-[#FCF9F4]  mb-28 2md:hidden">
          <divorders
            className={`fixed w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
              } overflow-hidden`}
          >
            <SideMenu />
          </divorders>
          <h1 className="text-[26px] mediumFont text-black tracking-wide border-b-[1px] border-black pt-9 px-4 pb-3.5">
            Purchase History
          </h1>
          {orders && orders.map((order, index) => (
            <div className="mt-5 bg-white w-[95%] mx-auto border-x-2 border-black rounded" key={index}>
              <div className=" flex items-center justify-between border-y-2 border-black pt-2.5 pb-3.5 px-4">
                <h1 className="text-sm font-normal text-black">
                  Order No:{" "}
                  <span className="mediumFont text-[#1E44CD] ml-2 underline underline-offset-2">
                    #{order?._id}
                  </span>
                </h1>
                <h1 className="text-sm font-normal text-black">
                  Total:{" "}
                  <span className="mediumFont text-black ml-2 tracking-wide">
                    ₹{order?.amount}/-
                  </span>
                </h1>
              </div>
              {order && products && order?.order_items.map((productId, index) => {
                const product = products.find(
                  (product) => product?.id === productId
                );
                return (
                  <div className=" pt-6">
                    <div className="mx-4 mb-5">
                      <div className=" flex items-start">
                        <div className="border border-black w-[27%] h-[85px] rounded">
                          <img
                            src={product?.thumbnail}
                            alt=""
                            className="w-full h-full object-cover aspect-square"
                          />
                        </div>
                        <div className="ml-3 flex-1">
                          <h1 className="text-[16px] mediumFont text-black leading-5">
                            {product?.title}
                          </h1>
                          <div className="mt-1 flex items-center justify-between">
                            <h1 className="text-[20px] boldFont text-black tracking-wide">

                            </h1>
                            <button className="bg-sa-success-green text-[10px] px-4 py-[5px] rounded text-white">
                              Shipped
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="w-full border-black border-y-[1px] flex items-center justify-between mt-3">
                        <div className="border-r-[1px] border-black w-1/2 pt-2 pb-3.5">
                          <h1 className="text-sm mediumFont text-black flex items-center justify-start">
                            Left facility, in t...
                          </h1>
                        </div>
                        <div className="flex items-center justify-end w-1/2">
                          <h1 className="text-sm mediumFont text-black">
                            Track ID:{" "}
                            <span className="mediumFont text-[#1E44CD] ml-2 underline underline-offset-2">
                              10007363782
                            </span>
                          </h1>
                        </div>
                      </div>
                      <div className="mt-2.5">
                        <h1 className="text-sm mediumFont text-black">
                          Purchase Date:
                          <span className="mediumFont text-black ml-2">
                            {calculatePurchaseDate(order?.paid_at)}
                          </span>
                        </h1>
                        <div className=" px-3 pb-1 border-[1px] rounded border-black mt-4">
                          <Accourdion title={"Contact Information"}>
                            <div className="pb-3">
                              <button
                                onClick={() => {
                                  showModal({
                                    modalType: CONTACT_SELLER_MODAL,
                                    modalTitle: ``,
                                    modalSubTitle: "",
                                  });
                                }}
                                className=" w-full text-[14px] mt-2.5 py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                              >
                                Contact Seller
                              </button>
                              <button
                                onClick={() => {
                                  showModal({
                                    modalType: REVIEW_MODAL,
                                    modalTitle: ``,
                                    modalSubTitle: "",
                                    modalProps: {
                                      product,
                                      order
                                    }
                                  });
                                }}
                                className=" w-full text-[14px] mt-2.5 py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                              >
                                Leave a review
                              </button>
                              <button
                                onClick={() => {
                                  showModal({
                                    modalType: REPORT_MODAL,
                                    modalTitle: ``,
                                    modalSubTitle: "",
                                  });
                                }}
                                className=" w-full text-[14px] mt-2.5 py-2 px-3 rounded-[4px] bg-[#D1503B] text-black border-[1px] border-sa-border-black mediumFont flex items-center justify-center"
                              >
                                Report a problem
                              </button>
                            </div>
                          </Accourdion>
                        </div>
                      </div>
                    </div>
                  </div>)
              })}
            </div>
          ))}
          <div className="fixed bottom-0 w-full grid grid-cols-[100%]">
            <div
              className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
                }`}
            >
              <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
              <div
                onClick={() => {
                  setshowsort(!showsort);
                }}
                className=" absolute top-4 right-3"
              >
                <Cancel className="text-base " />
              </div>
              {sortarray.map((type, i) => (
                <button
                  className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                    }`}
                  onClick={() => {
                    setsorttype(i);
                  }}
                >
                  {type}
                </button>
              ))}
            </div>
            <div className="grid grid-cols-[50%_50%]">
              <button
                onClick={() => {
                  showphonesidenav(!phonesidenav);
                }}
                className={` w-full text-base py-2 ${phonesidenav
                  ? "bg-black text-sa-primary-yellow"
                  : "bg-sa-primary-yellow text-black"
                  } border-[1px] border-sa-border-black mediumFont`}
              >
                Purchase History
              </button>
              <button
                onClick={() => {
                  setshowsort(!showsort);
                }}
                className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
              >
                Sort: {sortarray[sorttype]}
              </button>
            </div>
          </div>
        </div> : !loading && user && orders && orders.length === 0 ?
          <div className="bg-[#FCF9F4] mb-28 2md:hidden">
            <div className="self-stretch">
              <SideMenu />
            </div>
            <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
              <div className="text-[32px] mediumFont p-40  text-center item-center">
                You Don't Have any Orders Yet.
                <p className="text-sm mediumFont text-center">
                  Start by buying something, {' '}
                  <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
                </p>
              </div>
            </div>
          </div>
          : !loading && user && !orders ?
            <div className="bg-[#FCF9F4] mb-28 2md:hidden">
              <div className="self-stretch">
                <SideMenu />
              </div>
              <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
                <div className="text-[32px] mediumFont p-40  text-center item-center">
                  You Don't Have any Orders Yet.
                  <p className="text-sm mediumFont text-center">
                    Start by buying something, {' '}
                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
                  </p>
                </div>
              </div>
            </div>
            :
            <div className=" bg-[#FCF9F4] mb-28 2md:hidden">
              <div className="w-full ">
                <div>
                  <div className="text-[32px] mediumFont p-40 text-center item-center">
                    You must Sign In to View this page.
                    <p className="text-sm mediumFont text-center">
                      Have a Stake Atlas account? Sign in, or Sign Up
                    </p>

                    <div className="w-full item-center text-center" >
                      <SignInpanelGeneral />
                    </div>
                  </div>
                </div>
              </div>
            </div>
      }
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(null, mapDispatchToProps)(PurchaseHistory);

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import OfferCountered from "./OfferCountered";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { counterOfferbySeller } from "../../redux/actions/product";

const OfferCounterModal = ({ onBack,offer }) => {
  const dispatch = useDispatch();
  const [isCounter, setIsCounter] = useState(false);
  const [amount, setAmount] = useState(0);

  const handleCounter = () => {
    dispatch(counterOfferbySeller(offer._id, offer.product, {
      counterOfferAmount : amount
    }));
    setIsCounter(true);
  };

  if (isCounter) {
    return <OfferCountered />;
  }
  return (
    <div>
      {/* desktop */}
      <div class="w-[529px] 2md:flex hidden h-[376px] relative bg-stone-50 rounded-lg border border border border border-black">
        <div class="left-[129px] top-[32px] absolute text-center text-black text-[42px] mediumFont leading-10">
          Counter Offer
        </div>
        <button
          onClick={handleCounter}
          class="w-[481px] h-[50px] px-8 py-3 left-[24px] top-[294px] absolute bg-[#0167DE] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex"
        >
          <div class="text-white text-[16px] mediumFont leading-tight">
            Counter Offer
          </div>
        </button>
        <div class="w-[481px] h-[54px] left-[24px] top-[108px] absolute">
          <div class="w-[109px] h-[54px] left-0 top-0 absolute">
            <div class="left-0 top-[24px] absolute text-black text-[24px] font-normal leading-7">
              @{offer?.buyer?.username}
            </div>
            <div class="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              From
            </div>
          </div>
          <div class="w-[140px] h-[54px] left-[341px] top-0 absolute">
            <div class="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              Offer Amount
            </div>
            <div class="w-[140px] h-[30px] left-0 top-[24px] absolute bg-[#FEFFB2] justify-center items-center gap-2.5 inline-flex">
              <div class="text-center text-black text-[24px] boldFont leading-7">
              ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}/-
              </div>
            </div>
          </div>
        </div>
        <div className="w-[484px] h-[84px] left-[24px] top-[186px] absolute">
          <div className="w-[484px] h-[54px] left-0 top-[30px] absolute bg-white rounded border border border border border-black"></div>
          <input
            type="number"
            placeholder={`₹ ${offer?.offer[offer?.offer?.length - 1]?.amount} or More`}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              setAmount(newValue);
            }}
            className="left-[18px] top-[43px] absolute text-zinc-600 text-[20px] mediumFont leading-7 rounded focus:outline-none focus:border-none"
          />
          <div className="left-0 top-0 absolute text-stone-500 text-[18px] boldFont leading-snug">
            Counter Amount
          </div>
        </div>

        <div
          onClick={onBack}
          class="left-[35px] top-[33px] absolute cursor-pointer text-gray-900 text-[42px] mediumFont leading-10"
        >
          <FaArrowLeft size={30} />
        </div>
      </div>
      {/* mobile */}
      <div class="w-[340px] 2md:hidden h-[450px] relative bg-white rounded-md">
        <div class="w-full justify-center z-0 flex top-[32px] absolute text-center text-black text-[28px] boldfont leading-9">
          Counter Offer
        </div>
        <div
          className="left-[20px] top-[35px] z-10 absolute text-gray-900 text-[30px] mediumFont leading-10 cursor-pointer"
          onClick={onBack}
        >
          <FaArrowLeft />
        </div>

        <div class="left-[16px] top-[88px] absolute">
          <div class="left-0 top-[25px] absolute text-black text-[24px] font-normal leading-7">
          @{offer?.buyer?.username}
          </div>
          <div class="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldfont leading-snug">
            From
          </div>
        </div>
        <div class="w-full h-[57px]  top-[163px] absolute">
          <div class="w-full flex justify-center top-0 absolute text-center text-stone-500 text-[18px] boldfont leading-snug">
            Offer Amount
          </div>
          <div class="w-full  h-[35px]  top-[22px] absolute justify-center items-center gap-2.5 inline-flex">
            <div class="text-center text-black text-[28px] bg-yellow-100 boldfont leading-9">
            ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}/-
            </div>
          </div>
        </div>
        <div className="w-[310px] h-[84px] left-[16px] top-[250px] absolute">
          <input
            type="number"
            placeholder="46,000.00 or Less"
            className="left-0 h-[50px] border text-[20px] border-black top-[30px] w-[310px] absolute text-zinc-600 text-[20px] mediumFont leading-7 rounded focus:outline-none "
          />
          <div className="left-0 top-0 absolute text-stone-500 text-[18px] boldFont leading-snug">
            Counter Amount
          </div>
        </div>
        <div class="w-[310px] h-[50px] px-8 py-3 left-[16px] top-[340px] absolute bg-sky-600 rounded border border border border border-black justify-center items-center gap-2.5 inline-flex">
          <button
            onClick={handleCounter}
            class="text-white text-[16px] mediumfont leading-tight"
          >
            Counter Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferCounterModal;

import {
  Bookmark,
  ExpandLess,
  ExpandMore,
  Star,
  StarOutline,
} from "@mui/icons-material";
import { useState } from "react";
import ProductCard from "../components/Home/ProductCard";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import ReactLoading from 'react-loading';
import NestedFilterDropDown from "../components/NestedFilterDropDown";

const SellerScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = location?.state?.data;
  const { products } = useSelector((state) => state.product);
  const [expand, setExpand] = useState(false);
  const [activeTab, setActiveTab] = useState("shop");
  const { categories } = useSelector((state) => state.category);
  //sort
  const sortarray = ["Price: Low to High", "Price: High to low", "Recently Added"];
  const [showsort, setshowsort] = useState(false);
  const [sorttype, setsorttype] = useState(2);
  const sortOption = ['cost', '-cost', '-createdAt']

  // filter
  const [category, setCategory] = useState(null);

  const [sellerProducts, setSellerProducts] = useState([]);
  const sellerActiveProducts = sellerProducts;
  // ?.filter(item => item.status.state === 'active');
  const [reviews, setReviews] = useState([]);

  // seller-since
  let seller_since = null;
  let seller_year = null;
  let seller_month = null;

  if (user && user?.createdAt) {
    seller_since = new Date(user?.createdAt);
    seller_year = seller_since.getFullYear();
    seller_month = seller_since.toLocaleString('default', { month: 'long' });
  }

  const reviewAsSeller = reviews && reviews?.filter(review => review.reviewType === 'seller');
  const reviewAsBuyer = reviews && reviews?.filter(review => review.reviewType === 'customer');

  const calculateTimeDifference = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const timeDifference = now - createdDate;

    // Calculate the time difference in hours
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursDifference;
  };

  const sideReview = [
    {
      title: "Communication",
      value: `${user?.rating?.communication === 1 ? 'Bad' : user?.rating?.communication === 2 ? 'Average' : user?.rating?.communication === 3 ? 'Satisfied' : user?.rating?.communication === 4 ? 'Good' : 'Great'}`
    },
    {
      title: "Item as described",
      value: `${user?.rating?.item_as_described === 1 ? 'Bad' : user?.rating?.item_as_described === 2 ? 'Average' : user?.rating?.item_as_described === 3 ? 'Satisfied' : user?.rating?.item_as_described === 4 ? 'Good' : 'Great'}`
    },
    {
      title: "On time payment",
      value: `${user?.rating?.on_time_payment === 1 ? 'Bad' : user?.rating?.on_time_payment === 2 ? 'Average' : user?.rating?.on_time_payment === 3 ? 'Satisfied' : user?.rating?.on_time_payment === 4 ? 'Good' : 'Great'}`
    },
  ];

  // total 5 star ratings 
  const reviewseller5star = reviews && reviews?.filter(review => review.reviewType === 'seller' && review.overall_rating === 5);
  const reviewbuyer5star = reviews && reviews?.filter(review => review.reviewType === 'customer' && review.overall_rating === 5);


  useEffect(() => {
    // getting all reviews
    axiosInstance
      .get(`review/${user?._id}`)
      .then((res) => {
        setReviews(res.data.data);
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      })
  }, [])
  useEffect(() => {
    // getting all seller 
    if (user) {
      let url = "";
      if (category) {
        url = `product/productsBySeller/${user?._id}?sort=${sortOption[sorttype]}&category=${category}`;
      } else {
        url = `product/productsBySeller/${user?._id}?sort=${sortOption[sorttype]}`
      }

      axiosInstance
        .get(url)
        .then((res) => {
          setSellerProducts(res.data.data);
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");

                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");

                break;
              default:
                // server error
                toast.error("Oops, something went wrong");

                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        })
    }
  }, [dispatch, sorttype, category])


  return (
    <>
      {user ?
        <div>
          <div className="bg-[#005C51] px-16 h-[42vh] border-b-2 border-black relative">
            <div className=" flex items-center gap-x-4 pt-16">
              <div className="w-24 border-[1.5px] border-black rounded-full">
                <img
                  src={require("../assets/new design/user.png")}
                  alt=""
                  className=" w-full aspect-auto"
                />
              </div>
              <div className="flex items-start gap-x-4">
                <div>
                  <h1 className=" text-4xl text-white mediumfont tracking-wide">
                    @{user?.username}
                  </h1>
                  <h1 className=" text-base text-white mediumfont tracking-wide mt-1">
                    {user?.first_name}
                  </h1>
                  <h1 className="text-xl font-normal text-sa-primary-yellow">
                    Member since {seller_month} {seller_year}
                  </h1>
                </div>
                <div>
                  <button className=" w-full text-[12px] py-2 px-2 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumfont flex items-center justify-center">
                    <Bookmark className=" text-xs mr-1 text-sa-primary-yellow" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-start absolute -bottom-[1.5px] gap-x-4 z-10">
              <div
                className={`w-52 py-2 bg-white rounded-t-lg border-x-2 border-t-2 border-b-2  border-black ${activeTab == "shop" ? "border-b-white" : "border-b-black"
                  }`}
              >
                <button
                  onClick={() => setActiveTab("shop")}
                  className=" w-full text-[12px] py-2 px-2 rounded-[4px] bg-[#FCF9F4] text-black mediumfont flex items-center justify-center"
                >
                  <h1 className="text-[22px] mediumfont text-black underline underline-offset-2">
                    Shop
                  </h1>
                </button>
              </div>

              <div
                className={`w-52 py-2 bg-white rounded-t-lg border-x-2 border-t-2 border-b-2  border-black ${activeTab == "reviewSeller" ? "border-b-white" : "border-b-black"
                  }`}
              >
                <button
                  onClick={() => setActiveTab("reviewSeller")}
                  className=" w-full text-[12px] py-2 px-2 rounded-[4px] bg-[#FCF9F4] text-black mediumfont flex items-center justify-center"
                >
                  <h1 className="text-[22px] mediumfont text-black underline underline-offset-2">
                    Review as a seller
                  </h1>
                </button>
              </div>
              <div
                className={`w-52 py-2 bg-white rounded-t-lg border-x-2 border-t-2 border-b-2  border-black ${activeTab == "reviewBuyer" ? "border-b-white" : "border-b-black"
                  }`}
              >
                <button
                  onClick={() => setActiveTab("reviewBuyer")}
                  className=" w-full text-[12px] py-2 px-2 rounded-[4px] bg-[#FCF9F4] text-black mediumfont flex items-center justify-center"
                >
                  <h1 className="text-[22px] mediumfont text-black underline underline-offset-2">
                    Review as a buyer
                  </h1>
                </button>
              </div>
            </div>
          </div>
          {activeTab === "shop" && (
            <div className="pl-16 pr-8 flex items-start pt-9">
              <div className="w-[23%]">
                {/* <div className="w-full border-[1px] border-black rounded py-7 px-3">
              <h1 className="text-sa-menu-green text-[20px] boldfont">
                OUR CATEGORIES
              </h1>
              <div className="mt-4 space-y-3">
                <div className="flex items-start">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        require("../assets/icons/electronics_icon.svg").default
                      }
                      alt="summary icon"
                    />
                  </div>
                  <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                    Electronics
                  </h1>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        require("../assets/icons/electronics_icon.svg").default
                      }
                      alt="summary icon"
                    />
                  </div>
                  <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                    Automobiles
                  </h1>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        require("../assets/icons/electronics_icon.svg").default
                      }
                      alt="summary icon"
                    />
                  </div>
                  <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                    Collectibles & Art
                  </h1>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        require("../assets/icons/electronics_icon.svg").default
                      }
                      alt="summary icon"
                    />
                  </div>
                  <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                    Musical Instruments & Gear
                  </h1>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center justify-center">
                    <img
                      src={
                        require("../assets/icons/electronics_icon.svg").default
                      }
                      alt="summary icon"
                    />
                  </div>
                  <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                    Toys & Hobbies
                  </h1>
                </div>
              </div>
            </div> */}

                <div className="w-full border-[1px] border-black rounded py-7 px-3 mt-4">
                  <h1 className="text-sa-menu-green text-[20px] boldfont">
                    SHOP BY CATEGORY
                  </h1>
                  <div className="mt-4 space-y-3">
                    {/* <div>
                  <div
                    className="flex items-start justify-between"
                    onClick={() => setExpand(!expand)}
                  >
                    <div className="flex items-start">
                      <div className="flex items-center justify-center">
                        <img
                          src={
                            require("../assets/icons/electronics_icon.svg")
                              .default
                          }
                          alt="summary icon"
                        />
                      </div>
                      <h1 className="text-[17px] mediumfont text-black tracking-wide ml-2">
                        Cell Phone & SmartPhones
                      </h1>
                    </div>
                    {expand ? (
                      <ExpandLess className="text-black text-2xl" />
                    ) : (
                      <ExpandMore className="text-black text-2xl" />
                    )}
                  </div>
                  {expand && (
                    <div className="pl-9">
                      <h1
                        className={`text-[16px] mediumfont  tracking-wide ml-4 mt-2 block text-[#005C51]`}
                      >
                        Smartwatch Accessories
                      </h1>
                      <h1
                        className={`text-[16px] mediumfont  tracking-wide ml-4 mt-2 block text-black`}
                      >
                        Smartwatch parts
                      </h1>
                      <h1
                        className={`text-[16px] mediumfont  tracking-wide ml-4 mt-2 block text-black`}
                      >
                        Smartwatches
                      </h1>
                    </div>
                  )}
                </div> */}
                    <div>
                      <div
                        className="flex items-start justify-between"
                      >
                        <div className="w-full">
                          <NestedFilterDropDown categories={categories} filter setCategory={setCategory} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="flex-1">
                <div className="flex justify-end">
                  <div className="relative z-50 w-max place-items-center">
                    <button
                      onClick={() => {
                        setshowsort(!showsort);
                      }}
                      className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
                  ${showsort ? "rounded rounded-b-none border-b-0" : "rounded"} 
                  text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}
                    >
                      Sort: {sortarray[sorttype]} &nbsp;
                      {!showsort ? <ExpandMore /> : <ExpandLess />}
                    </button>
                    <div
                      className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort
                        ? "max-h-[100vh] border-[1px]"
                        : "max-h-0 border-[0px]"
                        }`}
                    >
                      {sortarray.map((type, i) => (
                        <div
                          key={`cat${i}`}
                          onClick={() => {
                            setsorttype(i);
                            setshowsort(!showsort);
                          }}
                          className="mt-2.5 py-2 px-5 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3"
                        >
                          <p>{type}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {sellerActiveProducts.length>0 
                ? <div className="pl-12 pt-9 h-[70vh] overflow-y-auto flex items-start flex-wrap gap-x-10 no-scrollbar">
                  {sellerActiveProducts.map((e) => (
                    <div className="w-[30%] mb-10">
                      <ProductCard product={e} />
                    </div>
                  ))}
                </div> :
                  <div className="pl-12 pt-9 h-[70vh] overflow-y-auto no-scrollbar">
                    <div className="w-[30%] mb-10 font-bold text-[42px]">
                    <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
                    </div>
                  </div>
                }
              </div>
            </div>
          )}
          {activeTab === "reviewSeller" && (
            <>
              {reviewAsSeller.length > 0 ?
                <div className="pl-16 pr-16 flex items-start pt-9 bg-[#FCF9F4] h-[400px]">
                  <div className="w-[65.5%] pr-20">
                    <h1 className=" text-3xl mediumfont">
                      Here’s what others have to say about {user?.first_name}:
                    </h1>
                    <div>
                      {reviewAsSeller && reviewAsSeller.map((review) => (
                        <div className="border-b-[1px] border-black pb-4 mb-3">
                          <div className="flex items-center justify-between">
                            <div className=" flex items-center gap-x-1 mt-3">
                              <h1 className=" text-lg boldfont">@{review?.from?.username}</h1>
                              <div className=" flex items-start">
                                <Rating
                                  className="flex flex-row justify-center h-9"
                                  ratingValue={review?.overall_rating}
                                  allowHover={false}
                                  disableFillHover={true}
                                  readonly={true}
                                  emptyColor="orange"
                                />
                              </div>
                            </div>
                            <h1 className=" text-base boldfont text-[#888888]">
                              {calculateTimeDifference(review.createdAt) === 0 ? "Just Now" : `${calculateTimeDifference(review.createdAt)} hours ago`}
                            </h1>
                          </div>
                          <div>
                            <p className="underline underline-offset-2 mediumfont text-base text-blue-100 mt-2">
                              {review?.product?.title}
                            </p>
                            <p className=" text-lg font-normal text-grey-100 mt-2">
                              {review?.review}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="border-x-[1px] border-y-[1px] border-black rounded-lg bg-white">
                      {sideReview.map((e) => (
                        <div className=" flex items-center justify-between px-2 border-b-[1px] border-black">
                          <div className=" w-1/2 border-r-[1px] border-black py-2.5 pl-3">
                            <h1 className="text-base ">{e.title}</h1>
                          </div>
                          <div className=" w-1/2 py-2.5 flex-1 pl-10">
                            <h1 className="text-base mediumfont">{e.value}</h1>
                          </div>
                        </div>
                      ))}
                      <div className="flex items-start">
                        <div className=" w-1/2 py-2 flex items-center justify-center flex-col border-r-[1px] border-black">
                          <h1 className="text-xl boldfont text-[#888888]">
                            Items Sold
                          </h1>
                          <h1 className=" text-4xl boldfont">{user?.items_sold}</h1>
                        </div>
                        <div className=" w-1/2 py-2 flex items-center justify-center flex-col">
                          <h1 className="text-xl boldfont text-[#888888]">
                            5 Start Reviews
                          </h1>
                          <h1 className=" text-4xl boldfont">{reviewseller5star.length}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> :
                <div className="pl-16 pr-16 flex items-start pt-9 bg-[#FCF9F4] h-[500px]">
                  <div className="w-[65.5%] pr-20">
                    <h1 className=" text-3xl mediumfont">
                      There is no Review.
                    </h1>
                  </div>
                </div>
              }
            </>
          )}
          {activeTab === "reviewBuyer" && (
            <>
              {reviewAsBuyer.length > 0 ?
                <div className="pl-16 pr-16 flex items-start pt-9 bg-[#FCF9F4]">
                  <div className="w-[65.5%] pr-20">
                    <h1 className=" text-3xl mediumfont">
                      Here’s what others have to say about Akshat:
                    </h1>
                    <div>
                      {reviewAsBuyer.map((e) => (
                        <div className="border-b-[1px] border-black pb-4 mb-3">
                          <div className="flex items-center justify-between">
                            <div className=" flex items-center gap-x-1 mt-3">
                              <h1 className=" text-lg boldfont">@Sart....mk</h1>
                              <div className=" flex items-start">
                                <Star className=" text-yellow-100" fontSize="medium" />
                                <Star className=" text-yellow-100" fontSize="medium" />
                                <Star className=" text-yellow-100" fontSize="medium" />
                                <StarOutline className=" " fontSize="medium" />
                                <StarOutline className=" " fontSize="medium" />
                              </div>
                            </div>
                            <h1 className=" text-base boldfont text-[#888888]">
                              2 hours ago
                            </h1>
                          </div>
                          <div>
                            <p className="underline underline-offset-2 mediumfont text-base text-blue-100 mt-2">
                              Maruti 800 2004 CNG fit
                            </p>
                            <p className=" text-lg font-normal text-grey-100 mt-2">
                              Check who the seller is very carefully. If it says Loop
                              Mobile then the phone is most definitely used even though
                              they say in the phone specifications that it is a new
                              phone. When you open the box, there is paperwork that
                              specifically states that it is a refurbished phone and
                              that there is no warranty on the phone.
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="border-x-[1px] border-y-[1px] border-black rounded-lg bg-white">
                      {sideReview.map((e) => (
                        <div className=" flex items-center justify-between px-2 border-b-[1px] border-black">
                          <div className=" w-1/2 border-r-[1px] border-black py-2.5 pl-3">
                            <h1 className="text-base ">{e.title}</h1>
                          </div>
                          <div className=" w-1/2 py-2.5 flex-1 pl-10">
                            <h1 className="text-base mediumfont">{e.value}</h1>
                          </div>
                        </div>
                      ))}
                      <div className="flex items-start">
                        <div className=" w-1/2 py-2 flex items-center justify-center flex-col border-r-[1px] border-black">
                          <h1 className="text-xl boldfont text-[#888888]">
                            Items Sold
                          </h1>
                          <h1 className=" text-4xl boldfont">04</h1>
                        </div>
                        <div className=" w-1/2 py-2 flex items-center justify-center flex-col">
                          <h1 className="text-xl boldfont text-[#888888]">
                            5 Start Reviews
                          </h1>
                          <h1 className=" text-4xl boldfont">02</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="pl-16 pr-16 flex items-start pt-9 bg-[#FCF9F4] h-[500px]">
                  <div className="w-[65.5%] pr-20">
                    <h1 className=" text-3xl mediumfont">
                      There is no Review.
                    </h1>
                  </div>
                </div>
              }
            </>
          )}
        </div>
        :
        <div className="hidden 2md:flex items-start bg-[#FCF9F4] min-h-screen">
          <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
            <div className="text-[32px] mediumFont p-40 text-center item-center">
              We’re unable to reach our servers, please try again later
              <p className="text-sm">We apologize for the inconvenience.</p>
              <button
                onClick={() => {
                  navigate("/")
                }}
                className="text-[14px] py-2.5 px-10 rounded-[4px] bg-yellow-200 text-black border-[1px] border-sa-border-black mediumFont mt-4"
              >
                Go Back Home
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SellerScreen;

import React, { useState } from "react";

const Dropdown = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="w-full">
      <select
        className="p-2 pt-1 w-full bg-yellow-200 rounded border border-black text-black"
        value={selectedOption}
        onChange={handleChange}
      >
        <option value="">Select an option</option>
        <option value="interior" className="bg-white">
          Interior
        </option>
        <option value="exterior" className="bg-white">
          Exterior
        </option>
        <option value="all" className="bg-white">
          All Photos
        </option>
      </select>

      <div className="mt-4">
        {selectedOption && <p>Selected option: {selectedOption}</p>}
      </div>
    </div>
  );
};

export default Dropdown;

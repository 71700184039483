import React, { useEffect } from "react";
import SideMenu from "../components/Buying/SideMenu";
import { useState } from "react";
import ActiveListingCard from "../components/Selling/ActiveListingCard";
import { ExpandMore, ExpandLess, Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUserChats, userActiveListings } from "../redux/actions/auth";
import { getProductCompressedImages } from "../redux/actions/product";
import { Link, useParams } from "react-router-dom";
import { PhotographIcon, UserCircleIcon } from "@heroicons/react/outline";
import { editUser } from "../redux/actions/admin";

const UserEditScreen = () => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const { users } = useSelector((state) => state.admin);
    const user = users.find(user => user.id === userId);
    const [fontSize, setFontSize] = useState("text-4xl");
    const [selectedRole, setSelectedRole] = useState(user.role);

    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop > 0) {
            setFontSize("text-xl");
        } else {
            setFontSize("text-4xl");
        }
    };

    const [phonesidenav, showphonesidenav] = useState(true);
    const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
    const [showsort, setshowsort] = useState(false);
    const [sorttype, setsorttype] = useState(0);

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleEdit = (e) => {
        e.preventDefault();
        let role = "";
        switch (selectedRole) {
            case "Seller":
                role = "seller"
                break;
            case "Admin":
                role = "admin"
                break;
            case "Customer":
                role = "customer"
                break;
            case "Customer Unverified":
                role = "customer_unverified"
                break;
            case "Blocked":
                role = "blocked"
                break;
            default:
                break;
        }
        dispatch(editUser(userId, { role: role }));
    }

    return (
        <div>
            {/* desktop */}
            <div className="hidden 2md:flex items-start min-h-screen bg-[#FCF9F4]">
                <div className="self-stretch">
                    <SideMenu />
                </div>
                <div className="flex-1 w-full overflow-hidden bg-[#FCF9F4]">
                    <div
                        className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                            }`}
                    >
                        <h1
                            className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
                        >
                            User : {user.id}
                        </h1>
                    </div>
                    <div
                        onScroll={handleScroll}
                        className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 h-screen overflow-y-auto overflow-x-hidden grid grid-cols-fluid"
                    >
                        <form>
                            <div className="space-y-12">
                                <div className="border-gray-900/10">
                                    <h2 className="text-xl font-semibold leading-7 text-gray-900 pb-2">Name : {user.first_name}</h2>
                                    <h2 className="text-xl font-semibold leading-7 text-gray-900 pb-2 ">Email : {user.email}</h2>
                                    <h2 className="text-xl font-semibold leading-7 text-gray-900 pb-2 ">Phone : {user.phone}</h2>
                                </div>

                                <div className="border-gray-900/10">
                                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-xl font-semibold leading-6 text-gray-900">
                                                Role
                                            </label>
                                            <div className="mt-2">
                                                <select
                                                    id="role"
                                                    name="role"
                                                    autoComplete="role"
                                                    value={selectedRole}
                                                    onChange={handleRoleChange}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-yellow-300 sm:max-w-xs sm:text-sm sm:leading-6"
                                                >
                                                    <option>Admin</option>
                                                    <option>Customer Unverified</option>
                                                    <option>Customer</option>
                                                    <option>Seller</option>
                                                    <option>Blocked</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 flex items-center justify-end gap-x-6 border-t-[1px] p-2">
                                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                    Cancel
                                </button>
                                <button
                                    onClick={(e) => handleEdit(e)}
                                    className="rounded-md bg-yellow-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-300"
                                >
                                    Edit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* mobile */}
            <div className=" bg-[#FCF9F4] min-h-screen pb-28 2md:hidden">
                <div
                    className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
                        } overflow-hidden`}
                >
                    <SideMenu />
                </div>
                <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
                    <h1 className="text-[26px] mediumFont text-black tracking-wide">
                        User : {user.id}
                    </h1>
                </div>
                <div className="pt-7 px-4 transition-all  duration-500 ease">
                    <form>
                        <div className="space-y-12">
                            <div className="border-gray-900/10">
                                <h2 className="text-xl font-semibold leading-7 text-gray-900 pb-2">Name : {user.first_name}</h2>
                                <h2 className="text-xl font-semibold leading-7 text-gray-900 pb-2 ">Email : {user.email}</h2>
                                <h2 className="text-xl font-semibold leading-7 text-gray-900 pb-2 ">Phone : {user.phone}</h2>
                            </div>

                            <div className="border-gray-900/10">
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-xl font-semibold leading-6 text-gray-900">
                                            Role
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="role"
                                                name="role"
                                                autoComplete="role"
                                                value={selectedRole}
                                                onChange={handleRoleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-yellow-300 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                <option>Admin</option>
                                                <option>Customer Unverified</option>
                                                <option>Customer</option>
                                                <option>Seller</option>
                                                <option>Blocked</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6 border-t-[1px] p-2">
                            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                            <button
                                onClick={(e) => handleEdit(e)}
                                className="rounded-md bg-yellow-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-300"
                            >
                                Edit
                            </button>
                        </div>
                    </form>
                </div>
                <div className="fixed  z-50 bottom-0 w-full grid grid-cols-[100%]">
                    <div
                        className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
                            }`}
                    >
                        <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
                        <div
                            onClick={() => {
                                setshowsort(!showsort);
                            }}
                            className=" absolute top-4 right-3"
                        >
                            <Cancel className="text-base " />
                        </div>
                        {sortarray.map((type, i) => (
                            <button
                                className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                                    }`}
                                onClick={() => {
                                    setsorttype(i);
                                }}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                    <div className="grid grid-cols-[50%_50%]">
                        <button
                            onClick={() => {
                                showphonesidenav(!phonesidenav);
                            }}
                            className={` w-full text-base py-2 ${phonesidenav
                                ? "bg-black text-sa-primary-yellow"
                                : "bg-sa-primary-yellow text-black"
                                } border-[1px] border-sa-border-black mediumFont`}
                        >
                            SideMenu
                        </button>
                        <button
                            onClick={() => {
                                setshowsort(!showsort);
                            }}
                            className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
                        >
                            Sort: {sortarray[sorttype]}
                        </button>
                    </div>
                    {/* <div className="pl-12 pr-8 pt-9 h-screen overflow-y-auto overflow-x-hidden">
                        
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default UserEditScreen;

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import OfferDeclined from "./OfferDeclined";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { declineOfferbySeller } from "../../redux/actions/product";
const OfferDeclinedModal = ({ onBack, offer, productId }) => {
  const dispatch = useDispatch();
  const [isDeclined, setIsDeclined] = useState(false);

  const handleDecline = () => {
    dispatch(declineOfferbySeller(offer._id, productId));
    setIsDeclined(true);
  };

  if (isDeclined) {
    return <OfferDeclined />;
  }
  //D1503B
  return (
    <div>
      {/* desktop */}
      <div className="w-[529px] 2md:flex hidden h-[330px] relative bg-stone-50 rounded-lg border border border border border-black">
        <div className="left-[127px] top-[32px] absolute text-center text-black text-[42px] mediumFont leading-10">
          Decline Offer
        </div>
        <button
          onClick={handleDecline}
          className="w-[481px] h-[50px] px-8 py-3 left-[24px] top-[248px] absolute bg-[#D1503B] rounded border border border border border-black justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-[16px] mediumFont leading-tight">
            Decline
          </div>
        </button>

        <div className="w-[481px] h-[54px] left-[24px] top-[108px] absolute">
          <div className="w-[109px] h-[54px] left-0 top-0 absolute">
            <div className="left-0 top-[24px] absolute text-black text-[24px] font-normal leading-7">
              @{offer?.buyer?.username}
            </div>
            <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              From
            </div>
          </div>
          <div className="w-[140px] h-[54px] left-[341px] top-0 absolute">
            <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              Offer Amount
            </div>
            <div className="w-[140px] h-[30px] left-0 top-[24px] absolute bg-[#FEFFB2] justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-black text-[24px] boldFont leading-7">
                ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}/-
              </div>
            </div>
          </div>
          <div className="w-[57px] h-[54px] left-[201px] top-0 absolute">
          </div>
        </div>
        <div className="w-[435px] left-[48px] top-[184px] absolute text-center text-black text-[16px] font-normal leading-tight">
          70% buyers accept a counter offer from a seller. Make a counter offer
          to make a deal!
        </div>
        <button
          className="left-[35px] top-[33px] absolute text-gray-900 text-[42px] mediumFont leading-10 cursor-pointer"
          onClick={onBack}
        >
          <FaArrowLeft />
        </button>
      </div>
      {/* mobile */}
      <div className="2md:hidden w-[340px] h-[400px] relative bg-stone-50 rounded-lg border border-black">
        <div className="left-[127px] top-[32px] absolute text-center text-black text-[42px] mediumFont leading-10">
          Decline Offer
        </div>
        <button
          onClick={handleDecline}
          className="w-[312px] h-[50px] px-8 py-3 left-[14px] top-[296px] absolute bg-[#D1503B] rounded border border-black justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-[16px] mediumFont leading-tight">
            Decline
          </div>
        </button>
        <div className="w-[312px] h-[54px] left-[14px] top-[108px] absolute">
          <div className="w-[109px] h-[54px] left-0 top-0 absolute">
            <div className="left-0 top-[24px] absolute text-black text-[24px] font-normal leading-7">
              @{offer?.buyer?.username}
            </div>
            <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              From
            </div>
          </div>
          <div className="w-[140px] h-[54px] left-[159px] top-0 absolute">
            <div className="left-0 top-0 absolute text-center text-stone-500 text-[18px] boldFont leading-snug">
              Offer Amount
            </div>
            <div className="w-[140px] h-[30px] left-0 top-[24px] absolute bg-[#FEFFB2] justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-black text-[24px] boldFont leading-7">
                ₹ {offer?.offer[offer?.offer?.length - 1]?.amount}/-
              </div>
            </div>
          </div>
          <div className="w-[57px] h-[54px] left-[201px] top-0 absolute"></div>
        </div>
        <div className="w-[312px] left-[14px] top-[184px] absolute text-center text-black text-[16px] font-normal leading-tight">
          70% buyers accept a counter offer from a seller. Make a counter offer
          to make a deal!
        </div>
        <button
          className="left-[9px] top-[33px] absolute text-gray-900 text-[42px] mediumFont leading-10 cursor-pointer"
          onClick={onBack}
        >
          <FaArrowLeft />
        </button>
      </div>
    </div>
  );
};

export default OfferDeclinedModal;

import React, { useEffect } from "react";
import SideMenu from "../components/Buying/SideMenu";
import PreviewCardMob from "../components/Seller/PreviewCardMob";
import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardDesktop from "../components/Seller/PreviewCardDesktop";
import PendingPayment from "../components/Selling/PendingPayment";
import { ExpandMore, ExpandLess, Cancel } from "@mui/icons-material";

import ListingPreview from "../components/Selling/ListingPreview";
import ActiveListingAnalytics from "./ActiveListingAnalytics";
import ActiveListingMessages from "./ActiveListingMessages";
import ActiveListingOffers from "./ActiveListingOffers";
import { getProductOffers } from "../redux/actions/product";
import Messaging from "../components/Messaging";

const ActiveListingProduct = ({}) => {
  const data = [
    {
      id: 1,
      status: "Pending Payment",
    },
    {
      id: 2,
      status: "paid",
    },
    {
      id: 3,
      status: "completed",
    },
    { id: 4, status: "Pending Payment" },
    {
      id: 5,
      status: "paid",
    },
    {
      id: 6,
      status: "completed",
    },
    { id: 7, status: "Pending Payment" },
    {
      id: 8,
      status: "paid",
    },
    {
      id: 9,
      status: "completed",
    },
    { id: 10, status: "Pending Payment" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerTabs = ["Preview", "Analytics", "Messages", "Offers"];
  const [fontSize, setFontSize] = useState("text-4xl");
  const [activeTab, setActiveTab] = useState("Preview");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { productId } = useParams();
  const { products } = useSelector((state) => state.product);
  // selecting the specific product
  // this will give error on reloading
  const listing = products?.find(item => item.id === productId);
  const offers = listing?.offers;

  useEffect(() => {
    dispatch(getProductOffers(productId));
  }, [])

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setIsAccordionOpen(false);
  };
  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setFontSize("text-xl");
    } else {
      setFontSize("text-4xl");
    }
  };
  const [selectedOption, setSelectedOption] = useState("New To Old");
  const [isOpen, setIsOpen] = useState(false);

  const options = ["New To Old", "Old To New", "A to Z", "Z to A"];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [phonesidenav, showphonesidenav] = useState(true);
  const [showsort, setshowsort] = useState(false);
  const [sorttype, setsorttype] = useState(0);
  const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
  return (
    <>
    { listing && 
    <div className="bg-[#FCF9F4]">
      {/* desktop */}
      <div className="hidden 2md:flex items-start min-h-screen">
        <div className="self-stretch">
          <SideMenu />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex-1 w-full overflow-hidden bg-[#FCF9F4]">
            <div
              className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                }`}
            >
              <div className="flex space-x-2 items-center">
                <button
                  onClick={() => {
                    navigate("/selling/activelistings");
                  }}
                >
                  {" "}
                  <BsArrowLeft className="text-black boldFont text-[35px]" />
                </button>

                <h1
                  className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
                >
                  {listing.title}
                </h1>
              </div>

              <div
                className={`${fontSize === "text-4xl" ? "mt-5" : "mt-2"
                  } transition-all duration-300 ease-in-out flex items-center justify-between`}
              >
                <div className="flex items-center flex-wrap max-w-[600px] mr-10 grow justify-between">
                  {offerTabs.map(function (e, i) {
                    return (
                      <button
                        onClick={() => setActiveTab(e)}
                        key={i}
                        className={`px-5 text-[18px] mt-2.5 py-2  rounded-md ${activeTab === e
                            ? "bg-white border-[1px] border-sa-border-black"
                            : "bg-[#FCF9F4]  border-[1px] border-[#FCF9F4]"
                          } text-black mediumFont flex items-center justify-center`}
                      >
                        {e}
                      </button>
                    );
                  })}
                </div>
                {activeTab === "Preview" && (
                  <div className="relative z-30 flex space-x-4">
                    <div className="text-[14px] bg-[#FFDC25] border-black border rounded-md w-[112px] flex justify-center py-2 capitalize" onClick={() => navigate("activelistings/edit/product/:productId")}>
                      edit listing
                    </div>
                    <div className="text-[14px] bg-[#D1503B] text-white border-black border rounded-md w-[112px] flex justify-center py-2 capitalize">
                      delete listing
                    </div>
                  </div>
                )}

                {/* <div className="relative z-20 w-max place-items-center">
            <button
              onClick={() => { setshowsort(!showsort) }}
              className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
              ${(showsort) ? "rounded rounded-b-none border-b-0" : "rounded"} 
              text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}>
              Sort: {sortarray[sorttype]}  &nbsp;{!showsort ? <ExpandMore /> : <ExpandLess />}
            </button>
            <div className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort ? "max-h-[100vh] border-[1px]" : "max-h-0 border-[0px]"}`}>
              {sortarray.map((type, i) => (
                <div key={`cat${i}`} onClick={() => { setsorttype(i); setshowsort(!showsort); }} className='mt-2.5 py-2 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3'>
                  <p>{type}</p>
                </div>
              ))}
            </div>
          </div> */}
              </div>
            </div>
          </div>
          <div className=" py-6 h-full pl-12 pr-16">
            {activeTab === "Preview" && <ListingPreview product={listing} />}
            {activeTab === "Analytics" && <ActiveListingAnalytics />}
            {activeTab === "Messages" && <ActiveListingMessages />}
            {activeTab === "Offers" && <ActiveListingOffers offers={offers} productId={productId} />}
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="bg-[#FCF9F4] mb-28 2md:hidden">
        <div
          className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
            } overflow-hidden`}
        >
          <SideMenu />
        </div>
        <div className="fixed bottom-[60px] w-full  z-30 flex justify-end px-3">
          {isAccordionOpen && (
            <div className="bg-white w-[97%] rounded-md shadow-lg absolute overflow-hidden  border border-black bottom-12 z-10">
              {offerTabs.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => handleTabChange(tab)}
                  className={`w-full py-2 px-2 ${index === 0 ? "border-t-[0px]" : "border-t"
                    } border-black text-left ${activeTab === tab ? "bg-gray-200" : ""
                    }`}
                >
                  {tab}
                </button>
              ))}
            </div>
          )}
          <button
            onClick={handleAccordionToggle}
            className="bg-[#769ED9] rounded-full p-2 shadow-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isAccordionOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
          <h1 className="text-[20px] mediumFont text-sa-menu-green">Selling</h1>
          <h1 className="text-[26px] mediumFont text-black tracking-wide">
            Active Listing
          </h1>
        </div>
        <div className="flex space-x-2 pl-2 items-center pt-[32px]">
          <button
            onClick={() => {
              navigate("/selling/activelistings");
            }}
          >
            {" "}
            <BsArrowLeft className="text-black boldFont text-[30px]" />
          </button>

          <h1
            className={`text-[26px] transition-all duration-300 ease  boldFont text-black`}
          >
            {listing.title}
          </h1>
        </div>
        <div className="w-full flex flex-col pt-[22px] items-center space-y-3 px-[10px]">
          {activeTab === "Preview" && (
            <div className="flex w-full space-x-2">
              <button className="w-1/2 flex justify-center text-[16px] bg-[#FFDC25] rounded-md border border-black capitalize py-2" onClick={() => navigate("activelistings/edit/product/:productId")}>
                edit listing
              </button>
              <button className="w-1/2 flex justify-center text-[16px] bg-[#D1503B] text-white rounded-md border border-black capitalize py-2">
                delete listing
              </button>
            </div>
          )}
        </div>
        <div>
          {activeTab === "Preview" && <ListingPreview product={listing} />}
          {activeTab === "Analytics" && <ActiveListingAnalytics />}
          {activeTab === "Messages" && <ActiveListingMessages />}
          {activeTab === "Offers" && <ActiveListingOffers productId={productId} />}
        </div>
        <div className="fixed  z-50 bottom-0 w-full grid grid-cols-[100%]">
          <div
            className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
              }`}
          >
            <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
            <div
              onClick={() => {
                setshowsort(!showsort);
              }}
              className=" absolute top-4 right-3"
            >
              <Cancel className="text-base " />
            </div>
            {sortarray.map((type, i) => (
              <button
                className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                  }`}
                onClick={() => {
                  setsorttype(i);
                }}
              >
                {type}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-[50%_50%]">
            <button
              onClick={() => {
                showphonesidenav(!phonesidenav);
              }}
              className={` w-full text-base py-2 ${phonesidenav
                  ? "bg-black text-sa-primary-yellow"
                  : "bg-sa-primary-yellow text-black"
                } border-[1px] border-sa-border-black mediumFont`}
            >
              Offers
            </button>
            <button
              onClick={() => {
                setshowsort(!showsort);
              }}
              className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
            >
              Sort: {sortarray[sorttype]}
            </button>
          </div>
        </div>
      </div>
    </div> }
    </>
  );
};

export default ActiveListingProduct;

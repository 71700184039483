export default function TabMenu({ state, setState, items }) {
  return (
    <div className="flex flex-col w-full bg-white">
      {/* NavBar */}
      <div className="flex w-full justify-center items-center">
        {Object.keys(items).map((key, index) => {
          return (
            <div
              id={index}
              onClick={() => {
                setState(index);
              }}
              className={`flex justify-center text-[18px] ${
                index === state
                  ? " border-l border-r border-black px-3 py-2 cursor-pointer relative w-1/3 boldFont"
                  : `border-r border-gray-300 px-3 py-2 cursor-pointer text-[#8A8A8A] w-1/3 ${
                      index === state - 1 && "border-r-transparent"
                    } ${index === state + 1 && "border-l-transparent"}`
              } ${index === 0 && "border-l-0"} 
              ${index === 2 && "border-r-0"}`}
            >
              {key}
              <div className="absolute -bottom-2 bg-white w-full h-2 left-0" />
            </div>
          );
        })}
      </div>
      {/* Tab Content */}
      <div className="flex flex-col border-black px-4 py-3 border-t text-[18px]">
        {Object.values(items)[state]}
      </div>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router";
import { useState } from "react";
import validator from "validator";

const SignInUsernameScreen = () => {
  const navigate = useNavigate();
  const [error, showerror] = useState("");
  
  const verify = () => {
    const value = document.getElementById("id_input").value;
    // Regular expressions for email, phone number, and username validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^\d{10}$/; 
    const usernameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9_-]+$/;

    if (emailRegex.test(value))
      navigate("/signin/password", { state: { id: 0, value: value } });
    else if (phoneRegex.test(value))
      navigate("/signin/password", { state: { id: 1, value: value } });
    else if (usernameRegex.test(value))
      navigate("/signin/password", { state: { id: 2, value: value } });
    else showerror("Please enter a valid Email/Username/Phone number");
  };


  return (
    <div className="w-full bg-[#FFF9F0] flex items-center justify-center py-10">
      <div className="cursor-default border md:border-2 border-black w-[90%] max-w-[450px] min-h-[73%] rounded-md py-6 md:py-12 px-4 md:px-7 bg-white">
        <h1 className="text-3xl mediumFont text-black">Sign In</h1>
        <div className="mt-8">
          <p className="text-base mediumFont text-black">
            Email ID/Phone Number/Username
          </p>
          <input
            id="id_input"
            type="text"
            placeholder="iPhone 12 mini, skoda fabia,"
            className=" w-full mt-1 py-2 px-2 rounded 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black"
          />
          {error && (
            <div className="mt-3 py-3 px-3 bg-[#FFE9E9]">
              <p className="text-sm">{error}</p>
            </div>
          )}
          <button
            onClick={() => verify()}
            className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont mt-5"
          >
            Sign In
          </button>
        </div>
        <div className="mt-9">
          <h1 className="text-center">New To Stake Atlas?</h1>
          <button
            onClick={() => navigate("/signup")}
            className="w-full text-xs xl:text-base py-2.5 px-7 rounded-[4px] bg-white text-black border-[1px] border-sa-border-black mediumFont mt-6"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInUsernameScreen;

import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { userOrders } from '../redux/actions/auth';
import { useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';

const OrderResponse = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { transactionId } = useParams();
    const [details, setDetails] = useState(null);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axiosInstance
            .get(`/payment/transactionStatus/${transactionId}`)
            .then((res) => {
                setLoading(false);
                const { data } = res.data;
                setDetails(data);
                setStatus(data.status);
                dispatch(userOrders());
            })
            .catch(({ response }) => {
                try {
                    switch (response.status) {
                        case 400:
                            //   add a toast
                            toast.error("Invalid Data!");

                            break;
                        case 422:
                            //   add a toast
                            toast.error("Invalid credentials!");

                            break;
                        case 404:
                            //   add a toast
                            break;
                        default:
                            // server error
                            toast.error("Oops, something went wrong");

                            break;
                    }
                } catch (e) {
                    toast.error("Couldn't reach the server");
                }
            })
    }, [])

    return (
        <>
            {loading &&
                <div className="mt-5 flex items-center justify-center flex-col px-3 pb-10 xl:mt-10">
                    <h1 className=" w-[95%] text-[26px] mediumFont text-black text-center xl:text-[32px] pb-10">
                        Please Wait.
                    </h1>
                    <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
                </div>}

            {/* successfull */}
            {status === "success" && !loading ?
                <div className="bg-white w-full h-[600px] overflow-y-auto text-left text-xs text-text font-sub-heading flex justify-center">
                    <div className="box-border w-[1090px] h-[401px] overflow-hidden text-mini border-[2px] border-solid border-text border-black flex justify-center mt-12 p-12">
                        <img
                            className="mx-4"
                            alt="Success Image"
                            src={require("../assets/new design/tick-circle-svgrepo-com 1.png")}
                        />
                        <div className="w-[80%] mx-4 my-4">
                            <div className="text-3xl">
                                <span>{`Order `}</span>
                                <span className="text-royalblue underline">#{details?.order}</span>
                                <span> successfully placed!</span>
                            </div>
                            <div className="my-4">
                                <p className="text-[16px]">A confirmation email has been sent to you.</p>
                                <p className="text-[16px]">
                                    <span>{`We’ll send you delivery updates on your email and phone, you can opt out of them `}</span>
                                    <span className="text-royalblue underline">here</span>
                                </p>
                            </div>
                            <div className="my-4">
                                <p className="text-[16px] my-2">Here’s a summary of your order:</p>
                                <ul className="list-disc list-inside">
                                    <li className="my-1 text-[16px]">Items: {details?.orderDetails?.total_qty}</li>
                                    <li className="my-1 text-[16px]">Total Amount: {details?.orderDetails?.total_price}</li>
                                    <li className="my-1 text-[16px]">Deliver to: Sarthak Mital, 9630447822</li>
                                    <li className="my-1 text-[16px]">Address: E-7-52 Arera Colony, Bhopal, MP, 462016</li>
                                </ul>
                            </div>
                            <button className="rounded bg-primary box-border w-[487px] py-4 px-[68px] border-[1px] border-solid border-black text-center mx-4 text-[16px] font-normal bg-sa-primary-yellow"
                                onClick={() => { navigate("/buying/purchaseHistory") }}
                            >
                                Order details
                            </button>
                        </div>
                    </div>
                </div> : status === "success" && loading &&
                <div className="mt-5 flex items-center justify-center flex-col px-3 pb-10 xl:mt-10">
                    <h1 className=" w-[95%] text-[26px] mediumFont text-black text-center xl:text-[32px] pb-10">
                        Please Wait.
                    </h1>
                    <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
                </div>
            }

            {/* failed */}
            {status === "failed" || status === "processing" && !loading ?
                <div className="bg-white w-full h-[600px] overflow-y-auto text-left text-xs text-text font-sub-heading flex justify-center">
                    <div className="box-border w-[1090px] h-[401px] overflow-hidden text-mini border-[2px] border-solid border-text border-black flex justify-center mt-12 p-12">
                        <img
                            className="mx-4"
                            alt="Failed Image"
                            src={require("../assets/new design/add-circle-svgrepo-com 1.png")}
                        />
                        <div className="w-[80%] mx-4 my-4">
                            <div className="text-3xl">
                                <span>{`We Couldn't Complete Your Order! `}</span>
                            </div>
                            <div className="my-4">
                                <p className="text-[16px]">The payment method you tried couldn’t be used to complete this payment.Please review and update your payment information, or choose an alternative payment method to complete your order.</p>
                            </div>
                            <div className="my-4">
                                <p className="text-[20px]">
                                    <span>{`Your items are still stored in cart`}</span>
                                </p>

                                <p className="text-[16px]">{' '}</p>
                                <p className="text-[16px]">{' '}</p>
                                <p className="text-[16px]">{' '}</p>
                                <p className="text-[16px]">{' '}</p>
                                <p className="text-[16px]"></p>
                            </div>
                            <button className="rounded bg-primary box-border w-[487px] py-4 px-[68px] border-[1px] border-solid border-black text-center mx-4 text-[16px] font-normal bg-sa-primary-yellow my-5"
                                onClick={() => { navigate("/cart") }}
                            >
                                Retry
                            </button>
                        </div>
                    </div>
                </div> : status === "failed" && loading &&
                <div className="mt-5 flex items-center justify-center flex-col px-3 pb-10 xl:mt-10">
                    <h1 className=" w-[95%] text-[26px] mediumFont text-black text-center xl:text-[32px] pb-10">
                        Please Wait.
                    </h1>
                    <ReactLoading type="spin" color="#000000" height={'20%'} width={'20%'} />
                </div>
            }
        </>
    );
};

export default OrderResponse;

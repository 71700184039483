import { useDispatch, useSelector } from "react-redux";
import { DELIVERY_LOCATION_MODAL } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { useState } from "react";
import { addAddress } from "../../redux/actions/auth";

const AddNewAddressModal = () => {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state) => state.modal);
  const [newAddress, setNewAddress] = useState({
    name: "",
    phone: "",
    address_line1: "",
    state: "",
    city: "",
    pincode: "",
  });
  const [error, setError] = useState("");

  const handleSave = () => {
    // Validate the form fields
    if (
      !newAddress.name ||
      !newAddress.phone ||
      !newAddress.address_line1 ||
      !newAddress.state ||
      !newAddress.city ||
      !newAddress.pincode
    ) {
      setError("Please fill out all fields.");
      return;
    }

    // Dispatch the action to create the address
    dispatch(addAddress(newAddress));
    dispatch(
      showModal({
        modalType: DELIVERY_LOCATION_MODAL,
        modalTitle: ``,
        modalSubTitle: "",
      })
    );
  };

  return (
    <div>
      {/* Desktop */}
      <div className="bg-white px-5 py-7 rounded hidden xl:flex xl:flex-col">
        <h1 className="text-xl mediumfont text-black mb-3">Create Address</h1>

        <div className="border-2 border-black rounded-md bg-[#FCF9F4]">
          <div className="px-3 py-3 border-b-2 border-black">
            <div className="flex items-start mb-1">
              <h1 className="w-[40%] text-lg font-bold text-black">Name:</h1>
              <input
                type="text"
                placeholder="Name"
                value={newAddress.name}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    name: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-1">
              <h1 className="w-[40%] text-lg font-bold text-black">
                Contact No:
              </h1>
              <input
                type="text"
                placeholder="Contact No"
                value={newAddress.phone}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    phone: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-1">
              <h1 className="w-[40%] text-lg font-bold text-black">Address:</h1>
              <input
                type="text"
                placeholder="Address"
                value={newAddress.address_line1}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    address_line1: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-1">
              <h1 className="w-[40%] text-lg font-bold text-black">State:</h1>
              <input
                type="text"
                placeholder="State"
                value={newAddress.state}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    state: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-1">
              <h1 className="w-[40%] text-lg font-bold text-black">City:</h1>
              <input
                type="text"
                placeholder="City"
                value={newAddress.city}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    city: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-1">
              <h1 className="w-[40%] text-lg font-bold text-black">Pincode:</h1>
              <input
                type="text"
                placeholder="Pincode"
                value={newAddress.pincode}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    pincode: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            {error && (
              <p className="text-red-500 text-lg mt-2">{error}</p>
            )}
          </div>

          <div className="px-3 py-3 rounded-md">
            <div className="w-full flex items-center gap-2 justify-end">
              <button
                onClick={() => {
                  dispatch(
                    showModal({
                      modalType: DELIVERY_LOCATION_MODAL,
                      modalTitle: ``,
                      modalSubTitle: "",
                    })
                  );
                }}
                className="text-[14px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumfont hover:cursor-pointer"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSave()}
                className="text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont hover:cursor-pointer"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="bg-white px-2 py-7 rounded xl:hidden">
        <h1 className="text-xl mediumfont text-black mb-3">Create Address</h1>

        <div className="border-2 border-black rounded-md bg-[#FCF9F4]">
          <div className="px-3 py-3 border-b-2 border-black">
            <div className="flex items-start mb-2">
              <h1 className="w-[40%] text-lg font-bold text-black">Name</h1>
              <input
                type="text"
                placeholder="Name"
                value={newAddress.name}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    name: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-2">
              <h1 className="w-[40%] text-lg font-bold text-black">
                Contact No:
              </h1>
              <input
                type="text"
                placeholder="Contact No"
                value={newAddress.phone}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    phone: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-2">
              <h1 className="w-[40%] text-lg font-bold text-black">Address:</h1>
              <input
                type="text"
                placeholder="Address"
                value={newAddress.address_line1}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    address_line1: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-2">
              <h1 className="w-[40%] text-lg font-bold text-black">State:</h1>
              <input
                type="text"
                placeholder="State"
                value={newAddress.state}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    state: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-2">
              <h1 className="w-[40%] text-lg font-bold text-black">City:</h1>
              <input
                type="text"
                placeholder="City"
                value={newAddress.city}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    city: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            <div className="flex items-start mb-2">
              <h1 className="w-[40%] text-lg font-bold text-black">Pincode:</h1>
              <input
                type="text"
                placeholder="Pincode"
                value={newAddress.pincode}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    pincode: e.target.value,
                  })
                }
                className="w-full text-black mt-1 py-1 px-2 rounded-md border-[1px] border-black"
              />
            </div>
            {error && (
              <p className="text-red-500 text-lg mt-2">{error}</p>
            )}
          </div>

          <div className="px-3 py-3 rounded-md">
            <div className="w-full flex items-center gap-2 justify-end">
              <button
                onClick={() => {
                  dispatch(
                    showModal({
                      modalType: DELIVERY_LOCATION_MODAL,
                      modalTitle: ``,
                      modalSubTitle: "",
                    })
                  );
                }}
                className="text-[14px] py-2 px-3 rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumfont"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSave()}
                className="text-[14px] py-2 px-3 rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black mediumfont"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAddressModal;

import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  AccessTimeOutlined,
  ArrowBackIos,
  ArrowForwardIos,
  LocationOnOutlined,
  MailOutline,
  Star,
} from "@mui/icons-material";
import DropDown from "../components/DropDown";
import PreviewCard from "../components/Seller/PreviewCard";
import { useDispatch, useSelector } from "react-redux";
import { saveDraft, saveProductDetails } from "../redux/actions/product";
import PhotoPreview from "../components/Seller/PhotoPreview";
import SaveItemSellingDraft from "../components/Modals/SaveItemSellingDraft";
import SignInpanelGeneral from "../components/SignInpanelGeneral";
import { Link } from "react-router-dom";

const MediaScreen = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { product } = useSelector((state) => state.product);
  const [info, setInfo] = useState(product);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadedImages, setUploadedImages] = useState(info?.images ?? []);
  const productDetailHandler = () => {
    dispatch(saveProductDetails(info));
    navigate("/sellnow/listing/details");
  };

  const [openGallery, setOpenGallery] = useState(false);
  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  const handleFileUpload = (files) => {
    const updatedImages = [...uploadedImages];

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;
        updatedImages.push(base64String);

        // Check if all images are processed
        if (updatedImages.length === uploadedImages.length + files.length) {
          setUploadedImages(updatedImages);
          setInfo({ ...info, images: updatedImages });
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = uploadedImages.filter((_, index) => index !== indexToRemove);
    setUploadedImages(updatedImages);
    setInfo({ ...info, images: updatedImages });
  };

  const handleNext = () => {
    dispatch(saveProductDetails(info));
    navigate("/sellnow/listing/details");
  }

  const handleSave = () => {
    dispatch(saveProductDetails(info));
    setModalOpen(true);
  }

  const handleSaveDraft = () => {
    dispatch(saveDraft(product));
    navigate("/sellnow/drafts");
  }

  const width = 50;
  return (
    <>
      {user && user.role !== "customer_unverified" ?
        <div>
          <div className="px-4.5 mt-7 md:px-[4vw] md:mt-10">
            <div className=" h-[80vh] border-[1px] border-black pl-3 max-[768px]:pr-3 mb-7 xl:border-[2px] xl:px-0 lg:flex md:items-start md:mb-10 overflow-y-hidden relative">
              {openGallery && <PhotoPreview onClose={handleCloseGallery} uploadedImages={uploadedImages} />}
              <div className="lg:w-[51%] lg:border-r-[2px] h-full border-black md:px-[2vw]">
                <div className="flex-col align-top h-full">
                  {/* heading div */}
                  <div className="h-[10%] flex items-start mt-7 gap-x-3 ">
                    <div>
                      <img
                        src={require("../assets/new design/Vector.png")}
                        alt=""
                        className="w-9 xl:w-12"
                      />
                    </div>
                    <h1 className=" text-2xl xl:text-4xl mediumFont text-black leading-7 p-0">
                      What are you selling today?
                    </h1>
                  </div>
                  {/* photos addition div */}
                  <div className="h-[70%] overflow-y-auto no-scrollbar md:pr-7">
                    <div className="mt-6 border-b-[1px] border-black pb-2.5">
                      <h1 className=" text-[21px] xl:text-2xl mediumFont text-black">
                        Media
                      </h1>
                    </div>
                    <div className="mt-6">
                      <h1 className="text-sm xl:text-base mediumFont text-blue-900">
                        Photos
                      </h1>
                      <p className="mt-1">
                        Please populate each of these sections with at least one
                        photo. Quality photos boost views, prices, and buyer
                        confidence.
                      </p>
                      <div
                        className="w-full mt-4 py-4 border-[1px] border-dashed border-black px-2"
                        style={{ background: "#FFFCF8" }}
                      >
                        <h1 className="text-center" style={{ color: "#005C51" }}>
                          Photos Pool
                        </h1>
                        <div className="flex gap-2">
                          <label
                            for="file-upload"
                            className="w-32 h-28 outline-none  mt-2 text-center text-l px-2 boldFont flex items-center justify-center border-[1px] border-black rounded cursor-pointer"
                          >
                            Upload Photos
                            <input
                              style={{ display: "none" }}
                              id="file-upload"
                              type="file"
                              className="focus:outline-none"
                              onChange={(e) => handleFileUpload(e.target.files)}
                              multiple
                            />
                          </label>
                          {uploadedImages.map((image, index) => (
                            <div key={index} className="relative">
                              <img
                                src={image}
                                alt={`Thumbnail ${index + 1}`}
                                className="w-32 h-28 outline-none  mt-2 text-center text-[2vw] boldFont flex items-center justify-center border-[1px] border-black rounded cursor-pointer"
                              />
                              <button
                                className="absolute top-0 right-0 bg-black text-white font-bold py-0 px-2 rounded"
                                style={{
                                  "transition": "background-color 0.3s",
                                  ":hover": {
                                    backgroundColor: "#FFDC25",
                                  }
                                }}
                                onClick={() => handleRemoveImage(index)}
                              >
                                X
                              </button>
                            </div>
                          ))}
                        </div>
                        <button
                          onClick={() => setOpenGallery(true)}
                          class="w-full bg-yellow-400 rounded-lg px-auto mt-4 py-2 text-black text-[14px] mediumFont "
                        >
                          Categorize Photos
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* buttons div */}
                  <div className="h-[20%] flex items-center justify-between gap-2 ">
                    <button
                      onClick={() => navigate("/sellnow/listing/general")}
                      className="text-[14px] py-3 2md:px-10  min-[444px]:px-10 px-5 rounded-[4px] bg-[#fff] text-black border-[1px] border-sa-border-black mediumFont"
                    >
                      Previous
                    </button>
                    <div className="space-x-2 px-2">
                      <button
                        onClick={() => handleSave()}
                        className="text-[14px] py-3 2md:px-10 min-[444px]:px-10 px-5 rounded-[4px] bg-[#00BEA7] text-black border-[1px] border-sa-border-black mediumFont"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => handleNext()}
                        className="text-[14px] py-3 2md:px-10 min-[444px]:px-10 px-5 rounded-[4px] bg-[#005C51] text-white border-[1px] border-sa-border-black mediumFont"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <PreviewCard info={info} width={width} />
            </div>
          </div>
          <SaveItemSellingDraft
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSuccess={() => handleSaveDraft()}
            title="Save Listing As a Draft"
            content="You can comeback anytime to complete this listing."
            confirmText="Save"
            cancelText="Cancel"
          />
        </div> :
        user && user.role === "customer_unverified" && user.seller_request !== "created" ?
          <div className=" min-h-screen md:flex hidden">
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must register as a seller to Proceed.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Only One listing per customer is allowed, For more please

                    <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/seller/registration">Register Here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div> : user && (user.role === "customer_unverified" || user.role === "customer") && user.seller_request === "created" ?
            <div className="h-[50vh] bg-[#F3CEB4] px-5 py-14 xl:pt-28 xl:h-[60vh]">
              <div className=" border-[1px] border-black bg-white py-12 px-5 xl:w-fit xl:mx-auto xl:px-10 xl:py-12">
                <h1 className="text-3xl mediumFont text-black text-center tracking-wide xl:text-[40px]">
                  Your Request is under Review!
                </h1>
                <p className="text-lg mediumFont text-black text-center mt-2 tracking-wide xl:text-[29px] xl:mt-3">
                  You will be informed, when we are done.
                </p>
                <div className="flex items-center justify-center gap-x-12 mt-5 xl:mt-8">
                  <h1 className="text-[22px] boldFont tracking-wide text-[#737373] xl:text-[25px]">
                    Status
                  </h1>
                  <div className="flex items-center gap-x-1">
                    <AccessTimeOutlined fontSize="small" />
                    <h1 className="text-[22px] boldFont tracking-wide text-sa-icon-green xl:text-[25px]">
                      Pending
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="w-full border-b-[1px] border-black">
              <div>
                <div className="text-[32px] mediumFont leading-3 p-40 text-center item-center">
                  You must Sign In to View this page.
                  <p className="text-sm mediumFont leading-3 p-5  text-center">
                    Have a Stake Atlas account? Sign in, or Sign Up
                  </p>

                  <div className="w-full item-center text-center" >
                    <SignInpanelGeneral />
                  </div>
                </div>
              </div>
            </div>}
    </>
  );
};

export default MediaScreen;

import { Cancel, Star } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { CONTACT_SELLER_SUBMIT_MESSAGE_MODAL } from "../../extras/constants";
import { hideModal, showModal } from "../../redux/actions/modal";
import { useParams } from "react-router";
import { counterOfferbyBuyer } from "../../redux/actions/product";
import { useState } from "react";

const CounterOfferModal = ({props}) => {
  const dispatch = useDispatch();
  const offer = props;
  const [amount,setAmount] = useState(0);

  const yourOffer = offer?.offer[offer?.offer?.length - 2];
  const sellerOffer = offer?.offer[offer?.offer?.length - 1];

  const handleCounter = () => {
    dispatch(counterOfferbyBuyer(offer._id,offer.product.id,amount));
    dispatch(
      showModal({
        modalType: CONTACT_SELLER_SUBMIT_MESSAGE_MODAL,
        modalTitle: ``,
        modalSubTitle: "",
        modalProps: {
          type: "Counter Sent",
          color: "#0167DE",
          msg: "70% buyers accept a counter offer from a seller. Make a counter offer to make a deal!",
        },
      })
    );

  }

  return (
    <div>
      <div className="hidden xl:flex">
        <div className="px-4  w-[70%] mx-auto">
          <div className="border-[1px] border-black relative rounded-lg">
            <div
              onClick={() => dispatch(hideModal())}
              className=" absolute -top-4 -right-3"
            >
              <Cancel className="text-base text-red-400" />
            </div>
            <div className="bg-white px-7 py-5 rounded-lg">
              <div className="mb-2">
                <h1 className="text-[40px] mediumfont text-black text-center">
                  Counter Offer
                </h1>
                <div className="flex items-center justify-between my-4">
                  <div>
                    <h1 className="text-lg mediumfont text-sa-light-brown">
                      From
                    </h1>
                    <h1 className="text-2xl mediumfont text-black">
                      Seller
                    </h1>
                  </div>
                  <div>
                    
                  </div>
                  <div>
                    <h1 className="text-lg mediumfont text-sa-light-brown">
                      Offer Amount
                    </h1>
                    <h1 className="text-2xl mediumfont text-black">
                    ₹ {sellerOffer.amount}/-
                    </h1>
                  </div>
                </div>
                <div>
                  <h1 className="text-lg mediumfont text-sa-light-brown">
                    Counter Amount
                  </h1>
                  <input
                    type="Number"
                    placeholder={`₹ ${sellerOffer.amount} or Less`}
                    onChange={(e) => {setAmount(e.target.value)}}
                    className=" w-full h-10 px-2 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black mt-1 rounded"
                  />
                  <button
                    onClick={() => handleCounter()}
                    className="w-full text-[18px] mt-4 py-2 px-5 rounded-[4px] bg-[#0167DE] text-white border-[1px] border-black mediumfont flex items-center justify-center"
                  >
                    Counter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="w-screen xl:hidden">
        <div className="px-4">
          <div className="border-[1px] border-black rounded-md relative">
            <div
              onClick={() => dispatch(hideModal())}
              className=" absolute -top-4 -right-3"
            >
              <Cancel className="text-base text-red-400" />
            </div>
            <div className="bg-white px-3 py-5 rounded-md">
              <div>
                <div className="mb-2">
                  <h1 className="text-[28px] mediumfont text-black text-center">
                    Counter Offer
                  </h1>
                  <div className="my-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <h1 className="text-sm mediumfont text-sa-light-brown">
                          From
                        </h1>
                        <h1 className="text-base mediumfont text-black">
                          Seller
                        </h1>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <h1 className="text-sm mediumfont text-sa-light-brown">
                        Offer Amount
                      </h1>
                      <h1 className="text-base mediumfont text-black">
                      ₹ {sellerOffer.amount}/-
                      </h1>
                    </div>
                  </div>
                  <div>
                    <h1 className="text-lg mediumfont text-sa-light-brown">
                      Counter Amount
                    </h1>
                    <input
                      type="text"
                      placeholder={`₹ ${sellerOffer.amount} or Less`}
                      className=" w-full h-10 px-2 3tablet:text-xl 4tablet:text-[23px] focus:outline-none outline-none border-[1px] border-black mt-1 rounded"
                    />
                    <button
                      onClick={() => handleCounter()}
                      f
                      className="w-full text-[18px] mt-4 py-2 px-5 rounded-[4px] bg-[#0167DE] text-white border-[1px] border-black mediumfont flex items-center justify-center"
                    >
                      Counter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterOfferModal;

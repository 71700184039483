import React, { useEffect } from "react";
import SideMenu from "../components/Buying/SideMenu";
import { useState } from "react";
import ActiveListingCard from "../components/Selling/ActiveListingCard";
import { ExpandMore, ExpandLess, Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUserChats, userActiveListings } from "../redux/actions/auth";
import { getProductCompressedImages } from "../redux/actions/product";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";

const ActiveListings = () => {
  const dispatch = useDispatch();
  const offerTabs = ["All Active", "Local", "Shipment", "Pending Offers"];
  const [fontSize, setFontSize] = useState("text-4xl");
  const [activeTab, setActiveTab] = useState("All Active");
  const { user } = useSelector((state) => state.auth)
  const { products } = useSelector((state) => state.product);
  const [listings, setListings] = useState([]);

  useEffect(() => {
    dispatch(getUserChats());
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`/product/activeListings/active`)
      .then((response) => {
        setListings(response.data.data);
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops! Something Went Wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  }, []);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0) {
      setFontSize("text-xl");
    } else {
      setFontSize("text-4xl");
    }
  };
  const data = [
    {
      id: 1,
      status: "Pending Payment",
    },
    {
      id: 2,
      status: "paid",
    },
    {
      id: 3,
      status: "completed",
    },
    { id: 4, status: "Pending Payment" },
    {
      id: 5,
      status: "paid",
    },
    {
      id: 6,
      status: "completed",
    },
    { id: 7, status: "Pending Payment" },
    {
      id: 8,
      status: "paid",
    },
    {
      id: 9,
      status: "completed",
    },
    { id: 10, status: "Pending Payment" },
  ];
  const [phonesidenav, showphonesidenav] = useState(true);
  const sortarray = ["New to Old", "Old to New", "Recently Added", "Date All"];
  const [showsort, setshowsort] = useState(false);
  const [sorttype, setsorttype] = useState(0);

  return (
    <div>
      {/* desktop */}
      {listings ?
        <div className="hidden 2md:flex items-start min-h-screen">
          <div className="self-stretch">
            <SideMenu />
          </div>
          <div className="flex-1 w-full overflow-hidden bg-[#FCF9F4]">
            <div
              className={`border-b-[1px] border-black pl-12 pr-16 transition-all duration-300 ease-in-out ${fontSize === "text-4xl" ? "pt-8 pb-9" : "pt-4 pb-6"
                }`}
            >
              <h1
                className={`${fontSize} transition-all duration-300 ease  mediumFont text-black`}
              >
                Active Listings
              </h1>
              <div
                className={`${fontSize === "text-4xl" ? "mt-5" : "mt-2"
                  } transition-all duration-300 ease-in-out flex items-center justify-between`}
              >
                <div className="flex items-center flex-wrap max-w-[600px] mr-10 grow justify-between">
                  {offerTabs.map(function (e, i) {
                    return (
                      <button
                        onClick={() => setActiveTab(e)}
                        key={i}
                        className={`px-5 text-[18px] mt-2.5 py-2  rounded-md ${activeTab === e
                          ? "bg-white border-[1px] border-sa-border-black"
                          : "bg-[#FCF9F4]  border-[1px] border-[#FCF9F4]"
                          } text-black mediumFont flex items-center justify-center`}
                      >
                        {e}
                      </button>
                    );
                  })}
                </div>

                {/* <div className="relative z-20 w-max place-items-center">
                <button
                  onClick={() => { setshowsort(!showsort) }}
                  className={`text-[18px] mt-2.5 py-2 px-5 border-[1px] border-sa-border-black
                  ${(showsort) ? "rounded rounded-b-none border-b-0" : "rounded"} 
                  text-black mediumFont flex items-center justify-center bg-[#FCF9F4]`}>
                  Sort: {sortarray[sorttype]}  &nbsp;{!showsort ? <ExpandMore /> : <ExpandLess />}
                </button>
                <div className={`flex-col absolute w-full rounded rounded-t-none border-black bg-[#FCF9F4] overflow-hidden transition-all duration-500 ease-in-out ${showsort ? "max-h-[100vh] border-[1px]" : "max-h-0 border-[0px]"}`}>
                  {sortarray.map((type, i) => (
                    <div key={`cat${i}`} onClick={() => { setsorttype(i); setshowsort(!showsort); }} className='mt-2.5 py-2 relative cursor py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3'>
                      <p>{type}</p>
                    </div>
                  ))}
                </div>
              </div> */}
              </div>
            </div>
            <div
              onScroll={handleScroll}
              className="no-scrollbar transition-all duration-600 ease w-[100%] pl-12 pr-8 pt-9 h-screen overflow-y-auto overflow-x-hidden grid grid-cols-fluid"
            >
              {listings && listings.map((item, index) => (
                <ActiveListingCard key={index} listing={item} />
              ))}
            </div>
          </div>
        </div> :
        <div className="hidden 2md:flex items-start min-h-screen">
          <div className="self-stretch">
            <SideMenu />
          </div>
          <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
            <div className="text-[32px] mediumFont p-40  text-center item-center">
              You Don't Have any listings Yet.
              <p className="text-sm mediumFont text-center">
                <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/sellnow/drafts">Start by posting a listing or {' '}</Link>
                <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
              </p>
            </div>
          </div>
        </div>
      }
      {/* mobile */}
      {listings ?
        <div className=" bg-[#FCF9F4] min-h-screen pb-28 2md:hidden">
          <div
            className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
              } overflow-hidden`}
          >
            <SideMenu />
          </div>
          <div className="pt-9 px-4 pb-3.5 border-b-[1px] border-black">
            <h1 className="text-[20px] mediumFont text-sa-menu-green">Selling</h1>
            <h1 className="text-[26px] mediumFont text-black tracking-wide">
              Active Listings
            </h1>
          </div>
          <div className="pt-7 px-4 grid grid-flow-row align-center justify-center transition-all  duration-500 ease">
            {listings && listings.map((item, index) => (
              <ActiveListingCard key={index} listing={item} />
            ))}
          </div>
          <div className="fixed  z-50 bottom-0 w-full grid grid-cols-[100%]">
            <div
              className={`grid grid-cols-[100%] bg-white px-4 py-4 rounded-t-lg border-[2px] border-sa-border-black ${showsort ? "" : "hidden"
                }`}
            >
              <h2 className="text-2xl py-t-2 boldFont">Sorting</h2>
              <div
                onClick={() => {
                  setshowsort(!showsort);
                }}
                className=" absolute top-4 right-3"
              >
                <Cancel className="text-base " />
              </div>
              {sortarray.map((type, i) => (
                <button
                  className={`text-left py-3 border-b-[1px] border-gray-400 ${i !== sorttype ? "" : "boldFont"
                    }`}
                  onClick={() => {
                    setsorttype(i);
                  }}
                >
                  {type}
                </button>
              ))}
            </div>
            <div className="grid grid-cols-[50%_50%]">
              <button
                onClick={() => {
                  showphonesidenav(!phonesidenav);
                }}
                className={` w-full text-base py-2 ${phonesidenav
                  ? "bg-black text-sa-primary-yellow"
                  : "bg-sa-primary-yellow text-black"
                  } border-[1px] border-sa-border-black mediumFont`}
              >
                Offers
              </button>
              <button
                onClick={() => {
                  setshowsort(!showsort);
                }}
                className=" w-full text-base py-2 bg-white text-sa-menu-green border-[1px] border-sa-border-black mediumFont"
              >
                Sort: {sortarray[sorttype]}
              </button>
            </div>
          </div>
        </div> :
        <div className="bg-[#FCF9F4] mb-28 2md:hidden">
          <div
            className={`fixed z-40 w-full self-stretch transition-all duration-300 ease ${phonesidenav ? "h-0" : "h-full"
              } overflow-hidden`}
          >
            <SideMenu />
          </div>
          <div className="flex-1 w-full  overflow-hidden bg-[#FCF9F4]">
            <div className="text-[32px] mediumFont p-40  text-center item-center">
              You Don't Have any Listings Yet.
              <p className="text-sm mediumFont text-center">
                <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/sellnow/drafts">Start by posting a listing or {' '}</Link>
                <Link className="text-sm mediumFont leading-3  text-center text-blue-700 underline" to="/">Continue Browsing on State atlas</Link>
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default ActiveListings;

import React from "react";
import smiley from "../../assets/icons/smiley.svg";
const OfferDeclined = () => {
  return (
    <div>
      {/* desktop */}
      <div class="w-[529px] 2md:flex hidden h-[279px] relative bg-stone-50 rounded-lg border border border border border-black">
        <div class="left-[182px] top-[32px] absolute text-center text-[#D1503B] text-[42px] mediumFont leading-10">
          Declined
        </div>
        <div class="w-[435px] left-[47px] top-[92px] absolute text-center text-black text-[18px] font-normal leading-normal">
          There’s plenty of fish in the sea! On average 90% of the listings
          receive more than 3 offers.
        </div>
        <div className="w-[100px] h-[100px] absolute left-1/2 top-[220px] transform -translate-x-1/2 -translate-y-1/2">
          <img src={smiley} alt="Smiley" />
        </div>
      </div>
      {/* mobile */}
      <div class="w-[340px] 2md:hidden h-[290px] relative bg-white rounded-md">
        <div class="w-[300px] left-[20px] top-[78px] absolute text-center text-black text-[18px] font-normal leading-normal">
          There’s plenty of fish in the sea! On average 90% of the listings
          receive more than 3 offers.
        </div>
        <div class="w-full flex justify-center top-[32px] absolute text-center text-[#D1503B] text-[28px] boldFont leading-9">
          Declined!
        </div>
        <div className="w-full justify-center flex h-[100px] absolute  top-[220px] transform -translate-y-1/2">
          <img src={smiley} alt="Smiley" />
        </div>
      </div>
    </div>
  );
};

export default OfferDeclined;

import React, { useState } from "react";
import GoXCircleFill from "react-icons/go";
import "./SignInPanel.css";
import axiosInstance from "../axiosInstance";
import { LOGIN_USER } from "../redux/actions/types";
import { getUserCart } from "../redux/actions/cart";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { showModal } from "../redux/actions/modal";
import { CREATE_OFFER_MODAL } from "../extras/constants";
import { getCurrentUser } from "../redux/actions/auth";

const SignInPanel = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSignUp, setSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [isOtp, setisOtp] = useState(true);
  const { user } = useSelector((state) => (state.auth))
  const divStyle2 =
    "font-mabry-pro text-18 font-normal leading-23 tracking-normal text-center text-black pt-10";

  const divStyle = {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#000000",
    background: "#FFFFFF",
  };

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isOtpVisible, setOtpVisible] = useState(false);
  const [isContinueVisible, setContinue] = useState(true);
  const [isSubmitVisible, setSubmit] = useState(false);
  const [message, setmessage] = useState("");

  const togglePanel = () => {
    document.body.style.overflow = "hidden";
    if (user) {
      dispatch(
        showModal({
          modalType: CREATE_OFFER_MODAL,
          modalTitle: ``,
          modalSubTitle: "",
          modalProps: product,
        })
      );
    }
    else {
      setIsOpen(!isOpen);
    }
  };

  const handleinup = () => {
    setSignUp(!isSignUp);
    setIsOpen(true);
    setEmail("");
    setPassword("");

    setisOtp(true);
    setContinue(true);
    setPasswordVisible(false);
    setOtpVisible(false);
    setSubmit(false);
  };

  const closePanelUp = () => {
    setSignUp(!isSignUp);
    setEmail("");
    setNumber("");
    setSignUp(false);
    setContinue(true);
    setOtpVisible(false);
    setSubmit(false);
    document.body.style.overflow = "visible";
  };

  const handleContinueSignUp = () => {
    setOtpVisible(!isOtpVisible);
    setContinue(!isContinueVisible);
    setSubmit(!isSubmitVisible);
    setisOtp(!isOtp);
  };

  const closePanel = () => {
    document.body.style.overflow = "visible";
    setIsOpen(!isOpen);
    setEmail("");
    setPassword("");
    setIsOpen(false);
    setContinue(true);
    setPasswordVisible(false);
    setOtpVisible(false);
    setSubmit(false);
  };

  const togglePassword = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const toggleOtp = () => {
    setOtpVisible(!isOtpVisible);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (email.includes("@")) {
      togglePassword();
    } else {
      toggleOtp();
    }
    setContinue(!isContinueVisible);
    setSubmit(!isSubmitVisible);
  };

  const toggleSignUp = () => {
    document.body.style.overflow = "hidden";
    setIsOpen(!isOpen);
    setSignUp(!isSignUp);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    // Add your sign-in logic here

    axiosInstance
      .post("/auth/login", { email, password })
      .then((response) => {
        if (response.data.token) {
          console.log("Before Setting Access Token on Login"+ response.data.token);
          localStorage.setItem("accessToken", response.data.token);
          console.log("After Setting Access Token on Login"+ response.data.token);
          dispatch({
            type: LOGIN_USER,
            payload: response.data,
          });
          dispatch(getCurrentUser());
          toast.success("Logged in Successfully");
          setmessage(`Welcome, `);
          dispatch(
            showModal({
              modalType: CREATE_OFFER_MODAL,
              modalTitle: ``,
              modalSubTitle: "",
              modalProps: product,
            })
          );
        }
      })
      .catch((err) => {
        setmessage(err.response.data.error);
      })


    // Reset the form
    setEmail("");
    setPassword("");
    setIsOpen(false);
    setContinue(true);
    setPasswordVisible(false);
    setOtpVisible(false);
    setSubmit(false);
    setisOtp(true);
    document.body.style.overflow = "visible";
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    // Add your sign-in logic here
    console.log("Email:", email);
    console.log("Number", number);
    console.log("Password:", password);
    // Reset the form
    setEmail("");
    setNumber("");
    setSignUp(false);
    setContinue(true);
    setOtpVisible(false);
    setSubmit(false);
    setisOtp(true);
    document.body.style.overflow = "visible";
  };

  return (
    <div>
      <div className=" flex items-center lg:flex-col lg:space-y-4 justify-between mt-4">
        <button
          className=" w-[calc(50%-8px)] lg:w-full  text-[15px] py-[10px] flex justify-center rounded-[4px] bg-sa-primary-yellow text-black border-[1px] border-sa-border-black  mediumFont "
          onClick={togglePanel}
        >
          Pay Deposit
        </button>
        <button
          className=" w-[calc(50%-8px)] lg:w-full text-[15px] py-[10px] flex justify-center rounded-[4px] bg-[#FCF9F4] text-black border-[1px] border-sa-border-black mediumFont"
          onClick={togglePanel}
        >
          Make an Offer
        </button>
      </div>
      {isOpen && (
        <div className="fixed top-0 right-0 w-full h-screen bg-black bg-opacity-50 z-50 ">
          <div
            className={`fixed top-0 right-${isOpen ? "0" : "full"
              } lg:w-4/12 w-full md:6/12  h-screen bg-white z-100 border-black border-2 
        transition-transform duration-700 transform ${isOpen ? "translate-x-0" : "translate-x-full"
              }
        `}
          >
            <div className="flex items-center justify-between text-26 p-4 border-b-2 border-black mediumFont px-10">
              <p>Sign In/Sign Up</p>
              <button className="text-black" onClick={closePanel}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="px-10 pt-8">
              <form onSubmit={handleSignIn}>
                {!message && <>
                  <div class="font-mabry-pro text-24 font-normal tracking-normal leading-7 text-left">
                    Enter your email/phone number to make an offer for this
                    product
                  </div>
                  <div className="mb-4 pt-8">
                    <label className="custom-div-bg font-normal" style={divStyle}>
                      Email ID or Phone Number
                    </label>
                    <input
                      type="text"
                      id="email"
                      value={email}
                      placeholder="Enter Email ID or Phone Number"
                      onChange={handleEmailChange}
                      className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full "
                      required
                    />
                  </div></>}
                {isPasswordVisible && !message && (
                  <div className="mb-6">
                    <label className="custom-div-bg" style={divStyle}>
                      Password
                    </label>
                    <input
                      type="text"
                      id="password"
                      value={password}
                      placeholder="Enter Password"
                      onChange={handlePasswordChange}
                      className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full"
                      required
                    />
                  </div>
                )}

                {isOtpVisible && !message && (
                  <div className="mb-6">
                    <label className="custom-div-bg" style={divStyle}>
                      Verify OTP
                    </label>
                    <input
                      type="number"
                      id="password"
                      value={password}
                      placeholder="Enter OTP"
                      onChange={handlePasswordChange}
                      className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full "
                      required
                    />
                  </div>
                )}

                {isContinueVisible && !message && (
                  <div className="flex justify-end">
                    <button
                      className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  </div>
                )}

                {isSubmitVisible && !message && (
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black"
                    >
                      Sign In
                    </button>
                  </div>
                )}

                {message && (
                  <div className="text-center text-green-600">{message}</div>
                )}

                <div className={divStyle2}>New To Stake Atlas?</div>

                <button
                  className="bg-customWhite  hover:bg-gray-100 text-black px-4 py-2 border-2 border-black border-opacity-50 rounded-none w-full mt-5 mediumfont"
                  onClick={toggleSignUp}
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {isSignUp && (
        <div className="fixed top-0 right-0 w-full h-screen bg-black bg-opacity-50 z-50 ">
          <div
            className={`fixed top-0 right-${isSignUp ? "0" : "full"
              } lg:w-4/12 w-full h-screen bg-white transition duration-300 z-50`}
          >
            <div className="flex items-center justify-between text-26 p-4 border-b-2 border-black mediumFont px-10">
              <p>Sign In/Sign Up</p>
              <button className="text-black" onClick={closePanelUp}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="px-10 pt-7">
              <form onSubmit={handleSignUp}>
                <div class="font-mabry-pro text-24 font-normal tracking-normal leading-7 text-left pb-3">
                  Enter your email/phone number to make an offer for this
                  product
                </div>
                {isOtp && (
                  <div className="mb-2">
                    <label className="custom-div-bg" style={divStyle}>
                      Email ID
                    </label>
                    <input
                      type="text"
                      id="email"
                      value={email}
                      placeholder="Enter Email ID "
                      onChange={handleEmailChange}
                      className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full "
                      required
                    />
                  </div>
                )}

                {isOtp && (
                  <div className=" pb-10">
                    <label className="custom-div-bg" style={divStyle}>
                      Phone Number
                    </label>
                    <input
                      type="number"
                      id="email"
                      value={number}
                      placeholder="Enter Phone Number"
                      onChange={handleNumberChange}
                      className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full "
                      required
                    />
                  </div>
                )}

                {isOtpVisible && (
                  <div className="mb-6 mt-6">
                    <label className="custom-div-bg" style={divStyle}>
                      Verify OTP
                    </label>
                    <input
                      type="number"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter OTP"
                      className="mt-1 px-4 py-2 border border-black bg-gray-100 b-rounded w-full "
                      required
                    />
                  </div>
                )}

                {isContinueVisible && (
                  <div className="flex justify-end">
                    <button
                      className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black"
                      onClick={handleContinueSignUp}
                    >
                      Continue
                    </button>
                  </div>
                )}

                {isSubmitVisible && (
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className=" bg-customGreen  hover:bg-customGreen bg-opacity-90 text-white px-4 py-2 rounded-none w-full border-1 border-black"
                    >
                      Sign Up
                    </button>
                  </div>
                )}

                <div className={divStyle2}>Already a user?</div>

                <button
                  className="bg-customWhite  hover:bg-gray-100 text-black px-4 py-2 border-2 border-black border-opacity-50 rounded-none w-full mt-5 mediumfont"
                  onClick={handleinup}
                >
                  Sign In
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInPanel;
